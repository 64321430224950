import { Box } from '@mui/material'
import { get } from 'lodash'
import React from 'react'
import CircularLoader from 'src/components/CircularLoader'
import { useFilter } from 'src/context/FilterContext'
import { AdsDetailsAggregate, DefaultService } from 'src/services/openApi'
import { formatNumber } from 'src/utils/functions'
import Ads from './Tabs/Ads'

export default function Metrics() {
  const { getFiltersV2, getFilters } = useFilter()
  // const [tabValue, setTabValue] = useTabUrlState( 'metrics_tab', 'ads' )
  const [adsData, setAdsData] = React.useState<AdsDetailsAggregate>(null)
  const [adsLoading, setAdsLoading] = React.useState(false)

  // const onTabChange = (value: string) => {
  //   setTabValue(value)
  // }

  const getAdsData = async () => {
    setAdsLoading(true)
    try {
      const res = await DefaultService.callAdsDetailsApiAdsDetailsPost(
        getFiltersV2(['vb_name', 'chain', 'b_name', 'vb_platform', 'am_name', 'start_date', 'end_date'], true)
      )
      setAdsData(res)
    } catch (err) {
      console.log('ads data error: ', err)
    }
    setAdsLoading(false)
  }

  React.useEffect(() => {
    getAdsData()
  }, [...getFilters(['vb_name', 'chain', 'b_name', 'vb_platform', 'am_name', 'start_date', 'end_date'])])

  if (adsData === null && adsLoading) {
    return <CircularLoader />
  }

  return (
    <Box>
      {/* <Stack direction="row" alignItems="center">
        <Tabs
          value={tabValue}
          onChange={(e, value) => {
            onTabChange(value)
          }}
        >
          <Tab
            value="ads"
            label="ADS"
            sx={{ borderBottom: '2px solid rgba(186, 189, 204, 1)' }}
          />
          <Tab
            value="promos"
            label="PROMOS"
            sx={{ borderBottom: '2px solid rgba(186, 189, 204, 1)' }}
          />
        </Tabs>
      </Stack> */}

      {/* <Box sx={{ mt: '13px' }}> */}
      {/* <TabPanel value={tabValue} index="ads"> */}
      <Ads
        clickToOrderRatio={formatNumber(get(adsData, 'clicks_to_order', 0), {
          maxFractionDigits: 1
        })}
        funnelChartData={[
          {
            label: 'Impressions',
            value: get(adsData, 'impressions', 0),
            fill: 'rgba(246, 140, 96, 1)'
          },
          // {
          //   label: 'Clicks',
          //   value: get(adsData, 'clicks', 0),
          //   fill: 'rgba(249, 199, 79, 0.5)'
          // },
          {
            label: 'Orders',
            value: get(adsData, 'orders', 0),
            fill: 'rgba(246, 236, 212, 1)'
          }
        ]}
      />
      {/* </TabPanel> */}
      {/* </Box> */}
    </Box>
  )
}
