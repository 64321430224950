import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import LinkIcon from '@mui/icons-material/Link'
import StarRoundedIcon from '@mui/icons-material/StarRounded'
import { Box, Button, ButtonBase, Chip, CircularProgress, Collapse, Divider, IconButton, Skeleton, Stack, TextField, Tooltip, Typography } from '@mui/material'
import moment from 'moment'
import React from 'react'
import PlatformLogo from 'src/components/PlatformLogo'
import StoreLogo from 'src/components/StoreLogo'
import { StyledBox } from 'src/components/mui/Reusable'
import { useSnackData } from 'src/context/SnackContext'
import { DefaultService, UberPromotionType } from 'src/services/openApi'

interface ReviewAndRatingCompProps {
  platform: string
  storeName: string
  reviewID: string
  storeID: string
  deliveryUUID: string
  rating: number
  days_left?: number
  status?: string
  date?: string | Date
  reviewText?: string
  templateName?: string
  replyText?: string
  loading?: boolean
  onOrderIdClick?: () => void
}

export default function ReviewAndRatingComp(props: ReviewAndRatingCompProps) {
  const { openError, openSuccess } = useSnackData()
  const [hideReply, setHideReply] = React.useState(true)
  const [replyTextValue, setReplyTextValue] = React.useState('')
  const [replyLoading, setReplyLoading] = React.useState(false)
  const [reply, setReply] = React.useState(props.replyText)
  const { loading, onOrderIdClick } = props
  const daysLeft = React.useMemo(() => {
    if (!props.days_left || props.days_left <= 0) {
      return false
    }
    return props.days_left
    // const days = Math.abs(moment(new Date()).diff(props.date, 'days'))
    // const daysLeft = thresholdDays - days
    // return daysLeft < 0 ? false : daysLeft
  }, [props.days_left])

  const isDoordash = React.useMemo(() => {
    return props.platform.toLowerCase() === 'doordash'
  }, [props.platform])

  const isGrubhub = React.useMemo(() => {
    return props.platform.toLowerCase() === 'grubhub'
  }, [props.platform])

  const disableSubmitReplyBtn = React.useMemo(() => {
    if (replyTextValue.length === 0 || replyLoading) {
      return true
    }
    if (isDoordash && replyTextValue.length > 300) {
      return true
    }
    return false
  }, [replyTextValue, replyLoading, isDoordash])

  const submitReply = async () => {
    if (replyTextValue.length === 0) {
      openError('Please type a reply')
      return
    }
    setReplyLoading(true)
    try {
      const promotion = props.platform.toLowerCase() === 'ubereats' ? UberPromotionType.NONE : 1
      const res = await DefaultService.reviewReplyReviewReplyPost(props.reviewID, {
        platform: props.platform as any,
        comment: replyTextValue,
        promotion
      })
      openSuccess('Reply added')
      const success = typeof res === 'string' && res.toLowerCase() === 'success' ? true : false
      if (success) {
        setReply(replyTextValue)
      } else {
        openError('Something went wrong')
      }
    } catch (err) {
      openError(err.message)
    }
    setReplyLoading(false)
  }

  return (
    <>
      <StyledBox sx={{ px: '22px', py: '17px', borderRadius: '4px' }}>
        <Stack
          direction="row"
          alignItems="center"
          gap="20px">
          <Box>
            {loading ? (
              <Skeleton
                variant="circular"
                width={23}
                height={23}
              />
            ) : (
              <PlatformLogo
                shortImg
                platformName={props.platform}
                height="23px"
                width="23px"
              />
            )}
          </Box>
          <Box sx={{ minWidth: '100px', maxWidth: '100px' }}>
            {loading ? (
              <Skeleton
                variant="text"
                height={20}
                width={100}
              />
            ) : (
              <Tooltip
                arrow
                placement="top"
                title={props.storeName}>
                <Typography
                  variant="subtitle2"
                  sx={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis'
                  }}>
                  {props.storeName}
                </Typography>
              </Tooltip>
            )}
          </Box>
          {loading ? (
            <Skeleton
              variant="text"
              height={20}
              width={100}
            />
          ) : (
            [
              { label: 'Store ID', value: props.storeID },
              { label: 'Order ID', value: props.deliveryUUID, onClick: onOrderIdClick }
            ].map((data, index) => {
              if (data.value) {
                return (
                  <Box
                    key={data.value}
                    component={data.onClick && ButtonBase}
                    onClick={data.onClick}
                    sx={{ minWidth: '100px', px: '3px', borderRadius: '3px', bgcolor: data.onClick ? '#E4E4E4' : undefined }}>
                    <Tooltip
                      arrow
                      placement="top"
                      title={
                        <Stack
                          direction="row"
                          alignItems="center"
                          gap="7px">
                          <Typography
                            variant="subtitle2"
                            color="white">
                            {data.value}
                          </Typography>
                          <IconButton
                            sx={{ color: 'white', fontSize: '16px' }}
                            onClick={() => {
                              navigator.clipboard.writeText(data.value)
                              openSuccess(data.label + ' copied to clipboard')
                            }}>
                            <LinkIcon
                              color="inherit"
                              fontSize="inherit"
                            />
                          </IconButton>
                        </Stack>
                      }>
                      <Stack
                        direction="row"
                        alignItems="center"
                        gap="5px"
                        sx={{ width: '100%' }}>
                        <Typography
                          variant="subtitle2"
                          sx={{
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis'
                          }}>
                          {data.label}: {data.value}
                        </Typography>
                        {!!data.onClick && <ChevronRightRoundedIcon sx={{ fontSize: '16px', color: '#000' }} />}
                      </Stack>
                    </Tooltip>
                  </Box>
                )
              }
            })
          )}

          <Stack
            direction="row"
            alignItems={'center'}
            gap="5px">
            {loading ? (
              <>
                {[1, 2, 3, 4, 5].map((elm) => (
                  <Skeleton
                    key={elm}
                    variant="circular"
                    width={20}
                  />
                ))}
              </>
            ) : (
              props.rating !== undefined &&
              props.rating !== null &&
              [1, 2, 3, 4, 5].map((star) => {
                const isStarActive = star <= props.rating
                return (
                  <Box
                    key={star}
                    sx={{
                      color: isStarActive ? 'rgba(246, 186, 96, 1)' : 'rgba(245, 245, 245, 1)',
                      fontSize: '20px'
                    }}>
                    <StarRoundedIcon
                      color="inherit"
                      fontSize="inherit"
                    />
                  </Box>
                )
              })
            )}
          </Stack>

          {loading ? (
            <Skeleton
              variant="text"
              height={20}
              width={200}
            />
          ) : (
            props.status && (
              <Box sx={{ minWidth: '106px', maxWidth: '115px' }}>
                <Chip
                  label={props.status}
                  size="small"
                />
              </Box>
            )
          )}
          {loading ? (
            <Skeleton
              variant="text"
              height={20}
              width={100}
            />
          ) : (
            props.date && (
              <Box>
                <Typography variant="subtitle2">{moment(props.date).format('DD MMM, YYYY')}</Typography>
              </Box>
            )
          )}
        </Stack>
        <Divider sx={{ mt: '10px' }} />
        {props.reviewText && (
          <Box sx={{ mt: '30px' }}>
            <Typography
              fontSize="16"
              lineHeight="20px"
              fontWeight={600}>
              {props.reviewText}
            </Typography>
          </Box>
        )}
        {loading ? (
          <Stack
            sx={{
              mt: '25px'
            }}>
            <Skeleton
              variant="text"
              height={20}
              width={100}
            />
          </Stack>
        ) : (
          <Box sx={{ mt: '25px' }}>
            <Stack
              direction="row"
              alignItems="center"
              gap="15px">
              {reply && (
                <ButtonBase
                  onClick={() => {
                    setHideReply((prev) => !prev)
                  }}>
                  <Stack
                    direction="row"
                    alignItems="center"
                    gap="10px">
                    <Typography variant="subtitle2">{hideReply ? 'View Reply' : 'Hide Reply'}</Typography>
                    <Box
                      sx={{
                        fontSize: '16px',
                        transformOrigin: 'center',
                        transform: `rotateZ(${hideReply ? 0 : 180}deg)`,
                        transition: '0.2s'
                      }}>
                      <KeyboardArrowDownIcon fontSize="inherit" />
                    </Box>
                  </Stack>
                </ButtonBase>
              )}
              {!reply && typeof daysLeft === 'number' && !isGrubhub && (
                <>
                  <ButtonBase
                    onClick={() => {
                      setHideReply((prev) => !prev)
                    }}>
                    <Box
                      sx={{
                        px: '20px',
                        py: '9px',
                        transition: '0.2s',
                        borderRadius: '4px',
                        bgcolor: 'rgba(236, 236, 236, 0.5)',
                        '&:hover': { bgcolor: 'rgba(236, 236, 236, 1)' }
                      }}>
                      <Typography
                        variant="subtitle2"
                        fontWeight={600}
                        lineHeight={'20px'}>
                        Reply
                      </Typography>
                    </Box>
                  </ButtonBase>
                  <Chip
                    label={`${daysLeft} days left`}
                    color="error"
                  />
                </>
              )}

              {!reply && typeof daysLeft !== 'number' && !isGrubhub && (
                <Typography
                  variant="subtitle2"
                  color="error">
                  {/* Reply days past {thresholdDays} day
                {thresholdDays > 1 ? 's' : ''} */}
                  Reply time expired
                </Typography>
              )}

              {!reply && isGrubhub && (
                <Typography
                  variant="subtitle2"
                  color="error">
                  Reply not available
                </Typography>
              )}

              {props.templateName && (
                <Box>
                  <Chip
                    label={props.templateName}
                    variant="outlined"
                  />
                </Box>
              )}
            </Stack>

            <Collapse in={!hideReply}>
              <Divider sx={{ my: '17px' }} />
              {reply && (
                <Box>
                  <Stack
                    direction="row"
                    alignItems="flex-start"
                    gap="20px">
                    <Box>
                      <StoreLogo
                        showAvatarOnly
                        avatarSize={{ width: '28px', height: '28px' }}
                      />
                    </Box>
                    <Box>
                      <Typography
                        fontSize="14px"
                        lineHeight="20px"
                        color="rgba(73, 73, 73, 0.5)">
                        {reply}
                      </Typography>
                    </Box>
                  </Stack>
                </Box>
              )}
              {!reply && (
                <Box>
                  <TextField
                    label="Reply text"
                    value={replyTextValue}
                    onChange={(e) => {
                      setReplyTextValue(e.target.value)
                    }}
                    multiline
                    minRows={4}
                    maxRows={4}
                  />
                  <Button
                    size="small"
                    disabled={disableSubmitReplyBtn}
                    sx={{ mt: '10px' }}
                    startIcon={
                      replyLoading && (
                        <CircularProgress
                          size="14px"
                          color="inherit"
                        />
                      )
                    }
                    onClick={() => {
                      submitReply()
                    }}>
                    Submit
                  </Button>
                </Box>
              )}
            </Collapse>
          </Box>
        )}
      </StyledBox>
    </>
  )
}
