import { Announcement, Close } from '@mui/icons-material'
import LinkIcon from '@mui/icons-material/Link'
import { Button, CircularProgress, Fade, IconButton, Modal, Paper, Stack, Tooltip, Typography, Unstable_TrapFocus } from '@mui/material'
import { Box } from '@mui/system'
import { addBreadcrumb, captureEvent } from '@sentry/browser'
import { get } from 'lodash'
import React, { useState } from 'react'
import checkDone from 'src/assets/images/check_done.svg'
import InfoIcon from 'src/components/Icon/InfoIcon'
import StyledTypo from 'src/components/StyledTypo'
import { useAuth } from 'src/context/AuthContext'
import { useBugReportContext } from 'src/context/BugReportContext'
import { useErrorData } from 'src/context/ErrorContext'
import { useFilter } from 'src/context/FilterContext'
import { addImageAsset } from 'src/utils/functions/fileUpload'
import ImageViewer from './components/ImageViewer'

const DialogBugReport = () => {
  const { bannerOpen, setBannerOpen } = useBugReportContext()
  const { getUrlWithFilters } = useFilter()
  const { handleError } = useErrorData()
  const { currentUser } = useAuth()
  const [openSuccess, setOpenSucess] = React.useState(false)
  const [loading, setLoading] = useState(false)
  const [openImage, setOpenImage] = useState(false)

  const handleClose = () => {
    setBannerOpen((prev) => ({
      ...prev,
      open: false
    }))

    // Adding delay because of fade in out effect
    setTimeout(() => {
      setOpenSucess(false)
      setBannerOpen({
        open: false,
        data: {
          message: '',
          image: null,
          imageLoading: false
        }
      })
    }, 500)
  }

  const handleSubmit = async () => {
    setLoading(true)
    setOpenSucess(true)

    try {
      const base64Image = get(bannerOpen, 'data.image', null)
      const fileName = `${currentUser?.email + '_' + currentUser?.org + '_' + new Date().valueOf()}`.replace(/[^a-zA-Z0-9-_@]/g, '')

      const image_source = await addImageAsset(
        {
          type: 'bugs/screenshots',
          label: 'Bug Screenshot',
          tags: ['bugs', 'photos']
        },
        base64Image,
        fileName
      )

      addBreadcrumb({
        category: 'image',
        data: {
          uri: image_source
        }
      })
      addBreadcrumb({
        category: 'url',
        data: {
          uri: getUrlWithFilters()
        }
      })

      captureEvent({
        message: `Bug Reported by ${currentUser?.email} for ${currentUser?.org}`,
        level: 'error',
        tags: {
          type: 'bug-report',
          org: currentUser?.org,
          email: currentUser?.email,
          name: currentUser?.name
        },

        extra: {
          message: bannerOpen?.data?.message,
          uri: image_source,
          url: getUrlWithFilters()
        }
      })
    } catch (err) {
      handleError(err)
    }

    setLoading(false)
  }

  const handleRefresh = () => {
    handleClose()
    window.location.reload()
  }

  return (
    <Unstable_TrapFocus open>
      <Fade
        appear={false}
        in={bannerOpen?.open}>
        <Paper
          onClick={(e) => e.stopPropagation()}
          sx={{
            position: 'fixed',
            bottom: 10,
            right: 10,
            zIndex: 1200,
            width: '700px',
            height: 350,
            m: 0,
            p: 2,
            borderRadius: '4px',
            border: '1px solid #E1DDDD',
            background: '#FAFAFA'
          }}>
          {!openSuccess ? (
            <Stack>
              <Stack
                direction={{ xs: 'column', sm: 'row' }}
                justifyContent="space-between"
                gap={2}>
                <Stack
                  direction={'row'}
                  gap={'8px'}>
                  <Announcement />
                  <Stack
                    direction={'row'}
                    alignItems={'flex-start'}
                    justifyContent={'center'}>
                    <Typography
                      fontWeight="600"
                      fontSize={'16px'}
                      color={'#000'}
                      lineHeight={'20px'}>
                      Report an Issue with the Current Page
                    </Typography>
                    <Tooltip
                      title="Copy URL with all the filters"
                      arrow
                      placement="right"
                      sx={{ fontSize: '1rem' }}>
                      <LinkIcon
                        sx={{ ml: '8px', pb: '4px', cursor: 'pointer' }}
                        onClick={() => {
                          navigator.clipboard.writeText(getUrlWithFilters())
                        }}
                      />
                    </Tooltip>
                  </Stack>
                </Stack>
                <IconButton
                  sx={{
                    position: 'absolute',
                    top: '5px',
                    right: '5px',
                    p: 1
                  }}
                  onClick={handleClose}>
                  <Close />
                </IconButton>
              </Stack>

              <Stack
                pt={'20px'}
                mb={'15px'}>
                <Typography
                  fontSize={'14px'}
                  sx={{
                    fontWeight: 400,
                    lineHeight: '20px'
                  }}
                  color={'rgba(0, 0, 0, 1)'}>
                  Sorry for the trouble! Briefly describe what happened:
                </Typography>
              </Stack>

              <Stack direction={'row'}>
                <Stack
                  sx={{
                    position: 'relative',
                    p: 0.5
                  }}>
                  <ImageViewer
                    onIconClick={() => setOpenImage(true)}
                    onClick={() => setOpenImage(true)}
                    image={get(bannerOpen, 'data.image', null)}
                    imageLoading={get(bannerOpen, 'data.imageLoading', false)}
                    height={'40px'}
                    width={'40px'}
                  />
                </Stack>
                <Stack
                  direction={'row'}
                  alignItems={'center'}
                  rowGap={2}
                  pl={1}>
                  <InfoIcon
                    sx={{
                      fontSize: '16px',
                      fill: '#122937'
                    }}
                  />
                  <Typography
                    fontSize={'12px'}
                    sx={{
                      fontWeight: 400,
                      lineHeight: '130%',
                      pl: '5px'
                    }}
                    color={'#858585'}>
                    A screenshot will be attached automatically.
                  </Typography>
                </Stack>
              </Stack>

              <textarea
                value={get(bannerOpen, 'data.message', '')}
                onChange={(e) => setBannerOpen((prev) => ({ ...prev, data: { ...prev?.data, message: e.target.value } }))}
                placeholder="Describe issue"
                rows={5}
                style={{
                  fontFamily: 'Sora',
                  width: '100%',
                  border: '1px solid #E6E6E6',
                  outline: 'none',
                  resize: 'none',
                  backgroundColor: '#FFF',
                  padding: '10px',
                  borderRadius: '4px'
                }}
              />

              <Stack
                mt={3}
                direction={'row'}
                justifyContent={'flex-start'}
                gap={2}>
                <Button
                  disabled={get(bannerOpen, 'data.imageLoading', false)}
                  size="small"
                  sx={{ px: '20px', py: '13px' }}
                  onClick={handleSubmit}>
                  SUBMIT
                </Button>
                <Button
                  variant={'text'}
                  size="small"
                  sx={{ px: '20px', py: '13px' }}
                  onClick={handleClose}>
                  CANCEL
                </Button>
              </Stack>
            </Stack>
          ) : (
            <Stack p={3}>
              <Stack
                direction={{ xs: 'column', sm: 'row' }}
                justifyContent="space-between"
                gap={2}>
                <IconButton
                  sx={{
                    position: 'absolute',
                    top: '5px',
                    right: '5px',
                    p: 1
                  }}
                  onClick={handleClose}>
                  <Close />
                </IconButton>
              </Stack>

              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '80px',
                  height: '80px'
                }}>
                <img
                  src={checkDone}
                  alt={'done-img'}
                  style={{ width: '100%' }}
                />
              </Box>

              <StyledTypo
                pt={'35px'}
                fontWeight={600}
                fontSize={'20px'}
                color={'#186174'}
                lineHeight={'20px'}>
                Thank You!
              </StyledTypo>
              <StyledTypo
                pt={'15px'}
                fontWeight={400}
                fontSize={'20px'}
                lineHeight={'30px'}
                color={'rgba(0, 0, 0, 0.5)'}>
                Thanks for taking the time to submit a report. While we strive to reply to every report, we'll let you know if we need more details.
              </StyledTypo>

              <Stack
                direction={'row'}
                alignItems={'center'}
                gap={2}>
                <Button
                  disabled={loading}
                  size="small"
                  sx={{ mt: '25px', px: '20px', py: '13px', minWidth: '150px' }}
                  onClick={handleRefresh}>
                  {loading ? (
                    <CircularProgress
                      size={20}
                      sx={{ color: '#fff' }}
                    />
                  ) : (
                    'REFRESH THE PAGE'
                  )}
                </Button>
                <Button
                  variant="text"
                  size="small"
                  sx={{ mt: '25px', px: '20px', py: '13px' }}
                  onClick={handleClose}>
                  CLOSE
                </Button>
              </Stack>
            </Stack>
          )}

          <Modal
            open={openImage}
            onClose={() => setOpenImage(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description">
            <Box
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)'
              }}>
              <ImageViewer
                onClick={() => {}}
                onIconClick={() => setOpenImage(false)}
                image={get(bannerOpen, 'data.image', null)}
                imageLoading={false}
                isOpened={true}
                height={'600px'}
                width={'100%'}
              />
            </Box>
          </Modal>
        </Paper>
      </Fade>
    </Unstable_TrapFocus>
  )
}

const DialogBugReporting = React.memo(DialogBugReport)
export default DialogBugReporting
