import { Box, Typography } from '@mui/material'

const ErrorRateMessage = (props) => {
  return (
    <Box
      sx={{
        border: '1px solid #E6E6E6',
        height: '66px',
        display: 'flex',
        alignItems: 'center',
        paddingLeft: '15px'
      }}
    >
      {props.type === 'positive' ? (
        <Typography sx={{ fontWeight: '600', fontSize: '14px' }}>
          Congratulations, you{' '}
          <span style={{ color: 'rgba(15, 120, 60, 1)', fontSize: '14.5px' }}>
            {props.category}
          </span>{' '}
          on the dates selected!
        </Typography>
      ) : props.type === 'negetive' ? (
        <Typography sx={{ fontWeight: '600', fontSize: '14px' }}>
          <span style={{ color: 'rgba(255, 0, 0, 1)', fontSize: '14.5px' }}>
            {props.category}
          </span>
          for selected dates. This needs attention!
        </Typography>
      ) : (
        <></>
      )}
    </Box>
  )
}

export default ErrorRateMessage
