import { Box, Divider, Skeleton, Typography } from '@mui/material'
import { get } from 'lodash'
import React, { useState } from 'react'
import LogosCombined from 'src/assets/images/short_logos_combined.svg'
import ExportComponent from 'src/components/Export/ExportComponent'
import { PlatformBreakdownSwitch } from 'src/components/Switches'
import { StyledBox } from 'src/components/mui/Reusable'
import { useErrorData } from 'src/context/ErrorContext'
import { useFilter } from 'src/context/FilterContext'
import { usePrivateLayoutDrawerContext } from 'src/context/PrivateLayoutDrawerContext'
import { DefaultService, ThirdPartyFeeDatewise, ThirdPartyFeeSummaryModel } from 'src/services/openApi'
import { formatCurrency } from 'src/utils/functions'
import dateWiseGroupData from 'src/utils/functions/groupingFunctions/dateWiseGroupData'
import DonutChart from '../../../components/DonutChart'
import StackedBarChart, { GraphDataType } from '../../../components/StackedBarChart'
import { indicatorColor } from '../../../utils/constants'
import { useTransactionsBreakdownDataContext } from '../../../utils/context/TransactionsBreakdownDataContext'
import '../../../utils/finance.css'
import TotalBreakdown from '../TotalBreakdown'
import PlatformBreakdown from './PlatformBreakdown'

interface DateCheckerProps {
  start_date_in?: string
  end_date_in?: string
  b_name_ids_in?: string
  chain_in?: string
}

export default function TransactionsChartView() {
  const { getFilters, getFiltersV2 } = useFilter()
  const { setOpen } = usePrivateLayoutDrawerContext()
  const { handleError } = useErrorData()
  const { breakdownData, breakdownLoading, platformBreakdownData, platformBreakdownLoading } = useTransactionsBreakdownDataContext()
  const [thirdPartyData, setThirdPartyData] = React.useState<ThirdPartyFeeSummaryModel | null>(null)
  const [thirdPartyDataLoading, setThirdPartyDataLoading] = React.useState(false)
  const [showPlatformBreakdown, setShowPlatformBreakdown] = useState<boolean>(false)
  const breakdownRef = React.useRef<HTMLElement>(null)
  const totalRef = React.useRef<HTMLElement>(null)

  const hideOthers = React.useMemo(() => {
    if (!thirdPartyData) {
      return false
    }
    return get(thirdPartyData, 'third_party_fee_aggregate.others', 0) <= 0
  }, [thirdPartyData])

  const barChartData = React.useMemo(() => {
    if (!thirdPartyData) {
      return []
    }
    const chartData = get(thirdPartyData, 'third_party_fee_datewise', [] as typeof thirdPartyData.third_party_fee_datewise)

    let arr:
      | ThirdPartyFeeDatewise[]
      | (Partial<ThirdPartyFeeDatewise> & {
          group: string
        })[] = dateWiseGroupData({
      type: chartData.length > 60 ? 'monthly' : chartData.length > 20 ? 'week' : 'daily',
      data: chartData,
      avgKeys: hideOthers ? ['chargeback_perc', 'commission_perc', 'marketing_perc'] : ['chargeback_perc', 'commission_perc', 'marketing_perc', 'others_perc']
    })

    const result: GraphDataType[] = arr.map((e: (typeof arr)[0] | any) => {
      const obj = {
        date: 'group' in e ? e.group : e.date,
        chargebacks: e.chargeback_perc,
        commissions: e.commission_perc,
        marketing: e.marketing_perc
      }
      return hideOthers
        ? obj
        : {
            ...obj,
            others: e.others_perc
          }
    })
    return result
  }, [thirdPartyData, hideOthers])

  const getData = async () => {
    setThirdPartyDataLoading(true)
    try {
      let res = await DefaultService.callThirdPartyFeeSummaryApiThirdPartyFeeSummaryPost({
        ...getFiltersV2(['b_name', 'vb_name', 'chain', 'vb_platform', 'am_name', 'start_date', 'end_date', 'granularity'], true)
      })
      setThirdPartyData(res)
    } catch (err) {
      setThirdPartyData(null)
      handleError(err.message)
    }
    setThirdPartyDataLoading(false)
  }
  React.useEffect(() => {
    getData()
  }, [...getFilters(['b_name', 'vb_name', 'chain', 'vb_platform', 'am_name', 'granularity', 'start_date', 'end_date'])])

  return (
    <>
      <Box
        sx={{ display: 'flex' }}
        className="breakdown-wrapper">
        <Box className={`breakdown-flex breakdown-left ${showPlatformBreakdown ? 'breakdown-left-shrink' : 'breakdown-left-expand'}`}>
          {!thirdPartyDataLoading && !thirdPartyData && (
            <StyledBox
              sx={{
                p: '30px',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}>
              <Typography
                variant="subtitle2"
                textAlign="center">
                Third Party Fee Breakdown Data not available
              </Typography>
            </StyledBox>
          )}
          {(thirdPartyDataLoading || thirdPartyData) && (
            <StyledBox
              sx={{
                p: '30px',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'stretch'
              }}>
              <Typography
                fontSize="14px"
                fontWeight={600}
                lineHeight={'20px'}
                color="#000">
                {thirdPartyDataLoading ? <Skeleton width="40%" /> : 'Breakdown'}
              </Typography>
              <Box sx={{ mt: '20px', width: '100%', height: '100%' }}>
                <DonutChart
                  threePFeePerc={get(thirdPartyData, 'third_party_fee_aggregate.third_party_fee_perc', undefined)}
                  data={[
                    {
                      label: 'Marketing',
                      value: get(thirdPartyData, 'third_party_fee_aggregate.marketing', 0),
                      color: indicatorColor.marketing,
                      textColor: '#FFF'
                      // percentage: get(
                      //   thirdPartyData,
                      //   'third_party_fee_aggregate.marketing_perc',
                      //   0
                      // )
                    },
                    {
                      label: 'Net Chargebacks',
                      value: get(thirdPartyData, 'third_party_fee_aggregate.chargeback', 0),
                      color: indicatorColor.chargebacks,
                      tooltipLabel: 'Net Chargebacks (Chargebacks - Reversals)',
                      textColor: '#FFF'
                      // percentage: get(
                      //   thirdPartyData,
                      //   'third_party_fee_aggregate.chargeback_perc',
                      //   0
                      // )
                    },
                    {
                      label: 'Commissions',
                      value: get(thirdPartyData, 'third_party_fee_aggregate.commission', 0),
                      color: indicatorColor.commissions,
                      textColor: '#016A70'
                      // percentage: get(
                      //   thirdPartyData,
                      //   'third_party_fee_aggregate.commission_perc',
                      //   0
                      // )
                    },
                    {
                      label: 'Others',
                      hide: hideOthers,
                      value: get(thirdPartyData, 'third_party_fee_aggregate.others', 0),
                      color: indicatorColor.others,
                      textColor: '#016A70'
                      // percentage: get(
                      //   thirdPartyData,
                      //   'third_party_fee_aggregate.others_perc',
                      //   0
                      // )
                    }
                  ]}
                  centerLabelFormatter={(label) => label}
                  centerValueFormatter={(value) =>
                    formatCurrency(value, {
                      maxFractionDigits: 2,
                      notation: 'compact'
                    })
                  }
                  loading={thirdPartyDataLoading}
                />
              </Box>

              <Box sx={{ mt: '15px', height: '100%' }}>
                <StackedBarChart
                  data={barChartData}
                  loading={thirdPartyDataLoading}
                />
              </Box>
            </StyledBox>
          )}
        </Box>
        <Box
          sx={{
            minHeight: '900px'
          }}
          className={`breakdown-flex breakdown-right ${showPlatformBreakdown ? 'breakdown-right-expand' : 'breakdown-right-shrink'}`}>
          <StyledBox sx={{ px: '30px', py: '30px' }}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              gap={4}>
              <Typography
                fontSize="14px"
                fontWeight={600}
                lineHeight={'20px'}
                color="rgba(18, 19, 26, 1)">
                This period's payout summary
              </Typography>

              <Box
                display="flex"
                alignItems="center"
                gap={1}>
                <ExportComponent
                  componentRef={showPlatformBreakdown ? breakdownRef : totalRef}
                  fileName={showPlatformBreakdown ? 'Finance Breakdown by Platform' : 'Finance Breakdown Total'}
                  hideMenuOptions={['email', 'pdf']}
                  buttonSx={{
                    height: '38px',
                    width: '38px'
                  }}
                  buttonLoading={showPlatformBreakdown ? platformBreakdownLoading : breakdownLoading}
                  onPreExport={() => {
                    if (showPlatformBreakdown) breakdownRef.current.style.display = 'flex'
                  }}
                  onPostExport={() => {
                    if (showPlatformBreakdown) breakdownRef.current.style.display = 'none'
                  }}
                />

                <Box
                  sx={{
                    px: '8px',
                    height: '38px',
                    display: 'inline-flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    cursor: 'pointer',
                    border: '1px solid #EDEDED',
                    borderRadius: '4px',
                    background: showPlatformBreakdown ? '#EDEDED' : '#FFF',
                    width: 'auto'
                  }}
                  onClick={() => {
                    setOpen(showPlatformBreakdown)
                    setShowPlatformBreakdown(!showPlatformBreakdown)
                  }}>
                  <img
                    src={LogosCombined}
                    alt="logos combined"
                    style={{ width: '45px' }}
                  />
                  <Typography
                    fontSize="12px"
                    fontWeight={600}
                    mx="5px"
                    noWrap>
                    Breakdown
                  </Typography>
                  <PlatformBreakdownSwitch checked={showPlatformBreakdown} />
                </Box>
              </Box>
            </Box>

            <Divider sx={{ mt: 2, mb: 1, borderColor: '#E6E6E6E6' }} />

            <Box>
              <div className={`${showPlatformBreakdown ? 'show-platform-breakdown' : 'hide-platform-breakdown'}`}>
                <PlatformBreakdown
                  platformBreakdownData={platformBreakdownData}
                  platformBreakdownLoading={platformBreakdownLoading}
                  breakdownData={breakdownData}
                  breakdownLoading={breakdownLoading}
                />
              </div>

              <div className="total-breakdown">
                <TotalBreakdown
                  ref={totalRef}
                  breakdownData={breakdownData}
                  breakdownLoading={breakdownLoading}
                />
              </div>
            </Box>
          </StyledBox>
        </Box>
      </Box>

      {/* This is just a clone of platform breakdown which is used to export the component. CSS animations were causing issues with html2canvas */}
      {!platformBreakdownLoading && !breakdownLoading && (
        <PlatformBreakdown
          ref={breakdownRef}
          platformBreakdownData={platformBreakdownData}
          platformBreakdownLoading={platformBreakdownLoading}
          breakdownData={breakdownData}
          breakdownLoading={breakdownLoading}
          sx={{ display: 'none' }}
        />
      )}
    </>
  )
}
