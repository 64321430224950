import SearchIcon from '@mui/icons-material/Search'
import { InputAdornment, SxProps, TextField } from '@mui/material'
import { Dispatch, SetStateAction } from 'react'

interface SearchBarProps {
  searchText: string
  setSearchText: Dispatch<SetStateAction<string>>
  label?: string
  sx?: SxProps
}

const SearchBar = (props: SearchBarProps) => {
  const { searchText, setSearchText, label, sx } = props

  return (
    <TextField
      value={searchText}
      onChange={(e) => {
        e.stopPropagation()
        setSearchText(e.target.value)
      }}
      onKeyDown={(e) => {
        e.stopPropagation()
      }}
      placeholder={`Search${label ? ` for ${label}` : ''}`}
      variant="standard"
      sx={{
        fontSize: '12px',
        '& .MuiInputBase-input': { padding: '11px 11px 11px 0px' },
        '& .MuiInputBase-root': {
          fontSize: '12px',
          paddingLeft: '13px',
          backgroundColor: 'rgba(217, 217, 217, 0.2)'
        },
        '& ::placeholder': { color: '#000', opacity: 0.5 },
        ...sx
      }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon sx={{ fontSize: 14, color: '#1C1B1F' }} />
          </InputAdornment>
        ),
        disableUnderline: true
      }}
    />
  )
}

export default SearchBar
