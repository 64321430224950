import { DateRangePicker } from '@LoopKitchen/loop-ui'
import moment, { Moment } from 'moment'
import React, { useEffect } from 'react'
import 'react-daterange-picker/dist/css/react-calendar.css'
import { useAuth } from 'src/context/AuthContext'
import { useNewFilterContext } from 'src/context/NewFilterContext/NewFilterContext'
import { shouldBlockForUser } from 'src/utils/functions/sandbox'
import { updateDateRangeFromLastDate } from '../utils/filterUtils'
import { SelectWrapperRef } from './SelectWrapper'

interface DateRangePickerCompProps {
  maxDate: Moment | null
  fullWidthFilters?: boolean
}

const DateRangePickerComp = React.forwardRef<SelectWrapperRef, DateRangePickerCompProps>((props: DateRangePickerCompProps, ref) => {
  const { maxDate, fullWidthFilters = false } = props
  const { dateRange, activeGraph, reRenderFilterContext } = useNewFilterContext()
  const { currentUser } = useAuth()

  useEffect(() => {
    updateDateRangeFromLastDate(activeGraph, maxDate)
  }, [maxDate?.format('YYYY-MM-DD'), activeGraph])

  return (
    <>
      <DateRangePicker
        minDate={shouldBlockForUser(currentUser) ? moment('2023-10-15') : null}
        maxDate={shouldBlockForUser(currentUser) ? moment('2024-01-15') : maxDate}
        value={dateRange}
        onChange={(newDateRange) => {
          activeGraph.setDateRange(newDateRange)
          reRenderFilterContext()
        }}
        rootButtonSx={{
          width: fullWidthFilters ? '100%' : 'auto',
          py: fullWidthFilters ? 1.5 : '12px',
          px: '8px',
          justifyContent: fullWidthFilters ? 'flex-start' : 'center',
          '& p': {
            lineHeight: '1 !important'
          },
          '& .MuiButton-endIcon': {
            fontSize: '12px',
            height: '12px',
            display: 'flex',
            alignItems: 'center',
            paddingRight: 0.5,
            flexGrow: fullWidthFilters ? 1 : 0,
            justifyContent: fullWidthFilters ? 'flex-end' : 'normal'
          }
        }}
        showLastWeekShortcut
        showMonthSelect
      />
    </>
  )
})

export default DateRangePickerComp
