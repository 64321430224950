import { Alert } from '@LoopKitchen/loop-ui'
import { Box, Button } from '@mui/material'
import { get } from 'lodash'
import React from 'react'
import Page from 'src/components/mui/Page'
import PlatformLogo from 'src/components/PlatformLogo'
import { useAuth } from 'src/context/AuthContext'
import { useFilter } from 'src/context/FilterContext'
import { useSnackData } from 'src/context/SnackContext'
import { DefaultService, StoreAvailabilityBnameConfigResponse, StoreAvailabilityService, UptimeByPlatformPaginated } from 'src/services/openApi'
import { AccessLevelEnum } from 'src/utils/config/config'
import { getAccessLevel } from 'src/utils/functions/accessLevel'
import GraphContainer from '../components/Graphs/GraphContainer'
import StoreAvailabilityConfigDrawer from '../components/StoreAvailabilityConfigDrawer'
import StoreRestoreSummary from '../components/StoreRestoreSummary'
import StoreRestoreTable from '../components/StoreRestoreTable'

export default function Overview() {
  // =============== Variables / States ===============
  const { lastDate, getAllPossibleFilters } = useFilter()
  const { currentUser } = useAuth()
  const { openError } = useSnackData()
  const { getFilters, getFiltersV2 } = useFilter()
  const [uptimeDataLoading, setUptimeDataLoading] = React.useState(false)
  const [uptimeData, setUptimeData] = React.useState<UptimeByPlatformPaginated | null>(null)
  const [selectedFilters, setSelectedFilters] = React.useState<string[]>([])
  const [openConfigureTurnOn, setOpenConfigureTurnOn] = React.useState<'prefetched' | 'fetch' | null>(null)
  const [configDataLoading, setConfigDataLoading] = React.useState<number[] | null>([-1])
  const [configData, setConfigData] = React.useState<StoreAvailabilityBnameConfigResponse[]>([])
  const filterArr = ['b_name', 'vb_name', 'vb_platform', 'am_name', 'start_date', 'end_date', 'chain']

  // =============== Functions ===============
  // -> Get uptime data for overview tab
  const getUptimeData = async () => {
    setUptimeDataLoading(true)
    try {
      const res = await DefaultService.callUptimeByPlatformApiPaginatedUptimeByPlatformPost(
        {
          ...getFiltersV2(['chain', 'start_date', 'end_date', 'b_name', 'vb_name', 'vb_platform', 'am_name'], true)
        },
        false
      )
      setUptimeData(res)
    } catch (err) {
      openError(err.message)
    }
    setUptimeDataLoading(false)
  }

  const fetchConfigData = async () => {
    setConfigDataLoading([-1])
    const b_name_ids = getFiltersV2(['b_name_id'])?.b_name_ids_in || get(getFiltersV2(['b_name_id']), 'b_name_id_in', '')

    try {
      const bNameIds: number[] =
        getAccessLevel(currentUser) === AccessLevelEnum.BUSINESS_ADMIN
          ? b_name_ids === ''
            ? []
            : b_name_ids?.split('|')?.map((id) => Number(id))
          : b_name_ids === ''
          ? (getAllPossibleFilters(['b_name_id'], 'main')['b_name_id'] as number[])
          : b_name_ids?.split('|')?.map((id) => Number(id))
      const response = await StoreAvailabilityService.getStoreAvailabilityBnameConfigApiStoreAvailabilityBnameConfigGetPost(
        get(currentUser, 'org', ''),
        bNameIds
      )
      setConfigData(response.filter((config) => config.b_name !== 'UNMAPPED'))
    } catch (error) {
      openError(error.message)
    } finally {
      setConfigDataLoading(null)
    }
  }

  // ============= Use Effects =============
  React.useEffect(() => {
    getUptimeData()
  }, [...getFilters(filterArr)])

  React.useEffect(() => {
    fetchConfigData()
  }, [...getFilters(['b_name', 'b_name_id']), currentUser])

  return (
    <Page
      title="Overview"
      copyUrl
      sx={{ bgcolor: 'white', py: 4, px: 3 }}>
      <StoreAvailabilityConfigDrawer
        isDrawerOpen={!!openConfigureTurnOn}
        handleCloseDrawer={() => setOpenConfigureTurnOn(null)}
        selectedConfigData={openConfigureTurnOn === 'prefetched' ? configData : undefined}
        selectedConfigDataLoading={openConfigureTurnOn === 'prefetched' ? configDataLoading : undefined}
      />

      {getAccessLevel(currentUser) === AccessLevelEnum.BUSINESS_ADMIN && (
        <Box
          display="flex"
          justifyContent="flex-end"
          mb={2}>
          <Button
            variant="text"
            onClick={() => setOpenConfigureTurnOn('fetch')}
            startIcon={
              <img
                src={'/images/ManufacturingIcon.svg'}
                width={'14px'}
              />
            }
            sx={{
              borderRadius: '2px',
              backgroundColor: '#f3f3f3',
              color: '#000',
              fontSize: '14px',
              fontWeight: 500,
              padding: '8px 10px',
              '& .MuiButton-startIcon': {
                marginLeft: 0
              },
              '&:hover': {
                backgroundColor: '#e8e8e8',
                color: '#000'
              }
            }}>
            Configurations
          </Button>
        </Box>
      )}

      <Box sx={{ mb: 6 }}>
        <Box sx={{ mb: '25px' }}>
          <GraphContainer
            uptimeData={uptimeData}
            uptimeDataLoading={uptimeDataLoading}
          />
        </Box>

        <Alert
          content="These metrics are only available for UberEats and Doordash"
          variant="warning"
          alertSx={{
            borderColor: '#E6E6E6',
            bgcolor: '#F9F9F9',
            py: 1.2,
            mt: 2
          }}
          action={
            <Box
              display="flex"
              alignItems="center"
              gap={1}>
              <PlatformLogo
                platformName="UberEats"
                height="24px"
                width="24px"
                boxSx={{
                  flex: 'inherit',
                  display: 'flex'
                }}
                shortImg
              />
              <PlatformLogo
                platformName="Doordash"
                height="24px"
                width="24px"
                boxSx={{
                  flex: 'inherit',
                  display: 'flex'
                }}
                shortImg
              />
            </Box>
          }
          actionSx={{
            pl: 2,
            cursor: 'default',
            display: 'flex',
            alignItems: 'center',
            py: 0,

            '&:hover': {
              bgcolor: 'inherit',
              boxShadow: 'none'
            },
            '& .MuiTouchRipple-root': {
              display: 'none'
            }
          }}
        />
        <StoreRestoreSummary
          onClickConfigure={() => {
            setOpenConfigureTurnOn('prefetched')
          }}
          selectedFilters={selectedFilters}
          onFilterClick={(dataType) => {
            setSelectedFilters((prev) => (prev.includes(dataType) ? prev.filter((item) => item !== dataType) : [...prev, dataType]))
          }}
          configData={configData}
        />
        <StoreRestoreTable
          selectedFilters={selectedFilters}
          setSelectedFilters={setSelectedFilters}
        />
      </Box>
    </Page>
  )
}
