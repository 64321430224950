import { collection, onSnapshot } from 'firebase/firestore'
import { get } from 'lodash'
import React from 'react'
import { db } from 'src/services/firebase'
import { AccessLevelEnum } from 'src/utils/config/config'
import { getAccessLevel } from 'src/utils/functions/accessLevel'
import { useAuth } from './AuthContext'
import { usePrivateLayoutDrawerContext } from './PrivateLayoutDrawerContext'

type WizardType = {
  key: string
  label: string
  hide: boolean
  steps: {
    label: string
    key: string
  }[]
}

interface WizardContextInterface {
  wizardConfig: {
    [key: string]: WizardType
  }
  filteredWizardConfig: {
    [key: string]: WizardType
  }
}

const WizardContext = React.createContext<WizardContextInterface>({
  wizardConfig: {},
  filteredWizardConfig: {}
})

export const useWizardContext = () => React.useContext(WizardContext)

interface WizardContextProviderProps {
  children: React.ReactNode
}

export default function WizardContextProvider(props: WizardContextProviderProps) {
  const { orgConfig, currentUser } = useAuth()
  const { privateRoutes } = usePrivateLayoutDrawerContext()
  const [mainWizardConfig, setMainWizardConfig] = React.useState<WizardContextInterface['wizardConfig']>({})

  const hasWizardRoute = React.useMemo(() => {
    return true // to be replaced with below code when there functionality of enabling a route without displaying to side bar

    // for (let i = 0; i < privateRoutes.length; i++) {
    //   const route = privateRoutes[i]
    //   const path: string = get(route, 'path', undefined)
    //   if (path && (path.startsWith('wizard') || path.startsWith('/wizard'))) {
    //     return true
    //   }
    // }
    // return false
  }, [privateRoutes])

  const wizardConfig = React.useMemo(() => {
    if (!hasWizardRoute || !mainWizardConfig || Object.keys(mainWizardConfig).length === 0) {
      return {}
    }
    let accessLevel = getAccessLevel(currentUser)
    let wizardKeys: string[] = get(
      orgConfig,
      `access_levels.${accessLevel !== AccessLevelEnum.DEMO ? accessLevel : AccessLevelEnum.BUSINESS_ADMIN}.wizards`,
      []
    )
    let obj: WizardContextInterface['wizardConfig'] = {}
    wizardKeys.forEach((key) => {
      if (mainWizardConfig[key]) {
        obj[key] = mainWizardConfig[key]
      }
    })
    let result = obj

    return result
  }, [orgConfig, currentUser, privateRoutes, mainWizardConfig])

  const filteredWizardConfig = React.useMemo(() => {
    let result: typeof wizardConfig = {}
    Object.keys(wizardConfig).forEach((key) => {
      let obj = wizardConfig[key]

      if (!obj.hide) {
        result = {
          ...result,
          [key]: obj
        }
      }
    })
    return result
  }, [wizardConfig])

  const contextValue = React.useMemo(() => {
    return {
      wizardConfig,
      filteredWizardConfig
    }
  }, [wizardConfig, filteredWizardConfig])

  React.useEffect(() => {
    let unsubscribe = onSnapshot(collection(db, 'wizard_config'), (snapshot) => {
      const data = snapshot.docs.map((e) => {
        return e.data()
      }) as WizardType[]
      const obj: WizardContextInterface['wizardConfig'] = {}

      data.forEach((item) => {
        obj[item.key] = { ...item }
      })
      setMainWizardConfig(obj)
    })
    return () => {
      unsubscribe()
    }
  }, [])

  return <WizardContext.Provider value={contextValue}>{props.children}</WizardContext.Provider>
}
