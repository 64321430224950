import { Box, Icon, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material'
import React from 'react'
import { NavLink } from 'react-router-dom'
import { useAuth } from 'src/context/AuthContext'
import PopupMenu from './PopupMenu'
import { activeColor, inactiveColor } from './SubNav'

type HomeLinkProps = {
  open: boolean
  closeDrawer: () => void
}

const HomePageLink = ({ open, closeDrawer }: HomeLinkProps) => {
  const { posthogCapture } = useAuth()
  const [anchorElement, setAnchorElement] = React.useState<HTMLElement>(null)

  return (
    <NavLink
      to={'/home'}
      onClick={() => {
        posthogCapture('sidebar_link_clicked', { to: '/home' })
        closeDrawer()
      }}
      style={{ textDecoration: 'none' }}>
      {({ isActive }) => {
        if (isActive) document.title = 'Loop - My Dashboard'
        return (
          <div
            onMouseLeave={() => {
              setAnchorElement(null)
            }}>
            <PopupMenu
              open={open}
              anchorElement={anchorElement}
              label="My Dashboard"
              isActive={isActive}
            />
            <ListItem
              disablePadding
              sx={{
                color: isActive ? activeColor : inactiveColor,
                '&.Mui-selected': { bgcolor: 'transparent' }
              }}>
              <ListItemButton
                selected={isActive}
                sx={{
                  justifyContent: open ? 'initial' : 'center',
                  py: '6px',
                  bgcolor: isActive ? 'rgba(25, 110, 130, 0.5)' : 'transparent',
                  '&:hover': { bgcolor: 'rgba(25, 110, 130, 0.3)' },
                  '& span': { fontWeight: isActive && 600 }
                }}
                onMouseEnter={(e) => {
                  setAnchorElement(e.currentTarget)
                }}>
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? '22px' : 'auto',
                    justifyContent: 'center',
                    color: 'inherit'
                  }}>
                  <Icon>house</Icon>
                </ListItemIcon>
                {open && (
                  <>
                    <ListItemText
                      primary="My Dashboard"
                      color="inherit"
                    />
                    <Box
                      border="1px solid #0e8c43"
                      borderRadius="4px"
                      py="3px"
                      px={1}
                      bgcolor="rgba(0, 255, 106, 0.36)">
                      <Typography
                        color="white"
                        fontSize={11}>
                        What's new
                      </Typography>
                    </Box>
                  </>
                )}
              </ListItemButton>
            </ListItem>
          </div>
        )
      }}
    </NavLink>
  )
}

export default HomePageLink
