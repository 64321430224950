import { Avatar, Tooltip } from '@mui/material'
import { get } from 'lodash'
import React from 'react'
import BytetoBiteImg from 'src/assets/logos/LOGOS/bytetobite_logo.png'
import CatapultLogoImg from 'src/assets/logos/LOGOS/catapult_logo.png'
import CheckersLogoImg from 'src/assets/logos/LOGOS/checkers_logo.png'
import CuminBowlLogoImg from 'src/assets/logos/LOGOS/cuminbowl_logo.png'
import CurryUpNowLogoImg from 'src/assets/logos/LOGOS/curry_up_now_logo.png'
import DavesLogoImg from 'src/assets/logos/LOGOS/daves.webp'
import HalalGuysLogoImg from 'src/assets/logos/LOGOS/halalguys_logo.png'
import MayaEatsLogoImg from 'src/assets/logos/LOGOS/mayaeats_logo.svg'
import MixtLogoImg from 'src/assets/logos/LOGOS/mixt_logo.png'
import MoLogoImg from 'src/assets/logos/LOGOS/mo_logo.png'
import OnDemandLogoImg from 'src/assets/logos/LOGOS/ondemand_logo.png'
import PopChewLogoImg from 'src/assets/logos/LOGOS/popchew_logo.png'
import ShekysLogoImg from 'src/assets/logos/LOGOS/shakeys_logo.svg'
import StarBirdLogoImg from 'src/assets/logos/LOGOS/starbird_logo.jpg'
import SurftacoLogoImg from 'src/assets/logos/LOGOS/surftaco.png'
import vdcLogoImg from 'src/assets/logos/LOGOS/vdcLogoImg.svg'
import WoodRanchLogoImg from 'src/assets/logos/LOGOS/woodranch_logo.png'
import { useAuth } from 'src/context/AuthContext'

const storeImages = {
  ondemand: OnDemandLogoImg,
  popchew: PopChewLogoImg,
  mixt: MixtLogoImg,
  catapult: CatapultLogoImg,
  checkers: CheckersLogoImg,
  cuminbowl: CuminBowlLogoImg,
  mayaeats: MayaEatsLogoImg,
  starbird: StarBirdLogoImg,
  halalva: HalalGuysLogoImg,
  halalway: HalalGuysLogoImg,
  woodranch: WoodRanchLogoImg,
  mo: MoLogoImg,
  vdc: vdcLogoImg,
  bytetobite: BytetoBiteImg,
  daves: DavesLogoImg,
  surftaco: SurftacoLogoImg,
  shakeys: ShekysLogoImg,
  curryupnow: CurryUpNowLogoImg
}

interface StoreLogoProps {
  org?: string
  showAvatarOnly?: boolean
  showName?: boolean
  avatarSize?: { width: string; height: string }
}

export default function StoreLogo(props: StoreLogoProps) {
  const { currentUser, orgConfig, isDemo } = useAuth()

  const { avatarHeight, avatarWidth } = React.useMemo(() => {
    let avatarWidth = get(props, 'avatarSize.width', 38)
    let avatarHeight = get(props, 'avatarSize.height', 38)
    return { avatarWidth, avatarHeight }
  }, [props.avatarSize])

  const org = React.useMemo(() => {
    if (isDemo) return 'Demo'
    if (props.org) {
      return props.org
    }
    return get(orgConfig, 'id', get(currentUser, 'org', '')) || ''
  }, [props.org])

  const orgName = React.useMemo(() => {
    if (isDemo) return 'Demo'
    return get(orgConfig, 'name', org)
  }, [orgConfig, org])

  return (
    <>
      <Tooltip
        title={orgName}
        arrow
        placement="top"
        sx={{ width: '100%' }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: '10px'
          }}>
          <Avatar
            sx={{
              bgcolor: '#f15412',
              width: avatarWidth,
              height: avatarHeight
            }}>
            {org.slice(0, 1).toUpperCase()}
          </Avatar>
          {props.showName && (
            <p
              style={{
                textTransform: 'capitalize',
                color: 'white',
                fontSize: '15px',
                lineHeight: '132%',
                wordBreak: 'break-word',
                flex: 1,
                margin: '0px',
                whiteSpace: 'normal'
              }}>
              {orgName}
            </p>
          )}
        </div>
        {/* {props.showAvatarOnly || !(org.toLowerCase() in storeImages) ? (
        ) : (
          <div
            style={{
              // filter: 'drop-shadow(0px 0px 5px white)',
              display: 'flex'
            }}>
            <img
              src={storeImages[org.toLowerCase()]}
              alt={org.toUpperCase()}
              style={{ height: 45, maxWidth: '178px', objectFit: 'contain' }}
            />
          </div>
        )} */}
      </Tooltip>
    </>
  )
}
