import React from 'react'
import { useErrorData } from 'src/context/ErrorContext'
import { DefaultService, DisputeReasonConfigRow } from 'src/services/openApi'

export type ReasonContextType = DisputeReasonConfigRow[]

const ReasonContext = React.createContext<ReasonContextType>({} as ReasonContextType)
export const useReasonContext = () => React.useContext(ReasonContext)

interface ReasonContextProviderProps {
  children: JSX.Element[]
}

export default function ReasonContextProvider(props: ReasonContextProviderProps) {
  const [reasons, setReasons] = React.useState<DisputeReasonConfigRow[]>([])
  const { handleError } = useErrorData()
  React.useEffect(() => {
    const getDisputeReasons = async () => {
      try {
        const result = await DefaultService.getDisputeReasonsDisputeReasonsGet()
        setReasons(result)
      } catch (error) {
        handleError(error.message)
      }
    }
    getDisputeReasons()
  }, [])
  return <ReasonContext.Provider value={reasons}>{props.children}</ReasonContext.Provider>
}
