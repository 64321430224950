import { CsvBuilder } from 'filefy'
import forEach from 'lodash/forEach'
import get from 'lodash/get'
import uniqBy from 'lodash/uniqBy'
import moment from 'moment'
import { DefaultService, ErrorHourWeek, PLATFORM } from 'src/services/openApi'
import { Api } from 'src/utils/apiV2/Api'


// src/utils/loadCSS.js
export const loadCSS = (href) => {
  return new Promise<void>((resolve, reject) => {
    const link = document.createElement('link');
    link.rel = 'stylesheet';
    link.href = href;
    link.onload = () => resolve();
    link.onerror = () => reject(new Error(`Failed to load CSS: ${href}`));
    document.head.appendChild(link);
  });
};


interface ChartDataType {
  name: string
  data: number[]
  color?: string
}
interface HourType {
  hours: number
  dayofweek: number
  Count_sum: number
}
const {
  run: { runProcedureAndGetDataRunQueryV2Post }
} = new Api()
export function getChartFormattedData(chartResponse: ErrorHourWeek[], color: string) {
  const chartData: ChartDataType[] = [...Array(24).keys()].map((key) => ({
    name: `${key < 10 ? '0' + key.toString() : key.toString()}:00`,
    data: [0, 0, 0, 0, 0, 0, 0],
    color
  }))

  const getTime = (item: { name: string }) => parseInt(item.name.split(':')[0])

  function getChartObj(time: number) {
    let position = 0
    chartData.forEach((item, index) => {
      try {
        const time1 = getTime(item)
        if (index !== chartData.length - 1 && time1 <= time && getTime(chartData[index + 1]) > time) {
          position = index
        }
        if (index === chartData.length - 1 && time1 <= time) {
          position = index
        }
      } catch (error) {
        console.log(error.message)
      }
    })
    return position
  }
  forEach(chartResponse, (item: ErrorHourWeek) => {
    const timeIndex = getChartObj(item.hour)
    const chart = chartData[timeIndex]
    chart.data[item.dayofweek - 1] = chart.data[item.dayofweek - 1] + item.Count_sum
    chart.color = color
  })
  return chartData
}

export const getDisplayableName = (impact_name: string) => {
  const namesArr = impact_name.split('_')
  let name = ''
  for (let i = 0; i < namesArr.length; i++) {
    name += namesArr[i][0].toUpperCase() + namesArr[i].substring(1) + ' '
  }
  return name
}

export function getDateRange(dateRange) {
  const startDate = dateRange ? dateRange.start.format('MM-DD-YYYY') : ''
  const endDate = dateRange ? dateRange.end.format('MM-DD-YYYY') : ''
  const rangeValue = startDate && endDate ? `${startDate} to ${endDate}` : ''
  return rangeValue
}

export function formatCurrency(amount: number, obj?: { maxFractionDigits?: number; notation?: 'compact' }) {
  const formatting_options = {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: typeof obj?.maxFractionDigits === 'number' ? obj?.maxFractionDigits : 2,
    notation: obj?.notation
  }
  const dollarString = new Intl.NumberFormat('en-US', formatting_options)
  return dollarString.format(amount)
}
export function formatNumber(n: number, obj?: { maxFractionDigits?: number; notation?: 'compact' }) {
  return new Intl.NumberFormat('en-US', {
    maximumFractionDigits: typeof obj?.maxFractionDigits === 'number' ? obj?.maxFractionDigits : 2,
    notation: obj?.notation
  }).format(n)
}

export async function getCategory(reason: string, getFilters: any, reasonCategory?: boolean) {
  try {
    const links = {
      InAccurate: 'inaccurate-orders',
      Cancelled: 'cancelled-orders',
      Missed: 'missed-orders'
    }
    const response = await DefaultService.runProcedureAndGetDataRunQueryPost(
      'data_visualization_metrics.procedure_category_sub_catergory_reasons_actions',
      getFilters([
        'b_name',
        'vb_name',
        'chain',
        'platform',
        'am_name',
        `_${reasonCategory ? reason : ''}`,
        `_${!reasonCategory ? reason : ''}`,
        'start_date',
        'end_date'
      ])
    )
    if (response && response.length > 0) {
      return links[response[0].error_category]
    } else {
      return 'overview'
    }
  } catch (error: any) {
    console.log(error.message)
  }
}

export async function getCategoryV2(reason: string, getFilters: any, field: string) {
  try {
    const links = {
      InAccurate: 'inaccurate-orders',
      Cancelled: 'cancelled-orders',
      Missed: 'missed-orders'
    }
    const response = await runProcedureAndGetDataRunQueryV2Post(
      { procedure_name: 'procedure_category_sub_catergory_reasons_actions' },
      getFilters(['b_name', 'vb_name', 'chain', 'platform', 'am_name', `#${field}#${reason}`, 'start_date', 'end_date']),
      {}
    )
    if (response.status === 200 && response.data.length > 0) {
      return links[response.data[0].error_category]
    } else {
      return 'overview'
    }
  } catch (error: any) {
    console.log(error.message)
  }
}

export const dollarFormatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 0 })

export function getPositiveValue(num: number) {
  if (num > 0) return num
  else if (num <= 0) return 0 - num
}

export function calculateDelta(currentValue: number, prevValue: number) {
  if (prevValue === 0) return 0
  const result = ((currentValue - prevValue) * 100) / prevValue
  return parseFloat(result.toFixed(2))
}

export function exportCsv(columns, data, title: string, dateFormat?: string) {
  const newColumns = columns.filter((item) => get(item, 'hidden', false) === false)
  const csvData = data.map((rowData) => {
    return newColumns.map((item) => {
      if (item.type === 'date' || item.type === 'datetime') return moment(rowData[item.field]).format(dateFormat || 'DD-MM-YY')
      if (item.type === 'currency') return formatCurrency(parseInt(rowData[item.field]))
      return rowData[item.field]
    })
  })
  const builder = new CsvBuilder(`${title}.csv`)
    .setColumns(newColumns.map((item) => item.title))
    .addRows(csvData)
    .exportFile()

  return builder
}

export function downloadCsv(filename: string, csvData: any) {
  const blob = new Blob([csvData], { type: 'text/csv' })
  const url = URL.createObjectURL(blob)
  const a = document.createElement('a')
  a.href = url
  a.download = filename
  a.click()
}

export function getShortOrderId(platform: string, orderId: string) {
  if (platform?.toLowerCase() === PLATFORM?.DOORDASH?.toLowerCase()) {
    return orderId?.toLowerCase()?.slice(-8)
  } else if (platform?.toLowerCase() === PLATFORM?.UBER_EATS?.toLowerCase()) {
    return orderId?.toUpperCase()?.slice(-5)
  } else {
    return orderId
  }
}

export function getAccessRoutes(accessLevel: string, orgConfig) {
  let accessRoutes = []
  if (typeof accessLevel === 'string') {
    accessRoutes = uniqBy(accessRoutes.concat(get(orgConfig, `accessConfig.${accessLevel}.navConfig`, [])), 'key')
  } else if (typeof accessLevel === 'object' && Array.isArray(accessLevel)) {
    ;(accessLevel as string[]).forEach((item) => {
      const route = get(orgConfig, `accessConfig.${item}.navConfig`, [])
      accessRoutes = uniqBy(accessRoutes.concat(route), 'key')
    })
  }
  return accessRoutes
}

export const getRandomUserAvatarColor = (i?: number | string) => {
  const userAvatarColors = ['#95A2B3', '#717F87', '#FF5454', '#3FBC75']
  const getIndexFromString = (str: string) => {
    let hash = 0
    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash)
    }

    const index = Math.abs(hash)
    return index
  }
  const index =
    typeof i === 'number'
      ? i % userAvatarColors.length
      : typeof i === 'string'
      ? getIndexFromString(i) % userAvatarColors.length
      : Math.floor(Math.random() * userAvatarColors.length)
  return userAvatarColors[index]
}

export function getLast30DaysStartAndEndDates(): { start: string; end: string } {
  const start_date = moment().subtract(30, 'd').format('YYYY-MM-DD')
  const end_date = moment().format('YYYY-MM-DD')
  return {
    start: start_date,
    end: end_date
  }
}

export function createMagicLinkFromToken(magicToken: string) {
  const url = new URL(window.location.origin)
  url.searchParams.set('magic_token', magicToken)
  return url
}

export async function sendMagicLinkToEmail(email: string) {
  await DefaultService.userSendMagicLinkUserSendMagicLinkPost(email)
}
