import { get } from 'lodash'
import { formatNumber } from 'src/utils/functions'

interface RatingStyledCompProps {
  data: object
}

export default function RatingStyledComp(props: RatingStyledCompProps) {
  const { data } = props
  const ratingNumerator = get(data, 'rating_numerator', 0)
  const ratingDenominator = get(data, 'rating_denominator', 1) || 1
  return <p style={{ textAlign: 'right' }}>{formatNumber(ratingNumerator / ratingDenominator, { maxFractionDigits: 1 })}</p>
}
