import { Breadcrumbs, ButtonBase, Typography } from '@mui/material'
import { Link } from 'react-router-dom'
import { BreadcrumbsQueueType } from 'src/types/BreadcrumbsQueueType'

interface BreadcrumbsCompProps {
  breadcrumbArr: BreadcrumbsQueueType[]
}

export default function BreadcrumbsComp(props: BreadcrumbsCompProps) {
  const { breadcrumbArr } = props
  return (
    <Breadcrumbs>
      {breadcrumbArr.map((obj) => {
        const typo = (
          <Typography
            fontSize="14px"
            color="inherit"
            fontWeight="inherit">
            {obj.label}
          </Typography>
        )
        if (obj.route) {
          return (
            <Link
              key={obj.label}
              to={obj.route}
              style={{ fontWeight: 600, color: '#196E82' }}>
              {typo}
            </Link>
          )
        } else if (obj.onClick) {
          return (
            <ButtonBase
              onClick={obj.onClick}
              sx={{ fontWeight: 600, color: '#196E82', '&:hover': { textDecoration: 'underline' } }}>
              {typo}
            </ButtonBase>
          )
        }
        return typo
      })}
    </Breadcrumbs>
  )
}
