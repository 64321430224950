import { Alert, IosSwitch, PaginationTableComp } from '@LoopKitchen/loop-ui'
import { NotificationsNone, NotificationsPaused, SettingsBackupRestore } from '@mui/icons-material'
import InfoIcon from '@mui/icons-material/Info'
import { Box, Checkbox, CircularProgress, Stack, Tooltip, Typography } from '@mui/material'
import { get } from 'lodash'
import moment from 'moment'
import React from 'react'
import SearchBar from 'src/components/Inputs/SearchBar'
import SlackIntegrationButton from 'src/components/SlackIntegrationButton/SlackIntegrationButton'
import { useAuth } from 'src/context/AuthContext'
import { useErrorData } from 'src/context/ErrorContext'
import { useNewFilterContext } from 'src/context/NewFilterContext/NewFilterContext'
import { useSnackData } from 'src/context/SnackContext'
import { StoreAvailabilityBnameConfigRequest, StoreAvailabilityBnameConfigResponse, StoreAvailabilityService } from 'src/services/openApi'
import { sendSlackNotification } from 'src/utils/api'
import { AccessLevelEnum, Modules, filterNames } from 'src/utils/config/config'
import { getAccessLevel } from 'src/utils/functions/accessLevel'
import { pluralize } from 'src/utils/functions/pluralize'
import SnoozeTill from './SnoozeTill'
import StoreAvailabilityBnameConfig from './StoreAvailabilityBnameConfig'

type NoFetchDataProps = {
  selectedConfigData?: undefined
  selectedConfigDataLoading?: undefined
}

type FetchDataProps = {
  selectedConfigData: StoreAvailabilityBnameConfigResponse[]
  selectedConfigDataLoading: number[] | null
}

type StoreConfigurationCompProps = NoFetchDataProps | FetchDataProps

const StoreConfigurationComp = ({ selectedConfigData, selectedConfigDataLoading }: StoreConfigurationCompProps) => {
  const { getAllPossibleFilters } = useNewFilterContext()
  const { currentUser } = useAuth()
  const { handleError } = useErrorData()
  const { openSuccess } = useSnackData()
  const [selectedConfigs, setSelectedConfigs] = React.useState<number[]>([])
  const [configDataLoading, setConfigDataLoading] = React.useState<number[] | null>([-1])
  const [configData, setConfigData] = React.useState<StoreAvailabilityBnameConfigResponse[]>([])
  const [paginatedPageSize, setPaginatedPageSize] = React.useState<{ startIndex: number; endIndex: number }>({
    startIndex: 0,
    endIndex: 0
  })
  const [autoTurnOnAllLoading, setAutoTurnOnAllLoading] = React.useState<boolean>(false)
  const [searchText, setSearchText] = React.useState<string>('')

  const fetchConfigData = async () => {
    setConfigDataLoading([-1])
    try {
      const bNameIds: number[] =
        getAccessLevel(currentUser) === AccessLevelEnum.BUSINESS_ADMIN ? [] : (getAllPossibleFilters(['b_name_id'], 'main')['b_name_id'] as number[])

      const response = await StoreAvailabilityService.getStoreAvailabilityBnameConfigApiStoreAvailabilityBnameConfigGetPost(
        get(currentUser, 'org', ''),
        bNameIds
      )
      setConfigData(response.filter((config) => config.b_name !== 'UNMAPPED'))
    } catch (error) {
      handleError(error.message)
    } finally {
      setConfigDataLoading(null)
    }
  }

  const handleSingleConfigChange = async (config: StoreAvailabilityBnameConfigRequest) => {
    setConfigDataLoading([...(configDataLoading || []), config.b_name_id])
    try {
      const response = await StoreAvailabilityService.setStoreAvailabilityBnameConfigApiStoreAvailabilityBnameConfigSetPost(get(currentUser, 'org', ''), [
        config
      ])
      setConfigData([...configData.filter((c) => c.b_name_id !== config.b_name_id), ...response])
      openSuccess(`Configurations updated successfully for ${response[0].b_name}`)

      const slackMsg = {
        title:
          `User: \`<https://us.posthog.com/project/26198/person/${encodeURIComponent(currentUser.email)}|${currentUser.email}>\`\n` +
          `Org: \`${currentUser?.org}\`\n` +
          `Access Level: \`${getAccessLevel(currentUser)}\`\n` +
          `BName: \`${response[0].b_name}\`\n` +
          `Auto Turn On: \`${'auto_turn_on' in config ? config.auto_turn_on : 'no change'}\`\n` +
          `Notify Enabled: \`${'notify_enabled' in config ? config.notify_enabled : 'no change'}\`\n` +
          `Snooze Till: \`${
            'snooze_till' in config ? (response[0].snooze_till ? moment(response[0].snooze_till).format('YYYY-MM-DD hh:mm A') + ' UTC' : 'none') : 'no change'
          }\``,
        message: `${Modules.StoreAvailability} Configuration updated for single bname`,
        channel: 'fe-logs'
      }
      sendSlackNotification(slackMsg)
    } catch (error) {
      handleError(error.message)
    } finally {
      setConfigDataLoading(null)
    }
  }

  const handleBulkConfigChange = async (config: StoreAvailabilityBnameConfigRequest, bNameIds?: number[]) => {
    const bNameIdList = bNameIds || selectedConfigs
    setConfigDataLoading([...(configDataLoading || []), ...bNameIdList])
    try {
      const response = await StoreAvailabilityService.setStoreAvailabilityBnameConfigApiStoreAvailabilityBnameConfigSetPost(
        get(currentUser, 'org', ''),
        bNameIdList.map((bNameId) => ({
          ...config,
          b_name_id: bNameId
        }))
      )
      setConfigData([...configData.filter((c) => !bNameIdList.includes(c.b_name_id)), ...response])
      openSuccess(`Configurations updated successfully for ${bNameIdList.length} ${pluralize(filterNames.b_name)}`)
      setSelectedConfigs([])

      const slackMsg = {
        title:
          `User: \`<https://us.posthog.com/project/26198/person/${encodeURIComponent(currentUser.email)}|${currentUser.email}>\`\n` +
          `Org: \`${currentUser?.org}\`\n` +
          `Access Level: \`${getAccessLevel(currentUser)}\`\n` +
          `BNames: \`${response.map((config) => config.b_name).join(', ')}\`\n` +
          `Auto Turn On: \`${'auto_turn_on' in config ? config.auto_turn_on : 'no change'}\`\n` +
          `Notify Enabled: \`${'notify_enabled' in config ? config.notify_enabled : 'no change'}\`\n` +
          `Snooze Till: \`${
            'snooze_till' in config ? (response[0].snooze_till ? moment(response[0].snooze_till).format('YYYY-MM-DD hh:mm A') + ' UTC' : 'none') : 'no change'
          }\``,
        message: `${Modules.StoreAvailability} Configuration updated for ${bNameIdList.length} bnames`,
        channel: 'fe-logs'
      }
      sendSlackNotification(slackMsg)
    } catch (error) {
      handleError(error.message)
    } finally {
      setConfigDataLoading(null)
    }
  }

  const handleAllLocationsEnable = async () => {
    setAutoTurnOnAllLoading(true)
    const bNameIds: number[] = configData.map((config) => config.b_name_id)
    await handleBulkConfigChange(
      {
        b_name_id: -1,
        auto_turn_on: true,
        notify_enabled: true
      },
      bNameIds
    )
    setAutoTurnOnAllLoading(false)
  }

  const handleSelectBnameConfig = (bNameId: number, selected: boolean) => {
    if (selected) {
      setSelectedConfigs([...selectedConfigs, bNameId])
    } else {
      setSelectedConfigs(selectedConfigs.filter((id) => id !== bNameId))
    }
  }

  const noLocationsEnabled = React.useMemo(() => {
    return configData.filter((config) => config.auto_turn_on).length === 0
  }, [configData])

  const filteredConfigData = React.useMemo(() => {
    return configData
      .filter((config) => config.b_name.toLowerCase() !== 'unmapped' && config.b_name.toLowerCase().includes(searchText.toLowerCase()))
      .sort((a, b) => a.b_name.localeCompare(b.b_name))
  }, [configData, searchText])

  React.useEffect(() => {
    if (!selectedConfigData) {
      fetchConfigData()
    } else {
      setConfigData(selectedConfigData)
      setConfigDataLoading(selectedConfigDataLoading)
    }
  }, [selectedConfigData])

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          gap: '12px',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: '10px 16px',
          backgroundColor: 'rgba(250, 250, 250, 0.98)',
          border: 'solid 1px #eee',
          borderRadius: '4px'
        }}>
        <Typography
          fontSize={14}
          color={'#000'}>
          Enhance your team's response time. Integrate with Slack now!
        </Typography>
        <SlackIntegrationButton />
      </Box>
      {!configDataLoading && noLocationsEnabled ? (
        <Box
          sx={{
            display: 'flex',
            gap: '40px',
            alignItems: 'center',
            mb: '36px'
          }}>
          <Box
            sx={{
              display: 'flex',
              gap: '14px',
              alignItems: 'center'
            }}>
            {autoTurnOnAllLoading ? (
              <CircularProgress size={'20px'} />
            ) : (
              <IosSwitch
                checked={false}
                onChange={() => {
                  handleAllLocationsEnable()
                }}
                sx={{ height: '18px', width: '34px', '& .MuiSwitch-thumb': { height: '14px', width: '14px' } }}
              />
            )}
            <Typography
              fontSize={'16px'}
              fontWeight={600}>
              Store Restore
            </Typography>
          </Box>
          <Alert
            content={`This will opt all ${pluralize(filterNames.b_name)} for store restore, and every other new onboarded location in the future`}
            variant="warning"
          />
        </Box>
      ) : (
        <Box>
          <table
            style={{
              width: '100%',
              borderCollapse: 'collapse'
            }}>
            <tr>
              <td
                style={{
                  padding: '12px 16px',
                  width: '50%'
                }}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                  <Checkbox
                    disabled={configDataLoading !== null}
                    checked={selectedConfigs.length === configData.length}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setSelectedConfigs(configData.map((config) => config.b_name_id))
                      } else {
                        setSelectedConfigs([])
                      }
                    }}
                    sx={{
                      '& .MuiSvgIcon-root': { fontSize: 16 },
                      color: 'rgba(230, 230, 230, 0.90)'
                    }}
                  />
                  <Box sx={{ flex: 1 }}>
                    <SearchBar
                      searchText={searchText}
                      setSearchText={setSearchText}
                      label={filterNames.b_name}
                      sx={{
                        width: '100%',
                        '& .MuiInput-root': {
                          borderRadius: '4px'
                        }
                      }}
                    />
                  </Box>
                </Box>
              </td>

              <td
                style={{
                  padding: '12px 16px'
                }}>
                {selectedConfigs.length > 0 ? (
                  <IosSwitch
                    checked={selectedConfigs.every((bNameId) => configData.find((config) => config.b_name_id === bNameId)?.auto_turn_on)}
                    onChange={(e) => {
                      handleBulkConfigChange({
                        b_name_id: 0, // will get overwritten
                        auto_turn_on: e.target.checked
                      })
                    }}
                    sx={{ height: '18px', width: '34px', mb: 1, '& .MuiSwitch-thumb': { height: '14px', width: '14px' } }}
                  />
                ) : (
                  <Box
                    sx={{
                      fontSize: '18px'
                    }}>
                    <SettingsBackupRestore fontSize="inherit" />
                  </Box>
                )}
                <Typography
                  fontSize={12}
                  color={'#8A8A8A'}
                  noWrap>
                  Store Restore
                </Typography>
              </td>
              <td
                style={{
                  padding: '12px 16px'
                }}>
                {selectedConfigs.length > 0 ? (
                  <IosSwitch
                    checked={selectedConfigs.every((bNameId) => configData.find((config) => config.b_name_id === bNameId)?.notify_enabled)}
                    onChange={(e) => {
                      handleBulkConfigChange({
                        b_name_id: 0, // will get overwritten
                        notify_enabled: e.target.checked
                      })
                    }}
                    sx={{ height: '18px', width: '34px', mb: 1, '& .MuiSwitch-thumb': { height: '14px', width: '14px' } }}
                  />
                ) : (
                  <Box
                    sx={{
                      fontSize: '18px'
                    }}>
                    <NotificationsNone fontSize="inherit" />
                  </Box>
                )}
                <Typography
                  fontSize={12}
                  color={'#8A8A8A'}
                  noWrap>
                  Notification
                </Typography>
              </td>
              <td
                style={{
                  padding: '12px 16px',
                  maxWidth: '200px'
                }}>
                {selectedConfigs.length > 0 ? (
                  <SnoozeTill
                    formattedSnoozeTill={null}
                    handleSnoozeTill={(snoozeHours) => {
                      handleBulkConfigChange({
                        b_name_id: 0, // will get overwritten
                        snooze_till: snoozeHours
                      })
                    }}
                    loading={false}
                  />
                ) : (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: 1
                    }}>
                    <NotificationsPaused fontSize="small" />
                    <Typography
                      fontSize={12}
                      color={'#000'}>
                      Snooze
                    </Typography>
                    <Tooltip
                      title="This will disable both store restore attempts and notifications for the specified duration, starting now"
                      placement={'top'}
                      arrow>
                      <InfoIcon
                        sx={{
                          fontSize: '14px',
                          color: '#888'
                        }}
                      />
                    </Tooltip>
                  </Box>
                )}
              </td>
            </tr>
            {configDataLoading && configDataLoading.includes(-1)
              ? Array.from({ length: 10 }).map((_, index) => (
                  <StoreAvailabilityBnameConfig
                    key={index}
                    config={{ b_name_id: -1 } as StoreAvailabilityBnameConfigResponse}
                    loading={configDataLoading}
                    checked={false}
                    handleSingleConfigChange={handleSingleConfigChange}
                    handleSelectBnameConfig={handleSelectBnameConfig}
                  />
                ))
              : filteredConfigData.slice(paginatedPageSize.startIndex, paginatedPageSize.endIndex)?.map((config) => (
                  <StoreAvailabilityBnameConfig
                    key={config.b_name_id}
                    config={config}
                    loading={configDataLoading}
                    checked={selectedConfigs.includes(config.b_name_id)}
                    handleSingleConfigChange={handleSingleConfigChange}
                    handleSelectBnameConfig={handleSelectBnameConfig}
                    disabled={selectedConfigs.length > 0}
                  />
                ))}
          </table>
          <Stack
            direction="row"
            justifyContent="right"
            marginTop={1}>
            <PaginationTableComp
              totalDataLength={configData?.length || 0}
              pageSize={10}
              pageSizeList={[10, 50, 100]}
              onChange={(obj) => setPaginatedPageSize({ startIndex: obj.startIndex, endIndex: obj.endIndex })}
            />
          </Stack>
        </Box>
      )}
    </>
  )
}

export default StoreConfigurationComp
