import InfoIcon from '@mui/icons-material/Info'
import {
  Box,
  Skeleton,
  Stack,
  SxProps,
  Tooltip,
  Typography
} from '@mui/material'
import React from 'react'

export interface DataCardProps {
  title: string
  amount: string
  subAmount?: string
  tooltip?: string
  outOfAmount?: string
  dense?: boolean
  icon?: JSX.Element
  theme?: 'grey'
  sx?: SxProps
  removeTitleHeight?: boolean
  loading?: boolean
}

export default function DataCard(props: DataCardProps) {
  const { backgroundColor, color } = React.useMemo(() => {
    switch (props.theme) {
      case 'grey':
        return {
          backgroundColor: 'rgba(244, 244, 244, 1)',
          color: 'rgba(28, 27, 31, 1)'
        }
      default:
        return {
          backgroundColor: 'rgba(255, 255, 255, 1)',
          color: 'rgba(0, 0, 0, 1)'
        }
    }
  }, [props.theme])

  return (
    <Box
      sx={{
        px: props.dense ? '13px' : '18px',
        py: props.dense ? '9px' : '18px',
        border: '1px solid #EEEEEE',
        borderRadius: '2px',
        backgroundColor,
        position: 'relative',
        ...props.sx
      }}
    >
      <Box sx={{ visibility: props.loading ? 'hidden' : 'visible' }}>
        {(props.icon || props.tooltip) && (
          <Stack
            direction="row"
            alignItems="center"
            gap="5px"
            sx={{ height: '24px', mb: '10px' }}
          >
            {props.icon && (
              <Box sx={{ display: 'flex', fontSize: '24px', color: color }}>
                {props.icon}
              </Box>
            )}
            {props.tooltip && (
              <Tooltip
                title={props.tooltip}
                arrow
                placement="top"
                componentsProps={{
                  tooltip: {
                    style: {
                      color: 'white',
                      fontSize: 14,
                      fontWeight: 500,
                      background: 'black',
                      maxWidth: 600,
                      padding: '10px'
                    }
                  },
                  arrow: {
                    style: {
                      color: 'black'
                    }
                  }
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    fontSize: '18px',
                    color: 'rgba(18, 41, 55, 0.25)'
                  }}
                >
                  <InfoIcon fontSize="inherit" color="inherit" />
                </Box>
              </Tooltip>
            )}
          </Stack>
        )}
        <Box
          sx={{
            mb: '6px',
            minHeight: props.removeTitleHeight ? 'none' : '32px'
          }}
        >
          <Typography
            fontSize="12px"
            fontWeight={400}
            lineHeight={'16px'}
            sx={{ color: color }}
          >
            {props.title}
          </Typography>
        </Box>

        <Typography
          fontSize={props.dense ? '12px' : '14px'}
          fontWeight={600}
          color={color}
        >
          {props.amount}{' '}
          {props.outOfAmount && (
            <span style={{ fontSize: '12px', fontWeight: 400 }}>
              out of {props.outOfAmount}
            </span>
          )}
          {props.subAmount && (
            <span style={{ fontSize: '12px', fontWeight: 400 }}>
              {props.subAmount}
            </span>
          )}
        </Typography>
      </Box>
      {props.loading && (
        <Skeleton
          component="div"
          sx={{
            transform: 'none',
            position: 'absolute',
            top: '0px',
            right: '0px',
            bottom: '0px',
            left: '0px'
          }}
        />
      )}
    </Box>
  )
}
