import { Box, SxProps, Typography } from '@mui/material'
import React from 'react'
import { formatNumber } from 'src/utils/functions'

export type FunnelChartData = {
  label: string
  value: number
  fill?: string
  formatValue?: (value: number) => string
}[]

interface FunnelChartProps {
  rootSx?: SxProps
  data: FunnelChartData
  svgHeight?: string | number
}

export default function FunnelChart(props: FunnelChartProps) {
  const maxValue = React.useMemo(() => {
    return Math.max(1, ...props.data.map((e) => e.value))
  }, [props.data])
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'stretch',
        gap: '5px',
        ...props.rootSx
      }}
    >
      {props.data.map((item, i, arr) => {
        let start = 100 - (item.value / maxValue) * 100
        let end =
          100 - ((i === arr.length - 1 ? 0 : arr[i + 1].value) / maxValue) * 100
        return (
          <Box key={i} sx={{ flex: 1 }}>
            <Typography fontSize="12px" fontWeight={400}>
              {item.label}
            </Typography>
            <Typography fontSize="14px" fontWeight={600} sx={{ mb: '15px' }}>
              {item.formatValue
                ? item.formatValue(item.value)
                : formatNumber(item.value, { maxFractionDigits: 0 })}
            </Typography>
            <CustomSVG
              path={`M0 ${start} C50 ${start}, 50 ${end}, 100 ${end} L100 100 L0 100z`}
              fill={item.fill}
              svgHeight={props.svgHeight}
            />
          </Box>
        )
      })}
    </Box>
  )
}

const CustomSVG = (props: {
  path: string
  fill: string
  svgHeight?: string | number
}) => {
  const animateSeconds = 1
  const prevPath = React.useRef(
    'M0 100 C50 100, 50 100, 100 100 L100 100 L0 100z'
  )

  React.useEffect(() => {
    setTimeout(() => {
      prevPath.current = props.path
    }, animateSeconds * 1000)
  }, [props.path])

  return (
    <svg
      key={props.path}
      viewBox="0 0 100 100"
      preserveAspectRatio="none"
      style={{ height: props.svgHeight || '100px', width: '100%' }}
    >
      <path key={props.path} d={prevPath.current} fill={props.fill}>
        <animate
          key={props.path}
          attributeName="d"
          dur={`${animateSeconds}s`}
          from={prevPath.current}
          to={props.path}
          repeatCount="1"
          fill="freeze"
        />
      </path>
    </svg>
  )
}
