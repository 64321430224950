import { CustomMaterialTable, Drawer } from '@LoopKitchen/loop-ui'
import { KeyboardBackspace } from '@mui/icons-material'
import { IconButton, Stack, Typography } from '@mui/material'
import { get } from 'lodash'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { useErrorData } from 'src/context/ErrorContext'
import { useFilter } from 'src/context/FilterContext'
import { useSnackData } from 'src/context/SnackContext'
import { DefaultService, StoreAutoTurnOnOrdersPaginated } from 'src/services/openApi'
import { downloadCsv, formatCurrency } from 'src/utils/functions'
import { SavedOrdersType } from './StoreRestoreTable'

interface StoreRestoreOrdersDrawerProps {
  savedOrdersData: SavedOrdersType
  handleClose: () => void
}

const StoreRestoreOrdersDrawer = ({ savedOrdersData, handleClose }: StoreRestoreOrdersDrawerProps) => {
  const { setDownloadSnack } = useSnackData()
  const { handleError } = useErrorData()
  const { getFiltersV2, getFilters } = useFilter()
  const [tableDataloading, setTableDataLoading] = useState(false)
  const [tableData, setTableData] = useState<StoreAutoTurnOnOrdersPaginated | null>(null)
  const [orderBy, setOrderBy] = useState<string>('order_timestamp')
  const [ascending, setAscending] = useState(true)
  const limit = 100

  const getTableData = async (obj: { limit: number; offset: number }, callback: (data: StoreAutoTurnOnOrdersPaginated) => void) => {
    setTableDataLoading(true)
    try {
      const res = await DefaultService.callStoreAutoTurnOnOrdersApiPaginatedStoreAutoTurnOnOrdersPost(
        {
          ...getFiltersV2(['chain']),
          order_ids: get(savedOrdersData, 'order_ids', [])?.join('|'),
          limit: obj.limit,
          offset: obj.offset,
          order_by: orderBy,
          ascending: ascending
        },
        true
      )
      callback(res)
    } catch (err) {
      handleError(err.message)
    }
    setTableDataLoading(false)
  }

  const exportCsv = async () => {
    setDownloadSnack({ status: 'start' })
    try {
      const res = await DefaultService.exportStoreAutoTurnOnOrdersApiExportStoreAutoTurnOnOrdersPost({
        ...getFiltersV2(['chain']),
        order_ids: get(savedOrdersData, 'order_ids', [])?.join('|')
      })
      downloadCsv(`orders_post_restore_${savedOrdersData.b_name?.toLowerCase().replace(/[^a-zA-Z0-9-_@]/g, '')}.csv`, res)
      setTimeout(() => {
        setDownloadSnack({ status: 'complete' })
      }, 1000)
    } catch (err) {
      setDownloadSnack({ status: 'close' })
      handleError(err.message)
    }
  }

  const onLastPage = () => {
    const next_offset = get(tableData, 'next_offset', null)
    if (next_offset) {
      getTableData(
        {
          limit,
          offset: next_offset
        },
        (data) => {
          setTableData((prev) => {
            return {
              ...data,
              data: [...get(prev, 'data', []), ...get(data, 'data', [])]
            }
          })
        }
      )
    }
  }

  useEffect(() => {
    if (!savedOrdersData || !savedOrdersData?.order_ids) return

    getTableData({ limit: limit, offset: 0 }, (data) => {
      setTableData(data)
    })
  }, [orderBy, ascending, savedOrdersData, ...getFilters(['chain'])])

  return (
    <Drawer
      open={Boolean(savedOrdersData) && Boolean(savedOrdersData?.order_ids)}
      onClose={handleClose}
      anchor="right">
      <Stack
        width="50vw"
        minWidth="300px"
        height="100%">
        <Stack
          direction="row"
          alignItems="center"
          gap={2}>
          <IconButton onClick={handleClose}>
            <KeyboardBackspace sx={{ color: '#000', fontSize: 20 }} />
          </IconButton>
          <Typography
            fontSize={16}
            color="#000"
            fontWeight={500}>
            Orders post restore for{' '}
            <Typography
              fontSize={16}
              color="#999"
              fontWeight={500}
              component="span">
              {get(savedOrdersData, 'b_name', '')}
            </Typography>
          </Typography>
        </Stack>
        <CustomMaterialTable
          isLoading={tableDataloading}
          data={tableData?.data || []}
          columns={[
            {
              title: 'Order ID',
              field: 'order_id'
            },
            {
              title: 'Order date',
              field: 'order_timestamp',
              render: (data) => {
                return (
                  <Typography
                    variant="subtitle2"
                    color="#000">
                    {moment(data.order_timestamp).format('DD MMM, YYYY hh:mm A')}
                  </Typography>
                )
              }
            },
            {
              title: 'Payout date',
              field: 'payout_date',
              hidden: get(tableData, 'data', []).every((data) => data.payout_date === null),
              render: (data) => {
                return (
                  <Typography
                    variant="subtitle2"
                    color="#000">
                    {data.payout_date ? moment(data.payout_date).format('DD MMM, YYYY') : ''}
                  </Typography>
                )
              }
            },
            {
              title: 'Net payout',
              field: 'net_payout',
              alignType: 'currency',
              render: (data) => {
                const val = get(data, 'net_payout', '0.0')
                return (
                  <Typography
                    variant="subtitle2"
                    color="#000">
                    {formatCurrency(typeof val === 'number' ? val : Number.parseFloat(val), { maxFractionDigits: 2 })}
                  </Typography>
                )
              }
            },
            {
              title: 'Subtotal',
              field: 'subtotal',
              alignType: 'currency',
              render: (data) => {
                const val = get(data, 'subtotal', '0.0')
                return (
                  <Typography
                    variant="subtitle2"
                    color="#000">
                    {formatCurrency(typeof val === 'number' ? val : Number.parseFloat(val), { maxFractionDigits: 2 })}
                  </Typography>
                )
              }
            }
          ]}
          options={{
            stickyHeader: '-16px',
            pagination: true,
            export: true,
            totalPaginatedDataLength: get(tableData, 'max_rows', undefined),
            sortable: true,
            showOrderBy: true,
            initialSortableOption: 'dtr_start_time_local',
            initialOrderBy: ascending ? 'ascending' : 'descending'
          }}
          onSortByChange={(param) => {
            setOrderBy(param.value)
          }}
          onOrderByChange={(param) => {
            setAscending(param === 'ascending')
          }}
          onExportCSV={() => {
            exportCsv()
          }}
          onLastPage={onLastPage}
        />
      </Stack>
    </Drawer>
  )
}

export default StoreRestoreOrdersDrawer
