import CheckIcon from '@mui/icons-material/Check'
import { ExternalStatus } from 'src/services/openApi'

type StatusLogoProps = {
  status: ExternalStatus
  animate?: boolean
}

export default function StatusLogo(props: StatusLogoProps) {
  if (props.status === ExternalStatus.IN_PROGRESS) {
    return (
      <span
        style={{
          width: '18px',
          height: '18px',
          borderRadius: '50%',
          background: 'rgba(224, 235, 255, 1)',
          display: 'inline-flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}>
        <span
          style={{
            width: '9px',
            height: '9px',
            borderRadius: '50%',
            background: 'rgba(0, 76, 229, 1)',
            display: 'inline-block',
            animation: props.animate ? 'inProgressAnimate 1.5s linear infinite' : 'none'
          }}
        />
      </span>
    )
  } else if (props.status === ExternalStatus.ACCEPTED) {
    return (
      <span style={{ color: 'rgba(13, 164, 78, 1)', fontSize: '18px' }}>
        <CheckIcon
          fontSize="inherit"
          color="inherit"
        />
      </span>
    )
  }

  return <></>
}
