import { Box, Skeleton, Stack, Typography } from '@mui/material'
import { ApexOptions } from 'apexcharts'
import moment from 'moment'
import React from 'react'
import ReactApexChart from 'react-apexcharts'

type OptionType = {
  colorType: 'gradient' | 'solid'
  opacity: number
  yaxis: ApexYAxis
}

interface ChartProps<T extends object> {
  title: string
  data: ({ date: string } & T)[]
  options: OptionType[]
  series: ApexAxisChartSeries
  stacked?: boolean
  loading?: boolean
  actionComp?: React.ReactNode
}

export default function Chart<T extends object>(props: ChartProps<T>) {
  const { options: propOptions, stacked, data, series, loading, title, actionComp } = props

  const options = React.useMemo(() => {
    return {
      noData: {
        text: 'No Data Available'
      },
      chart: {
        height: 400,
        toolbar: {
          show: true,
          tools: {
            zoom: false,
            pan: false,
            zoomin: false,
            zoomout: false,
            reset: false
          }
        },
        stacked: stacked,
        stackType: '100%'
      },
      stroke: {
        curve: 'straight',
        width: 1
      },
      fill: {
        type: propOptions.map((e) => e.colorType),
        gradient: {
          opacityFrom: 0.5,
          opacityTo: 0.2,
          type: 'vertical'
        },
        opacity: propOptions.map((e) => e.opacity)
      },
      tooltip: {
        x: {
          formatter: (value, { dataPointIndex, w }) => {
            const fullDate = data.find((item) => moment(item?.date).format('DD MMM') === w?.globals?.categoryLabels[dataPointIndex])
            return moment(fullDate?.date).format('DD MMM, ddd')
          }
        }
      },
      states: {
        hover: {
          filter: {
            type: 'darken',
            value: 0.7
          }
        }
      },
      markers: {
        size: 0
      },
      xaxis: {
        tickAmount: 7,
        categories: data.map((item) => item.date),
        labels: {
          formatter: (value) => {
            return moment(value).format('DD MMM')
          }
        }
      },
      yaxis: propOptions.map((e) => e.yaxis),
      dataLabels: {
        enabled: false
      },
      legend: {
        position: 'top',
        show: true,
        showForSingleSeries: true,
        horizontalAlign: 'left'
      }
    } as ApexOptions
  }, [propOptions, stacked, data])

  if (loading) {
    return (
      <Skeleton
        width={'100%'}
        height={'300px'}
      />
    )
  }

  return (
    <>
      <Box sx={{ p: '16px', border: '1px solid #E1DDDD', borderRadius: '4px' }}>
        <Typography
          fontSize="12px"
          color={'rgb(25, 110, 130)'}
          fontWeight={600}
          sx={{ mb: actionComp ? '0px' : '10px' }}>
          {title}
        </Typography>

        {loading ? (
          <Skeleton
            width={'100%'}
            height={'300px'}
          />
        ) : (
          <>
            {actionComp && (
              <Stack
                direction="row"
                justifyContent={'flex-end'}
                gap="10px"
                sx={{ my: '5px' }}>
                {actionComp}
              </Stack>
            )}

            <ReactApexChart
              options={options}
              series={series}
              height={300}
              zoom={{ type: 'y' }}
            />
          </>
        )}
      </Box>
    </>
  )
}
