import { SvgIcon, SvgIconProps } from '@mui/material'

export default function BoltCancelIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg
        width="12"
        height="22"
        viewBox="0 0 12 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.60413 13.4444L3.50173 22H4.60413L7.62084 16.1398L2.79941 7.78883L0.359905 12.5278C0.212919 12.813 0.171395 13.037 0.235334 13.2C0.300008 13.363 0.470144 13.4444 0.745744 13.4444H4.60413ZM3.79159 5.86144L8.61302 14.2124L11.0532 9.47222C11.2001 9.18704 11.2413 8.96296 11.1766 8.8C11.1127 8.63704 10.9429 8.55556 10.6673 8.55556H6.80892L7.91132 0H6.80892L3.79159 5.86144Z"
          fill="black"
        />
        <rect
          x="0.350852"
          y="2.23814"
          width="0.513683"
          height="20.5317"
          transform="rotate(-30 0.350852 2.23814)"
          fill="black"
          stroke="black"
          strokeWidth="0.513683"
        />
      </svg>
    </SvgIcon>
  )
}
