import { CustomMaterialTable, SingleSelect } from '@LoopKitchen/loop-ui'
import { Box, Icon, Stack, SxProps, Typography } from '@mui/material'
import { get, uniqueId } from 'lodash'
import moment from 'moment'
import React from 'react'
import DeltaChip from 'src/components/DeltaChip'
import { useDrillDownContext } from 'src/context/DrillDownContext'
import { useErrorData } from 'src/context/ErrorContext'
import { useSnackData } from 'src/context/SnackContext'
import defaultStyledColumns from 'src/pages/members/DrillDown/utils/defaultStyledColumns'
import useServiceHook, { DateRangeCompareDataResponseType, TableDataResponseType } from 'src/pages/members/DrillDown/utils/hooks/useServiceHook'
import allJsonData from 'src/pages/members/DrillDown/utils/jsonConfigs'
import componentsObject from 'src/pages/members/DrillDown/utils/jsonConfigs/componentsObject'
import JSONDataType, { BreakdownEnumsType, FiltersObjType } from 'src/pages/members/DrillDown/utils/jsonConfigs/types'
import { MetricsKPIDrillDownOutput } from 'src/services/openApi'
import { filterNames } from 'src/utils/config/config'
import { formatCurrency, formatNumber } from 'src/utils/functions'
import TimePeriodFormatter from 'src/utils/functions/timePeriodFormatter'
import { getFiltersArrStr } from '../utils/functions'

interface DrillDownTableProps {
  jsonData: JSONDataType
  drillDownName: keyof typeof allJsonData
  filtersArr: FiltersObjType[]
  setFilterData: (data: FiltersObjType[]) => void
}

export default function DrillDownTable(props: DrillDownTableProps) {
  const { jsonData, drillDownName, filtersArr, setFilterData } = props
  const { activeGraph, stack } = useDrillDownContext()
  // const { hasFinalComp, filtersArr, jsonData, setFilterData, goToFinalComp } = useDrillDownURLParams()
  const { handleError } = useErrorData()
  const { setDownloadSnack } = useSnackData()
  const { getTableData, getExportData, getDateRangeCompareData, getDeltaValue: getDeltaValueHook } = useServiceHook(drillDownName)
  const [tableData, setTableData] = React.useState<TableDataResponseType | null>(null)
  const [dateRangeCompareData, setDateRangeCompareData] = React.useState<DateRangeCompareDataResponseType | null>(null)
  const [tableDataLoading, setTableDataLoading] = React.useState(false)
  const [deltaLoading, setDeltaLoading] = React.useState(false)
  const [sortBy, setSortBy] = React.useState<string>()
  const [ascending, setAscending] = React.useState<boolean>(true)
  const [pageNumber, setPageNumber] = React.useState(0)

  const breakdownOptions = React.useMemo(() => {
    const mainBreakdownOptions = get(jsonData, 'mainBreakdownOptions', [] as typeof jsonData.mainBreakdownOptions)
    const values = filtersArr.map((e) => e.key)
    let result: typeof mainBreakdownOptions = mainBreakdownOptions.filter((option) => !values.includes(option.value))

    const resultsCanHaveDigitalStores = result.some((item) => item.value === 'vb_name' || item.value === 'vb_platform')

    if (!resultsCanHaveDigitalStores) {
      result = result.filter((item) => item.value !== 'slug')
    }

    return result
  }, [getFiltersArrStr(filtersArr), jsonData])

  const [breakdownBy, setBreakdownBy] = React.useState<BreakdownEnumsType>(undefined)

  const selectedBreakdownOption = React.useMemo(() => {
    const option = breakdownOptions.find((e) => e.value === breakdownBy)
    return option
  }, [breakdownBy, breakdownOptions])

  const activeStackObj = React.useMemo(() => {
    if (!selectedBreakdownOption) {
      return null
    }
    for (let i = stack.length - 1; i >= 0; i--) {
      const obj = stack[i]
      const currentStackCondition = get(obj, 'filterObj', [] as typeof obj.filterObj).some(
        (item) => item.key === get(selectedBreakdownOption, 'value', 'breakdown_not_found')
      )
      if (currentStackCondition) {
        return obj
      }
    }
    return null
  }, [selectedBreakdownOption, stack])

  const isActive = (value: string) => {
    if (!activeStackObj) {
      return false
    }
    const values = get(activeStackObj, 'filterObj.value', [] as FiltersObjType['value'])
    return values.includes(value)
  }

  const getDeltaValue = (breakdownKey: string, key: string) => {
    return getDeltaValueHook(dateRangeCompareData, breakdownKey, key as keyof MetricsKPIDrillDownOutput)
  }

  const finalCompObj = React.useMemo(() => {
    if (jsonData && jsonData['finalComponentKey'] && jsonData['finalComponentKey'] in componentsObject) {
      return componentsObject[jsonData['finalComponentKey']]
    }
    return null
  }, [jsonData])

  const getFilterBody = () => {
    const body: { [key: string]: string } = {}
    filtersArr.forEach((obj) => {
      body[`${obj.key}_in`] = obj.value.join('|')
    })

    const dateRange = activeGraph.getDateRange()
    const dateRangeFilter = {
      start_date_in: get(dateRange, 'start', moment()).format('YYYY-MM-DD'),
      end_date_in: get(dateRange, 'end', moment()).format('YYYY-MM-DD')
    }

    return {
      ...dateRangeFilter,
      ...body,
      breakdown_column: selectedBreakdownOption?.value
    }
  }

  // this variable is used when there is rapid drill-down API calling
  const apiId = React.useRef(uniqueId())
  const getData = async (id: string, obj: { limit?: number; offset: number }, callback: (data: TableDataResponseType) => void) => {
    if (!selectedBreakdownOption) {
      return
    }
    setTableDataLoading(true)
    try {
      const body = getFilterBody()

      const res = await getTableData({
        ...body,
        order_by: sortBy,
        ascending: ascending,
        limit: get(obj, 'limit', 100),
        offset: get(obj, 'offset', 0)
      })
      if (id === apiId.current) {
        callback(res)
      }
    } catch (err) {
      handleError(err.message)
    }
    if (id === apiId.current) {
      setTableDataLoading(false)
    }
  }

  const getDateRangeCompare = async () => {
    setDeltaLoading(true)
    try {
      let filterBody = getFilterBody()
      const res = await getDateRangeCompareData({
        ...filterBody
      })
      setDateRangeCompareData(res)
    } catch (err) {
      handleError(err.message)
    } finally {
      setDeltaLoading(false)
    }
  }

  const exportCSV = async () => {
    setDownloadSnack({ status: 'start' })
    try {
      const body: { [key: string]: string } = {}
      filtersArr.forEach((obj) => {
        body[`${obj.key}_in`] = obj.value.join('|')
      })

      const dateRange = activeGraph.getDateRange()
      const dateRangeFilter = {
        start_date_in: get(dateRange, 'start', moment()).format('YYYY-MM-DD'),
        end_date_in: get(dateRange, 'end', moment()).format('YYYY-MM-DD')
      }

      const data = await getExportData({
        ...dateRangeFilter,
        ...body,
        breakdown_column: selectedBreakdownOption?.value
      })
      const blob = new Blob([data], { type: 'text/csv' })
      const url = URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.download = `breakdown_by_${filtersArr.map((e) => get(filterNames, e.key, e.key)).join('_')}.csv`
      a.href = url
      a.click()
      setDownloadSnack({ status: 'complete' })
    } catch (err) {
      // handleError(err.message)
      setDownloadSnack({ status: 'close', message: 'Something went wrong' })
    }
  }

  React.useEffect(() => {
    apiId.current = uniqueId()
    getData(apiId.current, { offset: 0 }, (data) => {
      setTableData(data)
    })
  }, [
    sortBy,
    ascending,
    selectedBreakdownOption,
    getFiltersArrStr(filtersArr),
    activeGraph.getDateRange()?.start?.format('YYYY-MM-DD'),
    activeGraph.getDateRange()?.end?.format('YYYY-MM-DD')
  ])

  React.useEffect(() => {
    if (tableData && tableData.data.length > 0) {
      getDateRangeCompare()
    }
  }, [
    tableData && tableData.data.length > 0 ? 'loaded' : 'loading',
    selectedBreakdownOption,
    getFiltersArrStr(filtersArr),
    activeGraph.getDateRange()?.start?.format('YYYY-MM-DD'),
    activeGraph.getDateRange()?.end?.format('YYYY-MM-DD')
  ])

  React.useEffect(() => {
    if (Array.isArray(breakdownOptions) && breakdownOptions.length > 0) {
      setBreakdownBy(breakdownOptions[0].value)
    }
  }, [breakdownOptions])

  if (breakdownOptions.length === 0) {
    return (
      <Box sx={{ py: 3, bgcolor: 'white' }}>
        <Typography
          variant="h6"
          textAlign="center"
          color="#000">
          No Breakdown found
        </Typography>
      </Box>
    )
  }

  return (
    <Box>
      <CustomMaterialTable
        titleComp={
          Array.isArray(breakdownOptions) &&
          breakdownOptions.length > 0 && (
            <SingleSelect
              key={breakdownBy}
              options={breakdownOptions}
              value={breakdownBy}
              onChange={(value) => {
                setPageNumber(0)
                setBreakdownBy(value)
              }}
              renderValue={(selected) => {
                const option = selectedBreakdownOption
                return (
                  <Box sx={{ height: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '8px' }}>
                    {option && (
                      <Icon
                        color="inherit"
                        sx={{ fontSize: '16px' }}>
                        {option?.icon}
                      </Icon>
                    )}
                    <Typography
                      fontSize="inherit"
                      color="#000">
                      Breakdown by {option?.label}
                    </Typography>
                  </Box>
                )
              }}
              disableSort
            />
          )
        }
        isLoading={tableDataLoading}
        // @ts-ignore
        data={tableData?.data || []}
        columns={get(selectedBreakdownOption, 'columns', [] as typeof selectedBreakdownOption.columns).map((obj) => {
          return {
            title: obj.title,
            field: obj.field,
            headerStyle: {
              textAlign: ['number', 'currency', 'percentage'].includes(obj.type) ? 'right' : 'left'
            },
            render: (data: (typeof tableData.data)[0]) => {
              const sx: SxProps = {
                fontWeight: obj.style && obj.style === 'bold' ? 700 : 'normal',
                color: 'black'
              }
              const comp = (() => {
                if (obj.field in defaultStyledColumns) {
                  const active = isActive(obj.field.toString())
                  const Component = defaultStyledColumns[obj.field]
                  return (
                    <Component
                      data={data}
                      active={active}
                    />
                  )
                } else if (obj.type && obj.type === 'currency') {
                  try {
                    const n = Number(get(data, obj.field))
                    if (isNaN(n)) {
                      return <></>
                    }
                    return (
                      <Typography
                        variant="subtitle2"
                        textAlign="right"
                        sx={sx}>
                        {formatCurrency(n, { maxFractionDigits: 2 })}
                      </Typography>
                    )
                  } catch (err) {
                    console.log('err: ', err)
                  }
                } else if (obj.type && obj.type === 'number') {
                  try {
                    const n = Number(get(data, obj.field))
                    if (isNaN(n)) {
                      return <></>
                    }
                    return (
                      <Typography
                        variant="subtitle2"
                        textAlign="right"
                        sx={sx}>
                        {formatNumber(n, { maxFractionDigits: 2 })}
                      </Typography>
                    )
                  } catch (err) {
                    console.log('err: ', err)
                  }
                } else if (obj.type && obj.type === 'date') {
                  try {
                    return (
                      <Typography
                        variant="subtitle2"
                        sx={sx}>
                        {moment(get(data, obj.field, '')).format('DD MMM, YYYY')}
                      </Typography>
                    )
                  } catch (err) {
                    console.log('err: ', err)
                  }
                } else if (obj.type && obj.type === 'percentage') {
                  try {
                    const n = Number(get(data, obj.field))
                    if (isNaN(n)) {
                      return <></>
                    }
                    return (
                      <Typography
                        variant="subtitle2"
                        textAlign="right"
                        sx={sx}>
                        {formatNumber(n, { maxFractionDigits: 2 })}%
                      </Typography>
                    )
                  } catch (err) {
                    console.log('err: ', err)
                  }
                } else if (obj.type && obj.type === 'time_period_formatter') {
                  try {
                    const n = Number(get(data, obj.field))
                    if (isNaN(n)) {
                      return <></>
                    }
                    return (
                      <Typography
                        variant="subtitle2"
                        sx={sx}>
                        {TimePeriodFormatter(n)}
                      </Typography>
                    )
                  } catch (err) {
                    console.log('err: ', err)
                  }
                }
                return (
                  <Typography
                    variant="subtitle2"
                    sx={sx}>
                    {get(data, obj.field, '').toString()}
                  </Typography>
                )
              })()

              const { delta, previous } = getDeltaValue(data.breakdown_column, obj.field)
              const formattedPrevious = (() => {
                if (obj.type === 'currency') {
                  return formatCurrency(previous, { maxFractionDigits: 2 })
                } else if (obj.type === 'number') {
                  return formatNumber(previous, { maxFractionDigits: 2 })
                } else if (obj.type === 'percentage') {
                  return `${formatNumber(previous, { maxFractionDigits: 2 })}%`
                } else if (obj.type === 'time_period_formatter') {
                  return TimePeriodFormatter(previous)
                }
                return previous?.toString()
              })()
              const expectDelta = ['currency', 'number', 'percentage', 'time_period_formatter'].includes(obj.type)
              return (
                <Stack
                  direction="column"
                  alignItems={['currency', 'number', 'percentage'].includes(obj.type) ? 'flex-end' : 'flex-start'}
                  gap="5px">
                  {comp}
                  <DeltaChip
                    loading={expectDelta && deltaLoading}
                    delta={delta}
                    deltaType={
                      obj.deltaType === 'positive_delta_green_color'
                        ? delta > 0
                          ? 'success'
                          : 'error'
                        : obj.deltaType === 'positive_delta_red_color'
                        ? delta < 0
                          ? 'success'
                          : 'error'
                        : 'zero'
                    }
                    tooltipTitle={`Previous ${obj.title}: ${formattedPrevious}`}
                  />
                </Stack>
              )
            }
          }
        })}
        options={{
          stickyHeader: '-16px',
          export: true,
          pagination: true,
          sortable: true,
          pageNumber: pageNumber,
          initialOrderBy: ascending ? 'ascending' : 'descending',
          initialSortableOption: sortBy,
          totalPaginatedDataLength: get(tableData, 'max_rows', undefined),
          headerRowStyle: {
            bgcolor: '#FBFAFA'
          },
          toolbarSx: { px: 0 }
        }}
        onPaginationNext={({ totalPages }) => {
          if (pageNumber < totalPages) {
            setPageNumber((prev) => prev + 1)
          }
        }}
        onPaginationPrev={() => {
          if (pageNumber > 0) {
            setPageNumber((prev) => prev - 1)
          }
        }}
        onRowClick={
          tableDataLoading || (breakdownOptions.length <= 1 && !finalCompObj)
            ? undefined
            : (data) => {
                if (selectedBreakdownOption) {
                  if (selectedBreakdownOption.value === 'slug') {
                    setFilterData([
                      {
                        key: 'vb_platform' as BreakdownEnumsType,
                        value: [get(data, 'vb_platform') as string]
                      },
                      {
                        key: 'vb_name' as BreakdownEnumsType,
                        value: [get(data, 'vb_name') as string]
                      }
                    ])
                    return
                  }
                  const key = selectedBreakdownOption.value
                  setFilterData([{ key, value: [data[key]] }])
                }
                // if (breakdownOptions.length === 1 && selectedBreakdownOption && goToFinalComp) {
                //   const key = selectedBreakdownOption.value
                //   if (key in data) {
                //     goToFinalComp({ key, value: [data[key]] })
                //   }
                // } else if (selectedBreakdownOption) {
                //   const key = selectedBreakdownOption.value
                //   if (key in data) {
                //   }
                // }
              }
        }
        onOrderByChange={(newOrderBy) => {
          setAscending(newOrderBy === 'ascending' ? true : false)
        }}
        onSortByChange={(newSortBy) => {
          setSortBy(newSortBy?.value)
        }}
        onExportCSV={() => {
          exportCSV()
        }}
        onLastPage={() => {
          if (get(tableData, 'next_offset', undefined)) {
            apiId.current = uniqueId()
            getData(apiId.current, { limit: 100, offset: get(tableData, 'next_offset', 0) }, (data) => {
              setTableData((prev) => {
                return {
                  ...data,
                  data: [...prev.data, ...data.data]
                }
              })
            })
          }
        }}
      />
    </Box>
  )
}
