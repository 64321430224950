import LinkIcon from '@mui/icons-material/Link'
import { ButtonBase, Skeleton, Stack, Typography } from '@mui/material'
import { useSnackData } from 'src/context/SnackContext'

interface OrderIdProps {
  orderId?: string
  loading?: boolean
}

export default function OrderId(props: OrderIdProps) {
  const { orderId, loading } = props
  const { openSuccess, openError } = useSnackData()

  return (
    <Stack
      direction="row"
      alignItems="center"
      gap="10px"
      sx={{ minWidth: '250px', px: '10px', py: '10px', borderRadius: '2px', border: '1px solid rgba(230, 230, 230, 0.9)' }}>
      {loading ? (
        <Skeleton
          variant="text"
          sx={{ flex: 1 }}
        />
      ) : (
        <Typography
          fontSize="14px"
          lineHeight={1}
          color="#000"
          sx={{ flex: 1, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
          Order id: #{orderId}
        </Typography>
      )}
      <ButtonBase
        disabled={loading || !orderId}
        sx={{ bgcolor: 'rgba(237, 237, 237, 1)', width: '15px', height: '15px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
        onClick={async () => {
          try {
            await navigator.clipboard.writeText(orderId)
            openSuccess('Order id copied to clipboard')
          } catch (err) {
            openError(err.message)
          }
        }}>
        <LinkIcon sx={{ fontSize: '14px', color: '#000', transform: 'rotateZ(-45deg)' }} />
      </ButtonBase>
    </Stack>
  )
}
