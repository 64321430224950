import { SvgIcon, SvgIconProps } from '@mui/material'
import { get } from 'lodash'

const AlarmDetector = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        width="19"
        height="19"
        viewBox="0 0 19 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M8.74512 18.7305V13.7305H10.7451V18.7305H8.74512ZM16.8201 16.2055L13.2701 12.6805L14.6951 11.2555L18.2201 14.8055L16.8201 16.2055ZM2.67012 16.2055L1.27012 14.8055L4.79512 11.2555L6.22012 12.6805L2.67012 16.2055ZM5.84512 5.73047L6.14512 6.73047H13.3451L13.6451 5.73047H5.84512ZM6.14512 8.73047C5.71178 8.73047 5.32012 8.6013 4.97012 8.34297C4.62012 8.08464 4.37845 7.74714 4.24512 7.33047L3.74512 5.73047H2.74512C2.19512 5.73047 1.72428 5.53464 1.33262 5.14297C0.940951 4.7513 0.745117 4.28047 0.745117 3.73047V0.730469H18.7451V3.73047C18.7451 4.28047 18.5493 4.7513 18.1576 5.14297C17.766 5.53464 17.2951 5.73047 16.7451 5.73047H15.7451L15.0951 7.43047C14.9451 7.8138 14.7035 8.1263 14.3701 8.36797C14.0368 8.60964 13.6618 8.73047 13.2451 8.73047H6.14512Z"
          fill={get(props, 'sx.color', '#000000')}
        />
      </svg>
    </SvgIcon>
  )
}

export default AlarmDetector
