import { get } from 'lodash'
import { AccessLevelType } from 'src/context/AuthContext.type'
import { AccessLevelEnum, accessLevelConfig } from '../config/config'

export const getAccessLevel = (userObject) => {
  const accessLevel: AccessLevelEnum = Array.isArray(userObject?.['access-level'])
    ? get(userObject, 'access-level[0]', AccessLevelEnum.BUSINESS_ADMIN)
    : get(userObject, 'access-level', AccessLevelEnum.BUSINESS_ADMIN)

  return accessLevel
}

export const getAccessLevelLabel = (accessLevel: AccessLevelEnum) => {
  const label = get(accessLevelConfig, `${accessLevel}.label`, '')
  return label
}

export const canAccessUserManagementRoute = (orgConfig, currentUser) => {
  const canAccess =
    (get(orgConfig, `access_levels.${getAccessLevel(currentUser)}.settings`) === undefined ||
      get(orgConfig, `access_levels.${getAccessLevel(currentUser)}.settings.user_management`) === undefined) &&
    (get(currentUser, 'access-level', []).includes(AccessLevelEnum.BUSINESS_ADMIN) ||
      get(currentUser, 'access-level', []).includes(AccessLevelEnum.DEMO) ||
      get(currentUser, 'access-level', []).includes(AccessLevelEnum.INTERNAL_OPERATOR) ||
      get(currentUser, 'access-level', []).includes(AccessLevelEnum.OWNER_VIEW))
      ? true
      : orgConfig?.access_levels[getAccessLevel(currentUser)]?.settings !== undefined &&
        get(orgConfig, `access_levels.${getAccessLevel(currentUser)}.settings.user_management`) !== undefined &&
        get(orgConfig, `access_levels.${getAccessLevel(currentUser)}.settings.user_management`) === false
      ? false
      : orgConfig?.access_levels[getAccessLevel(currentUser)]?.settings !== undefined &&
        get(orgConfig, `access_levels.${getAccessLevel(currentUser)}.settings.user_management`) !== undefined &&
        get(orgConfig, `access_levels.${getAccessLevel(currentUser)}.settings.user_management`) === true
      ? true
      : false

  return canAccess
}

export const canAccessNotificationsRoute = (orgConfig, currentUser) => {
  const canAccess =
    get(orgConfig, `access_levels.${getAccessLevel(currentUser)}.settings`) === undefined ||
    get(orgConfig, `access_levels.${getAccessLevel(currentUser)}.settings.notifications`) === undefined
      ? true
      : orgConfig?.access_levels[getAccessLevel(currentUser)]?.settings !== undefined &&
        get(orgConfig, `access_levels.${getAccessLevel(currentUser)}.settings.notifications`) !== undefined &&
        get(orgConfig, `access_levels.${getAccessLevel(currentUser)}.settings.notifications`) === false
      ? false
      : orgConfig?.access_levels[getAccessLevel(currentUser)]?.settings !== undefined &&
        get(orgConfig, `access_levels.${getAccessLevel(currentUser)}.settings.notifications`) !== undefined &&
        get(orgConfig, `access_levels.${getAccessLevel(currentUser)}.settings.notifications`) === true
      ? true
      : false

  return canAccess
}

export const canControlAccessLevels = (currentUser, accessLevels: AccessLevelType[]): AccessLevelType[] => {
  let controllableAccessLevels: AccessLevelType[] = []

  if (getAccessLevel(currentUser) === AccessLevelEnum.BUSINESS_ADMIN) {
    controllableAccessLevels = accessLevels
  } else if (getAccessLevel(currentUser) === AccessLevelEnum.INTERNAL_OPERATOR || getAccessLevel(currentUser) === AccessLevelEnum.OWNER_VIEW) {
    controllableAccessLevels = accessLevels.filter(
      (accessLevel) =>
        accessLevel.id === AccessLevelEnum.STORE_MANAGER ||
        accessLevel.id === AccessLevelEnum.OBSERVER
    )
  }

  return controllableAccessLevels
}
