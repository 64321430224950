import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import StarRoundedIcon from '@mui/icons-material/StarRounded'
import { Box, Button, ButtonBase, Chip, CircularProgress, Collapse, Divider, Skeleton, Stack, TextField, Tooltip, Typography } from '@mui/material'
import React from 'react'
import StoreLogo from 'src/components/StoreLogo'
import { useSnackData } from 'src/context/SnackContext'
import { DefaultService, OrderContextModel, UberPromotionType } from 'src/services/openApi'
import DataNotAvailable from './helpers/DataNotAvailable'
import HeadingContainer from './helpers/HeadingContainer'

interface ReviewAndFeedbackProps {
  platform?: string
  review?: OrderContextModel['review']
  loading?: boolean
  type: 'error_feedback' | 'review'
}

export default function ReviewAndFeedback(props: ReviewAndFeedbackProps) {
  const { review, loading, platform, type } = props
  const { openError, openSuccess } = useSnackData()
  const [hideReply, setHideReply] = React.useState(true)
  const [replyTextValue, setReplyTextValue] = React.useState('')
  const [replyLoading, setReplyLoading] = React.useState(false)
  const [reply, setReply] = React.useState<string>(null)

  const icon = React.useMemo(() => {
    return type === 'review' ? 'rate_review' : 'feedback'
  }, [type])

  const daysLeft = React.useMemo(() => {
    if (!review?.days_left || review?.days_left <= 0) {
      return false
    }
    return review?.days_left
  }, [review?.days_left])

  const { isGrubhub, isDoordash, isUbereats } = React.useMemo(() => {
    return {
      isGrubhub: platform?.toLowerCase() === 'grubhub',
      isDoordash: platform?.toLowerCase() === 'doordash',
      isUbereats: platform?.toLowerCase() === 'ubereats'
    }
  }, [platform])

  const disableSubmitReplyBtn = React.useMemo(() => {
    if (replyTextValue.length === 0 || replyLoading) {
      return true
    }
    if (isDoordash && replyTextValue.length > 300) {
      return true
    }
    return false
  }, [replyTextValue, replyLoading, isDoordash])

  const submitReply = async () => {
    if (replyTextValue.length === 0) {
      openError('Please type a reply')
      return
    }
    setReplyLoading(true)
    try {
      const promotion = isUbereats ? UberPromotionType.NONE : 1
      const res = await DefaultService.reviewReplyReviewReplyPost(review?.reviewId, {
        platform: props.platform as any,
        comment: replyTextValue,
        promotion
      })
      openSuccess('Reply added')
      const success = typeof res === 'string' && res.toLowerCase() === 'success' ? true : false
      if (success) {
        setReply(replyTextValue)
      } else {
        openError('Something went wrong')
      }
    } catch (err) {
      openError(err.message)
    }
    setReplyLoading(false)
  }

  return (
    <HeadingContainer
      icon={icon}
      title={type === 'review' ? 'Customer Review' : 'Error Feedback'}>
      {!loading && !review ? (
        <DataNotAvailable
          icon={icon}
          label={type === 'review' ? 'No rating or review' : 'No feedback'}
        />
      ) : (
        <Box sx={{ px: '25px', py: '15px', borderRadius: '10px', bgcolor: '#F8F8F8', border: '1px solid rgba(225, 221, 221, 1)' }}>
          <Stack
            direction="row"
            alignItems="center"
            gap="8px">
            {loading ? (
              <Skeleton
                variant="text"
                sx={{ flex: 1 }}
              />
            ) : (
              review?.customerName && (
                <Typography
                  fontSize="12px"
                  color="#000"
                  sx={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                  {review?.customerName}
                </Typography>
              )
            )}

            {loading ? (
              <Skeleton
                variant="text"
                sx={{ flex: 1 }}
              />
            ) : (
              <>
                {review?.reviewId && (
                  <Tooltip title={review?.reviewId}>
                    <Typography
                      fontSize="12px"
                      color="#000"
                      sx={{ flex: 1, overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                      ID {review?.reviewId}
                    </Typography>
                  </Tooltip>
                )}
              </>
            )}

            <Stack
              direction="row"
              alignItems={'center'}
              gap="5px">
              {loading ? (
                <>
                  {[1, 2, 3, 4, 5].map((elm) => (
                    <Skeleton
                      key={elm}
                      variant="circular"
                      width={20}
                    />
                  ))}
                </>
              ) : (
                review?.rating !== undefined &&
                review?.rating !== null &&
                [1, 2, 3, 4, 5].map((star) => {
                  const isStarActive = star <= review?.rating
                  return (
                    <Box
                      key={star}
                      sx={{
                        color: isStarActive ? 'rgba(246, 186, 96, 1)' : 'rgba(245, 245, 245, 1)',
                        fontSize: '20px'
                      }}>
                      <StarRoundedIcon
                        color="inherit"
                        fontSize="inherit"
                      />
                    </Box>
                  )
                })
              )}
            </Stack>
          </Stack>
          <Divider sx={{ mt: '10px' }} />
          {loading ? (
            <Skeleton
              variant="text"
              width="100%"
            />
          ) : (
            review?.feedback && (
              <Box sx={{ mt: '10px' }}>
                <Typography
                  fontSize="16px"
                  color="#000">
                  {review?.feedback}
                </Typography>
              </Box>
            )
          )}

          {loading ? (
            <Skeleton
              variant="rounded"
              width="100px"
              height="30px"
            />
          ) : (
            <>
              <Stack
                direction="row"
                alignItems="center"
                gap="15px"
                sx={{ mt: '10px' }}>
                {review?.reply && (
                  <ButtonBase
                    onClick={() => {
                      setHideReply((prev) => !prev)
                    }}>
                    <Stack
                      direction="row"
                      alignItems="center"
                      gap="10px">
                      <Typography variant="subtitle2">{hideReply ? 'View Reply' : 'Hide Reply'}</Typography>
                      <Box
                        sx={{
                          fontSize: '16px',
                          transformOrigin: 'center',
                          transform: `rotateZ(${hideReply ? 0 : 180}deg)`,
                          transition: '0.2s'
                        }}>
                        <KeyboardArrowDownIcon fontSize="inherit" />
                      </Box>
                    </Stack>
                  </ButtonBase>
                )}
                {!review?.reply && typeof daysLeft === 'number' && !isGrubhub && (
                  <>
                    <ButtonBase
                      onClick={() => {
                        setHideReply((prev) => !prev)
                      }}>
                      <Box
                        sx={{
                          px: '20px',
                          py: '9px',
                          transition: '0.2s',
                          borderRadius: '4px',
                          bgcolor: 'rgba(236, 236, 236, 0.5)',
                          '&:hover': { bgcolor: 'rgba(236, 236, 236, 1)' }
                        }}>
                        <Typography
                          variant="subtitle2"
                          fontWeight={600}
                          lineHeight={'20px'}>
                          Reply
                        </Typography>
                      </Box>
                    </ButtonBase>
                    <Chip
                      label={`${daysLeft} days left`}
                      color="error"
                    />
                  </>
                )}

                {!review?.reply && typeof daysLeft !== 'number' && !isGrubhub && (
                  <Typography
                    variant="subtitle2"
                    color="error">
                    {/* Reply days past {thresholdDays} day
                {thresholdDays > 1 ? 's' : ''} */}
                    Reply time expired
                  </Typography>
                )}

                {!review?.reply && isGrubhub && (
                  <Typography
                    variant="subtitle2"
                    color="error">
                    Reply not available
                  </Typography>
                )}
              </Stack>

              <Collapse in={!hideReply}>
                <Divider sx={{ my: '17px' }} />
                {review?.reply && (
                  <Box>
                    <Stack
                      direction="row"
                      alignItems="flex-start"
                      gap="20px">
                      <Box>
                        <StoreLogo
                          showAvatarOnly
                          avatarSize={{ width: '28px', height: '28px' }}
                        />
                      </Box>
                      <Box>
                        <Typography
                          fontSize="14px"
                          lineHeight="20px"
                          color="rgba(73, 73, 73, 0.5)">
                          {review?.reply}
                        </Typography>
                      </Box>
                    </Stack>
                  </Box>
                )}
                {!review?.reply && (
                  <Box>
                    <TextField
                      label="Reply text"
                      value={replyTextValue}
                      onChange={(e) => {
                        setReplyTextValue(e.target.value)
                      }}
                      multiline
                      minRows={4}
                      maxRows={4}
                    />
                    <Button
                      size="small"
                      disabled={disableSubmitReplyBtn}
                      sx={{ mt: '10px' }}
                      startIcon={
                        replyLoading && (
                          <CircularProgress
                            size="14px"
                            color="inherit"
                          />
                        )
                      }
                      onClick={() => {
                        submitReply()
                      }}>
                      Submit
                    </Button>
                  </Box>
                )}
              </Collapse>
            </>
          )}
        </Box>
      )}
    </HeadingContainer>
  )
}
