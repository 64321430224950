import moment from "moment";

interface DataObject {
  date: string;
  [key: string]: any;
}

type GroupType = 'monthly' | 'week' | 'daily';

export default function dateWiseGroupData<T extends DataObject, K extends keyof T>(
  body: {
    data: T[],
    avgKeys?: Exclude<K, 'date'>[],
    sumKeys?: Exclude<K, 'date'>[],
    type: GroupType
  }
): ( Partial<T> & { group: string } )[] {
  const { data, avgKeys = [], sumKeys = [], type } = body
  const groupedData: { [group: string]: Partial<T> } = {};
  const groupCounts: { [group: string]: number } = {};

  let minDate: Date;
  let maxDate: Date;
  // Finding minimum and maximum date in this for loop
  for ( let i = 0; i < data.length; i++ ) {
    const { date } = data[i]
    const d = new Date( date )
    if ( !minDate ) {
      minDate = new Date( d )
    } else if ( minDate.getTime() > d.getTime() ) {
      minDate = new Date( d )
    }

    if ( !maxDate ) {
      maxDate = new Date( d )
    } else if ( maxDate.getTime() < d.getTime() ) {
      maxDate = new Date( d )
    }
  }

  for ( const item of data ) {
    const { date } = item;
    const group = getGroupKey( date, type, minDate, maxDate );
    if ( !groupedData[group] ) {
      groupedData[group] = { date } as Partial<T>;
      groupCounts[group] = 0;
    }
    for ( const key of avgKeys ) {
      groupedData[group] = {
        ...groupedData[group],
        [key]: ( Number( groupedData[group][key] ) || 0 ) + item[key]
      }
    }
    for ( const key of sumKeys ) {
      groupedData[group] = {
        ...groupedData[group],
        [key]: ( Number( groupedData[group][key] ) || 0 ) + item[key]
      }
    }
    groupCounts[group]++;

  }

  for ( const group in groupedData ) {
    for ( const key of avgKeys ) {
      groupedData[group] = {
        ...groupedData[group],
        [key]: ( groupedData[group][key] || 0 ) / groupCounts[group]
      }
    }
  }

  const sortedData = Object.keys( groupedData ).map( ( group ) => ( { group, ...groupedData[group] } ) );

  sortedData.sort( ( a, b ) => {
    const dateA = new Date( a.date );
    const dateB = new Date( b.date );
    return dateA.getTime() - dateB.getTime();
  } )

  return sortedData;
}

function getGroupKey( date: string, type: GroupType, minDate: Date, maxDate: Date ): string {
  const dateObj = new Date( date );

  if ( type === 'monthly' ) {
    return createGroup( 'month', dateObj, minDate, maxDate )
  }

  if ( type === 'week' ) {
    return createGroup( 'week', dateObj, minDate, maxDate )
  }

  if ( type === 'daily' ) {
    return moment( dateObj ).format( "DD MMM, YY" )
  }

  throw new Error( `Invalid group type: ${type}` );
}

function formatDates( d1: Date, d2: Date, showOnlyMonth?: boolean ) {
  if ( showOnlyMonth ) {
    return moment( d1 ).format( "MMM YYYY" )
  }

  if ( d1.getTime() === d2.getTime() ) {
    return moment( d1 ).format( "D MMM" )
  }
  return `${moment( d1 ).format( "D MMM" )} - ${moment( d2 ).format( "D MMM" )}`
}

function createGroup( type: "week" | "month", date: Date, minDate: Date, maxDate: Date ) {
  minDate = new Date( minDate.getFullYear(), minDate.getMonth(), minDate.getDate(), 0, 0, 0, 0 )
  maxDate = new Date( maxDate.getFullYear(), maxDate.getMonth(), maxDate.getDate(), 0, 0, 0, 0 )

  // first date of month
  const startDate = type === "month" ? new Date( date.getFullYear(), date.getMonth(), 1, 0, 0, 0, 0 ) : new Date( date.getFullYear(), date.getMonth(), date.getDate() - date.getDay(), 0, 0, 0, 0 )

  // last date of month
  const endDate = type === "month" ? new Date( date.getFullYear(), date.getMonth() + 1, 0, 0, 0, 0, 0 ) : new Date( startDate.getFullYear(), startDate.getMonth(), startDate.getDate() + 6, 0, 0, 0, 0 )

  if ( minDate.getTime() <= startDate.getTime() && endDate.getTime() <= maxDate.getTime() ) {
    return formatDates( startDate, endDate, type === "month" )
  }
  else if ( minDate.getTime() > startDate.getTime() && endDate.getTime() <= maxDate.getTime() ) {
    return formatDates( minDate, endDate )
  }
  else if ( minDate.getTime() <= startDate.getTime() && endDate.getTime() > maxDate.getTime() ) {
    return formatDates( startDate, maxDate )
  }
  else {
    return formatDates( minDate, maxDate )
  }
}
