import { Box, Divider, Drawer as MuiDrawer, styled } from '@mui/material'
import { usePrivateLayoutDrawerContext } from 'src/context/PrivateLayoutDrawerContext'
import SideBarHeader from './components/SideBarHeader'
import SideBarRouteList from './components/SideBarRouteList'

const drawerWidth = '280px'
const drawerCloseWidth = '60px'
const Drawer = styled(MuiDrawer)(({ theme }) => ({
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen
  }),
  '&.open': {
    width: drawerWidth,
    '& .MuiDrawer-paper': {
      width: drawerWidth,
      boxSizing: 'border-box',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen
      })
    }
  },
  '&.close': {
    width: drawerCloseWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    '& .MuiDrawer-paper': {
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      }),
      width: drawerCloseWidth
    }
  },
  [theme.breakpoints.down('sm')]: {
    position: 'absolute',
    '&.open': {
      width: '100%',
      '& .MuiDrawer-paper': {
        width: '100%',
        top: '67px',
        paddingTop: '10px'
      }
    },
    '&.close': {
      width: '0',
      '& .MuiDrawer-paper': {
        width: '0',
        top: '67px',
        paddingTop: '10px'
      }
    }
  }
}))

export default function PrivateLayoutDrawer() {
  const { theme, open, privateDrawerChildren } = usePrivateLayoutDrawerContext()

  return (
    <>
      <Drawer
        variant="permanent"
        className={open ? 'open' : 'close'}
        sx={{
          '& .MuiDrawer-paper': {
            bgcolor: theme.colors.navyBlack,
            border: 'none',
            color: 'white',
            borderRadius: '0px 0px 0px 0px',
            // zIndex: 1500,
            overflow: 'visible'
          },
          '& .MuiBox-root::-webkit-scrollbar-track': {
            borderRadius: '10px',
            backgroundColor: theme.colors.navyBlack
          },
          '& .MuiBox-root::-webkit-scrollbar': {
            borderRadius: '4px',
            width: '7px',
            backgroundColor: theme.colors.navyBlack
          },
          '& .MuiBox-root::-webkit-scrollbar-thumb': {
            borderRadius: '10px',
            backgroundColor: '#2F4A5A'
          },
          bgcolor: theme.colors.background
        }}>
        {privateDrawerChildren ? (
          privateDrawerChildren
        ) : (
          <>
            <Box
              sx={{
                display: {
                  xs: 'none',
                  sm: 'block'
                }
              }}>
              <SideBarHeader />
            </Box>
            <SideBarRouteList isSidebarOpen={open} />
            <Divider />
          </>
        )}
      </Drawer>
    </>
  )
}
