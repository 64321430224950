export const BASE_URL = 'app.tryloop.ai'

export const filterNames = {
  b_name: 'Location',
  vb_name: 'Brand',
  vb_platform: 'Marketplace',
  vb_address: 'Address',
  am_name: 'Account Manager',
  slug: 'Digital Stores'
}

export const filterIcons = {
  b_name: 'museum',
  vb_name: 'workspaces',
  vb_platform: 'store',
  slug: 'store'
}

export enum AccessLevelEnum {
  BUSINESS_ADMIN = 'business-admin',
  DEMO = 'demo',
  FINANCE_TEAM = 'finance-team',
  INTERNAL_OPERATOR = 'internal-operator',
  OWNER_VIEW = 'owner-view',
  STORE_MANAGER = 'store-manager',
  OBSERVER = 'observer'
}

export const Modules = {
  StoreAvailability: 'Guard',
  Chargebacks: 'Recover',
  Marketing: 'TruROI',
  ReviewsAndRatings: 'Re-Engage'
}

export const accessLevelConfig: Record<AccessLevelEnum, { label: string }> = {
  [AccessLevelEnum.BUSINESS_ADMIN]: {
    label: 'Business Admin'
  },
  [AccessLevelEnum.DEMO]: {
    label: 'Demo'
  },
  [AccessLevelEnum.FINANCE_TEAM]: {
    label: 'Accounting'
  },
  [AccessLevelEnum.INTERNAL_OPERATOR]: {
    label: 'Franchise Admin'
  },
  [AccessLevelEnum.OWNER_VIEW]: {
    label: 'Owner View'
  },
  [AccessLevelEnum.STORE_MANAGER]: {
    label: 'Store Manager'
  },
  [AccessLevelEnum.OBSERVER]: {
    label: 'Observer'
  }
}

export const swrRefreshIntervalMilliseconds = 45 * 60 * 1000 // 45 minutes
export const automaticallyUpdateFiltersDuration = 10 * 60 * 1000 // 10 minutes

export const blockedOrgIds = ['sandbox', 'worldwide']

export const blockedApiEndpointsForSandbox = [
  'bulkUploadBnamesV2BnamesBulkUploadV2Post',
  'createBnameBnamesPost',
  'updateDigitalStoresStoreMappingStoresUpdatePost',
  'updateBnameBnamesBnameIdPut',
  'deleteBnameBnamesBnameIdDelete',
  'bulkDeleteDigitalStoresFromListDigitalStoresBulkDeleteFromListPost',
  'bulkUploadDigitalStoresV2DigitalStoresBulkUploadV2Post',
  'restoreDeletedStoreStoreMappingDeletedRestorePost',
  'runCustomerOnboardingActionsOnboardingCustomerPost',
  'addLoginUsernameStoreMappingUsernameAddPost',
  'getStatusReasonsBulkGetBackendStatusReasonBulkPost',
  'refreshStoreAvailabilityStoreAvailabilityRefreshSlugPost',
  'setStoresStatusSetStoreStatusPost',
  'setStoresStatusBulkSetStoreOnlineBulkPost',
  'getStatusReasonsGetBackendStatusReasonGet',
  'stopCampaignsStopCampaignPost',
  'startCampaignStartCampaignPost',
  'inviteOrgUserOrgInviteUserPost',
  'triggerEmailDigestNotificationEmailDigestPost',
  'deleteOrgUserOrgUserUserEmailDelete',
  'getOrgUsersOrgUsersGet',
  'inviteOrgUserOrgInviteUserPost',
  'updateBnamesToUserAdminUpdateBnamesPut',
  'createCompanyCodatCreateCompanyPost',
  'getAccessTokensPlaidGetAccessTokensPost',
  'createLinkTokenPlaidCreateLinkTokenPost',
  'setAccessTokenPlaidSetAccessTokenPost',
  'generateReviewSummaryReviewsSummaryGeneratePost',
  'setUserNotificationConfigsNotificationConfigSetPost',
  'triggerPushJournalEntriesAccountingJournalEntriesTriggerPushPost',
  'updateCompanyCodatUpdateConnectionsPost',
  'createAccountingSageCompanySageCreateCompanyPost',
  'createAccountingNetsuiteCompanyNetsuiteCreateCompanyPost',
  'createAccountingR365CompanyR365CreateCompanyPost',
  'createAccountingFreetextCompanyFreetextCreateCompanyPost',
  'authorizeLinkAuthorizeGet',
  'setPlaidMappingsPlaidMappingsSetPost',
  'createLinkTokenPlaidCreateLinkTokenPost',
  'disconnectAccountPlaidDeleteAccountDelete',
  'setBnameMappingsAccountingBnameMappingsSetPost',
  'setAccountingCadenceConfigAccountingConfigurationSetPost',
  'setGlMappingsAccountingGlMappingsSetPost',
  'updateFreetextCompanyGlCodesFreetextCompanyGlCodesPost',
  'deleteAccountingCadenceConfigAccountingConfigurationDeleteDelete',
  'updateR365CompanyClassesR365CompanyClassesPost',
  'updateFreetextCompanyClassesFreetextCompanyClassesPost',
  'reviewReplyReviewReplyPost',
  'getItemMetricsCompetitionItemMetricsPost',
  'getStoresWithItemCompetitionItemStoresPost',
  'updateDigitalStoreDigitalStoresDigitalStoreIdPut',
  'deleteDigitalStoresDigitalStoresDigitalStoreIdDelete',
  'mapUsersToSubchainsSubchainMapUserPost', // assigns users to subchain
  'getAllUserSubChainsSubchainOrgUsersGet', // get all users for org, with assigned subchains
  'createSubchainSubchainSetPost', // create subchain
  'deleteSubchainSubchainDeleteDelete' // delete subchain
]
