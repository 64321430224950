import { MetricsKPIDrillDownBreakDownOptions } from 'src/services/openApi'
import { filterIcons, filterNames } from 'src/utils/config/config'
import JSONDataType from '../types'

const commonColumnsArr: JSONDataType['mainBreakdownOptions'][0]['columns'] = [
  // { title: 'Chargebacks', field: 'total_chargebacks', type: 'currency' }
  // {title: "Error Rate", field: "error_rate_percent", type: "percentage"},
  // { title: '3P Fee %', field: 'third_party_fee_perc', type: 'percentage' },
  // { title: "Total Sales", field: 'total_sales', type: 'currency' },
  { title: 'Average rating', field: 'avg_rating', type: 'number', deltaType: 'positive_delta_green_color' },
  { title: 'Total Reviews', field: 'rating_denominator', type: 'number', deltaType: 'positive_delta_green_color' }
  // {title: "Downtime", field: "downtime_percentage", type: "percentage"},
  // {title: 'Average downtime', field: 'average_downtime_in_minutes', type: 'time_period_formatter'},
  // {title: 'Marketing spend', field: 'total_marketing_spend', type: 'currency'},
]

const jsonData: JSONDataType = {
  title: 'Customer Sentiment',
  mainBreakdownOptions: [
    {
      icon: filterIcons.b_name,
      label: filterNames.b_name,
      value: MetricsKPIDrillDownBreakDownOptions.B_NAME,
      columns: [{ title: filterNames.b_name, field: 'b_name' }, ...commonColumnsArr]
    },
    {
      icon: filterIcons.slug,
      label: filterNames.slug,
      value: MetricsKPIDrillDownBreakDownOptions.SLUG,
      columns: [{ title: filterNames.slug, field: 'slug' }, ...commonColumnsArr]
    },
    {
      icon: filterIcons.vb_name,
      label: filterNames.vb_name,
      value: MetricsKPIDrillDownBreakDownOptions.VB_NAME,
      columns: [{ title: filterNames.vb_name, field: 'vb_name' }, ...commonColumnsArr]
    },
    {
      icon: filterIcons.vb_platform,
      label: filterNames.vb_platform,
      value: MetricsKPIDrillDownBreakDownOptions.VB_PLATFORM,
      columns: [{ title: filterNames.vb_platform, field: 'vb_platform' }, ...commonColumnsArr]
    }
  ],
  finalComponentKey: 'review_and_reply'
  // tabs: {
  //   Overview: 'Overview component'
  // }
}

export default jsonData
