import localforage from 'localforage'

type FilterStorageKey = 'AllFiltersData' | 'SelectedFilters'

export const getDataFromIndexedDB = async (key: FilterStorageKey): Promise<any> => {
  // TODO error handling
  const data = await localforage.getItem(key)
  return data
}

export const saveDataToIndexedDB = (key: FilterStorageKey, data: any): void => {
  localforage
    .setItem(key, data)
    .then(() => {})
    .catch((err) => {
      console.log('Error while saving data to IndexedDB: ', err.message)
    })
}
