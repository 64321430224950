import chargebackJsonData from './objects/Chargebacks'
import errorRateJsonData from './objects/ErrorRate'
import financeJsonData from './objects/FinanceOrderTransactions'
import lostSalesJsonData from './objects/LostSales'
import marketingJsonData from './objects/Marketing'
import metricsJsonData from './objects/MetricsKPI'
import ratingJsonData from './objects/Ratings'
import salesJsonData from './objects/Sales'

export type JSONDataType = typeof financeJsonData

const allJsonData = {
  FinanceOrderTransactions: financeJsonData,
  Ratings: ratingJsonData,
  MetricsKPI: metricsJsonData,
  Chargebacks: chargebackJsonData,
  Sales: salesJsonData,
  LostSales: lostSalesJsonData,
  Marketing: marketingJsonData,
  ErrorRate: errorRateJsonData
}

export default allJsonData
