import { Box, Stack } from '@mui/material'
import DataCard from '../../../DataCard'
import FunnelChart, { FunnelChartData } from '../../../FunnelChart'

interface AdsProps {
  viewsToClickRatio?: number | string
  clickToOrderRatio?: number | string
  funnelChartData?: FunnelChartData
}

export default function Ads(props: AdsProps) {
  return (
    <Box>
      <Stack
        direction="row"
        gap={'10px'}
        flexWrap={'wrap'}>
        {props.viewsToClickRatio && (
          <DataCard
            removeTitleHeight
            title="Views-to-click ratio"
            amount={`${props.viewsToClickRatio}`}
          />
        )}
        {/* {props.clickToOrderRatio && (
          <DataCard
            removeTitleHeight
            title="Click-to-order ratio"
            amount={`${props.clickToOrderRatio}`}
          />
        )} */}
      </Stack>
      {props.funnelChartData && (
        <FunnelChart
          rootSx={{ mt: '12px' }}
          data={props.funnelChartData}
          svgHeight={'132px'}
        />
      )}
    </Box>
  )
}
