import { ChargebackOrdersV2, DefaultService, FiltersForChargebackOrdersQuery } from 'src/services/openApi'

export default async function getChargebacksOrders(body: {
  requestBody: FiltersForChargebackOrdersQuery
  offset: number
  limit: number
  orderBy?: string
  onSuccess: (data: ChargebackOrdersV2) => void
  onError: (err: Error) => void
  onStart: () => void
  onEnd: () => void
}) {
  body.onStart?.call({})
  try {
    let res = await DefaultService.getChargebackOrdersV2ChargebacksOrdersV2Post(body.requestBody, Math.max(100, body.limit), body.offset, body.orderBy)
    body.onSuccess(res)
  } catch (err) {
    body.onError(err)
  }
  body.onEnd?.call({})
}
