import { Paper } from '@LoopKitchen/loop-ui'
import { Box, Divider, Grid, Skeleton, Stack, Typography } from '@mui/material'
import { get } from 'lodash'
import React from 'react'
import LogosCombined from 'src/assets/images/short_logos_combined.svg'
import DeltaCard from 'src/components/DeltaCard'
import PlatformLogo from 'src/components/PlatformLogo'
import { PlatformBreakdownSwitch } from 'src/components/Switches'
import TabPanel from 'src/components/TabPanel'
import { SectionLink, SectionLinkBtn } from 'src/components/mui/Reusable'
import { useAuth } from 'src/context/AuthContext'
import { useErrorData } from 'src/context/ErrorContext'
import { useFilter } from 'src/context/FilterContext'
import { useLoadingData } from 'src/context/LoadingContext'
import { ChargebackOrderSummary, DefaultService, DisputeResponse, ExternalStatus } from 'src/services/openApi'
import { track } from 'src/utils/analytics/analytics'
import { formatCurrency } from 'src/utils/functions'
import ErrorsRevenueRecapture from '../Tabs/ErrorsRevenueRecapture'
import StatusLogo from './StatusLogo'

type ChargebackOverviewCompProps =
  | {
      tabValue: 'overview'
      setTabValue: React.Dispatch<React.SetStateAction<'overview'>>
    }
  | {}

export default function ChargebackOverviewComp(props: ChargebackOverviewCompProps) {
  const { currentUser } = useAuth()
  const { getFilters, getFiltersV2 } = useFilter()
  const [tabValue, setTabValue] = 'tabValue' in props ? [props.tabValue, props.setTabValue] : React.useState<'overview'>('overview')
  const { loading, setLoading } = useLoadingData()
  const [disputeResponse, setDisputeResponse] = React.useState<DisputeResponse>(null)
  const { handleError } = useErrorData()
  const [showPlatformSummary, setShowPlatformSummary] = React.useState(false)

  const [summaryData, setSummaryData] = React.useState<ChargebackOrderSummary>(null)

  const { gridSize, columns } = React.useMemo(() => {
    if (!summaryData || !get(summaryData, 'self_disputable', false)) {
      return {
        columns: 12,
        gridSize: {
          xs: 12,
          md: 6,
          lg: 3
        }
      }
    } else {
      return {
        columns: 10,
        gridSize: {
          xs: 10,
          md: 5,
          lg: 2
        }
      }
    }
  }, [summaryData])

  const getSummaryData = async () => {
    try {
      let res = await DefaultService.getChargebackOrdersSummaryV2ChargebacksOrdersSummaryV2Post({
        ...getFiltersV2(['b_name', 'vb_name', 'vb_platform', 'chain', 'am_name', 'start_date', 'end_date']),
        // external_status_in: 'ACCEPTED|IN_PROGRESS|DENIED|TO_BE_RAISED|EXPIRED',
        order_state_in: 'CHARGEBACK|LOST_SALE|REVERSAL'
      })
      setSummaryData(res)
    } catch (err) {
      handleError(err.message)
    }
  }

  const customDeltaCard = (label: string, key: ExternalStatus) => {
    const title =
      key === ExternalStatus.ACCEPTED
        ? get(summaryData || {}, `accepted_summary["${key}"]["total_reversals"]`, 0)
        : get(summaryData || {}, `external_summary["${key}"]["total_error_charges"]`, 0)

    const doordash = {
      value:
        key === ExternalStatus.ACCEPTED
          ? get(summaryData || {}, `accepted_summary["${key}"]["Doordash"]["reversals"]`, 0)
          : get(summaryData || {}, `external_summary["${key}"]["Doordash"]["error_charges"]`, 0),
      orders: get(summaryData || {}, `external_summary["${key}"]["Doordash"]["count"]`, 0)
    }
    const ubereats = {
      value:
        key === ExternalStatus.ACCEPTED
          ? get(summaryData || {}, `accepted_summary["${key}"]["UberEats"]["reversals"]`, 0)
          : get(summaryData || {}, `external_summary["${key}"]["UberEats"]["error_charges"]`, 0),
      orders: get(summaryData || {}, `external_summary["${key}"]["UberEats"]["count"]`, 0)
    }
    const grubhub = {
      value:
        key === ExternalStatus.ACCEPTED
          ? get(summaryData || {}, `accepted_summary["${key}"]["Grubhub"]["reversals"]`, 0)
          : get(summaryData || {}, `external_summary["${key}"]["Grubhub"]["error_charges"]`, 0),
      orders: get(summaryData || {}, `external_summary["${key}"]["Grubhub"]["count"]`, 0)
    }

    return (
      <DeltaCard
        label={
          <Stack
            direction="row"
            alignItems="center"
            spacing={1}
            style={{ marginBottom: '0.9rem' }}>
            <span style={{ fontSize: '16px' }}>{label} </span>
            {key === ExternalStatus.IN_PROGRESS && (
              <StatusLogo
                status={ExternalStatus.IN_PROGRESS}
                animate
              />
            )}
          </Stack>
        }
        labelTooltip={key === ExternalStatus.ACCEPTED ? "The won amount includes lost sales that we've been able to get back for you" : null}
        loading={summaryData === null}
        title={formatCurrency(title)}
        extraFields={
          key === ExternalStatus.ACCEPTED
            ? [
                {
                  label: 'Attributed',
                  value: formatCurrency(get(summaryData || {}, `accepted_summary["${key}"]["total_attributed_reversals"]`, 0)),
                  valueSx: { fontSize: '12px' },
                  labelSx: { fontWeight: 500 }
                },
                {
                  label: 'Unattributed',
                  value: formatCurrency(get(summaryData || {}, `accepted_summary["${key}"]["total_unattributed_reversals"]`, 0)),
                  valueSx: { fontSize: '12px' },
                  labelSx: { fontWeight: 500 }
                }
              ]
            : []
        }
        removeMaxWidth
        extraComp={
          showPlatformSummary && (
            <Stack
              direction="column"
              spacing={1}
              p={1}
              mt={1}>
              {[
                {
                  name: 'grubhub',
                  value: grubhub.value
                },
                {
                  name: 'ubereats',
                  value: ubereats.value
                },
                {
                  name: 'doordash',
                  value: doordash.value
                }
              ].map((item) => {
                if ('type' in item) {
                  return <Divider />
                }
                /**
                 * image ratio
                 * grubhub:ubereats:doordash = 1:3:4
                 */

                return (
                  <Stack
                    direction="row"
                    alignItems="center"
                    spacing={2}>
                    {/* <img
                      src={platformImg[item.name]}
                      alt={item.name}
                      style={{
                        height,
                        flex: 1,
                        objectFit: 'contain',
                        objectPosition: '0% 50%'
                      }}
                    /> */}
                    <PlatformLogo
                      platformName={item.name}
                      height={'25px'}
                      width={'25px'}
                      shortImg
                      boxSx={{
                        flex: '0'
                      }}
                    />

                    {loading ? (
                      <Skeleton
                        width="50%"
                        height="18px"
                      />
                    ) : (
                      <>
                        <Typography
                          variant="subtitle2"
                          color="rgba(0,0,0,0.5)">
                          {formatCurrency(item.value)}
                        </Typography>
                      </>
                    )}
                    {/* <Stack
                    direction="row"
                    alignItems={'center'}
                    justifyContent="space-between"
                    mt={'-5px'}
                  >
                    <Typography variant="subtitle2" component="p">
                      {formatCurrency(item.value)}
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      component="p"
                      color="rgba(0,0,0,0.5)"
                    >
                      ({item.orders}) orders
                    </Typography>
                  </Stack> */}
                  </Stack>
                )
              })}
            </Stack>
          )
        }
        sx={{ px: '10px', py: '8px' }}
      />
    )
  }

  React.useEffect(() => {
    track('chargebacks', currentUser.org, currentUser.email.replace('@', '-'))
  }, [])

  React.useEffect(() => {
    getSummaryData()
  }, [...getFilters(['b_name', 'vb_name', 'vb_platform', 'chain', 'am_name', 'start_date', 'end_date'])])

  return (
    <Box sx={{ background: 'white' }}>
      <Paper sx={{ m: 0, p: 0 }}>
        <SectionLink
          id="chargeback-summary"
          sx={{ mb: 2 }}>
          <Stack
            direction="row"
            alignItems="center"
            spacing={2}
            flexWrap="wrap">
            <Stack
              direction="row"
              alignItems={'center'}
              spacing={1}
              flex={1}>
              <Typography variant="h3">Summary</Typography>
              <SectionLinkBtn link="chargeback-summary" />
            </Stack>
            <Stack
              direction="row"
              alignItems="center"
              spacing={1}>
              <Box
                sx={{
                  p: '8px',
                  display: 'inline-flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                  cursor: 'pointer',
                  border: '1px solid #EDEDED',
                  borderRadius: '4px',
                  background: showPlatformSummary ? '#EDEDED' : '#FFF'
                }}
                onClick={() => {
                  setShowPlatformSummary(!showPlatformSummary)
                }}>
                <img
                  src={LogosCombined}
                  alt="logos combined"
                  style={{ width: '45px' }}
                />
                <Typography
                  fontSize="12px"
                  fontWeight={600}
                  mx="5px">
                  Platform breakdown
                </Typography>
                <PlatformBreakdownSwitch checked={showPlatformSummary} />
              </Box>
            </Stack>
          </Stack>

          <Grid
            container
            spacing={2}
            columns={columns}
            sx={{ mt: 2 }}>
            <Grid
              item
              {...gridSize}>
              {customDeltaCard('Total Chargebacks', ExternalStatus.ALL_ELIGIBLE)}
            </Grid>
            {get(summaryData, 'self_disputable', false) && (
              <Grid
                item
                {...gridSize}>
                {customDeltaCard(
                  // Raised
                  'To be Raised',
                  ExternalStatus.TO_BE_RAISED
                )}
              </Grid>
            )}
            <Grid
              item
              {...gridSize}>
              {customDeltaCard(
                // Approved
                'In Progress',
                ExternalStatus.IN_PROGRESS
              )}
            </Grid>
            <Grid
              item
              {...gridSize}>
              {customDeltaCard(
                // "Need Images",
                'Accepted',
                ExternalStatus.ACCEPTED
              )}
            </Grid>
            <Grid
              item
              {...gridSize}>
              {customDeltaCard('Denied', ExternalStatus.DENIED)}
            </Grid>
          </Grid>
        </SectionLink>
      </Paper>

      <Box mt={'32px'}>
        <TabPanel
          index="overview"
          value={tabValue}>
          <ErrorsRevenueRecapture />
        </TabPanel>
      </Box>
    </Box>
  )
}
