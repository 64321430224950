import { CSVLink } from "react-csv";
// import FileDownloadIcon from '@mui/icons-material/FileDownload';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import usePostHogHook from 'src/utils/hooks/usePostHogHook'

const ExportCSV = ({ csvHeaders, csvData, fileName, showIcon = false }) => {
  const { trackPostHogDownload } = usePostHogHook()
  return (
    <CSVLink
      headers={csvHeaders}
      data={csvData}
      filename={fileName}
      onClick={() => {
        trackPostHogDownload({
          fileName,
          type: 'CSV'
        })
      }}
    >
      <span style={{ color: 'rgba(0, 0, 0, 0.6)' }}>
        {showIcon ? <FileDownloadOutlinedIcon color="inherit" /> : 'Export CSV'}
      </span>
    </CSVLink>
  )
}

export default ExportCSV;
