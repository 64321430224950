import { Box } from '@mui/material'
import { Outlet } from 'react-router-dom'
import { useAuth } from 'src/context/AuthContext'

export default function AuthLayout() {
  const { magicLinkLoading } = useAuth()
  return (
    <Box sx={{ visibility: magicLinkLoading ? 'hidden' : 'visible' }}>
      <Box component="main">
        <Outlet />
      </Box>
    </Box>
  )
}
