import { Paper } from '@LoopKitchen/loop-ui'
import { Box, CircularProgress } from '@mui/material'
import React from 'react'
import SimpleBarChart from '../Errors/components/SimpleBarChart'
// import useFilter from 'src/utils/hooks/useFilter'
import map from 'lodash/map'
import NoGraphDataImg from 'src/assets/images/no_data.png'
import EmptyScreen from 'src/components/EmptyScreen/EmptyScreen'
import { useAuth } from 'src/context/AuthContext'
import { useFilter } from 'src/context/FilterContext'
import { DefaultService } from 'src/services/openApi'
import { emptyScreenMessages } from 'src/utils/constants/emptyScreen'
import { measurePerformance } from 'src/utils/functions/performance'
import { ApiKpiSalesOverviewType } from './types'

interface PlatformChartProps {
  tab: number
}

const PlatformChart = (props: PlatformChartProps) => {
  const [graphData, setGraphData] = React.useState([])
  const { currentUser } = useAuth()
  const [graphDataLoading, setGraphDataLoading] = React.useState(true)
  const { getFilters, getFiltersV2 } = useFilter()
  const procedureNameArray = [
    'data_visualization_metrics.procedure_dashboard__sales_overview__sales__platform',
    'data_visualization_metrics.procedure_dashboard__sales_overview__ordercount__platform',
    'data_visualization_metrics.procedure_dashboard__sales_overview__payout__platform'
  ]
  React.useEffect(() => {
    ;(async () => {
      setGraphDataLoading(true)
      try {
        const responseData = (await measurePerformance(
          async () => {
            return await DefaultService.getSalesOverviewKpiSalesOverviewPost({
              ...getFiltersV2(['b_name', 'vb_name', 'chain', 'vb_platform', 'am_name', 'start_date', 'end_date'], true)
            })
          },
          {
            procedure: procedureNameArray[props.tab],
            email: currentUser.email,
            org: currentUser.org
          }
        )) as ApiKpiSalesOverviewType
        if (responseData) {
          setGraphData(
            map(responseData.sales_platform, (item, index) => ({
              id: index + 1,
              name: item.platform,
              // count: props.tab === 0 ? item.sales : item.orders,
              sales: item.sales,
              orders: item.orders,
              color: '#196E82'
            }))
          )
          setGraphDataLoading(false)
        }
      } catch (error) {
        setGraphDataLoading(false)
        console.log(error)
      }
    })()
  }, [currentUser, ...getFilters(['b_name', 'vb_name', 'vb_platform', 'am_name', 'start_date', 'end_date'])])

  const formattedGraphData = React.useMemo(() => {
    if (!Array.isArray(graphData)) {
      return []
    }
    return graphData.map((item) => ({
      ...item,
      count: props.tab === 0 ? item.sales : item.orders,
      symbol: props.tab !== 1 ? '$' : undefined
    }))
  }, [graphData, props.tab])

  return (
    <Paper>
      {graphDataLoading ? (
        <Box sx={{ width: '100%', textAlign: 'center', p: 3 }}>
          <CircularProgress />
        </Box>
      ) : graphData && graphData.length === 0 ? (
        <EmptyScreen
          NoDataImg={NoGraphDataImg}
          labels={emptyScreenMessages.emptyGraph}
        />
      ) : (
        <SimpleBarChart
          key={props.tab}
          data={formattedGraphData}
          xKey="name"
          yKey={'count'}
        />
      )}
    </Paper>
  )
}

export default PlatformChart
