import React from 'react'
import CustomSwitch from 'src/pages/members/dashboard/components/CustomSwitch'
import { ThirdPartyFeeDatewise } from 'src/services/openApi'
import { formatCurrency, formatNumber } from 'src/utils/functions'
import Chart from '../Chart'

interface ThreePFeeChartProps {
  loading?: boolean
  data: ThirdPartyFeeDatewise[]
}

export default function ThreePFeeChart(props: ThreePFeeChartProps) {
  const { loading: feeLoading, data: feeData } = props
  const [showBreakdown, setShowBreakdown] = React.useState(false)
  return (
    <>
      <Chart
        title="3P Fee"
        loading={feeLoading}
        data={feeData || []}
        stacked={showBreakdown}
        series={
          showBreakdown
            ? [
                {
                  color: '#EEF9DD',
                  name: 'Commission',
                  type: 'bar',
                  data: feeData?.map((item) => item.commission_perc) || []
                },
                {
                  color: '#FFE8C5',
                  name: 'Chargeback',
                  type: 'bar',
                  data: feeData?.map((item) => item.chargeback_perc) || []
                },
                {
                  color: '#E2E9FA',
                  name: 'Marketing',
                  type: 'bar',
                  data: feeData?.map((item) => item.marketing_perc) || []
                },
                {
                  color: '#179942',
                  name: 'Others',
                  type: 'bar',
                  data: feeData?.map((item) => item.others_perc) || []
                }
              ]
            : [
                {
                  color: '#004DE5',
                  name: 'Third party fee',
                  data: feeData?.map((item: ThirdPartyFeeDatewise) => item.third_party_fee) || [],
                  type: 'area'
                },
                {
                  color: '#179942',
                  name: 'Third Party Fee (in percentage)',
                  data: feeData?.map((item: ThirdPartyFeeDatewise) => item.third_party_fee_perc) || [],
                  type: 'area'
                }
              ]
        }
        options={
          showBreakdown
            ? [
                {
                  opacity: 0.5,
                  colorType: 'solid',
                  yaxis: {
                    min: 0,
                    max: 100,
                    tickAmount: 5,
                    labels: {
                      formatter: (value) => {
                        return `${formatNumber(value, { notation: 'compact' })}%`
                      }
                    }
                  }
                }
              ]
            : [
                {
                  colorType: 'gradient',
                  opacity: 0.5,
                  yaxis: {
                    seriesName: 'Third party fee',
                    labels: {
                      style: {
                        colors: '#004DE5'
                      },
                      formatter: (value) => {
                        return `${formatCurrency(value, {
                          notation: 'compact'
                        })}`
                      }
                    }
                  }
                },
                {
                  colorType: 'gradient',
                  opacity: 0.5,
                  yaxis: {
                    opposite: true,
                    seriesName: 'Third Party Fee (in percentage)',
                    min: 0,
                    max: 100,
                    tickAmount: 5,
                    labels: {
                      style: {
                        colors: '#179942'
                      },
                      formatter: (value) => {
                        return `${formatNumber(value, {
                          notation: 'compact'
                        })}%`
                      }
                    }
                  }
                }
              ]
        }
        actionComp={
          <>
            <CustomSwitch
              value={showBreakdown}
              setValue={setShowBreakdown}
            />
          </>
        }
      />
    </>
  )
}
