import axios from 'axios'

interface CreateAuthTokensRequestBody {
  userUidList: string[]
  expiresInSeconds?: number
}

interface CreateAuthTokensResponseBody {
  userList: Array<{ userUid: string; token: string }>
}

export const createAuthTokens = async (params: CreateAuthTokensRequestBody) => {
  return (await axios.post(`${process.env.REACT_APP_FIREBASE_FUNCTION}/createAuthTokens`, params)).data as CreateAuthTokensResponseBody
}
