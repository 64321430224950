import Chip from '@mui/material/Chip'
import CustomTypo from 'src/components/StyledTypo'

interface TableChipProps {
  label: string
  variant?: string
  size?: 'small' | 'medium'
}

const TableChip = (props: TableChipProps) => {
  const bgcolor =
    props.variant === 'primary'
      ? '#E0EBFF'
      : props.variant === 'error'
      ? '#FFE6E6'
      : props.variant === 'warning'
      ? '#FDE9DB'
      : '#EBECF2'
  const color =
    props.variant === 'primary'
      ? '#004DE6'
      : props.variant === 'error'
      ? '#E62E2E'
      : props.variant === 'warning'
      ? '#A1673F'
      : '#12131A'
  return (
    <Chip
      label={
        <CustomTypo fw={400} color={color} sx={{ whiteSpace: 'normal', p: 1 }}>
          {props.label}
        </CustomTypo>
      }
      size={props.size}
      sx={{
        bgcolor,
        height: 'auto'
      }}
    />
  )
}

export default TableChip
