import { Box, Grid, Stack, Typography } from '@mui/material'
import React from 'react'
import { AverageUptimeAggregateDateRangeCompare, DefaultService, UptimeByPlatformPaginated } from 'src/services/openApi'
// import platformCombined from 'src/assets/images/short_logos_combined.svg'
import CircularLoader from 'src/components/CircularLoader'
// import StyledTypo from 'src/components/StyledTypo'
// import { IOSSwitch } from 'src/components/Switches'
import { get } from 'lodash'
import moment from 'moment'
import { useErrorData } from 'src/context/ErrorContext'
import { useFilter } from 'src/context/FilterContext'
import { formatNumber } from 'src/utils/functions'
import { pluralize } from 'src/utils/functions/pluralize'
import { formatDowntimeToLostSales } from '../../utils/formatUptimeData'
import StoreRestoreCard from '../StoreRestoreSummaryComps/StoreRestoreCard'
import ChartForDowntime from './Chart'

export type GraphDataType = {
  date: string
  lost_sales_opportunity: {
    doordash: number
    ubereats: number
    grubhub: number
  }
  uptime_percent: {
    doordash: number
    ubereats: number
    grubhub: number
  }
}

interface GraphContainerProps {
  uptimeDataLoading: boolean
  uptimeData: UptimeByPlatformPaginated | null
}
export type SeriesType = {
  x: string
  missed?: number
  canceled?: number
  lost_sales: number
  grubhub?: number
  ubereats?: number
  doordash?: number
  date?: number
}
export default function GraphContainer(props: GraphContainerProps) {
  const { uptimeData, uptimeDataLoading } = props
  const { getFilters, getFiltersV2 } = useFilter()
  const { handleError } = useErrorData()
  const [downtimeData, setDowntimeData] = React.useState<AverageUptimeAggregateDateRangeCompare>(null)
  const [downtimeDataLoading, setDowntimeDataLoading] = React.useState(false)

  const getDowntimeData = async () => {
    setDowntimeDataLoading(true)
    try {
      let data = await DefaultService.averageUptimeAggregateDateRangeCompareApiDateRangeCompareAverageUptimeAggregatePost({
        ...getFiltersV2(['b_name', 'b_name_id', 'vb_name', 'vb_platform', 'start_date', 'end_date', 'chain'], true)
      })
      setDowntimeData(data)
    } catch (err) {
      handleError(err.message)
    } finally {
      setDowntimeDataLoading(false)
    }
  }

  const { graphDataForDownTime } = React.useMemo(() => {
    const data = formatDowntimeToLostSales(uptimeData)
    data.sort((a, b) => moment(a.date).toDate().valueOf() - moment(b.date).toDate().valueOf())

    data.forEach((item) => {
      item.date = moment(item.date).format('D MMM')
    })

    return {
      graphDataForDownTime: data
    }
  }, [uptimeData])

  React.useEffect(() => {
    getDowntimeData()
  }, [...getFilters(['chain', 'start_date', 'end_date', 'b_name', 'vb_name', 'vb_platform', 'am_name'])])

  return (
    <>
      <Box sx={{ bgcolor: 'white' }}>
        <Grid
          container
          sx={{ mt: 1 }}>
          <Grid
            item
            sm={12}>
            <StoreRestoreCard.BorderBox sx={{ px: '20px', py: '16px' }}>
              <StoreRestoreCard
                loading={downtimeDataLoading}
                label="Average Downtime"
                labelSx={{
                  fontWeight: 600
                }}
                value={`${formatNumber(get(downtimeData, 'current_range_data.downtime_minutes', 0) as number, { maxFractionDigits: 2 })} ${pluralize('min', get(downtimeData, 'current_range_data.downtime_minutes', 0) as number)}`}
                deltaChipProps={{
                  delta: Number(get(downtimeData, 'percentages.downtime_minutes', 0)) as number,
                  deltaType: Number(get(downtimeData, 'percentages.downtime_minutes', 0) as number) > 0 ? 'error' : 'success',
                  tooltipTitle: `Previous average downtime ${formatNumber(get(downtimeData, 'previous_range_data.downtime_minutes', 0) as number, {
                    maxFractionDigits: 2
                  })} minutes`
                }}
                boxSx={{
                  display: 'inline-flex'
                }}
              />

              <Stack sx={{ mt: '14px' }}>
                {uptimeDataLoading ? (
                  <CircularLoader />
                ) : graphDataForDownTime === null ? (
                  <Typography
                    variant="subtitle2"
                    textAlign={'center'}
                    sx={{ py: 2 }}>
                    Downtime data not found
                  </Typography>
                ) : (
                  <Box>
                    <ChartForDowntime data={graphDataForDownTime} />
                  </Box>
                )}
              </Stack>
            </StoreRestoreCard.BorderBox>
          </Grid>
        </Grid>
      </Box>
    </>
  )
}
