import DocumentScannerIcon from '@mui/icons-material/DocumentScanner'
import { Box, Divider, List, ListItemButton, ListItemText, Paper, Popper, Stack, Typography, useMediaQuery } from '@mui/material'
import { get } from 'lodash'
import React from 'react'
import { Link } from 'react-router-dom'
import { useAuth } from 'src/context/AuthContext'
import { usePrivateLayoutDrawerContext } from 'src/context/PrivateLayoutDrawerContext'
import SubNav, { activeColor, inactiveColor } from './SubNav'

type PopupMenuProps = {
  open: boolean
  anchorElement: HTMLElement | null
  closeSubnavPopup?: () => void
  getLink?: (page: any, index: number) => string
  hasSubnav?: boolean
  label?: string
  page?: any
  isActive?: boolean
}

const PopupMenu = (props: PopupMenuProps) => {
  const { open, anchorElement, closeSubnavPopup, getLink, page, hasSubnav, label, isActive } = props
  const { theme } = usePrivateLayoutDrawerContext()
  const { posthogCapture } = useAuth()
  const sm = useMediaQuery(theme.breakpoints.down('sm'))

  const isPopperOpen = React.useMemo(() => {
    return !open && !!anchorElement && !sm
  }, [open, anchorElement])

  return (
    <Popper
      open={isPopperOpen}
      anchorEl={anchorElement}
      placement="right"
      disablePortal>
      <Stack
        direction={'row'}
        alignContent={'stretch'}>
        <Box sx={{ width: 14, height: '100%', bgcolor: 'transparent' }} />
        <Paper
          elevation={2}
          sx={{ bgcolor: '#122937', p: 2 }}>
          <Typography sx={{ color: hasSubnav ? '#fffb' : isActive ? activeColor : inactiveColor, fontSize: 10, px: 1.5, fontWeight: 600 }}>
            {label?.toUpperCase() || page?.label?.toUpperCase()}
          </Typography>
          {hasSubnav && <Divider sx={{ bgcolor: '#fffb', my: 1 }} />}
          {hasSubnav && (
            <List
              component="div"
              sx={{ py: 0 }}>
              {get(props.page, 'subNav', []).map((subPage, index) => {
                const isActive = location.pathname.startsWith(`/${page.key}/${subPage.key}`)
                if (isActive) {
                  document.title = subPage?.pageTitle || `Loop - ${page.label} - ${subPage.label}`
                }
                if (get(subPage, 'subNav', []).length > 0) {
                  return (
                    <Box key={subPage.key}>
                      <SubNav
                        open={true}
                        closeDrawer={() => {}}
                        page={subPage}
                        parentKey={page.key}
                        icon={subPage.icon || <DocumentScannerIcon />}
                      />
                    </Box>
                  )
                }
                return (
                  <ListItemButton
                    sx={{
                      color: isActive ? activeColor : inactiveColor,
                      bgcolor: isActive ? 'rgba(25, 110, 130, 0.5)' : 'transparent',
                      '&:hover': { bgcolor: 'rgba(25, 110, 130, 0.3)' },
                      pl: 1.5,
                      py: '6px',
                      borderRadius: '3px'
                    }}
                    component={Link}
                    onTouchEnd={(event) => {
                      event?.currentTarget?.click()
                      if (closeSubnavPopup) closeSubnavPopup()
                    }}
                    onClick={() => {
                      posthogCapture('sidebar_link_clicked', { href: `${page.label}/${subPage.label}` })
                    }}
                    to={getLink(page, index)}
                    key={subPage.key}>
                    <ListItemText
                      primary={subPage.label}
                      sx={{ '& span': { fontWeight: isActive && 600 } }}
                    />
                  </ListItemButton>
                )
              })}
            </List>
          )}
        </Paper>
      </Stack>
    </Popper>
  )
}

export default PopupMenu
