import { Icon, Stack, Typography } from '@mui/material'

interface DataNotAvailableProps {
  icon: string
  label: string
}

export default function DataNotAvailable(props: DataNotAvailableProps) {
  const { icon, label } = props

  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      height="200px"
      gap="10px"
      sx={{ borderRadius: '10px', border: '1px solid rgba(218, 218, 218, 1)' }}>
      <Icon sx={{ fontSize: '78px', color: 'rgba(218, 218, 218, 1)' }}>{icon}</Icon>
      <Typography
        fontSize="14px"
        color="#000"
        fontWeight={600}>
        {label}
        {/* {type === 'review' ? 'No rating or review' : 'No feedback'} */}
      </Typography>
    </Stack>
  )
}
