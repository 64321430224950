import BrowserUpdatedIcon from '@mui/icons-material/BrowserUpdated'
import TonalityIcon from '@mui/icons-material/Tonality'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import IconButton from '@mui/material/IconButton'
import React, { useEffect } from 'react'

import { Paper } from '@LoopKitchen/loop-ui'

// import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined'
// import DownloadingIcon from '@mui/icons-material/Downloading'
import { Box, CircularProgress, Grid, Typography } from '@mui/material'
// import TooltipCover from './TooltipCover'
// import InfoIcon from '@mui/icons-material/Info'
import { CustomMaterialTable } from '@LoopKitchen/loop-ui'
import { Info } from '@mui/icons-material'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import CloseIcon from '@mui/icons-material/Close'
import WarningIcon from '@mui/icons-material/Warning'
import { Stack } from '@mui/system'
import get from 'lodash/get'
import isNaN from 'lodash/isNaN'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'
import PlatformLogo from 'src/components/PlatformLogo'
import { CustomTypoContainer, TypoContainer } from 'src/components/StyledTypo'
import { useAuth } from 'src/context/AuthContext'
import { useErrorData } from 'src/context/ErrorContext'
import { useFilter } from 'src/context/FilterContext'
import { useLoadingData } from 'src/context/LoadingContext'
import { useSnackData } from 'src/context/SnackContext'
import { DefaultService } from 'src/services/openApi'
import { filterNames } from 'src/utils/config/config'
import { measurePerformance } from 'src/utils/functions/performance'

interface RefreshProps {
  open: boolean
  to: string
  closeDrawer?: () => void
  isVisible?: boolean
}
const RefreshStatus = React.memo(
  ({
    open,
    to,
    closeDrawer
  }: // isVisible = false
  RefreshProps) => {
    const { currentUser, posthogCapture } = useAuth()
    const { setSnack } = useSnackData()
    const { setLoading } = useLoadingData()
    const { handleError } = useErrorData()
    const { refreshBusinessData, getAllPossibleFilters, lastDate, getFilters, getFiltersV2 } = useFilter()
    const {
      // nextDate,
      status
    } = refreshBusinessData
    const navigate = useNavigate()
    // const datePrev = new Date()
    // const dateNext = new Date()
    // const dateCurr = new Date()
    // const datePrev = new Date()
    // const dateNext = new Date()
    // const dateCurr = new Date()
    const [anchorEl, setAnchorEl] = React.useState(null)
    const [failedResult, setFailedResult] = React.useState([])
    const [totalFailedStores, setTotalFailedStores] = React.useState(0)
    const [tempFailedStores, setTempFailedStores] = React.useState(0)
    const [storeDivisions, setStoreDivisions] = React.useState([])
    const [isLoading, setIsLoading] = React.useState(false)

    const showRefreshDateNotification = React.useMemo(() => {
      const selectedBNames = getFiltersV2(['b_name'])?.b_name_in?.split('|')
      const allBNames = getAllPossibleFilters(['b_name'])?.b_name

      return open && selectedBNames.length !== 0 && selectedBNames[0] !== '' && selectedBNames.length !== allBNames.length
    }, [getFiltersV2(['b_name'])?.b_name_in, getAllPossibleFilters(['b_name'])?.b_name?.join('|')])

    useEffect(() => {
      async function getData() {
        setIsLoading(true)
        try {
          let result = await getFailedStores()
          const host_kitchens = get(currentUser, 'host_kitchens', []).map((item) => item.label)
          result = host_kitchens.length > 0 ? result.filter((item) => host_kitchens.includes(item.b_name)) : result
          const tempFailedResult = result.filter((item) => isNaN(Date.parse(item.lastDate)))
          setTotalFailedStores(result.length)
          setTempFailedStores(result.length - tempFailedResult.length)
        } catch (error) {
          handleError(error.message)
        }
        setIsLoading(false)
      }
      if (currentUser) getData()
    }, [currentUser, ...getFilters(['b_name'])])

    const handleClick = async (event: React.MouseEvent<HTMLButtonElement>) => {
      try {
        event.stopPropagation()
        posthogCapture('data_freshness_clicked')
        setAnchorEl(event.currentTarget)
        let result = await getFailedStores()
        const host_kitchens = get(currentUser, 'host_kitchens', []).map((item) => item.label)
        result = host_kitchens.length > 0 ? result.filter((item) => host_kitchens.includes(item.b_name)) : result
        const tempFailedResult = result.filter((item) => isNaN(Date.parse(item.lastDate)))
        setFailedResult(tempFailedResult)
        const res = Object.values(
          tempFailedResult.reduce((a, curr) => {
            const { lastDate } = curr
            const key = `${lastDate}`
            ;(a[key] = a[key] || []).push(curr)
            return a
          }, {})
        )
        const finalResult = []
        res.forEach((obj) => {
          const lastDate = obj[0]['lastDate']
          if (lastDate !== 'NaT') {
            const noOfStores = Object.keys(obj).length
            const details = {
              lastDate: lastDate,
              stores: noOfStores
            }
            finalResult.push(details)
          }
        })
        setStoreDivisions(finalResult)
      } catch (error) {
        setSnack({ open: true, text: error.message, type: 'error' })
        handleError(error.message)
      }
    }

    const handleClose = () => {
      setAnchorEl(null)
    }

    async function getFailedStores() {
      try {
        setLoading(true)
        const response: any = await measurePerformance(
          async () => {
            return await DefaultService.runProcedureAndGetDataV2RunQueryV2Post('business_refresh_vb_name_level', {
              chain_in: currentUser.org,
              b_name_in: getFiltersV2(['b_name']).b_name_in
            })
          },
          {
            procedure: 'business_refresh_vb_name_level',
            email: currentUser.email,
            org: currentUser.org
          }
        )
        setLoading(false)
        return response
      } catch (error) {
        setLoading(false)
        throw error
      }
    }
    const showPopUp = Boolean(anchorEl)

    return (
      <>
        <Dialog
          open={showPopUp}
          onClose={handleClose}
          sx={{
            '& .MuiDialog-container': {
              '& .MuiPaper-root': {
                width: '100%',
                maxWidth: '1100px'
              }
            }
          }}>
          <Box
            display="flex"
            justifyContent="space-between"
            sx={{ height: '11rem' }}>
            <Box sx={{ display: 'flex', alignItems: 'end' }}>
              <DialogTitle
                sx={{ padding: '0px', marginLeft: '45px' }}
                id="alert-dialog-title">
                <Typography
                  align="inherit"
                  sx={{ fontSize: '24px', fontWeight: '600', color: '#122937' }}>
                  Unrefreshed stores
                </Typography>
                <Typography
                  sx={{
                    fontSize: '14px',
                    fontWeight: '400',
                    color: 'rgba(0, 0, 0, 0.5)'
                  }}>
                  Last refresh: {moment(lastDate).format('DD MMM, H:HH, YYYY')}
                </Typography>
              </DialogTitle>
            </Box>
            <TypoContainer
              sx={{
                pr: 2,
                display: 'flex',
                justifyContent: 'flex-start',
                mt: 2
              }}>
              <IconButton
                onClick={handleClose}
                sx={{ width: 35, height: 35 }}>
                <CloseIcon fontSize="large" />
              </IconButton>
            </TypoContainer>
          </Box>

          <DialogContent>
            <Typography
              sx={{
                width: 'max-content',
                fontSize: '14px',
                fontWeight: '600',
                marginLeft: '23px'
              }}>
              Brand Refreshed
            </Typography>
            <Grid
              container
              spacing={2}
              sx={{ my: 2 }}>
              {storeDivisions.map((item, index) => {
                return (
                  <Grid
                    item
                    xs={6}
                    md={4}
                    sx={{ textAlign: 'center' }}>
                    <Box
                      sx={{ p: 3, textAlign: 'center' }}
                      component={Paper}
                      elevation={1}>
                      <Box
                        display="flex"
                        textAlign="center"
                        justifyContent="center">
                        <Typography
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            fontSize: '32px',
                            fontWeight: '400',
                            color: 'rgba(108, 171, 186, 0.5)'
                          }}>
                          {item.stores}
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: '18px',
                            fontWeight: '400',
                            color: 'rgba(0, 0, 0, 0.5)',
                            display: 'flex',
                            alignItems: 'center',
                            margin: '0px 5px'
                          }}>
                          /
                        </Typography>
                        <Typography
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            fontSize: '32px',
                            fontWeight: '600',
                            color: 'rgba(108, 171, 186, 1)'
                          }}>
                          {totalFailedStores}
                        </Typography>
                      </Box>
                      <Typography
                        sx={{
                          fontSize: '14px',
                          fontWeight: '400',
                          color: 'rgba(0, 0, 0, 0.5)'
                        }}>
                        stores have data until
                      </Typography>
                      <Typography sx={{ fontSize: '14px', fontWeight: '400' }}>{item.lastDate}</Typography>
                    </Box>
                  </Grid>
                )
              })}
            </Grid>
            <Box>
              <Typography
                sx={{
                  width: 'max-content',
                  fontSize: '14px',
                  fontWeight: '600',
                  color: '#F15412',
                  marginLeft: '23px'
                }}>
                {failedResult.length} stores with older data
              </Typography>
            </Box>
            <CustomMaterialTable
              data={failedResult}
              columns={[
                {
                  title: filterNames.vb_name,
                  field: 'vb_name',
                  render: (params) => {
                    return (
                      <Typography sx={{ display: 'flex', alignItems: 'center' }}>
                        <WarningIcon
                          sx={{ marginRight: '5px' }}
                          color="error"
                        />
                        {params.vb_name}
                      </Typography>
                    )
                  }
                },
                {
                  title: filterNames.b_name,
                  field: 'b_name',
                  textStyle: { color: 'rgba(0, 0, 0, 0.5)' }
                },
                {
                  title: filterNames.vb_platform,
                  field: 'vb_platform',
                  cellStyle: { width: '20%' },
                  render: (params) => {
                    return (
                      <PlatformLogo
                        platformName={params.vb_platform}
                        height="15px"
                        width="auto"
                      />
                    )
                  }
                }
              ]}
              options={{
                stickyHeader: '-24px',
                pagination: true
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleClose}
              autoFocus>
              GOT IT
            </Button>
          </DialogActions>
        </Dialog>
        {!open ? (
          <Box
            onClick={() => {
              navigate(to)
              closeDrawer && closeDrawer()
            }}
            sx={{
              margin: '2px',
              minHeight: '30px',
              padding: '2px',
              transition: 'all 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: '#091F2D',
              color: 'grey',
              p: 2,
              cursor: 'pointer'
            }}>
            <BrowserUpdatedIcon sx={{ color: 'grey', marginBottom: '12px' }} />
            <Typography
              variant="subtitle1"
              color="#FFFFFF">
              {status}%
            </Typography>
            {/* <TooltipCover
              names={comment}
              tooltipPosition={'right-start'}
              arrow={true}
            >
              <Box>
                <Typography>
                  <InfoIcon fontSize="small" sx={{ color: "#FFFFFF" }} />
                </Typography>
              </Box>
            </TooltipCover> */}
          </Box>
        ) : (
          <Box
            sx={{
              margin: open ? '15px' : '5px',
              minHeight: '70px',
              padding: open ? '20px' : '5px',
              transition: 'all 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
              marginTop: open ? '24px' : '15px',
              backgroundColor: '#091F2D',
              color: '#FFFFFF',
              cursor: 'pointer',
              borderRadius: '5px'
            }}
            onClick={() => {
              navigate(to)
              closeDrawer && closeDrawer()
            }}>
            {isLoading ? (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}>
                <CircularProgress />
              </Box>
            ) : (
              <>
                <Stack
                  direction="row"
                  whiteSpace="pre-line"
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'stretch',
                    marginBottom: '15px',
                    whiteSpace: 'pre-line'
                  }}>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    width="100%"
                    whiteSpace="pre-line">
                    <CustomTypoContainer
                      c="white"
                      fs={13}
                      fw={400}>
                      Data Freshness
                    </CustomTypoContainer>
                    <IconButton
                      onClick={handleClick}
                      sx={{
                        '&:hover': {
                          bgcolor: 'rgba(255, 255, 255, 0.05)'
                        }
                      }}>
                      <ChevronRightIcon sx={{ color: 'white' }} />
                    </IconButton>
                  </Box>
                </Stack>
                <Stack
                  direction="row"
                  spacing={1}
                  sx={{ align: 'left', marginBottom: '13px' }}
                  whiteSpace={'pre-line'}>
                  <BrowserUpdatedIcon sx={{ color: 'grey', whiteSpace: 'initial' }} />
                  <Typography
                    variant="subtitle1"
                    color="#FFFFFF"
                    sx={{ fontSize: 10, fontWeight: 400 }}>
                    {`${tempFailedStores}/${totalFailedStores}`} stores updated
                  </Typography>
                </Stack>
                <Stack
                  direction="row"
                  spacing={1}
                  sx={{ align: 'left', marginBottom: '13px' }}
                  whiteSpace={'pre-line'}>
                  <TonalityIcon sx={{ color: '#0F783C' }} />
                  <Typography
                    variant="subtitle2"
                    color="#FFFFFF"
                    sx={{
                      whiteSpace: 'initial',
                      fontSize: 10,
                      fontWeight: 400
                    }}>
                    Data Available Until :{' '}
                    {lastDate
                      ? // [0, 5, 6].includes(dateCurr.getDay())
                        //   ? moment(
                        //       new Date(
                        //         datePrev.setDate(
                        //           datePrev.getDate() + (5 - 7 - datePrev.getDay())
                        //         )
                        //       )
                        //     ).format('DD MMM, YYYY')
                        //   :
                        moment(lastDate).format('D MMM, YYYY')
                      : 'No Data'}
                  </Typography>
                </Stack>
                <Stack
                  direction="row"
                  spacing={1}
                  sx={{ align: 'left' }}>
                  {/* {[0, 5, 6].includes(dateCurr.getDay()) ? (
                    <DownloadingIcon sx={{ color: '#F15412' }} />
                  ) : (
                    <CircleOutlinedIcon sx={{ color: '#FFFFFF' }} />
                  )}
                  <Typography
                    variant="subtitle2"
                    color="#FFFFFF"
                    sx={{ whiteSpace: 'initial' }}
                  >
                    {[0, 5, 6].includes(dateCurr.getDay())
                      ? `Data is Fetching: ETA ${moment(
                          new Date(
                            dateNext.setDate(
                              dateNext.getDate() +
                                ((1 + 7 - dateNext.getDay()) % 7 || 7)
                            )
                          )
                        ).format('DD MMM')}`
                      : `Next Update: ${
                          nextDate
                            ? moment(nextDate).format('DD MMM')
                            : 'No Data'
                        }`}
                  </Typography> */}
                  {/* <CircleOutlinedIcon sx={{ color: '#FFFFFF' }} />
                  <Typography
                    variant="subtitle2"
                    color="#FFFFFF"
                    sx={{ whiteSpace: 'initial' }}
                  >
                     Next Update: {
                          lastDate
                            ? moment(lastDate).clone().add(3, 'days').format('D MMM, YYYY')
                            : 'No Data'
                        }
                  </Typography> */}
                </Stack>
              </>
            )}
          </Box>
        )}

        {showRefreshDateNotification && (
          <Box
            display="flex"
            alignItems="center"
            gap={1}
            px={2}>
            <Info
              fontSize="small"
              sx={{ color: '#FFFFFF80' }}
            />
            <Typography
              color="#FFFFFF80"
              fontSize={11}
              whiteSpace="normal">
              Showing data freshness for selected {filterNames.b_name}s
            </Typography>
          </Box>
        )}
      </>
    )
  }
)
export default RefreshStatus
