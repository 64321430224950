import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { Box, Stack, Typography } from '@mui/material'
import { get } from 'lodash'
import React from 'react'
import { Link } from 'react-router-dom'
import { useWizardContext } from 'src/context/WizardContext'

export default function SideBarWizardList() {
  const { filteredWizardConfig } = useWizardContext()
  const wizardList = React.useMemo(() => {
    if (!filteredWizardConfig) {
      return []
    }
    const keys = Object.keys(filteredWizardConfig)
    const result = keys.map((key) => {
      return {
        routeKey: key,
        ...filteredWizardConfig[key]
      }
    })
    return result
  }, [filteredWizardConfig])
  if (wizardList.length === 0) {
    return <></>
  }
  return (
    <Box sx={{ mb: '20px', px: '10px' }}>
      <Stack direction="column" alignItems="stretch" gap="10px">
        {wizardList.map((wizard) => {
          return (
            <Link
              key={wizard.routeKey}
              to={`wizard/${wizard.routeKey}`}
              style={{ textDecoration: 'none' }}
            >
              <Stack
                direction="row"
                alignItems={'center'}
                gap="5px"
                sx={{
                  px: '13px',
                  py: '10px',
                  bgcolor: '#091F2D',
                  borderRadius: '4px'
                }}
              >
                <Typography variant="subtitle2" color="#FFF" sx={{ flex: 1 }}>
                  {get(wizard, 'label', '')}
                </Typography>
                <Box sx={{ color: '#FFF', display: 'flex', fontSize: '13px' }}>
                  <ChevronRightIcon color="inherit" fontSize="inherit" />
                </Box>
              </Stack>
            </Link>
          )
        })}
      </Stack>
    </Box>
  )
}
