import { AttachFile, Close } from '@mui/icons-material'
import { CircularProgress, Stack } from '@mui/material'

interface ImageViewerProps {
  image: string
  imageLoading: boolean
  height: string
  width: string
  isOpened?: boolean
  onIconClick: () => void
  onClick: () => void
}

const ImageViewer = ({ image, imageLoading, height, width, isOpened = false, onIconClick, onClick }: ImageViewerProps) => {
  return (
    <Stack
      sx={{
        position: 'relative',
        p: 0.5
      }}>
      {imageLoading ? (
        <Stack
          width={width}
          height={height}
          sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <CircularProgress
            size={15}
            sx={{ color: '#000' }}
          />
        </Stack>
      ) : (
        <>
          <Stack
            onClick={onIconClick}
            sx={{
              position: 'absolute',
              right: '0px',
              top: '0px',
              zIndex: 10,
              backgroundColor: isOpened ? 'black' : 'info.main',
              borderRadius: '50%',
              cursor: 'pointer',
              p: isOpened ? '1' : '0.25'
            }}>
            {isOpened ? (
              <Close
                style={{
                  fontSize: '1rem',
                  color: 'white'
                }}
              />
            ) : (
              <AttachFile
                style={{
                  fontSize: '0.75rem',
                  color: 'white',
                  margin: 2
                }}
              />
            )}
          </Stack>
          <img
            onClick={onClick}
            src={image}
            style={{ height: height, width: width, borderRadius: '3px', cursor: 'pointer' }}
          />
        </>
      )}
    </Stack>
  )
}

export default ImageViewer
