import { IconButton, styled } from '@mui/material'

const StyledIcon = styled(IconButton)({
  backgroundColor: '#2F4A5A',
  '&:hover': { backgroundColor: '#2F4A5A' },
  position: 'relative',
  left: '103%'
})

export default StyledIcon
