import { axiosConfig as axios } from '../axios'

const appName = process.env.REACT_APP_NAME
const appVersion = process.env.REACT_APP_VERSION

class Slack {
  baseUrl: string
  constructor(baseUrl: string = process.env.REACT_APP_ONBOARDING_URL) {
    this.baseUrl = baseUrl
  }

  postSlack = async ({ message, title, channel }: { title: string; message: string; channel?: string }) => {
    const object = {
      message: message + ` on \`${appName + '@' + appVersion}\``,
      title: title,
      channel: channel
    }

    const queryParams = new URLSearchParams(object).toString()
    return axios.post(`${this.baseUrl}/actions/internal/send_slack_message?${queryParams}`)
  }
}

export default Slack
