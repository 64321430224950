import { get } from 'lodash'
import { useAuth } from 'src/context/AuthContext'
import { DefaultService, MetricsKPIDrillDownOutput, MetricsKPIDrillDownOutputPaginated, UniqueValuesForColumn } from 'src/services/openApi'
import allJsonData from '../jsonConfigs'
import { BreakdownEnumsType } from '../jsonConfigs/types'

export type TableDataResponseType = MetricsKPIDrillDownOutputPaginated

export type FilterDataResponseType = UniqueValuesForColumn

export type DateRangeCompareDataResponseType = {
  current: {
    [key: string]: MetricsKPIDrillDownOutput
  }
  previous: {
    [key: string]: MetricsKPIDrillDownOutput
  }
  percentages: {
    [key: string]: MetricsKPIDrillDownOutput
  }
}

export default function useServiceHook(
  // Should be used later
  tempDrillDownName: keyof typeof allJsonData
) {
  const { currentUser } = useAuth()

  const getTableData = async (obj: {
    b_name_in?: string
    vb_name_in?: string
    vb_platform_in?: string
    breakdown_column: BreakdownEnumsType
    start_date_in: string
    end_date_in: string
    limit: number
    offset: number
    order_by?: string
    ascending?: boolean
  }) => {
    const org = get(currentUser, 'org', '')
    const res = await DefaultService.callDrillDownGeneratedMetricsKpiGroupedListOfEntriesApiPaginatedDrillDownGeneratedMetricsKpiGroupedListOfEntriesPost(
      {
        ...obj,
        chain_in: org
      },
      true
    )

    return res as TableDataResponseType
  }

  const getDateRangeCompareData = async (obj: {
    b_name_in?: string
    vb_name_in?: string
    vb_platform_in?: string
    breakdown_column: BreakdownEnumsType
    start_date_in: string
    end_date_in: string
  }) => {
    const org = get(currentUser, 'org', '')
    const res =
      await DefaultService.drillDownGeneratedMetricsKpiGroupedListOfEntriesDateRangeCompareApiDateRangeCompareDrillDownGeneratedMetricsKpiGroupedListOfEntriesPost(
        {
          ...obj,
          compare_by: 'breakdown_column',
          chain_in: org
        }
      )

    const current = res.current_range_data.reduce(
      (acc, item) => {
        acc[item.breakdown_column] = item
        return acc
      },
      {} as { [key: string]: (typeof res.current_range_data)[0] }
    )
    const previous = res.previous_range_data.reduce(
      (acc, item) => {
        acc[item.breakdown_column] = item
        return acc
      },
      {} as { [key: string]: (typeof res.previous_range_data)[0] }
    )
    const percentages =
      res.percentages?.reduce(
        (acc, item) => {
          acc[item.breakdown_column] = item
          return acc
        },
        {} as { [key: string]: (typeof res.percentages)[0] }
      ) || {}

    return {
      current,
      previous,
      percentages
    } as DateRangeCompareDataResponseType
  }

  const getFilterData = async (obj: {
    b_name_in?: string
    vb_name_in?: string
    vb_platform_in?: string
    breakdown_column: BreakdownEnumsType
    start_date_in: string
    end_date_in: string
  }) => {
    const org = get(currentUser, 'org', '')
    const res = await DefaultService.callDrillDownGeneratedMetricsKpiUniqueValuesApiDrillDownGeneratedMetricsKpiUniqueValuesPost({
      ...obj,
      chain_in: org
    })

    return res as FilterDataResponseType
  }

  const getExportData = async (obj: {
    b_name_in?: string
    vb_name_in?: string
    vb_platform_in?: string
    breakdown_column: BreakdownEnumsType
    start_date_in: string
    end_date_in: string
  }) => {
    const org = get(currentUser, 'org', '')
    const res = await DefaultService.exportDrillDownGeneratedMetricsKpiGroupedListOfEntriesApiExportDrillDownGeneratedMetricsKpiGroupedListOfEntriesPost({
      ...obj,
      chain_in: org
    })

    return res
  }

  const getDeltaValue = (dateRangeCompareData: DateRangeCompareDataResponseType, breakdownKey: string, key: keyof MetricsKPIDrillDownOutput) => {
    if (!dateRangeCompareData) {
      return {}
    }
    const current = get(dateRangeCompareData, `current.${breakdownKey}.${key}`, 0)
    const previous = get(dateRangeCompareData, `previous.${breakdownKey}.${key}`, 0)
    const delta = get(dateRangeCompareData, `percentages.${breakdownKey}.${key}`, 0)
    if (typeof current !== 'number' || typeof previous !== 'number' || typeof delta !== 'number') {
      return {}
    }
    return { delta, current, previous }
  }

  return { getTableData, getFilterData, getExportData, getDateRangeCompareData, getDeltaValue }
}
