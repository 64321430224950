import { useLoadingData } from 'src/context/LoadingContext';
import { useErrorData } from 'src/context/ErrorContext';

export default function useAsyncWrapper() {
    const { setLoading } = useLoadingData()
    const { handleError } = useErrorData()

    async function asyncWrapper(fn: any) {
        setLoading(true)
        try {
            await fn
        } catch (error:any) {
            handleError(error.message)
        }
        setLoading(false)
    }
    
    return asyncWrapper
}
