import { Box, Icon, Tooltip, Typography } from '@mui/material'
import { get } from 'lodash'
import { filterIcons } from 'src/utils/config/config'
import CustomChip from '../../components/CustomChip'

interface DynamicIconStyledCompProps {
  data: object
  active: boolean
}

export default function DynamicIconStyledComp(key: string) {
  const icon = get(filterIcons, key, null)
  return (props: DynamicIconStyledCompProps) => {
    const { data, active } = props
    const name = get(data, key, key)
    return (
      <CustomChip
        active={active}
        showRightArrow
        sx={{ maxWidth: '250px' }}>
        <Tooltip
          arrow
          title={name}
          placement="top"
          sx={{ cursor: 'inherit' }}>
          <Box
            sx={{
              minWidth: 0,
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              gap: '7px'
              // minWidth: '0px',
              // width: '100%'
            }}>
            {icon && <Icon sx={{ fontSize: '16px', color: 'black' }}>{icon}</Icon>}
            <Typography
              variant="subtitle2"
              sx={{ flex: 1, textAlign: 'left', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
              {name}
            </Typography>
            {/* <ChevronRightIcon sx={{ fontSize: '12px', color: 'black' }} /> */}
          </Box>
        </Tooltip>
      </CustomChip>
    )
  }
}
