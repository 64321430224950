import { Box, Typography } from '@mui/material'

interface TemplateProps {
  title?: string
  description: string
  onClick?: () => void
}

export default function Template(props: TemplateProps) {
  return (
    <Box
      sx={{
        paddingY: 1,
        paddingX: 2,
        border: '1px solid rgba(25, 110, 130, 0.33)',
        background: 'rgba(241, 246, 247, 1)',
        cursor: 'pointer'
      }}
      onClick={() => {
        props.onClick()
      }}>
      {props.title && <Typography variant="subtitle2">{props.title}</Typography>}

      <Typography variant="subtitle2">{props.description}</Typography>
    </Box>
  )
}
