import { Box } from '@mui/material'
import TransactionsBreakdownDataContextProvider from '../../utils/context/TransactionsBreakdownDataContext'
import TransactionsChartView from './Tabs/TransactionsChartView'

export default function Transactions() {
  // const [tabValue, setTabValue] = useTabUrlState(
  //   'transaction_tab',
  //   'chart_view'
  // )

  return (
    <TransactionsBreakdownDataContextProvider>
      {/* <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}
      >
        <StyledTabs
          value={tabValue}
          onChange={(e, newValue) => {
            setTabValue(newValue)
          }}
        >
          <StyledTab
            value="chart_view"
            label={
              <Stack direction="row" alignItems={'center'} gap="5px">
                <Box sx={{ fontSize: '24px', color: 'black', display: 'flex' }}>
                  <VerticalSplitIcon fontSize="inherit" color="inherit" />
                </Box>
                <Typography variant="subtitle2">CHART VIEW</Typography>
              </Stack>
            }
          />
          <StyledTab
            value="by_order_view"
            label={
              <Stack direction="row" alignItems={'center'} gap="5px">
                <Box sx={{ fontSize: '24px', color: 'black', display: 'flex' }}>
                  <ViewHeadlineIcon fontSize="inherit" color="inherit" />
                </Box>
                <Typography variant="subtitle2">BY ORDER VIEW</Typography>
              </Stack>
            }
          />
        </StyledTabs>
      </Box> */}

      {/* <Box sx={{ mt: '15px' }}> */}
      <Box>
        {/* <TabPanel value={tabValue} index="chart_view"> */}
        {/* <BreakdownChart /> */}
        <TransactionsChartView />
        {/* </TabPanel>
        <TabPanel value={tabValue} index="by_order_view">
          <TransactionsByOrderView />
        </TabPanel> */}
      </Box>
    </TransactionsBreakdownDataContextProvider>
  )
}
