import axios, { AxiosError, AxiosResponse } from 'axios'

export const baseURL = process.env.REACT_APP_ONBOARDING_URL

export const axiosConfig = axios.create({
  baseURL,
  headers: {
    'Content-type': 'application/json'
  }
})

const getAuthToken = () => {
  return localStorage.getItem('token')
}

export const axiosSecureConfig = axios.create({
  baseURL,
  headers: {
    'Content-type': 'application/json'
  }
})

// defining a custom error handler for all APIs
const errorHandler = (error: AxiosError) => {
  const statusCode = error.response?.status

  // logging only errors that are not 401
  if (statusCode && statusCode !== 401) {
    console.error(error)
  }

  return Promise.reject(error)
}

axiosSecureConfig.interceptors.request.use(function (config: any) {
  const token = getAuthToken()

  config.headers.Authorization = token ? `Bearer ${token}` : ''
  return config
})
// registering the custom error handler to the
// "api" axios instance

axiosSecureConfig.interceptors.response.use(
  (response: AxiosResponse) => {
    return response
  },
  (error: AxiosError) => {
    console.error(error)

    // wrapApiHandlerWithSentry(() => console.log("handled"), "/api/myRoute");
    return errorHandler(error)
  }
)
