import moment from 'moment'
import { UptimeByPlatformPaginated } from 'src/services/openApi'
import { getWeekNumber } from './functions'

type FormatType = { date: string; ubereats: number[]; grubhub: number[]; doordash: number[] }

const initialObjData = {
  doordash: [] as number[],
  grubhub: [] as number[],
  ubereats: [] as number[]
}

export function groupByWeek(data: FormatType[]) {
  const supportObj: { [weekNum: string]: { dates: string[] } & typeof initialObjData } = {}

  data.forEach((obj) => {
    const date = moment(obj.date).toDate()
    const weekNum = getWeekNumber(date).toString()
    if (!(weekNum in supportObj)) {
      supportObj[weekNum] = {
        dates: [],
        ...JSON.parse(JSON.stringify(initialObjData))
      }
    }

    supportObj[weekNum].dates.push(moment(date).format('DD MMM'))
    supportObj[weekNum].doordash.push(...obj.doordash)
    supportObj[weekNum].grubhub.push(...obj.grubhub)
    supportObj[weekNum].ubereats.push(...obj.ubereats)
  })

  const result: FormatType[] = []

  Object.keys(supportObj).forEach((weekNum) => {
    const { dates, ubereats, grubhub, doordash } = supportObj[weekNum]
    result.push({
      date: dates.length === 1 ? dates[0] : `${dates[0]} - ${dates[dates.length - 1]}`,
      ubereats,
      grubhub,
      doordash
    })
  })

  return result
}

function groupByMonth(data: FormatType[]) {
  const supportObj: { [month: string]: { dates: string[] } & typeof initialObjData } = {}

  data.forEach((obj) => {
    const month = moment(obj.date).format('MMM YY')
    if (!(month in supportObj)) {
      supportObj[month] = {
        dates: [],
        ...JSON.parse(JSON.stringify(initialObjData))
      }
    }

    supportObj[month].dates.push(moment(obj.date).format('DD MMM'))
    supportObj[month].doordash.push(...obj.doordash)
    supportObj[month].grubhub.push(...obj.grubhub)
    supportObj[month].ubereats.push(...obj.ubereats)
  })

  const result: FormatType[] = []

  Object.keys(supportObj).forEach((month) => {
    const { ubereats, grubhub, doordash } = supportObj[month]
    result.push({
      date: `${month}`,
      ubereats,
      grubhub,
      doordash
    })
  })

  return result
}

export default function formatUptimeData(uptimeData: UptimeByPlatformPaginated, formatByWeek: boolean) {
  if (!uptimeData || !Array.isArray(uptimeData.data)) {
    return {
      isMonthly: false,
      lostSalesData: [],
      uptimeData: []
    }
  }

  const data = uptimeData.data.sort((a, b) => {
    const d1 = new Date(a.date)
    const d2 = new Date(b.date)
    return d1.getTime() - d2.getTime()
  })

  let uptimeSupportObj: { [date: string]: typeof initialObjData } = {}
  let lostSalesSupportObj: { [date: string]: typeof initialObjData } = {}

  data.forEach((data) => {
    const { platform: platformName, date, uptime_percent, lost_sales_opportunity } = data
    const platform = platformName.toLowerCase()
    if (!(platform in initialObjData)) {
      return
    }

    if (!(date in uptimeSupportObj)) {
      uptimeSupportObj[date] = JSON.parse(JSON.stringify(initialObjData))
    }
    uptimeSupportObj[date][platform].push(uptime_percent)

    if (!(date in lostSalesSupportObj)) {
      lostSalesSupportObj[date] = JSON.parse(JSON.stringify(initialObjData))
    }
    lostSalesSupportObj[date][platform].push(lost_sales_opportunity)
  })

  const uptimeResult: FormatType[] = []
  const lostSalesResult: FormatType[] = []

  Object.keys(uptimeSupportObj).forEach((dateKey) => {
    const date = moment(dateKey).format('DD MMM')
    uptimeResult.push({ date, ...uptimeSupportObj[dateKey] })
    lostSalesResult.push({ date, ...lostSalesSupportObj[dateKey] })
  })

  const totalDays = uptimeResult.length === 0 ? 0 : Math.abs(moment(uptimeResult[0].date).diff(uptimeResult[uptimeResult.length - 1].date, 'days'))

  const thresholdDays = 45

  const isMonthly = totalDays > thresholdDays

  return {
    isMonthly,
    lostSalesData: formatByWeek ? groupByWeek(lostSalesResult) : isMonthly ? groupByMonth(lostSalesResult) : lostSalesResult,
    uptimeData: formatByWeek ? groupByWeek(uptimeResult) : isMonthly ? groupByMonth(uptimeResult) : uptimeResult
  }
}

type downtimevslostSalesSupportObj = {
  date: string | number | symbol
  downtime_percent: Number
  lost_sales_opportunity: Number
  platform: String
  total_sales: Number
  uptime_percent: Number
}

export function formatDowntimeToLostSales(downTimeData: UptimeByPlatformPaginated) {
  let downTimeSupportObj = {}
  downTimeData?.data?.forEach((data) => {
    const { platform: platformName, date, lost_sales_opportunity, downtime_percent } = data
    const platform = platformName.toLowerCase()
    if (!(platform in initialObjData)) {
      return
    }

    if (!(date in downTimeSupportObj)) {
      downTimeSupportObj[date] = {
        doordash: 0,
        grubhub: 0,
        ubereats: 0,
        lost_sales: 0,
        date: date,
        lost_sales_ubereats: 0,
        lost_sales_grubhub: 0,
        lost_sales_doordash: 0
      }
    }
    downTimeSupportObj[date][platform] = Number(downtime_percent) * 100
    downTimeSupportObj[date]['lost_sales'] = (downTimeSupportObj[date].lost_sales || 0) + lost_sales_opportunity
    downTimeSupportObj[date][`lost_sales_${platform}`] = lost_sales_opportunity
  })
  return Object.keys(downTimeSupportObj).map((key) => ({ ...downTimeSupportObj[key], date: key }))
}
