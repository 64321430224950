import { CloudDownloadOutlined, DescriptionOutlined, ForwardToInbox, ImageOutlined } from '@mui/icons-material'
import { Box, CircularProgress, IconButton, MenuItem, Popover, Stack, SxProps, Tooltip, Typography } from '@mui/material'
import { useState } from 'react'
import { useAuth } from 'src/context/AuthContext'
import { useSnackData } from 'src/context/SnackContext'
import { convertComponentToPdf, convertComponentToPng, sendComponentToEmail } from 'src/utils/functions/downloadComponent'

const menuOptions = [
  {
    value: 'pdf',
    icon: <DescriptionOutlined sx={{ color: '#000', fontSize: '20px' }} />,
    text: 'Export as PDF',
    function: 'exportPdf'
  },
  {
    value: 'png',
    icon: <ImageOutlined sx={{ color: '#000', fontSize: '20px' }} />,
    text: 'Export as PNG',
    function: 'exportPng'
  },
  {
    value: 'email',
    icon: <ForwardToInbox sx={{ color: '#000', fontSize: '20px' }} />,
    text: 'Send as email',
    function: 'sendToEmail'
  }
]

type ExportTypes = 'pdf' | 'png' | 'email'

interface ExportComponentProps {
  componentRef: React.MutableRefObject<HTMLElement>
  fileName?: string
  buttonSx?: SxProps
  buttonIcon?: React.ReactNode
  buttonText?: string
  buttonLoading?: boolean
  menuSx?: SxProps
  hideMenuOptions?: ExportTypes[]
  onPreExport?: () => void
  onPostExport?: () => void
}

const ExportComponent = ({
  componentRef,
  fileName,
  buttonSx,
  buttonIcon,
  buttonText,
  buttonLoading,
  menuSx,
  hideMenuOptions,
  onPreExport,
  onPostExport
}: ExportComponentProps) => {
  const { openError, openSuccess, setDownloadSnack } = useSnackData()
  const { currentUser } = useAuth()
  const [popoverAnchorEl, setPopoverAnchorEl] = useState<Element | null>(null)

  const exportPdf = async () => {
    setDownloadSnack({ status: 'start' })
    try {
      onPostExport && onPreExport()
      await convertComponentToPdf(componentRef.current, fileName)
      onPostExport && onPostExport()
      setDownloadSnack({ status: 'complete' })
      handlePopoverClose()
    } catch (err) {
      setDownloadSnack({ status: 'close' })
      openError('Failed to download PDF: ' + err.message)
    }
  }

  const exportPng = async () => {
    setDownloadSnack({ status: 'start' })
    try {
      onPostExport && onPreExport()
      await convertComponentToPng(componentRef.current, fileName)
      onPostExport && onPostExport()
      setDownloadSnack({ status: 'complete' })
      handlePopoverClose()
    } catch (err) {
      setDownloadSnack({ status: 'close' })
      openError('Failed to download image: ' + err.message)
    }
  }

  const sendToEmail = async () => {
    try {
      onPostExport && onPreExport()
      await sendComponentToEmail(componentRef.current, currentUser.email)
      onPostExport && onPostExport()
      openSuccess('File sent to your email successfully')
      handlePopoverClose()
    } catch (err) {
      openError('Failed to send file to email: ' + err.message)
    }
  }

  const functions = {
    exportPdf,
    exportPng,
    sendToEmail
  }

  const handlePopoverClose = () => {
    setPopoverAnchorEl(null)
  }

  return (
    <Box>
      <Tooltip title="Export this data">
        <IconButton
          sx={{
            borderRadius: '4px',
            bgcolor: popoverAnchorEl ? '#EEEEEE' : '#F5F5F5',
            '&:hover': {
              bgcolor: '#EEEEEE'
            },
            display: 'flex',
            alignItems: 'center',
            gap: 1,
            ...buttonSx
          }}
          disabled={buttonLoading}
          onClick={(e) => {
            setPopoverAnchorEl(e.currentTarget)
          }}>
          {buttonLoading ? (
            <CircularProgress
              size={18}
              sx={{ color: '#000' }}
            />
          ) : buttonIcon ? (
            buttonIcon
          ) : (
            <CloudDownloadOutlined sx={{ color: '#000', fontSize: '20px' }} />
          )}
          {buttonText && (
            <Typography
              color="#000"
              fontWeight={600}
              fontSize={14}>
              {buttonText}
            </Typography>
          )}
        </IconButton>
      </Tooltip>
      <Popover
        open={Boolean(popoverAnchorEl)}
        anchorEl={popoverAnchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        onClose={handlePopoverClose}
        sx={{
          ...menuSx
        }}>
        <Stack p={1}>
          {menuOptions
            .filter((option) => !hideMenuOptions?.includes(option.value as ExportTypes))
            ?.map((option, index) => (
              <MenuItem
                key={option.value + index}
                onClick={functions[option.function]}
                sx={{ display: 'flex', alignItems: 'center', gap: 1.5, px: 0.5 }}>
                {option.icon}
                <Typography
                  color="#000"
                  fontWeight={500}
                  fontSize={13}>
                  {option.text}
                </Typography>
              </MenuItem>
            ))}
        </Stack>
      </Popover>
    </Box>
  )
}

export default ExportComponent
