import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'

export const convertComponentToPdf = async (printContentRef: HTMLElement, name: string) => {
  const canvas = await html2canvas(printContentRef)

  const imgData = canvas.toDataURL('image/png')
  const pdf = new jsPDF()
  const imgProps = pdf.getImageProperties(imgData)
  let pdfWidth = pdf.internal.pageSize.getWidth()
  let pdfHeight = (imgProps.height * pdfWidth) / imgProps.width

  if (pdf.internal.pageSize.getHeight() < pdfHeight) {
    pdfHeight = pdf.internal.pageSize.getHeight()
    pdfWidth = (imgProps.width * pdfHeight) / imgProps.height
  }

  pdf.addImage(imgData, 'png', 0, 0, pdfWidth, pdfHeight)
  pdf.save((name || 'file_name') + '.pdf')
}

export const convertComponentToPng = async (printContentRef: HTMLElement, name: string) => {
  const canvas = await html2canvas(printContentRef)

  const img = canvas.toDataURL('image/png').replace('image/png', 'image/octet-stream')
  const link = document.createElement('a')
  link.download = (name || 'file_name') + '.png'
  link.href = img
  link.click()
}

export const sendComponentToEmail = async (printContentRef: HTMLElement, email: string) => {
  // TODO for V2 of this feature
  // Ideally pdf should be sent
  // New email template needed
  // Option to add additional emails to send to
  // Need to create a new API endpoint to send emails to non-registered users
}
