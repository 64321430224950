import { capitalize, get, uniq } from 'lodash';
import posthog from 'posthog-js';
import React, { Suspense, useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import { useLocation, useSearchParams } from 'react-router-dom';
import DrillDownLoading from 'src/components/Loading';
import CustomDialog from 'src/components/mui/CustomDialog';
import Loading from 'src/components/mui/Loader';
import PublicLoader from 'src/components/mui/PublicLoader';
import { AccessLevelEnum } from 'src/context/AuthContext.type';
import DrillDownContextProvider from 'src/context/DrillDownContext';
import { useErrorData } from 'src/context/ErrorContext';
import FilterContextProvider from 'src/context/FilterContext';
import GlobalDialogContextProvider from 'src/context/GlobalDialogContext';
import NewFilterContextProvider from 'src/context/NewFilterContext/NewFilterContext';
import PrivateLayoutDrawerContextProvider from 'src/context/PrivateLayoutDrawerContext';
import SingleSelectFilterContextProvider from 'src/context/SingleSelectFilterContext';
import StoreDetailContextProvider from 'src/context/StoreDetailContext';
import TopbarContextProvider from 'src/context/TopbarContext';
import WizardContextProvider from 'src/context/WizardContext';
import PrivateRouter from 'src/routes/PrivateRouter'
import { getAccessLevel } from 'src/utils/functions/accessLevel';
import getFlatRoutes from 'src/utils/functions/flatRoutes';
import { useAuth } from '../context/AuthContext';



const NoInternetPage = React.lazy(() => import('src/pages/noInternet/NoInternet'));
const PublicRouter = React.lazy(() => import('src/routes/PublicRouter'));

const Router: React.FC = React.memo(() => {
  const { currentUser, loading, pageDetails, setPageDetails, orgConfig } = useAuth();
  const location = useLocation();
  const [noAccess, setNoAccess] = useState(false);
  const [params, setParams] = useSearchParams();
  const { handleError } = useErrorData();

  useEffect(() => {
    if (currentUser?.uid) {
      const { uid, email, phoneNumber } = currentUser;
      ReactGA.initialize('G-JZW0YC7GZN', { gaOptions: { userId: uid } });
      ReactGA.event({ action: 'User', category: 'user_id', label: uid, nonInteraction: true });

      if (email) {
        const emailLabel = email.replace('@', '-') || phoneNumber;
        ReactGA.event({ action: 'User', category: 'user_mail', label: emailLabel, nonInteraction: true });
        ReactGA.event({ action: 'user_mail', category: emailLabel, nonInteraction: true });
      }

      if (phoneNumber) {
        ReactGA.event({ action: 'User', category: 'user_phone', label: phoneNumber, nonInteraction: true });
        ReactGA.event({ action: 'user_phone', category: phoneNumber, nonInteraction: true });
      }

      ReactGA.event({ action: 'user_id', category: uid, nonInteraction: true });
    }
  }, [currentUser]);

  useEffect(() => {
    if (pageDetails?.path !== location.pathname) {
      params.delete('section')
      setParams(params)
      const currentTime = new Date().getTime()
      setPageDetails({ path: location.pathname, visitTime: currentTime })
    }
    ReactGA.send({ hitType: 'pageview', page: location.pathname })

    const msClarity = (window as any).clarity
    if (msClarity && currentUser) {
      if (currentUser.org) msClarity('set', 'chain', currentUser.org)
      if (currentUser.displayName) msClarity('set', 'name', currentUser.displayName)
      if (currentUser.email) msClarity('set', 'email', currentUser.email)
      if (currentUser.uid) msClarity('set', 'userid', currentUser.uid)
    }
  }, [location])

  useEffect(() => {
    if (currentUser?.email) {
      window.dataLayer = window.dataLayer || [];
      const { uid, email, phoneNumber, org, 'access-level': accessLevel, name: fullName, photoURL: avatar } = currentUser;

      const user = {
        id: uid,
        signup_date: Date.now() / 1000,
        userId: uid,
        fullName: capitalize(fullName),
        email,
        phoneNumber,
        company: org,
        accessLevel,
        avatar,
      };

      window.dataLayer.push(user);
      window.loop = {
        user,
        meta: {
          name: process.env.REACT_APP_NAME,
          version: process.env.REACT_APP_VERSION,
        },
      };

      posthog.identify(user.email, { ...window.loop.meta, ...window.loop.user });
      posthog.capture('$pageview', { ...window.loop.meta, ...window.loop.user });
      posthog.group('company', user.company, { ...window.loop.meta, ...window.loop.user });
      posthog.register({ email: user.email });
    }

    if (currentUser && orgConfig) {
      try {
        const accessLevel = getAccessLevel(currentUser);
        const accessRoutes = getFlatRoutes(
          get(orgConfig, `accessConfig.${accessLevel !== AccessLevelEnum.DEMO ? accessLevel : AccessLevelEnum.BUSINESS_ADMIN}.navConfig`, [])
        );
        const userRoutes = getFlatRoutes(get(orgConfig, 'navConfig', []));
        const compareRoutes = accessRoutes.length > 0 ? uniq(accessRoutes) : uniq(userRoutes);

        if (location.pathname !== '/' && !compareRoutes.includes(location.pathname)) {
          setNoAccess(false);
        }
      } catch (error) {
        handleError(error.message);
      }
    }
  }, [currentUser, location.pathname, orgConfig, handleError]);

  useEffect(() => {
    const url = new URL(window.location.href);
    if (url.searchParams.has('referred_by')) {
      url.searchParams.delete('referred_by');
      window.history.replaceState('', '', url.toString());
    }
  }, [window.location.href]);

  if (!navigator.onLine) {
    return (
      <Suspense fallback={<div>Loading...</div>}>
        <NoInternetPage />
      </Suspense>
    );
  }

  return (
    <GlobalDialogContextProvider>
      <CustomDialog />
      {loading ? (
        <Loading loading />
      ) : (
        <>
          {!currentUser ? (
            <Suspense fallback={<DrillDownLoading />}>
              <PublicLoader />
              <PublicRouter />
            </Suspense>
          ) : (
            <Suspense fallback={<DrillDownLoading />}>
              <DrillDownContextProvider>
                <FilterContextProvider>
                  <NewFilterContextProvider>
                    <SingleSelectFilterContextProvider>
                      <StoreDetailContextProvider>
                        <PrivateLayoutDrawerContextProvider>
                          <WizardContextProvider>
                            <TopbarContextProvider>
                              <PrivateRouter noAccess={noAccess} />
                            </TopbarContextProvider>
                          </WizardContextProvider>
                        </PrivateLayoutDrawerContextProvider>
                      </StoreDetailContextProvider>
                    </SingleSelectFilterContextProvider>
                  </NewFilterContextProvider>
                </FilterContextProvider>
              </DrillDownContextProvider>
            </Suspense>
          )}
        </>
      )}
    </GlobalDialogContextProvider>
  );
});

export default Router;
