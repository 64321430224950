import { SvgIcon, SvgIconProps } from '@mui/material'

export default function AdjustIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg
        width="21"
        height="21"
        viewBox="0 0 21 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10.4805 13.9414C11.3138 13.9414 12.0221 13.6497 12.6055 13.0664C13.1888 12.4831 13.4805 11.7747 13.4805 10.9414C13.4805 10.1081 13.1888 9.39974 12.6055 8.81641C12.0221 8.23307 11.3138 7.94141 10.4805 7.94141C9.64714 7.94141 8.9388 8.23307 8.35547 8.81641C7.77214 9.39974 7.48047 10.1081 7.48047 10.9414C7.48047 11.7747 7.77214 12.4831 8.35547 13.0664C8.9388 13.6497 9.64714 13.9414 10.4805 13.9414ZM10.4805 20.9414C9.09714 20.9414 7.79714 20.6789 6.58047 20.1539C5.3638 19.6289 4.30547 18.9164 3.40547 18.0164C2.50547 17.1164 1.79297 16.0581 1.26797 14.8414C0.742969 13.6247 0.480469 12.3247 0.480469 10.9414C0.480469 9.55807 0.742969 8.25807 1.26797 7.04141C1.79297 5.82474 2.50547 4.76641 3.40547 3.86641C4.30547 2.96641 5.3638 2.25391 6.58047 1.72891C7.79714 1.20391 9.09714 0.941406 10.4805 0.941406C11.8638 0.941406 13.1638 1.20391 14.3805 1.72891C15.5971 2.25391 16.6555 2.96641 17.5555 3.86641C18.4555 4.76641 19.168 5.82474 19.693 7.04141C20.218 8.25807 20.4805 9.55807 20.4805 10.9414C20.4805 12.3247 20.218 13.6247 19.693 14.8414C19.168 16.0581 18.4555 17.1164 17.5555 18.0164C16.6555 18.9164 15.5971 19.6289 14.3805 20.1539C13.1638 20.6789 11.8638 20.9414 10.4805 20.9414Z"
          fill="#1C1B1F"
        />
      </svg>
    </SvgIcon>
  )
}
