import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { Box, ButtonBase, SxProps, Typography, TypographyProps } from '@mui/material'
import React from 'react'

interface CustomChipProps {
  children: React.ReactNode
  active?: boolean
  sx?: SxProps
  onClick?: () => void
  showRightArrow?: boolean
}

export default function CustomChip(props: CustomChipProps) {
  return (
    <Box
      component={props.onClick ? ButtonBase : 'div'}
      sx={{
        px: '6.5px',
        py: '7px',
        bgcolor: props.active ? 'rgba(16, 93, 111, 0.5)' : '#F3F3F3',
        display: 'flex',
        alignItems: 'center',
        gap: '5px',
        width: '100%',
        textAlign: 'left',
        ...props.sx
      }}
      onClick={() => {
        if (props.onClick) {
          props.onClick()
        }
      }}>
      {typeof props.children === 'string' ? <CustomChip.Typography>{props.children}</CustomChip.Typography> : props.children}
      {(props.onClick || props.showRightArrow) && <ChevronRightIcon sx={{ fontSize: '15px', color: 'black' }} />}
    </Box>
  )
}

CustomChip.Typography = (props: TypographyProps) => {
  return (
    <Typography
      variant="subtitle2"
      textAlign="left"
      sx={{ flex: 1 }}
      {...props}
    />
  )
}
