import { SvgIcon, SvgIconProps } from '@mui/material'

export default function LocationAutomationIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} >
      <svg width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M14.7129 21.9316L13.3129 18.8316L10.2129 17.4316L13.3129 16.0316L14.7129 12.9316L16.1129 16.0316L19.2129 17.4316L16.1129 18.8316L14.7129 21.9316ZM0.212891 18.9316V6.93164L8.21289 0.931641L16.2129 6.93164V11.1066C15.9629 11.04 15.7087 10.9941 15.4504 10.9691C15.1921 10.9441 14.9379 10.9316 14.6879 10.9316C12.8712 10.9316 11.3379 11.565 10.0879 12.8316C8.83789 14.0983 8.21289 15.6316 8.21289 17.4316C8.21289 17.6816 8.22539 17.9316 8.25039 18.1816C8.27539 18.4316 8.32122 18.6816 8.38789 18.9316H0.212891Z" fill="#1C1B1F"/>
</svg>

    </SvgIcon>
  )
}
