import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'
import { addDoc, collection, deleteDoc, doc, getDoc, getDocs, getFirestore, query, setDoc, where } from 'firebase/firestore'
import { getPerformance } from 'firebase/performance'
import { getStorage } from 'firebase/storage'
import { DefaultService } from 'src/services/openApi'
import { SupersetLinkType } from 'src/types/supersetLink'
import { AccessLevelEnum, BASE_URL } from 'src/utils/config/config'

export const firebaseConfig = {
  apiKey: 'AIzaSyAPstTsXFKtlRyC5XnfE71A1PBwQGPhuNY',
  authDomain: BASE_URL,
  projectId: 'arboreal-vision-339901',
  storageBucket: 'arboreal-vision-339901.appspot.com',
  messagingSenderId: '983150008719',
  appId: '1:983150008719:web:094b902dc0e51ab27dd3fa',
  measurementId: 'G-G71XJ4JH01'
}

export const app = initializeApp(firebaseConfig)
export const db = getFirestore(app)
export const auth = getAuth(app)
// Initialize Performance Monitoring and get a reference to the service
export const perf = getPerformance(app)

export const fetchUserOrgAndRole = async (id: string) => {
  try {
    const response = await DefaultService.readFromFirebaseReadDocFromFirebaseGet('users', id)
    return response
  } catch (error) {
    throw error
  }
}

export const storage = getStorage(app)

export const fetchCredentials = async (org) => {
  try {
    const Doc = query(collection(db, 'credentials_v2'), where('chain', '==', org))
    const querySnapshot = await getDocs(Doc)
    return querySnapshot
  } catch (error) {
    return []
  }
}

export const savePanelSettings = async (org, panelSettings) => {
  try {
    const document = await setDoc(doc(db, 'panel_settings', org), panelSettings)
    console.log(document)
  } catch (error) {
    return []
  }
}

export const fetchPanelSettings = async (org) => {
  try {
    const querySnapshot = await getDoc(doc(db, 'panel_settings', org))
    return querySnapshot.data()
  } catch (error) {
    return {}
  }
}

export const fetchPanels = async () => {
  try {
    const querySnapshot = await getDocs(query(collection(db, 'panels')))
    const documents = querySnapshot.docs.map((doc) => doc.data())
    return documents
  } catch (error) {
    return []
  }
}

export const fetchGoogleStudioLinks = async () => {
  try {
    const response = await DefaultService.readFromFirebaseReadDocFromFirebaseGet('google_studios_links')
    return response
  } catch (error) {
    return []
  }
}

export const fetchSupersetLinks = async () => {
  try {
    const response: SupersetLinkType[] = await DefaultService.readFromFirebaseReadDocFromFirebaseGet('superset_links')
    return response
  } catch (err) {
    return []
  }
}

export const fetchOrgConfig = async (org: string, access_level: string | string[]) => {
  const admin = AccessLevelEnum.BUSINESS_ADMIN
  try {
    const response = await DefaultService.readFromFirebaseReadDocFromFirebaseGet('org_config_2', org.trim())
    let tempAcessLevel = []

    // this will check if access level is string (old version) type
    if (access_level) {
      if (typeof access_level === 'string') {
        tempAcessLevel = [access_level]
      } else tempAcessLevel = access_level
    } else {
      tempAcessLevel = [admin]
    }

    // this will join routes for multiple access levels
    const tempAccessLevels = Object.keys(response.access_levels || {})
    let routes = []

    // this will run the joining of routes if user is not business-admin
    if (!tempAccessLevels.includes(admin)) {
      tempAcessLevel.forEach((item) => {
        routes = routes.concat(response.access_levels ? response.access_levels[item]?.routes || [] : [])
      })
    } else {
      routes = response.access_levels ? response.access_levels[admin]?.routes || [] : []
    }
    return {
      ...response,
      navConfig: routes,
      filterConfig: response.filterConfig || {
        am_name: false,
        vb_name: false,
        vb_platform: true,
        b_name: true
      }
    }
  } catch (error) {
    console.log(error.message)
    return {}
  }
}

export const saveNote = async (note) => {
  try {
    const newDoc = await addDoc(collection(db, 'notes'), note)
    console.log(newDoc)
  } catch (error) {
    console.log(error)
  }
}

export const fetchNotes = async (user, bName) => {
  try {
    const querySnapshot = await getDocs(query(collection(db, 'notes'), where('createdBy.org', '==', user.org)))
    const documents = querySnapshot.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id
    }))
    const result = (documents as any).filter((document) => document.bName === bName && (document.createdBy.uid === user.uid || document.isPublic))
    result.sort((a, b) => b.updatedAt - a.updatedAt)
    return result
  } catch (error) {
    return []
  }
}

export const deleteNote = async (id: string) => {
  try {
    await deleteDoc(doc(db, 'notes', id))
  } catch (error) {
    return { role: 'user' }
  }
}

export const updateNote = async (id, note) => {
  try {
    const document = await setDoc(doc(db, 'notes', id), note)
    console.log(document)
  } catch (error) {
    return []
  }
}

export const saveSearch = async (savedSearch) => {
  try {
    const newDoc = await addDoc(collection(db, 'saved_searches'), savedSearch)
    console.log(newDoc)
  } catch (error) {
    console.log(error)
  }
}

export const fetchSavedSearches = async (user) => {
  try {
    const querySnapshot = await getDocs(query(collection(db, 'saved_searches'), where('createdBy.org', '==', user.org)))
    const documents = querySnapshot.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id
    }))
    const result = (documents as any).filter((document) => document.createdBy.uid === user.uid)
    result.sort((a, b) => b.updatedAt - a.updatedAt)
    return result
  } catch (error) {
    return []
  }
}

export const deleteSavedSearch = async (id: string) => {
  try {
    await deleteDoc(doc(db, 'saved_searches', id))
  } catch (error) {
    return { role: 'user' }
  }
}

export const updateSavedSearch = async (id, savedSearch) => {
  try {
    const document = await setDoc(doc(db, 'saved_searches', id), savedSearch)
    console.log(document)
  } catch (error) {
    return []
  }
}

export const getStoreDetails = async (org: string) => {
  try {
    const querySnapshot = await getDocs(query(collection(db, 'store_details'), where('chain', '==', org)))
    const result = querySnapshot.docs.map((doc) => doc.data())
    return result
  } catch (err) {
    return []
  }
}

export const isSlackIntegrated = async (org: string) => {
  try {
    const querySnapshot = await getDocs(query(collection(db, 'slack_teams_info'), where('chain', '==', org)))

    return !querySnapshot.empty
  } catch (err) {
    return false
  }
}

export function logTimeDifference(end: number, start: number, title?: string) {
  const diff = end - start
  if (diff > 400) console.log(`${title} - ${parseFloat((diff / 1000).toString()).toFixed(2)}s`)
}
export let labelList = []
export let timeList = []
export function appendLatestTime(label: string) {
  const end = Date.now()
  timeList.push(end)
  labelList.push(label)
  if (timeList.length > 1) {
    logTimeDifference(end, timeList[timeList.length - 2], `${labelList[labelList.length - 2]} - ${label}`)
    logTimeDifference(timeList[timeList.length - 1], timeList[0], 'TOTAL')
  }
  // if (timeList.length > 10) console.log({timeList, labelList})
}

export function clearList() {
  labelList = []
  timeList = []
}
