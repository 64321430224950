import { Box, Stack, Typography } from '@mui/material'
import smileRectImg from 'src/assets/icons/smile_rect.png'

export default function EmptyTemplate() {
  return (
    <>
      <Stack direction="column" alignItems="center">
        <Box>
          <img
            src={smileRectImg}
            style={{
              width: '108px',
              height: '69px',
              objectFit: 'contain'
            }}
          />
        </Box>
        <Typography variant="subtitle2">
          No evidence needed for this set of date selection{' '}
        </Typography>
        <Typography variant="subtitle2" color="rgba(0, 0, 0, 0.5)">
          Keep up the good work
        </Typography>
      </Stack>
    </>
  )
}
