import { SvgIcon, SvgIconProps } from '@mui/material'

export default function InfoIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg
        width="21"
        height="21"
        viewBox="0 0 21 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <rect
          x="0.599609"
          y="0.154297"
          width="20"
          height="20"
          rx="10"
          fill="#E6E6E6"
          fillOpacity="0.901961"
        />
        <path
          d="M9.98924 14.5942V8.18624H11.1412V14.5942H9.98924ZM9.00524 9.09824V8.18624H11.1412V9.09824H9.00524ZM10.3132 7.15424C10.0572 7.15424 9.86524 7.08624 9.73724 6.95024C9.61724 6.81424 9.55724 6.64224 9.55724 6.43424C9.55724 6.22624 9.61724 6.05424 9.73724 5.91824C9.86524 5.78224 10.0572 5.71424 10.3132 5.71424C10.5692 5.71424 10.7572 5.78224 10.8772 5.91824C11.0052 6.05424 11.0692 6.22624 11.0692 6.43424C11.0692 6.64224 11.0052 6.81424 10.8772 6.95024C10.7572 7.08624 10.5692 7.15424 10.3132 7.15424Z"
          fill="#1E1E1E"
        />
      </svg>
    </SvgIcon>
  )
}
