import React from 'react'
import { ResultType } from 'src/components/SingleSelectFilter/SingleSelectFilter'

type Types =
  | 'am_name'
  | 'b_name'
  | 'error_category'
  | 'error_subcategory'
  | 'vb_name'
  | 'vb_platform'
  | 'start_date'
  | 'end_date'

interface SingleSelectFilterContextInterface {
  values: ResultType
  setValues: React.Dispatch<React.SetStateAction<ResultType>>
  getSingleFilter: (types: Types[]) => any[]
  getSingleFilterV2: (
    types: Types[],
    dateWithHyphen?: boolean
  ) => { [key: string]: any }
}

const SingleSelectFilterContext = React.createContext(
  {} as SingleSelectFilterContextInterface
)

export const useSingleSelectFilterContext = () =>
  React.useContext(SingleSelectFilterContext)

export default function SingleSelectFilterContextProvider(props: {
  children?: React.ReactNode
}) {
  const [values, setValues] = React.useState<ResultType>({})

  const getSingleFilter = (types: Types[]) => {
    let arr = []
    types.forEach((type) => {
      if (type === 'start_date') {
        arr.push(values['dateRange']?.start.format('YYYYMMDD') || '')
      } else if (type === 'end_date') {
        arr.push(values['dateRange']?.end.format('YYYYMMDD') || '')
      } else {
        arr.push(values[type]?.value || '')
      }
    })
    return arr
  }
  const getSingleFilterV2 = (types: Types[], dateWithHyphen?: boolean) => {
    let obj: { [key: string]: any } = {}
    types.forEach((type) => {
      if (type === 'start_date') {
        obj[`${type}_in`] =
          values['dateRange']?.start.format(
            dateWithHyphen ? 'YYYY-MM-DD' : 'YYYYMMDD'
          ) || ''
      } else if (type === 'end_date') {
        obj[`${type}_in`] =
          values['dateRange']?.end.format(
            dateWithHyphen ? 'YYYY-MM-DD' : 'YYYYMMDD'
          ) || ''
      } else {
        obj[`${type}_in`] = values[type]?.value || ''
      }
    })
    return obj
  }

  const contextValue = React.useMemo(() => {
    return {
      values,
      setValues,
      getSingleFilter,
      getSingleFilterV2
    }
  }, [values, setValues])

  return (
    <SingleSelectFilterContext.Provider value={contextValue}>
      {props.children}
    </SingleSelectFilterContext.Provider>
  )
}
