import { Container, CustomMaterialTable } from '@LoopKitchen/loop-ui'
import BoltIcon from '@mui/icons-material/Bolt'
import CampaignIcon from '@mui/icons-material/Campaign'
import CategoryIcon from '@mui/icons-material/Category'
import { Box, Stack, Typography } from '@mui/material'
import { get } from 'lodash'
import moment from 'moment'
import React from 'react'
import PlatformLogo from 'src/components/PlatformLogo'
import { useTabPanelContext } from 'src/components/TabPanel'
import { useFilter } from 'src/context/FilterContext'
import { useSnackData } from 'src/context/SnackContext'
import { DefaultService, StoreCountAggregate, StoreRatingReviewsCampaign, StoreRatingReviewsCampaignPaginated } from 'src/services/openApi'
import { formatNumber } from 'src/utils/functions'
import { downloadFailedSlackAlert, downloadSuccessSlackAlert } from 'src/utils/functions/slackSupport'
import useLayoutDimension from 'src/utils/hooks/useLayoutDimension'
import usePostHogHook from 'src/utils/hooks/usePostHogHook'
import BoltCancelIcon from '../Icons/BoltCancelIcon'
import LocationAutomationIcon from '../Icons/LocationAutomationIcon'

export default function StoreMetrics() {
  const { trackPostHogDownload } = usePostHogHook()
  const { tabFocused } = useTabPanelContext()
  const { openError, setDownloadSnack } = useSnackData()
  const { getFiltersV2, getFilters } = useFilter()
  const { headerHeightPx } = useLayoutDimension()

  const [storeCountData, setStoreCountData] = React.useState<StoreCountAggregate>(null)
  const [storeCountLoading, setStoreCountLoading] = React.useState(false)

  const [storeRatingReviewData, setStoreRatingReviewData] = React.useState<StoreRatingReviewsCampaignPaginated>(null)
  const [storeRatingReviewLoading, setStoreRatingReviewLoading] = React.useState(false)

  const [storeRatingReviewOrderBy, setStoreRatingReviewOrderBy] = React.useState<string>(undefined)
  const [storeRatingReviewsAscending, setStoreRatingReviewAscending] = React.useState<boolean>(undefined)

  const getStoreCountData = async () => {
    setStoreCountLoading(true)
    try {
      const res = await DefaultService.callStoreCountApiStoreCountPost(
        getFiltersV2(['vb_name', 'chain', 'b_name', 'vb_platform', 'am_name', 'start_date', 'end_date'], true)
      )
      setStoreCountData(res)
    } catch (err) {
      if (tabFocused) {
        openError(err.message)
      }
    }
    setStoreCountLoading(false)
  }

  const getStoreRatingReviewData = async (body: { offset: number; limit?: number }, callback: (data: typeof storeRatingReviewData) => void) => {
    setStoreRatingReviewLoading(true)
    try {
      const res = await DefaultService.callStoreRatingReviewsCampaignApiPaginatedStoreRatingReviewsCampaignPost({
        limit: body.limit || 100,
        offset: body.offset,
        order_by: storeRatingReviewOrderBy,
        ascending: storeRatingReviewsAscending,
        ...getFiltersV2(['vb_name', 'chain', 'b_name', 'vb_platform', 'am_name', 'start_date', 'end_date'], true)
      })
      callback(res)
    } catch (err) {
      console.log('error: ', err)
      if (tabFocused) {
        openError(err.message)
      }
    }
    setStoreRatingReviewLoading(false)
  }

  const downloadStoreRatingReviewCSV = async () => {
    setDownloadSnack({ status: 'start' })
    const procedure = 'dynamic/StoreRatingReviewsCampaign'
    try {
      const res = await DefaultService.generateCsvFromProcedureGenerateCsvPost(
        procedure,
        getFiltersV2(['vb_name', 'chain', 'b_name', 'vb_platform', 'am_name', 'start_date', 'end_date'], true)
      )
      let blob = new Blob([res], { type: 'text/csv' })
      let url = URL.createObjectURL(blob)
      let a = document.createElement('a')
      a.download = 'store_rating_review.csv'
      a.href = url
      a.click()
      setDownloadSnack({ status: 'complete' })

      trackPostHogDownload({
        fileName: a.download,
        type: 'CSV'
      })
      downloadSuccessSlackAlert({ title: a.download, apiUrl: '/generate/csv', procedureName: procedure })
    } catch (err) {
      setDownloadSnack({ status: 'close' })
      openError(err.message)
      downloadFailedSlackAlert({ err, apiUrl: '/generate/csv', procedureName: procedure })
    }
  }

  React.useEffect(() => {
    // getQueryData()
    getStoreCountData()
    getStoreRatingReviewData({ offset: 0 }, (data) => {
      setStoreRatingReviewData(data)
    })
  }, [...getFilters(['vb_name', 'chain', 'b_name', 'vb_platform', 'am_name', 'start_date', 'end_date'])])

  React.useEffect(() => {
    if (storeRatingReviewOrderBy !== undefined || storeRatingReviewsAscending !== undefined) {
      getStoreRatingReviewData(
        {
          offset: 0,
          limit: Math.max(100, get(storeRatingReviewData, 'data.length', 100))
        },
        (data) => {
          setStoreRatingReviewData(data)
        }
      )
    }
  }, [storeRatingReviewOrderBy, storeRatingReviewsAscending])

  return (
    <>
      <Container
        sx={{ mt: '20px' }}
        icon={
          <CampaignIcon
            fontSize="inherit"
            color="inherit"
          />
        }
        title="Store Marketing Metrics"
        dataCards={
          storeCountData !== null && [
            {
              icon: (
                <BoltIcon
                  fontSize="inherit"
                  color="inherit"
                />
              ),
              title: 'Stores with active campaigns',
              loading: storeCountLoading,
              amount: formatNumber(get(storeCountData, 'count_of_active_campaign_store', 0), { maxFractionDigits: 0 }),
              sx: { width: '172px' }
            },
            {
              icon: (
                <BoltCancelIcon
                  fontSize="inherit"
                  color="inherit"
                />
              ),
              title: 'Stores with no campaigns',
              loading: storeCountLoading,
              amount: formatNumber(get(storeCountData, 'store_without_active_campaign', 0), { maxFractionDigits: 0 }),
              sx: { width: '172px' }
            },
            {
              icon: (
                <CategoryIcon
                  fontSize="inherit"
                  color="inherit"
                />
              ),
              title: 'Stores with Promos',
              loading: storeCountLoading,
              amount: formatNumber(get(storeCountData, 'count_of_promo_store_active', 0), { maxFractionDigits: 0 }),
              sx: { width: '172px' }
            },
            {
              icon: (
                <LocationAutomationIcon
                  fontSize="inherit"
                  color="inherit"
                />
              ),
              title: 'Stores with Ads',
              loading: storeCountLoading,
              amount: formatNumber(get(storeCountData, 'count_of_ad_store_active', 0), { maxFractionDigits: 0 }),
              sx: { width: '172px' }
            }
          ]
        }>
        <Box sx={{ mt: '26px' }}>
          {/* <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent={'space-between'}
                >
                  <FormControl sx={{ width: '233px' }}>
                    <InputLabel id="promo-select-label">Promo</InputLabel>
                    <Select
                      labelId="promo-select-label"
                      id="promo-select"
                      value={selectedCampaign}
                      label="Age"
                      onChange={onChangeCampaign}
                    >
                      {campaignList.map((item, i) => {
                        return (
                          <MenuItem key={i} value={item}>
                            {item}
                          </MenuItem>
                        )
                      })}
                    </Select>
                  </FormControl>
                </Stack> */}

          <Box>
            <CustomMaterialTable
              isLoading={storeRatingReviewLoading}
              data={get(storeRatingReviewData, 'data', []) as StoreRatingReviewsCampaign[]}
              columns={[
                {
                  title: 'Store',
                  field: 'vb_name',
                  // headerStyle: { textAlign: 'center' },
                  render: (data) => {
                    return (
                      <Stack
                        direction="row"
                        alignItems={'center'}
                        gap="25px">
                        <Box>
                          <PlatformLogo
                            shortImg
                            platformName={get(data, 'vb_platform', '')}
                            width="24px"
                            height="26px"
                          />
                        </Box>
                        <Box flex={1}>
                          {/* <Typography
                                  fontSize="12px"
                                  color="rgba(0, 0, 0, 0.5)"
                                  lineHeight="132%"
                                >
                                  #this_is_id
                                </Typography> */}
                          <Typography
                            fontSize="16px"
                            fontWeight={600}
                            lineHeight="132%">
                            {get(data, 'vb_name', '')}
                            {/* Halal Guy's */}
                          </Typography>
                        </Box>
                      </Stack>
                    )
                  }
                },
                {
                  title: 'Campaigns',
                  field: 'count_campaigns_active',
                  alignType: 'currency',
                  render: (data) => {
                    return <Typography fontSize="12px">{formatNumber(get(data, 'count_campaigns_active', 0) || 0, { maxFractionDigits: 0 })}</Typography>
                  }
                },
                {
                  title: 'Address',
                  field: 'vb_address',
                  render: (data) => {
                    return (
                      <Typography
                        fontSize="12px"
                        fontWeight="400">
                        {get(data, 'vb_address', '')}
                      </Typography>
                    )
                  }
                },
                {
                  title: 'Store start date',
                  field: 'start_date',
                  hidden: true,
                  render: (data) => {
                    return (
                      <Typography
                        fontSize="12px"
                        lineHeight="132%">
                        {moment(new Date()).format('D MMM, hA')}
                      </Typography>
                    )
                  }
                },
                {
                  title: 'Rating',
                  field: 'rating',
                  alignType: 'currency',
                  sortable: true,
                  render: (data) => {
                    return (
                      <Typography
                        fontSize="12px"
                        fontWeight={400}>
                        {formatNumber(get(data, 'rating', 0), {
                          maxFractionDigits: 1
                        })}
                      </Typography>
                    )
                  }
                },
                {
                  title: 'Num Ratings',
                  field: 'num_rating',
                  alignType: 'currency',
                  sortable: true,
                  render: (data) => {
                    return (
                      <Typography
                        fontSize="12px"
                        fontWeight={400}>
                        {formatNumber(get(data, 'num_rating', 0), {
                          maxFractionDigits: 0
                        })}
                      </Typography>
                    )
                  }
                },
                {
                  title: 'ROI',
                  field: 'roi',
                  alignType: 'currency',
                  sortable: true,
                  render: (data) => {
                    return (
                      <Typography
                        fontSize="12px"
                        fontWeight={600}>
                        {formatNumber(get(data, 'roi', 0), {
                          maxFractionDigits: 1
                        })}
                      </Typography>
                    )
                  }
                }
              ]}
              options={{
                stickyHeader: headerHeightPx,
                export: true,
                sortable: true,
                showOrderBy: true,
                initialOrderBy: storeRatingReviewsAscending ? 'ascending' : 'descending',
                pagination: true,
                totalPaginatedDataLength: get(storeRatingReviewData, 'max_rows', undefined),
                headerRowStyle: {
                  bgcolor: 'rgba(251, 251, 251, 1)'
                },
                headerCellStyle: {
                  color: 'rgba(0, 0, 0, 0.5)'
                },
                bodyCellStyle: {
                  borderLeft: 'none',
                  borderRight: 'none'
                }
              }}
              onExportCSV={() => {
                downloadStoreRatingReviewCSV()
              }}
              onLastPage={() => {
                if (get(storeRatingReviewData, 'next_offset', undefined)) {
                  getStoreRatingReviewData({ offset: get(storeRatingReviewData, 'next_offset', 0) }, (data) => {
                    setStoreRatingReviewData((prev) => {
                      return {
                        ...data,
                        data: [...get(prev, 'data', []), ...get(data, 'data', [])]
                      }
                    })
                  })
                }
              }}
              onSortByChange={(option) => {
                setStoreRatingReviewOrderBy(option?.value)
              }}
              onOrderByChange={(type) => {
                setStoreRatingReviewAscending(type === 'ascending')
              }}
            />
          </Box>
        </Box>
      </Container>
    </>
  )
}
