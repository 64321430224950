import { Box, Tooltip } from '@mui/material'
import React from 'react'
import { toast } from 'react-toastify'
import PlatformLogo from './PlatformLogo'
interface StringShortenerProps {
  str: string

  allowClickToCopy?: boolean
  displayLength?: number
  platform?: string // New prop for the platform
}

export const StringShortener: React.FC<StringShortenerProps> = ({ str, allowClickToCopy = false, displayLength = 6, platform }) => {
  const shortened = str.substring(str.length - displayLength)

  const [snackbarOpen, setSnackbarOpen] = React.useState(false)

  const handleCopy = () => {
    navigator.clipboard
      .writeText(str)
      .then(() => {
        setSnackbarOpen(true)
      })
      .catch((error) => console.error('Failed to copy text: ', error))
  }

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false)
  }

  const tooltipTitle = allowClickToCopy ? `${str} (Click to copy)` : str

  React.useEffect(() => {
    if (snackbarOpen) toast.info('Copied to clipboard', { onClose: handleCloseSnackbar, position: 'bottom-left' })
  }, [snackbarOpen])

  return (
    <div>
      <Tooltip
        title={tooltipTitle}
        placement="top"
        arrow>
        <Box
          component="span"
          sx={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
          onClick={allowClickToCopy ? handleCopy : undefined}>
          {platform && (
            <PlatformLogo
              platformName={platform}
              shortImg={true}
              height="20px"
            />
          )}
          {shortened}
        </Box>
      </Tooltip>
    </div>
  )
}
