import { get } from 'lodash'
import { useAuth } from 'src/context/AuthContext'
import { DefaultService, UsersService } from 'src/services/openApi'
import { ApiFilterResponseType, BusinessRefreshBnameLevelData, BusinessRefreshChainLevelData, UserBNameNoAuthType } from '../utils/types'

export default function useFilterDataServiceHook() {
  const { currentUser } = useAuth()

  /**
   * by this function we get all the data of filters related to specific ORG
   */
  const getAllFiltersData = async (org?: string) => {
    try {
      const res: ApiFilterResponseType[] = await DefaultService.fastProcedureReadChainLevelFastProcedureReadChainLevelChainGet(
        org || get(currentUser, 'org', ''),
        'procedure_slug_am_mapping_category_subcategory_filters'
      )
      return res
    } catch (err) {
      console.log('get all filters data: ', err)
      throw err
    }
  }

  /**
   * This function sets the b_names that is assigned to the user (mainly for `store-manager` access level)
   * @returns
   */
  const getUserAssignedBNamesData = async () => {
    try {
      if (!get(currentUser, 'org', false)) return []

      const res: UserBNameNoAuthType = await UsersService.getBnamesByUserNoAuthUserBnamesNoAuthGet(get(currentUser, 'uid', ''), 100000)
      return get(res, 'items', [] as typeof res.items)
    } catch (err) {
      console.log('getUserAssignedBNamesData: ', err)
      throw err
    }
  }

  /**
   *
   * @param bNames
   * @returns
   */
  const getBusinessRefreshChainLevelData = async (procedureName: string, bNames?: string, org?: string) => {
    try {
      if (!get(currentUser, 'org', false)) return undefined

      const res: BusinessRefreshChainLevelData[] = await DefaultService.runProcedureAndGetDataV2RunQueryV2Post(
        procedureName,
        bNames && procedureName === 'business_refresh_chain_level_v2'
          ? {
              b_name_in: bNames,
              chain_in: org || get(currentUser, 'org', '')
            }
          : { chain_in: org || get(currentUser, 'org', '') }
      )
      return res
    } catch (err) {
      console.log('getBusinessRefreshChainLevelData: ', err)
      throw err
    }
  }

  /**
   *
   * @param bNames
   * @returns
   */
  const getBusinessRefreshBNameLevelData = async (bNames?: string, org?: string) => {
    try {
      if (!get(currentUser, 'org', false)) return undefined

      const res: BusinessRefreshBnameLevelData[] = await DefaultService.runProcedureAndGetDataV2RunQueryV2Post(
        'business_refresh_b_name_level',
        bNames
          ? {
              b_name_in: bNames,
              chain_in: org || get(currentUser, 'org', '')
            }
          : { chain_in: org || get(currentUser, 'org', '') }
      )
      res.sort((item1, item2) => (item1.lastDate > item2.lastDate ? -1 : 1))
      return res
    } catch (err) {
      console.log('getBusinessRefreshChainLevelData: ', err)
      throw err
    }
  }

  return {
    getAllFiltersData,
    getUserAssignedBNamesData,
    getBusinessRefreshChainLevelData,
    getBusinessRefreshBNameLevelData
  }
}
