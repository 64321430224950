import { Alert } from '@LoopKitchen/loop-ui'
import { Box, Stack } from '@mui/material'
import React from 'react'
import { Area, Bar, CartesianGrid, ComposedChart, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import PlatformLogo from 'src/components/PlatformLogo'
import { useFilter } from 'src/context/FilterContext'
import { formatCurrency, formatNumber } from 'src/utils/functions'
import { SeriesType } from './GraphContainer'
import LegendIcon from './LegendIcon'

interface ColumnChartProps {
  data: SeriesType[]
}
const CustomLegends = (props: any) => {
  return (
    <Stack
      direction={'row'}
      alignItems="center"
      gap={'25px'}
      ml={'-20px'}>
      <Alert
        content="Grubhub data is based on store downtimes fetched from public website"
        variant="warning"
        alertSx={
          {
            // ml: '1.5em'
          }
        }
      />
      <div style={{ flex: 1 }} />
      {props?.payload.map((item: any, index: number) => (
        <React.Fragment key={index.toString()}>
          <Stack
            direction={'row'}
            justifyContent={'center'}
            alignItems={'center'}
            gap="10px">
            {item?.dataKey.includes('lost_sales') ? (
              <>
                <LegendIcon sx={{ color: item?.color, fontSize: 16 }} />
                <p
                  style={{
                    fontSize: '10pt',
                    fontWeight: 500,
                    color: '#555',
                    margin: 0,
                    fontFamily: 'Sora',
                    lineHeight: '18px'
                  }}>
                  {item.value}
                </p>
              </>
            ) : (
              <>
                <div style={{ width: '13px', height: '13px', borderRadius: '2px', background: item?.color || '#000' }} />
                <div>
                  <PlatformLogo
                    shortImg
                    platformName={item?.value}
                    width="26px"
                    height="26px"
                    boxSx={{ display: 'flex' }}
                  />
                </div>
              </>
            )}
          </Stack>
        </React.Fragment>
      ))}
    </Stack>
  )
}
export default function ChartForDowntime(props: ColumnChartProps) {
  const { getFilters } = useFilter()
  const platform = getFilters(['vb_platform'])[0]
  return (
    <Box>
      <ResponsiveContainer
        width={'100%'}
        height={270}>
        <ComposedChart
          height={'100%'}
          width={'100%'}
          data={props.data}
          margin={{
            left: 20,
            right: 20,
            top: 25
          }}>
          <defs>
            <linearGradient
              id="myGradient"
              gradientTransform="rotate(90)"
              stopColor="#F15411">
              <stop
                offset="5%"
                stopColor="#F15411"
              />
              <stop
                offset="95%"
                stopColor="#FFFFFF"
              />
            </linearGradient>
          </defs>
          <CartesianGrid stroke="#d8dae5" />
          <XAxis
            dataKey="date"
            style={{ fontSize: '14px' }}
          />
          <YAxis
            yAxisId="left"
            style={{ fontSize: '14px' }}
            label={{ value: 'Sales', angle: -90, position: 'insideLeft', dx: -18, dy: 20, fill: '#555' }}
            tickFormatter={(value) => {
              return formatCurrency(value, {
                maxFractionDigits: 1,
                notation: 'compact'
              })
            }}
          />
          <YAxis
            yAxisId="right"
            orientation="right"
            style={{ fontSize: '14px', color: '#555' }}
            label={{ value: 'Downtime', angle: -90, position: 'insideRight', dx: 18, dy: -45, fill: '#555' }}
            tickFormatter={(value) => {
              return `${formatNumber(value)}%`
            }}
          />
          <Tooltip
            formatter={(value, key) => {
              if (key == 'Lost Sales') {
                return `${formatCurrency(value, {
                  maxFractionDigits: 1
                })}`
              }
              return `${formatNumber(value)}%`
            }}
          />
          <Legend
            align="right"
            verticalAlign="top"
            wrapperStyle={{ left: '20px', top: '0px' }}
            content={(props) => {
              return <CustomLegends {...props} />
            }}
          />
          <Area
            dataKey={'lost_sales'}
            // fill="url(#myGradient)"
            // stroke="#F15412"
            stroke="#000"
            fill="transparent"
            yAxisId="left"
            name={'Lost Sales'}
          />
          {(platform?.toLowerCase()?.includes('doordash') || platform == '') && (
            <Bar
              // barSize={20}
              maxBarSize={4}
              dataKey="doordash"
              fill="rgba(255, 48, 8, 1)"
              yAxisId="right"
              name={'DoorDash'}
            />
          )}
          {(platform?.toLowerCase()?.includes('ubereats') || platform == '') && (
            <Bar
              // barSize={20}
              maxBarSize={4}
              dataKey="ubereats"
              fill="rgba(15, 120, 60, 1)"
              yAxisId="right"
              name={'UberEats'}
            />
          )}
          {(platform?.toLowerCase()?.includes('grubhub') || platform == '') && (
            <Bar
              dataKey="grubhub"
              // barSize={20}
              maxBarSize={4}
              fill="rgba(255, 128, 0, 1)"
              yAxisId="right"
              name={'Grubhub'}
            />
          )}

          {/* <Scatter dataKey="cnt" fill="red" /> */}
        </ComposedChart>
      </ResponsiveContainer>
    </Box>
  )
}
