import { dao__order_context__Transaction } from '@LoopKitchen/typescript-client/lib/clients/FrontendDataService'
import { Box, Stack, Typography } from '@mui/material'
import { capitalize } from 'lodash'
import moment from 'moment'
import React from 'react'
import { formatCurrency } from 'src/utils/functions'
import BorderedPriceBox from './helpers/BorderedPriceBox'
import HeadingContainer from './helpers/HeadingContainer'

interface TransactionsCompProps {
  transactions?: dao__order_context__Transaction[]
  loading?: boolean
}

export default function TransactionsComp(props: TransactionsCompProps) {
  const { transactions = [], loading } = props

  const data = React.useMemo(() => {
    if (loading) {
      const arr = new Array(3)
      arr.fill(1)
      return arr as typeof transactions
    }
    return transactions || []
  }, [transactions, loading])

  return (
    <HeadingContainer
      icon={'assured_workload'}
      title="Transactions">
      <Stack
        direction="column"
        alignItems="stretch"
        gap="10px">
        {data.map((item, index) => {
          return (
            <BorderedPriceBox
              key={index + (item?.payout_id || item).toString()}
              collapseDefaultExpanded={index === 0}
              loading={loading}
              label={capitalize(item.transaction_type?.replaceAll('_', ' ') || '')}
              value={formatCurrency(item?.net_payout, { maxFractionDigits: 2 })}
              collapseElement={
                <Box>
                  <TransactionsComp.Transaction
                    transaction={item}
                    loading={loading}
                  />
                </Box>
              }
            />
          )
        })}
      </Stack>
    </HeadingContainer>
  )
}

TransactionsComp.Transaction = (props: { transaction?: dao__order_context__Transaction; loading?: boolean }) => {
  const { transaction, loading } = props

  const data = React.useMemo(() => {
    const arr: { label: string; value: string }[] = []

    if (loading || transaction?.payout_id) {
      arr.push({
        label: 'Payout Id',
        value: transaction?.payout_id
      })
    }
    if (loading || transaction?.payout_date) {
      arr.push({
        label: 'Payout Date',
        value: moment(transaction?.payout_date).format('DD MMM, YYYY')
      })
    }
    if (loading || transaction?.net_payout) {
      arr.push({
        label: 'Net Payout',
        value: formatCurrency(transaction?.net_payout, { maxFractionDigits: 2 })
      })
    }
    if (loading || transaction?.lost_sale) {
      arr.push({
        label: 'Lost Sale',
        value: formatCurrency(transaction?.lost_sale, { maxFractionDigits: 2 })
      })
    }
    if (loading || transaction?.transaction_timestamp) {
      arr.push({
        label: 'Transaction Date',
        value: moment(transaction?.transaction_timestamp).format('DD MMM, YYYY')
      })
    }
    return arr
  }, [transaction, loading])

  return (
    <Stack
      direction="column"
      alignItems="stretch"
      gap="5px"
      sx={{ p: '10px' }}>
      {data.map((item) => {
        return (
          <Stack
            key={item?.label}
            direction="row"
            alignItems="center"
            justifyContent="space-between">
            <Typography
              fontSize="12px"
              color="rgba(0, 0, 0, 0.4)">
              {item?.label}
            </Typography>
            <Typography
              fontSize="12px"
              color="rgba(0, 0, 0, 1)">
              {item?.value}
            </Typography>
          </Stack>
        )
      })}
    </Stack>
  )
}
