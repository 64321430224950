import { styled } from '@mui/material'

const StyledLogo = styled((props: { src: string }) => (
  <img alt="img" {...props} />
))(({ theme, open }: { theme: any; open: boolean }) => ({
  marginLeft: open ? 16 : 'auto',
  marginTop: 16,
  marginBottom: 80,
  marginRight: open ? 0 : 'auto'
}))

export default StyledLogo
