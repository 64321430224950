import { Box, Tab, Tabs, Typography } from '@mui/material'
import { get } from 'lodash'
import React from 'react'
import { useLocation, useParams } from 'react-router'
import BreadcrumbsComp from 'src/components/BreadcrumbsComp'
import TabPanel from 'src/components/TabPanel'
import Page from 'src/components/mui/Page'
import { useDrillDownContext } from 'src/context/DrillDownContext'
import { usePrivateLayoutDrawerContext } from 'src/context/PrivateLayoutDrawerContext'
import { filterNames } from 'src/utils/config/config'
import DrillDownDrawerStack from './components/DrillDownDrawerStack'
import DrillDownFilterComponent from './components/DrillDownFilters'
import DrillDownTable from './components/DrillDownTable'
import allJsonData from './utils/jsonConfigs'
import componentsObject from './utils/jsonConfigs/componentsObject'
import { FiltersObjType } from './utils/jsonConfigs/types'

export const getBreadcrumbLabel = (dataArr: FiltersObjType[]) => {
  if (dataArr.length > 1 && ['b_name', 'vb_name', 'vb_platform'].every((item) => dataArr.some((e) => e.key === item))) {
    return `${filterNames.b_name}, Digital Stores`
  } else if (dataArr.length > 1 && ['vb_name', 'vb_platform'].every((item) => dataArr.some((e) => e.key === item))) {
    return `Digital Stores`
  } else if (dataArr.length > 0) {
    const getDataLabel = (obj: (typeof dataArr)[0]) => `${get(filterNames, obj.key, obj.key)} ${obj.value.length > 1 ? `(${obj.value.length})` : ''}`
    return dataArr.map(getDataLabel).join(', ')
  }
  return ``
}

interface DrillDownProps {}

function DrillDown(props: DrillDownProps) {
  const { stack, resetDrillDown, setFilterData } = useDrillDownContext()
  const { setOpen: setPrivateLayoutDrawerOpen } = usePrivateLayoutDrawerContext()
  const [tabValue, setTabValue] = React.useState('store_metrics')
  const params = useParams()
  const location = useLocation()

  const prevFromRoute = React.useRef(null)
  const fromRoute: { label: string; route: string } = React.useMemo(() => {
    const obj = location.state
    if (!obj || typeof obj !== 'object' || !('label' in obj) || !('route' in obj)) {
      return prevFromRoute.current || null
    }
    if (obj) {
      prevFromRoute.current = obj
      return obj
    }
    return null
  }, [location.state])

  const filtersArr = React.useMemo(() => {
    return []
  }, [])

  const drillDownName = React.useMemo(() => {
    const name = get(params, 'name', null)
    if (!name) {
      return null
    }
    const keys = Object.keys(allJsonData)

    const result = keys.find((item) => item.toLowerCase() === name.toLowerCase())

    return result as keyof typeof allJsonData
  }, [get(params, 'name', '')])

  const jsonData = React.useMemo(() => {
    if (!drillDownName) {
      return null
    }
    return get(allJsonData, drillDownName, null)
  }, [drillDownName])

  const tabsArr = React.useMemo(() => {
    if (!jsonData) {
      return []
    }
    const tabObj = get(jsonData, 'tabs', {} as typeof jsonData.tabs)
    const tabKeys = Object.keys(tabObj)

    return tabKeys.map((key) => {
      let component: React.ReactNode = tabObj[key]
      if (typeof component === 'string' && component in componentsObject) {
        const { Component, props } = componentsObject[component]
        component = <Component {...props} />
      }
      return {
        label: key,
        value: key,
        component
      }
    })
  }, [jsonData])

  const isTabsVisible = React.useMemo(() => {
    if (!Array.isArray(tabsArr) || tabsArr.length === 0 || filtersArr.length > 0) {
      return false
    }
    return true
  }, [tabsArr, filtersArr])

  React.useEffect(() => {
    return () => {
      resetDrillDown()
    }
  }, [])

  React.useEffect(() => {
    if (stack.length > 0) {
      setPrivateLayoutDrawerOpen(false)
    } else {
      setPrivateLayoutDrawerOpen(true)
    }
  }, [stack])

  return (
    <>
      <DrillDownDrawerStack fromRoute={fromRoute} />
      <Page
        hideFilters
        title={jsonData ? get(jsonData, 'title', '') : 'Invalid URL'}
        sx={{ bgcolor: 'white' }}>
        {!jsonData && (
          <Box sx={{ width: '100%', height: '80vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Typography variant="h3">Invalid</Typography>
          </Box>
        )}
        {jsonData && (
          <>
            <Box sx={{ px: '23px', pt: '15px' }}>
              <DrillDownFilterComponent
                stack={[]} // empty to show all data initially
                onFilterUpdate={() => {}} // empty because only dateRange select is vislble initially
              />
            </Box>
            <Box sx={{ pt: '15px', px: '23px', '& a': { textDecoration: 'none' }, '& a:hover': { textDecoration: 'underline' } }}>
              <BreadcrumbsComp breadcrumbArr={fromRoute ? [fromRoute, { label: 'Metrics' }] : []} />
            </Box>

            <Box sx={{ pt: '15px', px: '23px' }}>
              <Box sx={{ display: isTabsVisible ? 'block' : 'none' }}>
                <Tabs
                  value={tabValue}
                  onChange={(e, val) => {
                    setTabValue(val)
                  }}>
                  <Tab
                    label="STORE METRICS"
                    value="store_metrics"
                  />
                  {tabsArr.map((obj) => (
                    <Tab
                      key={obj.value}
                      label={get(obj, 'label', '').toUpperCase()}
                      value={obj.value}
                    />
                  ))}
                </Tabs>
              </Box>
              <Box sx={{ mt: 2 }}>
                <TabPanel
                  index="store_metrics"
                  value={tabValue}>
                  <DrillDownTable
                    drillDownName={drillDownName}
                    jsonData={jsonData}
                    filtersArr={[]}
                    setFilterData={(data) => {
                      setFilterData(data, drillDownName, getBreadcrumbLabel(data), stack.length)
                    }}
                  />
                </TabPanel>
                {tabsArr.map((obj) => {
                  return (
                    <TabPanel
                      index={obj.value}
                      value={tabValue}>
                      {obj.component}
                    </TabPanel>
                  )
                })}
              </Box>
            </Box>
          </>
        )}
      </Page>
    </>
  )
}

export default React.memo(DrillDown)
