import { collection, getDocs, query, where } from 'firebase/firestore';
import React from 'react';
import db from 'src/services/firebaseDb';
import { useErrorData } from 'src/context/ErrorContext';
import { useFilter } from 'src/context/FilterContext';

export type StoreDetailType = {
    activation_status: string
    address: string
    am_name: string
    b_name_id: string
    chain: string
    comments: any
    latitude: number
    longitude: number
    password: string
    slug: string
    storeID: string
    store_url: string
    username: string
    vb_name: string
    vb_platform: string
};

interface StoreDetailContextInterface {
    getStoreDetailBySlug: (slug: string) => StoreDetailType | undefined
}

const StoreDetailContext = React.createContext({} as StoreDetailContextInterface);

export const useStoreDetailContext = () => React.useContext(StoreDetailContext);

interface StoreDetailContextProviderProps {
    children: React.ReactNode
}

export default function StoreDetailContextProvider(props: StoreDetailContextProviderProps) {

    const { getFilters } = useFilter();
    const { handleError } = useErrorData();
    const [storeDetails, setStoreDetails] = React.useState<{ [key: string]: StoreDetailType }>(null);
    const inProgress = React.useRef(false);

    const getStoreDetails = async (org: string) => {
        try {
          const querySnapshot = await getDocs(query(collection(db, "store_details"), where("chain", "==", org)));
          const result = querySnapshot.docs.map(doc => (doc.data()));
          return result;
        } catch (err) {
          return [];
        }
    }
    const getData = async () => {
        const result: typeof storeDetails = {};
        const [chain] = getFilters(["chain"]);
        const res = await getStoreDetails(chain)
        const data = res as StoreDetailType[];
        for (let i = 0; i < data.length; i++) {
            const item = data[i];
            result[item.slug] = item;
        }
        setStoreDetails(result);
    }

    React.useEffect(() => {
        if (storeDetails) {
            inProgress.current = false;
        }
    }, [storeDetails]);

    const getStoreDetailBySlug = React.useCallback((slug: string) => {
        if (storeDetails === null && !inProgress.current) {
            inProgress.current = true;
            getData()
                .then(() => {
                })
                .catch((err) => {
                    handleError(err.message);
                    inProgress.current = false;
                });
        }
        return storeDetails ? storeDetails[slug] : undefined;
    }, [storeDetails, inProgress]);

    return (
        <StoreDetailContext.Provider value={{ getStoreDetailBySlug }} >
            {props.children}
        </StoreDetailContext.Provider>
    )
}