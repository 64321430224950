import React, { createContext, useContext, useMemo, useState } from 'react'

export interface BugBannerType {
  open: boolean
  data: {
    message: string
    image: string | null
    imageLoading: boolean
  }
}

interface BugReportContext {
  bannerOpen: BugBannerType
  setBannerOpen: React.Dispatch<React.SetStateAction<BugBannerType>>
}

const initialState = {} as BugReportContext

const BugLoadingContext = createContext<BugReportContext>(initialState)

export const useBugReportContext = () => useContext(BugLoadingContext)

const BugReportContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [bannerOpen, setBannerOpen] = useState<BugBannerType>({
    open: false,
    data: {
      message: '',
      image: null,
      imageLoading: false
    }
  })

  const contextValue = useMemo(() => ({ bannerOpen, setBannerOpen }), [bannerOpen, setBannerOpen])

  return <BugLoadingContext.Provider value={contextValue}>{children}</BugLoadingContext.Provider>
}

export default BugReportContextProvider
