import axios from 'axios'
import { Slack } from 'src/services'

const slackService = new Slack()

interface slackMessage {
  title: string
  message: string
  channel?: string
  test?: boolean
}

export default function getData(procedureName, data) {
  return fetch(`https://api.loopapplication.xyz/run/query?procedure_name=data_visualization_metrics.${procedureName}`, {
    headers: {
      'content-type': 'application/json'
    },
    body: JSON.stringify(data),
    method: 'POST'
  })
}

const sendSlackNotification = async (
  slackMessageObject: slackMessage = {
    title: '',
    message: '',
    channel: 'fe-logs',
    test: false
  }
) => {
  try {
    if (process.env.NODE_ENV === 'production' || slackMessageObject.test) {
      return await slackService.postSlack({
        channel: 'frontend-alerts',
        ...slackMessageObject
      })
    }
  } catch (error) {
    console.log(error)
    return await slackService.postSlack({
      ...slackMessageObject
    })
  }
}

export { sendSlackNotification }

export const getDataWithAxios = (procedureName: string, data: string[], compare?: boolean) =>
  axios.post(
    `${process.env.REACT_APP_PROCEDURES}/run/query${compare === true ? '/date_range_compare' : ''}?procedure_name=data_visualization_metrics.${procedureName}`,
    data
  )

export const getDataWithAxios1 = (procedureName: string, data: string[], compare?: boolean) =>
  axios.post(
    `${process.env.REACT_APP_PROCEDURES}/run/query${
      compare === true ? '/date_range_compare' : ''
    }?procedure_name=restaurant_aggregate_metrics.${procedureName}`,
    data
  )
