import Box from '@mui/material/Box'
import { SxProps } from '@mui/material/styles'
import * as Sentry from '@sentry/react'
import get from 'lodash/get'
import { useLayoutEffect, useRef } from 'react'
import SentryErrorBoundary from 'src/components/errors/SentryErrorBoundary'
import { useTopbarContext } from 'src/context/TopbarContext'
import DialogBugReporting from 'src/pages/members/ReportBug/DialogBugReport'
import { NewFilterComponentProps } from '../NewFilterComponent/NewFilterComponent'
interface PropTypes {
  title: string | React.ReactNode
  children: React.ReactNode
  copyUrl?: boolean
  sx?: SxProps
  bgcolor?: string
  filterProps?: NewFilterComponentProps
  hideFilters?: boolean
}

export default function Page(props: PropTypes) {
  const { setTitle, setCopyUrl, setFilterProps, setHideFilters } = useTopbarContext()

  const setTopbarContextStates = () => {
    setTitle(props.title)
    setCopyUrl(get(props, 'copyUrl', true))
    setFilterProps(get(props, 'filterProps', {}))
    setHideFilters(get(props, 'hideFilters', false))
  }

  const ref = useRef({})

  useLayoutEffect(() => {
    setTopbarContextStates()
  }, [get(props, 'filterProps', ref.current)])

  return (
    <Sentry.ErrorBoundary
      fallback={({ error }) => {
        return <SentryErrorBoundary error={error} />
      }}>
      <Box
        height="100%"
        sx={{
          ...get(props, 'sx', {}),
          position: 'relative'
        }}
        bgcolor={get(props, 'bgcolor', '#FFFFFF')}>
        {props.children}
      </Box>

      <DialogBugReporting />
    </Sentry.ErrorBoundary>
  )
}
