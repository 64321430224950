export const indicatorColor = {
  taxes: '#112857',
  marketing: '#196E82',
  reversals: '#42AC70',
  chargebacks: '#FFAB2D',
  commissions: '#FFE5BF',
  unaccounted: '#D8E5FF',
  others: '#D8E5FF',
  restaurant_delivery_charge: '#BDD5DE'
}

export const rowFlex = [1, 1, 1]
