import { ChevronRight } from '@mui/icons-material'
import { Box, SxProps, Typography } from '@mui/material'
import { get } from 'lodash'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Dictionary } from 'src/utils/config/dictionary'
import { formatCurrency, formatNumber } from 'src/utils/functions'
import DataComp from '../../components/DataComp'
import { indicatorColor, rowFlex } from '../../utils/constants'
import { OrderBreakdownResponse } from '../../utils/types'

interface TotalBreakdownProps {
  breakdownData: OrderBreakdownResponse
  breakdownLoading: boolean
  sx?: SxProps
}

const TotalBreakdown = React.forwardRef<HTMLElement, TotalBreakdownProps>((props, componentRef) => {
  const { breakdownData, breakdownLoading, sx } = props
  const navigate = useNavigate()

  const otherTotal = React.useMemo(() => {
    return get(breakdownData, 'values.customer_tip', 0) + get(breakdownData, 'values.miscellaneous', 0) + get(breakdownData, 'values.unaccounted', 0)
  }, [breakdownData])

  return (
    <Box
      ref={componentRef}
      sx={{ ...sx }}>
      <DataComp
        sx={{ border: 'none', py: 0, mt: '15px' }}
        rows={[
          {
            hideIndicator: true,
            sx: { pb: 1 },
            cols: [
              {
                label: 'Component',
                flex: rowFlex[0],
                sx: { fontWeight: 400 }
              },
              {
                label: 'Total',
                flex: rowFlex[2],
                sx: { textAlign: 'right', fontWeight: 600 }
              }
            ]
          }
        ]}
      />

      <DataComp
        sx={{ bgcolor: 'rgba(47, 74, 90, 1)' }}
        rows={[
          {
            hideIndicator: true,
            sx: { py: '3px' },
            cols: [
              {
                label: 'Sales Including Tax',
                flex: rowFlex[0],
                sx: { py: 0, fontWeight: 600, color: 'white', fontSize: '14px' },
                tooltip: 'Total revenue generated from orders, including taxes charged to customers.',
                tooltipColor: 'rgba(255, 255, 255, 0.6)'
              },
              {
                label: (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      flexWrap: 'wrap',
                      flexDirection: 'row-reverse',
                      gap: '10px'
                    }}>
                    <Typography
                      sx={{
                        fontSize: '22px',
                        fontWeight: 600,
                        lineHeight: 'initial',
                        color: '#fff'
                      }}>
                      {formatCurrency(get(breakdownData, 'values.subtotal_with_tax', 0), { maxFractionDigits: 2 })}
                    </Typography>
                    <Box
                      display="flex"
                      justifyContent="flex-end">
                      <Typography sx={{ bgcolor: '#E3EDF0', color: '#196E82', p: '5px 8px', lineHeight: 'initial', borderRadius: '14px', fontSize: '12px' }}>
                        {formatNumber(get(breakdownData, 'percentages.subtotal_with_tax', 0), { maxFractionDigits: 2 })}%
                      </Typography>
                    </Box>
                  </Box>
                ),
                loading: breakdownLoading,
                flex: 'initial',
                sx: {
                  py: 0,
                  color: 'white',
                  display: 'flex',
                  justifyContent: 'flex-end'
                }
              }
            ]
          },
          {
            hideIndicator: true,
            sx: { pt: '10px' },
            cols: [
              {
                label: 'Sales Excluding Tax',
                flex: rowFlex[0],
                sx: { py: 0, color: 'rgba(255, 255, 255, 0.5)', fontSize: '14px', fontWeight: 400 },
                tooltip: 'Total revenue generated from orders, excluding any taxes charged to customers.',
                tooltipColor: 'rgba(255, 255, 255, 0.6)'
              },
              {
                label: (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      flexWrap: 'wrap',
                      flexDirection: 'row-reverse',
                      gap: '10px'
                    }}>
                    <Typography
                      sx={{
                        fontSize: '22px',
                        fontWeight: 600,
                        lineHeight: 'initial',
                        color: '#fff'
                      }}>
                      {formatCurrency(get(breakdownData, 'values.subtotal', 0), { maxFractionDigits: 2 })}
                    </Typography>
                    <Box
                      display="flex"
                      justifyContent="flex-end">
                      <Typography sx={{ bgcolor: '#E3EDF0', color: '#196E82', p: '5px 8px', lineHeight: 'initial', borderRadius: '14px', fontSize: '12px' }}>
                        {formatNumber(get(breakdownData, 'percentages.subtotal', 0), { maxFractionDigits: 2 })}%
                      </Typography>
                    </Box>
                  </Box>
                ),
                flex: 'initial',
                loading: breakdownLoading,
                sx: {
                  py: 0,
                  color: 'rgba(255, 255, 255, 0.5)',
                  display: 'flex',
                  justifyContent: 'flex-end'
                }
              }
            ]
          },
          {
            hideIndicator: true,
            sx: { py: '3px' },
            cols: []
          }
        ]}
      />
      <DataComp
        sx={{ mt: '12px', py: '10px' }}
        rows={[
          {
            hideIndicator: true,
            cols: [
              {
                label: 'Lost Sales',
                flex: rowFlex[2],
                sx: { color: 'rgba(47, 74, 90, 0.6)', fontWeight: 400, fontSize: '14px' },
                tooltip: `Potential revenue lost due to ${Dictionary.CANCELLED.toLowerCase()} or failed orders.`
              },
              {
                label: (
                  <>
                    <span
                      style={{
                        color: 'rgba(47, 74, 90, 0.6)',
                        fontSize: '22px',
                        fontWeight: 600,
                        lineHeight: '175%'
                      }}>
                      {`${formatCurrency(get(breakdownData, 'values.lost_sales', 0), { maxFractionDigits: 2 })}`}
                    </span>
                  </>
                ),
                loading: breakdownLoading,
                flex: 'initial',
                sx: { display: 'flex', justifyContent: 'flex-end' }
              }
            ]
          }
        ]}
      />

      <DataComp
        sx={{ mt: '12px' }}
        rows={[
          {
            indicatorColor: indicatorColor.taxes,
            sx: { borderBottom: '1px solid rgba(230, 230, 230, 1)', pb: '10px' },
            cols: [
              {
                label: 'Taxes',
                flex: rowFlex[0],
                tooltip: 'Taxes collected from customers and paid to you by 3P platforms.',
                sx: { fontSize: '14px' }
              },
              {
                label: (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      flexWrap: 'wrap',
                      flexDirection: 'row-reverse',
                      gap: '10px'
                    }}>
                    <Typography
                      sx={{
                        fontSize: '22px',
                        fontWeight: 600,
                        lineHeight: 'initial',
                        color: 'rgba(15, 120, 60, 1)'
                      }}>
                      +{formatCurrency(get(breakdownData, 'values.tax_host', 0), { maxFractionDigits: 2 })}
                    </Typography>
                    <Box
                      display="flex"
                      justifyContent="flex-end">
                      <Typography sx={{ bgcolor: '#196E821F', color: '#196E82', p: '5px 8px', lineHeight: 'initial', borderRadius: '14px', fontSize: '12px' }}>
                        {formatNumber(get(breakdownData, 'percentages.tax_host', 0), { maxFractionDigits: 2 })}%
                      </Typography>
                    </Box>
                  </Box>
                ),
                loading: breakdownLoading,
                flex: 'initial',
                sx: { display: 'flex', justifyContent: 'flex-end' }
              }
            ]
          },
          {
            sx: {
              mt: '10px',
              borderRadius: '8px',
              border: '1px solid #E6E6E6E6',
              py: '10px',
              pl: '20px'
            },
            hideIndicator: true,
            cols: [
              {
                label: 'Taxes Withheld',
                flex: rowFlex[0],
                sx: { color: 'rgba(47, 74, 90, 0.6)', fontSize: '14px', fontWeight: 400 },
                tooltip: 'Taxes collected from customers and held by 3P platforms to pay to tax authorities on your behalf.'
              },
              {
                label: (
                  <>
                    <span
                      style={{
                        color: 'rgba(47, 74, 90, 0.6)',
                        fontSize: '22px',
                        fontWeight: 600,
                        lineHeight: '175%'
                      }}>
                      {formatCurrency(get(breakdownData, 'values.tax_witheld', 0), { maxFractionDigits: 2 })}
                    </span>
                  </>
                ),
                loading: breakdownLoading,
                flex: 'initial',
                sx: { display: 'flex', justifyContent: 'flex-end' }
              }
            ]
          }
        ]}
      />

      <DataComp
        sx={{ mt: '12px' }}
        rows={[
          {
            indicatorColor: indicatorColor.commissions,
            cols: [
              {
                label: 'Commissions',
                flex: rowFlex[0],
                tooltip: 'Fees charged by the platform for facilitating orders.',
                sx: { fontSize: '14px' }
              },
              {
                label: (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      flexWrap: 'wrap',
                      flexDirection: 'row-reverse',
                      gap: '10px'
                    }}>
                    <Typography
                      sx={{
                        fontSize: '22px',
                        fontWeight: 600,
                        lineHeight: 'initial',
                        color: 'rgba(241, 84, 18, 1)'
                      }}>
                      -{formatCurrency(get(breakdownData, 'values.commission', 0), { maxFractionDigits: 2 })}
                    </Typography>
                    <Box
                      display="flex"
                      justifyContent="flex-end">
                      <Typography sx={{ bgcolor: '#196E821F', color: '#196E82', p: '5px 8px', lineHeight: 'initial', borderRadius: '14px', fontSize: '12px' }}>
                        {formatNumber(get(breakdownData, 'percentages.commission', 0), { maxFractionDigits: 2 })}%
                      </Typography>
                    </Box>
                  </Box>
                ),
                loading: breakdownLoading,
                flex: 'initial',
                sx: { display: 'flex', justifyContent: 'flex-end' }
              }
            ]
          }
        ]}
      />

      <DataComp
        sx={{ mt: '12px' }}
        rows={[
          {
            indicatorColor: indicatorColor.restaurant_delivery_charge,
            cols: [
              {
                label: 'Restaurant Delivery Charge',
                flex: rowFlex[0],
                tooltip: 'Charges collected from customers for restaurant delivery.',
                sx: { fontSize: '14px' }
              },
              {
                label: (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      flexWrap: 'wrap',
                      flexDirection: 'row-reverse',
                      gap: '10px'
                    }}>
                    <Typography
                      sx={{
                        fontSize: '22px',
                        fontWeight: 600,
                        lineHeight: 'initial',
                        color: 'rgba(15, 120, 60, 1)'
                      }}>
                      +{formatCurrency(get(breakdownData, 'values.restaurant_delivery_charge', 0), { maxFractionDigits: 2 })}
                    </Typography>
                    <Box
                      display="flex"
                      justifyContent="flex-end">
                      <Typography sx={{ bgcolor: '#196E821F', color: '#196E82', p: '5px 8px', lineHeight: 'initial', borderRadius: '14px', fontSize: '12px' }}>
                        {formatNumber(get(breakdownData, 'percentages.restaurant_delivery_charge', 0), { maxFractionDigits: 2 })}%
                      </Typography>
                    </Box>
                  </Box>
                ),
                loading: breakdownLoading,
                flex: 'initial',
                sx: { display: 'flex', justifyContent: 'flex-end' }
              }
            ]
          }
        ]}
      />

      <DataComp
        sx={{ mt: '12px', bgcolor: '#E3EDF0', borderColor: '#E3EDF0', pr: '10px', cursor: 'pointer' }}
        rows={[
          {
            onClick: () => navigate('/marketing/new'),
            indicatorColor: indicatorColor.marketing,
            cols: [
              {
                label: 'Marketing',
                flex: rowFlex[0],
                tooltip: 'Costs associated with promoting the restaurant on the platform.',
                sx: { fontSize: '14px' }
              },
              {
                label: (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      flexWrap: 'wrap',
                      flexDirection: 'row-reverse',
                      gap: '10px'
                    }}>
                    <ChevronRight sx={{ color: '#196E82' }} />
                    <Typography
                      sx={{
                        fontSize: '22px',
                        fontWeight: 600,
                        lineHeight: 'initial',
                        color: 'rgba(241, 84, 18, 1)'
                      }}>
                      -{formatCurrency(get(breakdownData, 'values.marketing', 0), { maxFractionDigits: 2 })}
                    </Typography>
                    <Box
                      display="flex"
                      justifyContent="flex-end">
                      <Typography sx={{ bgcolor: '#196E821F', color: '#196E82', p: '5px 8px', lineHeight: 'initial', borderRadius: '14px', fontSize: '12px' }}>
                        {formatNumber(get(breakdownData, 'percentages.marketing', 0), { maxFractionDigits: 2 })}%
                      </Typography>
                    </Box>
                  </Box>
                ),
                loading: breakdownLoading,
                flex: 'initial',
                sx: { display: 'flex', justifyContent: 'flex-end' }
              }
            ]
          }
        ]}
      />

      <DataComp
        sx={{ mt: '12px' }}
        rows={[
          {
            sx: {
              borderRadius: '8px',
              py: '15px',
              pl: '20px',
              pr: '10px',
              border: '1px solid #E3EDF0',
              mb: '10px',
              bgcolor: '#E3EDF0',
              cursor: 'pointer'
            },
            onClick: () => navigate('/chargebacks/overview'),
            indicatorColor: indicatorColor.chargebacks,
            cols: [
              {
                label: 'Chargebacks',
                flex: rowFlex[0],
                tooltip: 'Amount refunded to customers due to disputes or issues with orders.',
                sx: { fontSize: '14px' }
              },
              {
                label: (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      flexWrap: 'wrap',
                      flexDirection: 'row-reverse',
                      gap: '10px'
                    }}>
                    <ChevronRight sx={{ color: '#196E82' }} />
                    <Typography
                      sx={{
                        fontSize: '22px',
                        fontWeight: 600,
                        lineHeight: 'initial',
                        color: 'rgba(241, 84, 18, 1)'
                      }}>
                      -{formatCurrency(get(breakdownData, 'values.chargeback', 0), { maxFractionDigits: 2 })}
                    </Typography>
                    <Box
                      display="flex"
                      justifyContent="flex-end">
                      <Typography sx={{ bgcolor: '#196E821F', color: '#196E82', p: '5px 8px', lineHeight: 'initial', borderRadius: '14px', fontSize: '12px' }}>
                        {formatNumber(get(breakdownData, 'percentages.chargeback', 0), { maxFractionDigits: 2 })}%
                      </Typography>
                    </Box>
                  </Box>
                ),
                loading: breakdownLoading,
                flex: 'initial',
                sx: { display: 'flex', justifyContent: 'flex-end' }
              }
            ]
          },
          {
            indicatorColor: indicatorColor.reversals,
            sx: { pt: '10px', borderTop: '1px solid #E6E6E6E6' },
            cols: [
              {
                label: 'Reversals',
                flex: rowFlex[0],
                sx: {
                  color: 'rgba(47, 74, 90, 0.6)',
                  fontWeight: 'normal',
                  fontSize: '14px'
                },
                tooltip: 'Amount reversed from previous transactions.'
              },
              {
                label: (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      flexWrap: 'wrap',
                      flexDirection: 'row-reverse',
                      gap: '10px'
                    }}>
                    <Typography
                      sx={{
                        fontSize: '22px',
                        fontWeight: 600,
                        lineHeight: 'initial',
                        color: 'rgba(14, 140, 67, 1)'
                      }}>
                      +{formatCurrency(get(breakdownData, 'values.reversal', 0), { maxFractionDigits: 2 })}
                    </Typography>
                    <Box
                      display="flex"
                      justifyContent="flex-end">
                      <Typography sx={{ bgcolor: '#196E821F', color: '#196E82', p: '5px 8px', lineHeight: 'initial', borderRadius: '14px', fontSize: '12px' }}>
                        {formatNumber(get(breakdownData, 'percentages.reversal', 0), { maxFractionDigits: 2 })}%
                      </Typography>
                    </Box>
                  </Box>
                ),
                loading: breakdownLoading,
                flex: 'initial',
                sx: { display: 'flex', justifyContent: 'flex-end' }
              }
            ]
          }
        ]}
      />

      <DataComp
        sx={{ mt: '12px' }}
        rows={[
          {
            indicatorColor: indicatorColor.unaccounted,
            sx: { pb: '5px' },
            cols: [
              {
                label: 'Others',
                flex: rowFlex[0],
                sx: { fontSize: '14px' }
              },
              {
                label: (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      flexWrap: 'wrap',
                      flexDirection: 'row-reverse',
                      gap: '10px'
                    }}>
                    <Typography
                      sx={{
                        fontSize: '22px',
                        fontWeight: 600,
                        lineHeight: 'initial',
                        color: otherTotal > 0 ? 'rgba(14, 140, 67, 1)' : 'rgba(241, 84, 18, 1)'
                      }}>
                      {otherTotal > 0 && '+'}
                      {formatCurrency(otherTotal, { maxFractionDigits: 2 })}
                    </Typography>
                    <Box
                      display="flex"
                      justifyContent="flex-end">
                      <Typography sx={{ bgcolor: '#196E821F', color: '#196E82', p: '5px 8px', lineHeight: 'initial', borderRadius: '14px', fontSize: '12px' }}>
                        {formatNumber(Math.abs((otherTotal * 100) / get(breakdownData, 'values.subtotal_with_tax', 0)), { maxFractionDigits: 2 })}%
                      </Typography>
                    </Box>
                  </Box>
                ),
                loading: breakdownLoading,
                flex: 'initial',
                sx: { display: 'flex', justifyContent: 'flex-end' }
              }
            ]
          },
          {
            sx: { py: '5px' },
            cols: [
              {
                label: 'Customer Tips',
                flex: rowFlex[0],
                sx: {
                  color: 'rgba(47, 74, 90, 0.6)',
                  fontWeight: 'normal'
                },
                tooltip: 'Additional amounts paid by customers as tips for service.'
              },
              {
                label: '+' + formatCurrency(get(breakdownData, 'values.customer_tip', 0), { maxFractionDigits: 2 }),
                flex: 'initial',
                loading: breakdownLoading,
                sx: {
                  display: 'flex',
                  justifyContent: 'flex-end',
                  fontSize: '16px',
                  color: 'rgba(14, 140, 67, 1)'
                }
              }
            ]
          },
          {
            sx: { py: '5px' },
            cols: [
              {
                label: 'Miscellaneous',
                flex: rowFlex[0],
                sx: {
                  color: 'rgba(47, 74, 90, 0.6)',
                  fontWeight: 'normal'
                },
                tooltip: 'Various fees or charges that do not fit into specific categories.'
              },
              {
                label:
                  (get(breakdownData, 'values.miscellaneous', 0) >= 0 ? '+' : '-') +
                  formatCurrency(Math.abs(get(breakdownData, 'values.miscellaneous', 0)), { maxFractionDigits: 2 }),
                flex: 'initial',
                loading: breakdownLoading,
                sx: {
                  display: 'flex',
                  justifyContent: 'flex-end',
                  color: get(breakdownData, 'values.miscellaneous', 0) >= 0 ? 'rgba(14, 140, 67, 1)' : 'rgba(241, 84, 18, 1)',
                  fontSize: '16px'
                }
              }
            ]
          },
          {
            sx: { py: '5px' },
            cols: [
              {
                label: 'Unaccounted',
                flex: rowFlex[0],
                sx: {
                  color: 'rgba(47, 74, 90, 0.6)',
                  fontWeight: 'normal'
                },
                tooltip: 'Amounts that are not allocated or categorized, often requiring further investigation or clarification.'
              },
              {
                label:
                  (get(breakdownData, 'values.unaccounted', 0) >= 0 ? '+' : '-') +
                  formatCurrency(Math.abs(get(breakdownData, 'values.unaccounted', 0)), { maxFractionDigits: 2 }),
                flex: 'initial',
                loading: breakdownLoading,
                sx: {
                  display: 'flex',
                  justifyContent: 'flex-end',
                  color: get(breakdownData, 'values.unaccounted', 0) >= 0 ? 'rgba(14, 140, 67, 1)' : 'rgba(241, 84, 18, 1)',
                  fontSize: '16px'
                }
              }
            ]
          }
        ]}
      />

      <DataComp
        sx={{ bgcolor: 'rgba(47, 74, 90, 1)', mt: '12px', py: 3 }}
        rows={[
          {
            hideIndicator: true,
            cols: [
              {
                label: 'Estimated Payout',
                flex: rowFlex[0],
                sx: { fontSize: '14px', color: 'white' }
              },
              {
                label: (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      flexWrap: 'wrap',
                      flexDirection: 'row-reverse',
                      gap: '10px'
                    }}>
                    <Typography
                      sx={{
                        fontSize: '22px',
                        fontWeight: 600,
                        lineHeight: 'initial',
                        color: '#fff'
                      }}>
                      {formatCurrency(get(breakdownData, 'values.total_payment', 0), { maxFractionDigits: 2 })}
                    </Typography>
                    <Box
                      display="flex"
                      justifyContent="flex-end">
                      <Typography sx={{ bgcolor: '#E3EDF0', color: '#196E82', p: '5px 8px', lineHeight: 'initial', borderRadius: '14px', fontSize: '12px' }}>
                        {formatNumber(get(breakdownData, 'percentages.total_payment', 0), { maxFractionDigits: 2 })}%
                      </Typography>
                    </Box>
                  </Box>
                ),

                loading: breakdownLoading,
                flex: 'initial',
                sx: { display: 'flex', justifyContent: 'flex-end' }
              }
            ]
          }
        ]}
      />
    </Box>
  )
})

export default TotalBreakdown
