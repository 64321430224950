import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'

interface ConfirmDialogProps {
  open: boolean
  title: string
  description: string
  handleClose: () => void
  onConfirm: () => void
}

export default function ConfirmDialog(props: ConfirmDialogProps) {
  return (
    <Dialog
      open={props.open || false}
      onClose={props.handleClose}
      aria-labelledby="confirm-dialog-title"
      aria-describedby="confirm-dialog-description">
      <DialogTitle id="confirm-dialog-title">{props.title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="confirm-dialog-description">{props.description}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          variant="text"
          size="small"
          onClick={props.handleClose}>
          Cancel
        </Button>
        <Button
          variant="contained"
          size="small"
          onClick={props.onConfirm}
          autoFocus>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  )
}
