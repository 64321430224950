import './style.css'
const content = `
<div>

  <body class="c32 doc-content">
    <div>
      <p class="c25 c18"><span class="c20 c8 c24" /></p>
      <a id="t.fb698c93a70f0c8882e00095baa072172a63f929" /><a id="t.1" />
      <img alt="" src="./images/MainLogo.png" style="
                    width: 96px;
                    height: 33px;
                  " title="" />

      <p class="c25 c18"><span class="c1" /></p>
    </div>
    <p class="c18 c25"><span class="c1" /></p>
    <p class="c14" id="h.gjdgxs"><span class="c0">Loop</span></p>
    <p class="c14"><span class="c20 c22 c8">TERMS OF SERVICE</span></p>
    <p class="c7">
      <span class="c20 c8 c22">Last Updated: </span><span class="c22 c8">28 March</span><span class="c0">, 2023</span>
    </p>
    <p class="c7 c18"><span class="c2" /></p>
    <p class="c7 c18"><span class="c2" /></p>
    <p class="c7">
      <span class="c20 c8">Thank you for using the Loop Platform (as defined below), available a
        SaaS-based platform to help manage your digital food brands</span><span class="c8">.</span><span
        class="c2">&nbsp; </span>
    </p>
    <p class="c7">
      <span class="c8 c20">To be eligible to register for an Loop account and use the Loop
        Platform, you must review and accept the terms of this Loop Terms of
        Service (this&nbsp;&ldquo;</span><span class="c4">Agreement</span><span class="c20 c8">&rdquo; or these
        &ldquo;</span><span class="c4">Terms</span><span class="c20 c8">&rdquo;) by clicking the terms of service
        checkbox or other mechanism
        provided within the online registration process.&nbsp;PLEASE REVIEW
        THESE TERMS CAREFULLY. BY ACCEPTING THESE TERMS OR USING THE LOOP
        PLATFORM, YOU AGREE TO THESE TERMS AND CONDITIONS WITH LOOP TECHNOLOGIES
        INC. (&ldquo;</span><span class="c4">Loop</span><span class="c20 c8">&rdquo;). &nbsp;</span><span
        class="c20 c8">IF YOU DO NOT AGREE TO BE BOUND BY THESE TERMS, YOU SHOULD NOT CLICK
        THE &ldquo;</span><span class="c8">Sign Up</span><span class="c20 c8">&rdquo; BUTTON AND YOU SHOULD NOT USE THE
        LOOP PLATFORM</span><span class="c2">. &nbsp;</span>
    </p>
    <p class="c7">
      <span class="c2">In this Agreement, &ldquo;you,&rdquo; &ldquo;your&rdquo; and
        &ldquo;Customer&rdquo; will refer to you. If you are registering for an
        Loop account or using the Loop Platform on behalf of an entity or other
        organization, you are agreeing to these Terms for that entity or
        organization and representing to Loop that you have the authority to
        bind that entity or organization to these Terms (and, in which case, the
        terms &ldquo;you&rdquo;, &ldquo;your&rdquo; and &ldquo;Customer&rdquo;
        will refer to that entity or organization).</span>
    </p>
    <p class="c7">
      <span class="c2">Loop may revise these Terms from time to time. If Loop does revise
        these Terms, the revised Terms will supersede prior versions. Revisions
        will be effective upon the effective date indicated at the top of these
        Terms, unless you have purchased a paid subscription to the Loop
        Platform, in which case such revisions will be effective upon the next
        renewal of your subscription. Loop will provide you advance notice of
        any material revisions. This notice will be provided via the account
        portal and/or via an email to the email address Loop has on file. For
        other revisions, Loop will update the effective date of these Terms at
        the top of the page. Loop encourages you to check the effective date of
        these Terms whenever you visit Loop&rsquo;s website or account portal.
        Your continued access or use of the Loop Platform constitutes your
        acceptance of any revisions. If you do not agree to the revisions, you
        should stop using the Loop Platform and Loop is not obligated to provide
        you with the Loop Platform.</span>
    </p>
    <p class="c7"><span class="c2">IMPORTANT NOTES: </span></p>
    <ul class="c15 lst-kix_list_2-0 start">
      <li class="c7 c37 li-bullet-0">
        <span class="c2">LOOP DOES NOT PROVIDE WARRANTIES OR INDEMNITIES FOR THE LOOP
          PLATFORM, AND THESE TERMS LIMIT LOOP&rsquo;S LIABILITY TO YOU.</span>
      </li>
    </ul>
    <h1 id="h.30j0zll">
          <span class="c0">1. Definitions</span>
        </h1>
    <h1 style="text-align: justify">
          <span class="c9">1.1 </span><span class="c2">The following terms, when used in this Agreement will have the
            following meanings:</span>
        </h1>
    <h2 class="c7">
      <span class="c10">&ldquo;Confidential Information&rdquo;</span><span class="c2">&nbsp;means any information or
        data disclosed by either party that is
        marked or otherwise designated as confidential or proprietary or that
        should otherwise be reasonably understood to be confidential in light of
        the nature of the information and the circumstances surrounding
        disclosure. &nbsp;However, &ldquo;Confidential Information&rdquo; will
        not include any information which (a) is in the public domain through no
        fault of the receiving party; (b) was properly known to the receiving
        party, without restriction, prior to disclosure by the disclosing party;
        (c) was properly disclosed to the receiving party, without restriction,
        by another person with the legal authority to do so; or (d) is
        independently developed by the receiving party without the use of or
        reference to the disclosing party&rsquo;s Confidential
        Information.</span>
    </h2>
    <p class="c7">
      <span class="c4">&ldquo;Documentation&rdquo;</span><span class="c2">&nbsp;means the printed and digital
        instructions, on-line help files,
        technical documentation and user manuals made available by Loop for the
        Loop Platform.</span>
    </p>
    <p class="c7">
      <span class="c20 c8">&ldquo;</span><span class="c4">Order Form</span><span class="c2">&rdquo; means an order form,
        online order page or other similar
        document that sets forth the components of the Loop Platform to which
        Customer is obtaining a subscription, applicable subscription term,
        pricing therefor and other relevant terms, and that references this
        Agreement.</span>
    </p>
    <p class="c7">
      <span class="c4">&ldquo;Loop Platform&rdquo;</span><span class="c2">&nbsp;means the SaaS-based platform to help
        manage your digital food
        brands made available by Loop, and all related documentation, software
        and intellectual property provided by Loop in relation thereto.</span>
    </p>
    <h1 ><span class="c0">2. Loop Platform</span></h1>
    <ol class="c15 lst-kix_list_1-1 start" start="1">
        <h1 id="h.3znysh7" style="text-align: justify">
          <span class="c9">2.1 Provision of Loop Platform</span><span class="c2">. &nbsp;Subject to the terms and conditions
            of this Agreement, Loop
            will use commercially reasonable efforts to make the Loop Platform
            available to Customer pursuant to this Agreement, and hereby grants
            Customer a non-exclusive right to access and use the Loop Platform
            for its internal business purposes to manage employee profiles,
            application and device assignments and onboarding and offboarding
            events.
          </span>
        </h1>
        <h1 id="h.2et92p0" style="text-align: justify">
          <span class="c9">2.2 Customer Limitations</span><span>. &nbsp;The rights granted herein are subject to the
            following
            restrictions (the&nbsp;&ldquo;</span><span class="c10">License Restrictions</span><span class="c2">&rdquo;).
            &nbsp;Customer will not directly or indirectly:</span>
        </h1>
      </li>
    </ol>
    <ol class="c15 lst-kix_list_1-2 start" start="1">
      <li class="c5">
        <h3 id="h.tyjcwt" style="display: inline">
          <span class="c2">reverse engineer, decompile, disassemble, modify, create derivative
            works of, or otherwise create, attempt to create or derive, or
            permit or assist any third party to create or derive, the source
            code underlying the Loop Platform;</span>
        </h3>
      </li>
      <li class="c5">
        <h3 style="display: inline">
          <span class="c2">attempt to probe, scan or test the vulnerability of the Loop
            Platform, breach the security or authentication measures of the Loop
            Platform without proper authorization or wilfully render any part of
            the Loop Platform unusable;</span>
        </h3>
      </li>
      <li class="c5">
        <h3 style="display: inline">
          <span class="c2">attack, or attempt to attack, the Loop Platform using a
            denial-of-service attack, a distributed denial-of-service attack or
            any other attack;</span>
        </h3>
      </li>
      <li class="c5">
        <h3 style="display: inline">
          <span class="c2">use or access the Loop Platform to develop a product or service
            that is competitive with Loop&rsquo;s products or Product;</span>
        </h3>
      </li>
      <li class="c5">
        <h3 id="h.3dy6vkm" style="display: inline">
          <span class="c2">use the Loop Platform to engage in any misleading or deceptive
            comparisons involving the Loop Platform or other products or
            services; or</span>
        </h3>
      </li>
      <li class="c5">
        <h3 id="h.1t3h5sf" style="display: inline">
          <span class="c2">otherwise use the Loop Platform (i)&nbsp;to engage in any illegal
            activity, (ii)&nbsp;to infringe or violate any third party rights,
            or (iii)&nbsp;otherwise outside the scope expressly permitted
            hereunder and in the applicable Order Form.</span>
        </h3>
      </li>
    </ol>
    <ol class="c15 lst-kix_list_1-1" start="3">
    <h1 id="h.2et92p0" style="text-align: justify">
        <span class="c9">2.3 Customer Responsibilities</span><span class="c2">. Customer will (a) be responsible
          for all use of the Loop Platform
          under its account, (b) use commercially reasonable efforts to prevent
          unauthorized access to or use of the Loop Platform and notify Loop
          promptly of any such unauthorized access or use, and (c)&nbsp;be
          responsible for obtaining and maintaining any equipment, software and
          ancillary services needed to connect to, access or otherwise use the
          Loop Platform, including as set forth in the Documentation.
          &nbsp;Customer will be solely responsible for its failure to maintain
          such equipment, software and services, and Loop will have no liability
          for such failure. &nbsp; If Customer has purchased the Chargeback
          Manager, as identified on the applicable Order Form, the following
          additional responsibilities will apply: To provide the services of the
          Chargeback Manager, Customer hereby appoints Loop as its agent for the
          limited purpose of initiating and facilitating certain charge-backs on
          behalf of a Customer&rsquo;s brand account with the applicable 3rd
          party delivery platform (e.g., Grubhub, Doordash, or Uber Eats) in
          Customer&rsquo;s name. &nbsp;With respect to the Chargeback Manager:
          Customer hereby represents and warrants that (i) it is solely
          responsible for initiating the charge-back process; (ii) the
          information provided by Customer is accurate; and (iii) Customer has
          obtained all necessary consents and approvals to accomplish the
          foregoing.</span>
      </h1>
    </ol>
    <p class="c21 c18"><span class="c1" /></p>
    <ol class="c15 lst-kix_list_1-1" start="4">
      <h1 id="h.2et92p0" style="text-align: justify">
          <span class="c9">2.4 Customer Support</span><span class="c2">. &nbsp;Loop&rsquo;s live technical support business
            hours will
            start at 9:00 am Pacific Time and run until 5:00 pm Pacific Time on
            weekdays. Technical support can be contacted as follows:
          </span>
        </h1>
    </ol>
    <a id="t.448172d130f296bdefba9d62d728e6c2379ee024" /><a id="t.0" />
    <table class="c17">
      <tr class="c16">
        <td class="c27" colspan="1" rowspan="1">
          <p class="c3"><span class="c2">EMAIL</span></p>
        </td>
      </tr>
      <tr class="c16">
        <td class="c27" colspan="1" rowspan="1">
          <p class="c3"><span class="c2">support@loopai.xyz</span></p>
        </td>
      </tr>
    </table>
    <p class="c3 c18"><span class="c2" /></p>
    <h2 class="c7">
      <span class="c2">Live technical support will not be available on Christmas Day (December
        25) and New Year&rsquo;s Day (January 1). Limited technical support will
        be available during the hours listed above during Loop holidays. The
        current Loop holidays are set forth below:</span>
    </h2>
    <ul class="c15 lst-kix_list_3-1 start">
      <li class="c6 li-bullet-0">
        <h2 style="display: inline">
          <span class="c2">Presidents Day (third Monday of February)</span>
        </h2>
      </li>
      <li class="c6 li-bullet-0">
        <h2 style="display: inline">
          <span class="c2">Memorial Day (last Monday of May)</span>
        </h2>
      </li>
      <li class="c6 li-bullet-0">
        <h2 style="display: inline">
          <span class="c2">Independence Day (July 4)</span>
        </h2>
      </li>
      <li class="c6 li-bullet-0">
        <h2 style="display: inline">
          <span class="c2">Labor Day (first Monday of September)</span>
        </h2>
      </li>
      <li class="c6 li-bullet-0">
        <h2 style="display: inline">
          <span class="c2">Thanksgiving Day (fourth Thursday in November)</span>
        </h2>
      </li>
      <li class="c6 li-bullet-0">
        <h2 style="display: inline">
          <span class="c2">Christmas Eve (December 24)</span>
        </h2>
      </li>
      <li class="c6 li-bullet-0">
        <h2 id="h.2s8eyo1" style="display: inline">
          <span>New Year&rsquo;s Eve (December 31)</span>
        </h2>
      </li>
    </ul>
    <p class="c3 c18"><span class="c1" /></p>

    <h1 style="display: inline"><span class="c0">3. Fees</span></h1>

    <ol class="c15 lst-kix_list_1-1 start" start="1">
      <h1 style="text-align: justify">
        <span class="c9">3.1 Fees</span><span class="c2">. If Customer has purchased a subscription or has otherwise
          agreed
          to pay any fees in an Order Form, Customer will pay Loop the fees
          set forth in the applicable Order Form.</span>
      </h1>
      <h1 style="text-align: justify">
        <span class="c9">3.2 Payment</span>
        <span class="c2">. &nbsp;All fees are quoted and payable in United States dollars,
          all payment obligations are non-cancelable and, except as expressly
          set forth herein, all fees paid are non-refundable. &nbsp;If
          Customer has selected a payment plan and provided its payment
          information to Loop, then Customer (a)&nbsp;represents and warrants
          to Loop that such information is true and that Customer is
          authorized to use the payment instrument, (b)&nbsp;will promptly
          update its account information with any changes to its payment
          instrument information, and (c)&nbsp;hereby authorizes Loop
          (including through its payment processor, in which case Customer
          hereby agrees to the applicable terms and policies of such payment
          processor) to bill your payment instrument in advance in accordance
          with the terms of the applicable payment plan. &nbsp;If Customer is
          paying the fees set forth in an Order Form by invoice, then all fees
          are due within thirty (30) days of the date of the invoice. &nbsp;If
          Customer is overdue on any payment and fail to pay within ten (10)
          business days of a written notice of your overdue payment, then Loop
          may assess a late fee and/or suspend Customer&rsquo;s account until
          Customer pays the amount Customer is overdue plus the late fee. The
          late fee will be either 1.5% per month, or the maximum amount
          allowable by law, whichever is less.</span>
      </h1>
      <h1 style="text-align: justify">
        <span class="c9">3.3 Net of Taxes</span><span class="c2">. &nbsp;All applicable use, sales and other similar
          taxes and
          government charges will be payable by Customer. Customer will not
          withhold any taxes from any amounts due to Loop.</span>
      </h1>
    </ol>
    <br />
    <br />
    <h1 id="h.17dp8vu" style="display: inline">
      <span class="c0">4. Proprietary Rights and Confidentiality</span>
    </h1>
    <ol class="c15 lst-kix_list_1-1 start" start="1">
      <h1 style="text-align: justify">
        <span class="c9">4.1 Loop Ownership Rights</span><span class="c2">. &nbsp;As between the parties, Loop
          exclusively owns all right,
          title and interest in and to the Loop Platform. &nbsp;Except for the
          express rights granted hereunder, Loop reserves all rights, title
          and interests in and to the Loop Platform and Loop&rsquo;s
          Confidential Information.</span>
      </h1>
      <h1 id="h.26in1rg" style="text-align: justify">
        <span class="c9">4.2 Feedback</span>
        <span class="c2">. Customer may from time to time provide Loop suggestions or
          comments for enhancements or improvements, new features or
          functionality or other feedback (&ldquo;</span><span class="c10">Feedback</span>
        <span class="c2">&rdquo;) with respect to the Loop Platform. &nbsp;Loop will have
          full discretion to determine whether or not to proceed with the
          development of any requested enhancements, new features or
          functionality. &nbsp;Loop will have the full, unencumbered right,
          without any obligation to compensate or reimburse Customer, to use,
          incorporate and otherwise fully exercise and exploit any such
          Feedback in connection with its products and services.</span>
      </h1>
      <h1 id="h.lnxbz9" style="text-align: justify;">
        <span class="c9">4.3 Confidentiality</span><span class="c2">. &nbsp;Each party agrees that it will use the
          Confidential
          Information of the other party solely in accordance with the
          provisions of this Agreement and it will not disclose, or permit to
          be disclosed, the same directly or indirectly, to any third party
          without the other party&rsquo;s prior written consent, except as
          otherwise permitted hereunder. &nbsp;However, either party may
          disclose Confidential Information &nbsp;to its employees, officers,
          directors, attorneys, auditors, financial advisors and other
          representatives who have a need to know and are legally bound to
          keep such information confidential by confidentiality obligations
          consistent with those of this Agreement; and as required by law (in
          which case the receiving party will provide the disclosing party
          with prior written notification thereof, will provide the disclosing
          party with the opportunity to contest such disclosure, and will use
          its reasonable efforts to minimize such disclosure to the extent
          permitted by applicable law. &nbsp;Each party agrees to exercise due
          care in protecting the Confidential Information from unauthorized
          use and disclosure. &nbsp;In the event of actual or threatened
          breach of the provisions of this Section or the License
          Restrictions, the non-breaching party will be entitled to seek
          immediate injunctive and other equitable relief, without waiving any
          other rights or remedies available to it. &nbsp;Each party will
          promptly notify the other in writing if it becomes aware of any
          violations of the confidentiality obligations set forth in this
          Agreement.</span>
      </h1>
      <h1 style="text-align: justify">
        <span class="c9">4.4 Aggregated Information</span><span class="c2">. &nbsp;Notwithstanding anything to the
          contrary, Loop shall have
          the right to aggregate, collect and analyze data and other
          information relating to the provision, use and performance of the
          Loop Platform and shall be free (during and after the term hereof)
          to (i) use such data and other information to develop and improve
          the Loop Platform and other Loop offerings, and (ii) disclose such
          data and other information solely in an aggregated and anonymized
          format that does not identify Customer or any individual.</span>
      </h1>
    </ol>
    <h1 style="display: inline">
      <span class="c0">5. Warranties and Disclaimers</span>
    </h1>
    <ol class="c15 lst-kix_list_1-1 start" start="1">
      <h1 style="text-align: justify">
        <span class="c9">5.1 Customer</span><span class="c2">. &nbsp;Customer warrants that it has all rights necessary
          to
          provide any information, data or other materials that it provides
          hereunder, and to permit Loop to use the same as contemplated
          hereunder.</span>
      </h1>
      <h1 style="text-align: justify">
        <span class="c9">5.2 DISCLAIMER</span><span class="c2">. &nbsp;THE LOOP PLATFORM AND ANY BETA SERVICES (AS
          DEFINED BELOW)
          IS PROVIDED &ldquo;AS IS&rdquo; TO THE FULLEST EXTENT PERMITTED BY
          LAW. LOOP HEREBY DISCLAIMS ANY AND ALL WARRANTIES, EXPRESS OR
          IMPLIED, INCLUDING BUT NOT LIMITED TO WARRANTIES OF MERCHANTABILITY,
          TITLE, NON-INFRINGEMENT, AND FITNESS FOR A PARTICULAR PURPOSE IN
          RELATION TO THE LOOP PLATFORM AND BETA SERVICES. &nbsp;WITHOUT
          LIMITING THE FOREGOING, LOOP DOES NOT WARRANT THAT THE LOOP PLATFORM
          OR BETA SERVICES WILL BE ERROR-FREE OR THAT IT WILL MEET ANY
          SPECIFIED SERVICE LEVEL, OR WILL OPERATE WITHOUT INTERRUPTIONS OR
          DOWNTIME. TO THE EXTENT THIS DISCLAIMER CONFLICTS WITH APPLICABLE
          LAW, THE SCOPE AND DURATION OF ANY APPLICABLE WARRANTY WILL BE THE
          MINIMUM PERMITTED UNDER THAT LAW.</span>
      </h1>
      <h1 style="text-align: justify">
        <span class="c9">5.3 BETA SERVICES</span><span class="c2">. &nbsp;FROM TIME TO TIME, CUSTOMER MAY HAVE THE
          OPTION TO
          PARTICIPATE IN A PROGRAM WITH LOOP WHERE CUSTOMER GETS TO USE ALPHA
          OR BETA SERVICES, PRODUCTS, FEATURES OR DOCUMENTATION (COLLECTIVELY,
          &ldquo;BETA SERVICES&rdquo;) OFFERED BY LOOP. THE BETA SERVICES ARE
          NOT GENERALLY AVAILABLE AND ARE PROVIDED &ldquo;AS IS&rdquo;.
          &nbsp;CUSTOMER OR LOOP MAY TERMINATE CUSTOMER&rsquo;S ACCESS TO THE
          BETA SERVICES AT ANY TIME.</span>
      </h1>
    </ol>
    <h1 style="display: inline"><span class="c0">6. Indemnification</span></h1>
    <h2 class="c7" id="h.1ksv4uv">
      <span class="c2">Customer will indemnify, defend and hold harmless Loop against any
        claim made or brought against Loop by a third party arising out of or
        relating to Customer&rsquo;s use or misuse of the Loop Platform.
        &nbsp;Loop will cooperate as reasonably required in the defense of any
        such claim, at Customer&rsquo;s expense. Loop reserves the right, at
        Customer&rsquo;s expense, to retain separate counsel for itself in
        connection with any such claim or, if Customer has not responded
        reasonably to such claim, to assume the exclusive defense and control of
        such claim. Customer will also be liable to Loop for any costs and
        attorneys&rsquo; fees Loop incurs to successfully establish or enforce
        Loop&rsquo;s right to indemnification under this Section.</span>
    </h2>
    <h1 id="h.44sinio" style="display: inline">
      <span class="c0">7. Limitation of Liability</span>
    </h1>
    <p class="c7">
      <span class="c20 c8" style="line-height: 18px">UNDER NO LEGAL THEORY, WHETHER IN TORT, CONTRACT, OR OTHERWISE, WILL
        LOOP BE LIABLE TO CUSTOMER FOR (A) ANY INDIRECT, SPECIAL, INCIDENTAL,
        CONSEQUENTIAL OR PUNITIVE DAMAGES OF ANY CHARACTER, INCLUDING DAMAGES
        FOR LOSS OF GOODWILL, LOST PROFITS, LOST SALES OR BUSINESS, WORK
        STOPPAGE, COMPUTER FAILURE OR MALFUNCTION, LOST CONTENT OR DATA, OR FOR
        ANY AND ALL OTHER DAMAGES OR LOSSES, EVEN IF A REPRESENTATIVE OF LOOP
        HAS BEEN ADVISED, KNEW OR SHOULD HAVE KNOWN OF THE POSSIBILITY OF SUCH
        DAMAGES, OR (B) ANY AGGREGATE DAMAGES, COSTS OR LIABILITIES IN EXCESS OF
        THE AMOUNTS PAID BY CUSTOMER UNDER THE APPLICABLE ORDER FORM DURING THE
        TWELVE (12) MONTHS PRECEDING THE INCIDENT OR CLAIM. &nbsp;</span><span class="c8" style="line-height: 18px">THE FOREGOING PROVISIONS
        ALLOCATE THE RISKS UNDER THIS AGREEMENT
        BETWEEN THE PARTIES, AND THE PARTIES HAVE RELIED ON THE LIMITATIONS SET
        FORTH HEREIN IN DETERMINING WHETHER TO ENTER INTO THIS AGREEMENT.</span>
    </p>
    <h1 style="display: inline"><span class="c0">8. Termination</span></h1>
    <ol class="c15 lst-kix_list_1-1 start" start="1">
      <h1 style="text-align: justify">
        <span class="c9">8.1 Term</span><span class="c2">. &nbsp;The term of this Agreement will commence on the date
          these
          Terms are accepted by you and continue until your account is
          terminated as set forth below.</span>
      </h1>
      <h1 id="h.2jxsxqh" style="text-align: justify">
        <span class="c9">8.2 Termination and Suspension</span><span class="c2">. &nbsp;You may terminate your account at
          any time through the
          account management tools made available through the Loop Platform.
          &nbsp;Loop may terminate or suspend your account in the event you
          commit any material breach of any provision of this Agreement and
          fail to fix that breach within five (5) days after written notice of
          that breach. &nbsp;Loop may also terminate or suspend your account
          immediately for cause if: (a) there is reason to believe the traffic
          created from your use of the Loop Platform or your use of the Loop
          Platform is fraudulent or negatively impacting the operating
          capability of Loop Platform; or; or (b) subject to applicable law,
          upon your liquidation, commencement of dissolution proceedings,
          disposal of your assets or change of control, a failure to continue
          business, assignment for the benefit of creditors, or if you become
          the subject of bankruptcy or similar proceeding. If Loop suspends
          your account, Loop will make a reasonable attempt to notify you.
          &nbsp;Loop may also terminate your access to any component of the
          Loop Platform and/or your account (i)&nbsp;for convenience or
          (ii)&nbsp;if it determines, in its sole discretion, that providing
          the Loop Platform is prohibited by law or has become impractical or
          unfeasible for any legal or regulatory reason; provided that if you
          have subscribed to any portion of the Loop Platform, in Loop&rsquo;s
          election, either (i)&nbsp;such termination will be effective upon
          expiration of your subscription or (ii)&nbsp;Loop will provide you
          with a pro rata refund of prepaid fees based on the amount of time
          remaining in your subscription term. &nbsp;Except as expressly set
          forth above, no refund will be provided in the event of any
          suspension or termination of your account or access to any component
          of the Loop Platform.</span>
      </h1>
      <h1 style="text-align: justify">
        <span class="c9">8.3 Survival</span><span class="c2">. &nbsp;Upon expiration or termination of this Agreement,
          all
          rights and obligations will immediately terminate except that any
          terms or conditions that by their nature should survive such
          termination will survive, including the License Restrictions and
          terms and conditions relating to proprietary rights and
          confidentiality, disclaimers, indemnification, limitations of
          liability and termination and the general provisions below.</span>
      </h1>
    </ol>
    <h1 id="h.z337ya" style="display: inline">
      <span class="c0">9. General</span>
    </h1>
    <ol class="c15 lst-kix_list_1-1 start" start="1">
      <h1 style="text-align: justify">
        <span class="c9">9.1 Export Compliance</span><span class="c2">. &nbsp;Each party will comply with the export
          laws and regulations
          of the United States, European Union and other applicable
          jurisdictions in providing and using the Loop Platform.</span>
      </h1>
      <h1 style="text-align: justify">
        <span class="c9">9.2 Publicity</span><span class="c2">. &nbsp;Customer agrees that Loop may refer to
          Customer&rsquo;s
          name and trademarks in Loop&rsquo;s marketing materials, the Loop
          Platform or Loop&rsquo;s website. Loop will not use Customer&rsquo;s
          name or trademarks in any other publicity (e.g., press releases,
          customer references and case studies) without Customer&rsquo;s prior
          written consent (which may be by email). &nbsp;</span>
      </h1>
      <h1 style="text-align: justify">
        <span class="c9">9.3 Assignment; Delegation</span><span class="c2">. &nbsp;Neither party hereto may assign or
          otherwise transfer this
          Agreement, in whole or in part, without the other party&rsquo;s
          prior written consent, except that either party may assign this
          Agreement without consent to a successor to all or substantially all
          of its assets or business related to this Agreement. &nbsp;Any
          attempted assignment, delegation, or transfer by either party in
          violation hereof will be null and void. &nbsp;Subject to the
          foregoing, this Agreement will be binding on the parties and their
          successors and assigns.</span>
      </h1>
      <h1 style="text-align: justify">
        <span class="c9">9.4 Waiver</span><span class="c2">. &nbsp;No waiver of any rights hereunder will be effective
          unless
          agreed to in writing by both parties. &nbsp;Any such waiver will be
          only to the specific provisions and under the specific circumstances
          for which it was given, and will not apply with respect to any
          repeated or continued violation of the same provision or any other
          provision. &nbsp;Failure or delay by either party to enforce any
          provision of this Agreement will not be deemed a waiver of future
          enforcement of that or any other provision.</span>
      </h1>
      <h1 style="text-align: justify">
        <span class="c9">9.5 Relationship</span><span class="c2">. &nbsp;Nothing contained herein will in any way
          constitute any
          association, partnership, agency, employment or joint venture
          between the parties hereto, or be construed to evidence the
          intention of the parties to establish any such relationship. Neither
          party will have the authority to obligate or bind the other in any
          manner, and nothing herein contained will give rise or is intended
          to give rise to any rights of any kind to any third parties.</span>
      </h1>
      <h1 style="text-align: justify">
        <span class="c9">9.6 Unenforceability</span><span class="c2">. &nbsp;If a court of competent jurisdiction
          determines that any
          provision of this Agreement is invalid, illegal, or otherwise
          unenforceable, such provision will be enforced as nearly as possible
          in accordance with the stated intention of the parties, while the
          remainder of this Agreement will remain in full force and effect and
          bind the parties according to its terms.</span>
      </h1>
      <h1 style="text-align: justify">
        <span class="c9">9.7 Governing Law; Venue</span><span class="c2">. &nbsp;This Agreement will be governed by the
          laws of the State of
          California, exclusive of its rules governing choice of law and
          conflict of laws. &nbsp;This Agreement will not be governed by the
          United Nations Convention on Contracts for the International Sale of
          Goods.</span>
      </h1>
      <h1 style="text-align: justify">
        <span class="c9">9.8 Notices</span><span class="c2">. &nbsp;Any notice required or permitted to be given
          hereunder will
          be given in writing by personal delivery, certified mail, return
          receipt requested, or by overnight delivery. &nbsp;Notices to
          Customer must be sent to Customer&rsquo;s email or other address as
          set forth in Customer&rsquo;s account information. &nbsp; Notices to
          Loop must be sent to the following address: Full Meals Technology
          Inc. DBA Loop 548 Market St PMB 39587, San Francisco, CA,
          94104-5401, USA, Attn: Legal.</span>
      </h1>
      <h1 style="text-align: justify">
        <span class="c9">9.9 Entire Agreement</span><span class="c2">. &nbsp;This Agreement (including all Order Forms)
          comprises the
          entire agreement between Customer and Loop with respect to its
          subject matter, and supersedes all prior and contemporaneous
          proposals, statements, sales materials or presentations and
          agreements (oral and written). &nbsp;No oral or written information
          or advice given by Loop, its agents or employees will create a
          warranty or in any way increase the scope of the warranties in this
          Agreement.</span>
      </h1>
      <h1 style="text-align: justify">
        <span class="c9">9.10 Force Majeure</span><span class="c2">. &nbsp;Excluding payment obligations, neither Party
          will be deemed
          in breach hereunder for any cessation, interruption or delay in the
          performance of its obligations due to causes beyond its reasonable
          control, including earthquake, flood, or other natural disaster, act
          of God, labor controversy, civil disturbance, terrorism, war
          (whether or not officially declared), cyber attacks (e.g., denial of
          service attacks), or the inability to obtain sufficient supplies,
          transportation, or other essential commodity or service required in
          the conduct of its business, or any change in or the adoption of any
          law, regulation, judgment or decree.</span>
      </h1>
      <h1 style="text-align: justify">
        <span class="c9">9.11 Government Terms</span><span class="c2">. &nbsp;Loop provides the Loop Platform, including
          related software
          and technology, for ultimate federal government end use solely in
          accordance with the terms of this Agreement. &nbsp;If Customer (or
          any of its customers) is an agency, department, or other entity of
          any government, the use, duplication, reproduction, release,
          modification, disclosure, or transfer of the Loop Platform, or any
          related documentation of any kind, including technical data,
          software, and manuals, is restricted by the terms of this Agreement.
          &nbsp;All other use is prohibited and no rights than those provided
          in this Agreement are conferred. The Loop Platform were developed
          fully at private expense.</span>
      </h1>
      <h1 style="text-align: justify">
        <span class="c9">9.12 Interpretation</span><span class="c2">. &nbsp;For purposes hereof, &ldquo;include&rdquo;,
          &ldquo;includes&rdquo;, &ldquo;including&rdquo;, or any variation
          thereof shall always be construed as if followed by the words
          &ldquo;without limitation&rdquo;.</span>
      </h1>
    </ol>
  </body>
</div>

`
export default function TermsOfService() {
  return (
    <div
      style={{
        maxWidth: 1000,
        margin: 'auto',
        padding: 48,
        background: 'white',
      }}
      dangerouslySetInnerHTML={{ __html: content }}
    />
  )
}
