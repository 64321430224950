import get from 'lodash/get'
import concat from 'lodash/concat'

export default function getFlatRoutes(routes: any[]){
    const subRoutes = []
    const data = routes.map(item => {
        if (get(item, 'subNav')) {
        item.subNav.forEach(nav => {
            subRoutes.push(`/${item.key}/${nav.key}`)
        })
        }
        return `/${item.key}`
    })
    return concat(data, subRoutes)
}