import CloseIcon from '@mui/icons-material/Close'
import RestartAltIcon from '@mui/icons-material/RestartAlt'
import { Box, Button, CircularProgress, Divider, IconButton, Popover, Skeleton, Snackbar, Stack, Tooltip, Typography, useMediaQuery } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import moment from 'moment'
import React from 'react'
import { useLocation } from 'react-router-dom'
import { useNewFilterContext } from 'src/context/NewFilterContext/NewFilterContext'
import { FilterNode } from 'src/context/NewFilterContext/utils/FilterNode'
import { updateObserverVisibleNodesFromGraph } from 'src/context/NewFilterContext/utils/updateObserverFunctions'
import { automaticallyUpdateFiltersDuration } from 'src/utils/config/config'
import DateRangePickerComp from './components/DateRangePickerComp'
import SelectWrapper, { SelectWrapperRef } from './components/SelectWrapper'
import { getFilterKeys, getNewLastDate, updateDateRangeFromLastDate } from './utils/filterUtils'

export interface NewFilterComponentProps {
  hideDateRange?: boolean
  hideResetButton?: boolean
  hideFilterKeys?: string[]
  singleSelectFilterKeys?: string[]
  extraFilters?: JSX.Element[]
  onResetCallback?: () => void
  allowedFilters?: { [key: string]: (string | number)[] }
  independentFilter?: boolean
  customMaxDate?: Date | string
  showFiltersOutsideDropdown?: boolean
  fullWidthFilters?: boolean
  closeFilterDrawer?: () => void
}

export default function NewFilterComponent(props: NewFilterComponentProps) {
  const {
    hideDateRange,
    hideResetButton,
    hideFilterKeys = [],
    singleSelectFilterKeys,
    extraFilters,
    onResetCallback,
    allowedFilters,
    independentFilter,
    customMaxDate,
    showFiltersOutsideDropdown: propsShowFiltersOutsideDropdown,
    fullWidthFilters = false,
    closeFilterDrawer
  } = props
  const {
    activeGraph,
    activeGraphId,
    reRenderFilterContext,
    filterConfig,
    updateActiveGraph,
    filterDataLoading,
    isFilterChanged,
    showFilterChangedNotification,
    setShowFilterChangedNotification,
    updateFiltersWithNewData,
    autoUpdateFiltersProgress,
    businessRefreshBnameLevelData
  } = useNewFilterContext()
  const location = useLocation()
  const theme = useTheme()
  const sm = useMediaQuery(theme.breakpoints.down('sm'))

  const [popoverAnchorEl, setPopoverAnchorEl] = React.useState<Element | null>(null)

  const defaultFilterKeys = getFilterKeys()

  const visibleFilterKeys = React.useMemo(() => {
    const keys = []
    if (!hideFilterKeys.includes('b_name') && filterConfig.bNameEnabled) keys.push('b_name')
    if (!hideFilterKeys.includes('vb_name') && filterConfig.vbNameEnabled) keys.push('vb_name')
    if (!hideFilterKeys.includes('vb_platform') && filterConfig.vbPlatformEnabled) keys.push('vb_platform')
    if (!hideFilterKeys.includes('am_name') && filterConfig.amNameEnabled) keys.push('am_name')

    return keys
  }, [filterConfig, hideFilterKeys])

  // * decides if independent instance is needed for current page
  React.useEffect(() => {
    if (!filterDataLoading) {
      const newGraphId = location.pathname.slice(1)
      if (singleSelectFilterKeys || allowedFilters || independentFilter) {
        updateActiveGraph(newGraphId, singleSelectFilterKeys, allowedFilters)
      }
    }

    return () => {
      updateActiveGraph('main')
    }
  }, [singleSelectFilterKeys, allowedFilters, independentFilter, filterDataLoading])

  // **************************** observer pattern and ref code *****************************
  const filterWrapperRefs = React.useRef({})

  React.useEffect(() => {
    subscribeRefs()

    return () => {
      defaultFilterKeys.forEach((subjectKey) => {
        defaultFilterKeys.forEach((observerKey) => {
          if (subjectKey === observerKey) return
          if (subjectKey in filterWrapperRefs.current) filterWrapperRefs.current[subjectKey].unsubscribe(observerKey)
        })
      })
    }
  }, [activeGraph])

  // ? wrapper components are mounting and unmounting frequently, so their refs are getting lost when unmounting
  // ? this function is called every time a wrapper component mounts, so that its ref is set and subscribed again
  const subscribeRefs = () => {
    defaultFilterKeys.forEach((subjectKey) => {
      defaultFilterKeys.forEach((observerKey) => {
        if (subjectKey === observerKey) return
        if (subjectKey in filterWrapperRefs.current) filterWrapperRefs.current[subjectKey].subscribe(observerKey, updateObserverVisibleNodes)
      })
      if (subjectKey in filterWrapperRefs.current) filterWrapperRefs.current[subjectKey].subscribe('date_range', updateObserverVisibleNodes)
    })
  }

  const handleRefAssignment = (param: SelectWrapperRef, key: string) => {
    if (param) filterWrapperRefs.current[key] = param
    else delete filterWrapperRefs.current[key]
    subscribeRefs()
  }

  const updateObserverVisibleNodes = (observerKey: string, subjectSelectedNodes: FilterNode[]) => {
    if (observerKey === 'date_range') {
      const newLastDate = getNewLastDate(businessRefreshBnameLevelData, activeGraph)
      activeGraph.setLastDate(newLastDate)
      updateDateRangeFromLastDate(activeGraph, newLastDate)
      return
    }
    updateObserverVisibleNodesFromGraph(observerKey, subjectSelectedNodes, activeGraph)
  }

  // ************************ user action handlers *********************************
  const handleReset = () => {
    activeGraph.reset()
    const newLastDate = getNewLastDate(businessRefreshBnameLevelData, activeGraph)
    activeGraph.setLastDate(newLastDate)
    updateDateRangeFromLastDate(activeGraph, newLastDate)
    if (onResetCallback) onResetCallback()
    reRenderFilterContext()
  }

  // ************** should filter be shown by default or in dropdown ******************
  const visibleSelectedFilters: { [key: string]: Set<FilterNode> } = React.useMemo(() => {
    const tempObj = {}
    visibleFilterKeys.forEach((key) => {
      tempObj[key] = activeGraph.getVisibleSelectedIntersectionNodes(key)
    })
    return tempObj
  }, [visibleFilterKeys, activeGraph])

  const showFilterInDropdown = (key) => {
    return visibleSelectedFilters[key].size === 0
  }

  // *************************** popover handlers ***************************
  const handlePopoverOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setPopoverAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setPopoverAnchorEl(null)
  }

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={showFilterChangedNotification}
        onClose={() => setShowFilterChangedNotification(false)}
        message={`Your filter data has been updated by your Organization. Changes will be applied in ${
          automaticallyUpdateFiltersDuration / (60 * 1000)
        } minutes.`}
        sx={{
          zIndex: theme.zIndex.drawer + 1,
          minWidth: '400px',
          '& .MuiSnackbarContent-root': {
            backgroundColor: '#fff',
            color: '#000',
            width: '100%',
            display: 'flex',
            border: '1px solid #E6E6E6',
            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)'
          },
          '& .MuiSnackbarContent-message': {
            flex: {
              xs: 'auto',
              sm: 1
            }
          },
          '& .MuiSnackbarContent-action': {
            ml: {
              sm: 0,
              xs: 'auto'
            }
          }
        }}
        action={
          <>
            <Button
              variant="text"
              disableElevation
              onClick={() => {
                updateFiltersWithNewData(true)
              }}
              sx={{
                padding: '7px 8px',
                fontSize: '12px',
                '&:hover': {
                  bgcolor: '#F3F3F3'
                }
              }}>
              <Typography
                fontSize="12px"
                fontWeight={600}
                sx={{
                  color: 'inherit'
                }}>
                Apply
              </Typography>
            </Button>
            <IconButton
              onClick={() => setShowFilterChangedNotification(false)}
              sx={{ width: 30, height: 30 }}>
              <CloseIcon />
            </IconButton>
          </>
        }
      />
      {!sm ? (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            flexWrap: 'wrap',
            gap: '6px'
            // pt: '8px',
            // pb: '8px'
            // borderBottom: '1px solid #E6E6E6'
          }}>
          {filterDataLoading ? (
            <>
              <Skeleton
                width="150px"
                height="28px"
              />
              <Skeleton
                width="100px"
                height="28px"
              />
              <Skeleton
                width="100px"
                height="28px"
              />
              <Skeleton
                width="80px"
                height="28px"
              />
            </>
          ) : (
            <>
              {extraFilters && extraFilters.map((component, index) => <React.Fragment key={index}>{component}</React.Fragment>)}
              {visibleFilterKeys.map((key) => {
                if (key !== 'b_name_id' && (propsShowFiltersOutsideDropdown || !showFilterInDropdown(key))) {
                  return (
                    <SelectWrapper
                      key={key + activeGraphId}
                      filterKey={key}
                      renderValue={() => {
                        return 'this is bName'
                      }}
                      ref={(param) => {
                        handleRefAssignment(param, key)
                      }}
                    />
                  )
                } else return <React.Fragment key={key} />
              })}
              {!propsShowFiltersOutsideDropdown && (
                <Button
                  variant="contained"
                  disableElevation
                  onClick={handlePopoverOpen}
                  sx={{
                    bgcolor: '#E8F1F3',
                    color: '#0F465A',
                    padding: '6px 8px',
                    '&:hover': {
                      color: '#FFF'
                    }
                  }}>
                  <Typography
                    fontSize="12px"
                    sx={{
                      color: 'inherit'
                    }}>
                    + Filter
                  </Typography>
                </Button>
              )}

              <Divider
                orientation="vertical"
                flexItem
              />

              {!hideDateRange && <DateRangePickerComp maxDate={customMaxDate ? moment(customMaxDate) : activeGraph.getLastDate()} />}

              {!hideResetButton && (
                <Button
                  variant="contained"
                  disableElevation
                  endIcon={<RestartAltIcon sx={{ width: '12px', height: '12px' }} />}
                  onClick={handleReset}
                  sx={{
                    bgcolor: '#FFEFEF',
                    color: '#E52E2E',
                    padding: '12px 8px',
                    fontSize: '12px',
                    '& .MuiButton-endIcon': { marginRight: 0 },
                    '&:hover': {
                      bgcolor: '#E52E2E',
                      color: '#FFF'
                    }
                  }}>
                  <Typography
                    fontSize="12px"
                    sx={{
                      color: 'inherit',
                      lineHeight: 1
                    }}>
                    Reset
                  </Typography>
                </Button>
              )}

              {isFilterChanged && !showFilterChangedNotification && (
                <Tooltip
                  title={
                    <Typography
                      fontSize="12px"
                      sx={{
                        textAlign: 'center',
                        color: '#FFFFFF'
                      }}>
                      Filters will be updated in {Math.floor((automaticallyUpdateFiltersDuration * autoUpdateFiltersProgress) / (100 * 60 * 1000))} minutes.
                      Click to apply immediately.
                    </Typography>
                  }>
                  <Button
                    variant="contained"
                    disableElevation
                    onClick={() => {
                      updateFiltersWithNewData(true)
                    }}
                    sx={{
                      bgcolor: '#FFFFFF',
                      color: '#E52E2E',
                      padding: '10px 8px',
                      fontSize: '12px',
                      minWidth: '32px',
                      '&:hover': {
                        bgcolor: '#FFF7EA'
                      }
                    }}>
                    <CircularProgress
                      variant="determinate"
                      value={autoUpdateFiltersProgress}
                      size={12}
                      thickness={20}
                      sx={{
                        color: '#FFBC57'
                      }}
                    />
                  </Button>
                </Tooltip>
              )}
            </>
          )}
        </Box>
      ) : (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: '100%',
            gap: 1
          }}>
          {filterDataLoading ? (
            <>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 1
                }}>
                <Skeleton
                  variant="rectangular"
                  width="100%"
                  height="45px"
                  sx={{ borderRadius: '4px' }}
                />
                <Skeleton
                  variant="rectangular"
                  width="100%"
                  height="45px"
                  sx={{ borderRadius: '4px' }}
                />
                <Skeleton
                  variant="rectangular"
                  width="100%"
                  height="45px"
                  sx={{ borderRadius: '4px' }}
                />
                <Skeleton
                  variant="rectangular"
                  width="100%"
                  height="45px"
                  sx={{ borderRadius: '4px' }}
                />
              </Box>

              <Box>
                <Skeleton
                  variant="rectangular"
                  width="100%"
                  height="45px"
                  sx={{ borderRadius: '4px', bgcolor: '#FFEFEF' }}
                />
              </Box>
            </>
          ) : (
            <>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 1
                }}>
                {extraFilters && extraFilters.map((component, index) => <React.Fragment key={index}>{component}</React.Fragment>)}
                {visibleFilterKeys.map((key) => {
                  if (key !== 'b_name_id' && (propsShowFiltersOutsideDropdown || !showFilterInDropdown(key))) {
                    return (
                      <SelectWrapper
                        key={key + activeGraphId}
                        filterKey={key}
                        renderValue={() => {
                          return 'this is bName'
                        }}
                        fullWidthFilters={fullWidthFilters}
                        ref={(param) => {
                          handleRefAssignment(param, key)
                        }}
                      />
                    )
                  } else return <React.Fragment key={key} />
                })}

                {!hideDateRange && (
                  <DateRangePickerComp
                    maxDate={customMaxDate ? moment(customMaxDate) : activeGraph.getLastDate()}
                    fullWidthFilters={fullWidthFilters}
                    ref={(param) => {
                      handleRefAssignment(param, 'date_range')
                    }}
                  />
                )}

                {!propsShowFiltersOutsideDropdown && (
                  <Button
                    variant="contained"
                    disableElevation
                    onClick={handlePopoverOpen}
                    sx={{
                      bgcolor: '#E8F1F3',
                      color: '#0F465A',
                      padding: '7px 8px',
                      '&:hover': {
                        color: '#FFF'
                      },
                      width: fullWidthFilters ? '100%' : 'auto',
                      py: fullWidthFilters ? 1.5 : 1
                    }}>
                    <Typography
                      fontSize="12px"
                      sx={{
                        color: 'inherit'
                      }}>
                      + Filter
                    </Typography>
                  </Button>
                )}
              </Box>

              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 1
                }}>
                {isFilterChanged && !showFilterChangedNotification && (
                  <Button
                    variant="contained"
                    disableElevation
                    onClick={() => {
                      updateFiltersWithNewData(true)
                    }}
                    sx={{
                      bgcolor: '#FFFFFF',
                      color: '#E52E2E',
                      padding: '10px 8px',
                      fontSize: '12px',
                      minWidth: '32px',
                      flexDirection: 'column',
                      gap: 0.5,
                      '&:hover': {
                        bgcolor: '#FFF7EA'
                      }
                    }}>
                    <Typography
                      fontSize="12px"
                      sx={{
                        textAlign: 'center',
                        color: '#000'
                      }}>
                      Filters will be updated in {Math.floor((automaticallyUpdateFiltersDuration * autoUpdateFiltersProgress) / (100 * 60 * 1000))} minutes.
                      Click to apply immediately.
                    </Typography>

                    <CircularProgress
                      variant="determinate"
                      value={autoUpdateFiltersProgress}
                      size={12}
                      thickness={20}
                      sx={{
                        color: '#FFBC57'
                      }}
                    />
                  </Button>
                )}

                {!hideResetButton && (
                  <Button
                    variant="contained"
                    disableElevation
                    endIcon={<RestartAltIcon sx={{ width: '12px', height: '12px' }} />}
                    onClick={handleReset}
                    sx={{
                      bgcolor: '#FFEFEF',
                      color: '#E52E2E',
                      padding: '7px 8px',
                      fontSize: '12px',
                      '& .MuiButton-endIcon': { marginRight: 0 },
                      '&:hover': {
                        bgcolor: '#E52E2E',
                        color: '#FFF'
                      },
                      width: fullWidthFilters ? '100%' : 'auto',
                      py: fullWidthFilters ? 1.5 : 1
                    }}>
                    <Typography
                      fontSize="12px"
                      sx={{
                        color: 'inherit'
                      }}>
                      Reset
                    </Typography>
                  </Button>
                )}

                <Button
                  variant="contained"
                  disableElevation
                  endIcon={<CloseIcon sx={{ width: '12px', height: '12px' }} />}
                  onClick={closeFilterDrawer}
                  sx={{
                    bgcolor: '#F6F6F6',
                    color: '#000',
                    padding: '7px 8px',
                    fontSize: '12px',
                    '& .MuiButton-endIcon': { marginRight: 0 },
                    '&:hover': {
                      bgcolor: '#EEEEEE',
                      color: '#000'
                    },
                    width: fullWidthFilters ? '100%' : 'auto',
                    py: fullWidthFilters ? 1.5 : 1
                  }}>
                  <Typography
                    fontSize="12px"
                    sx={{
                      color: 'inherit'
                    }}>
                    Close
                  </Typography>
                </Button>
              </Box>
            </>
          )}
        </Box>
      )}

      <Popover
        id={`filtersPopover`}
        open={Boolean(popoverAnchorEl)}
        anchorEl={popoverAnchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        sx={{
          '& .MuiPopover-paper': {
            border: '1px solid #E6E6E6',
            borderRadius: '4px'
          }
        }}>
        <Stack
          direction="column"
          gap={0}
          alignItems="stretch">
          {visibleFilterKeys.map((key) => {
            if (key !== 'b_name_id' && showFilterInDropdown(key)) {
              return (
                <SelectWrapper
                  key={key + activeGraphId}
                  filterKey={key}
                  renderValue={() => {
                    return 'this is bName'
                  }}
                  ref={(param) => {
                    handleRefAssignment(param, key)
                  }}
                />
              )
            } else return <React.Fragment key={key} />
          })}
        </Stack>
      </Popover>
    </>
  )
}
