import { isEqual } from 'lodash'
import filterGraphManager from './FilterGraphManager'
import { ApiFilterResponseType, UserBNameNoAuthType } from './types'

export const getFilterDataForUser = (filterDataForOrg: ApiFilterResponseType[], userAssignedBnames: UserBNameNoAuthType['items'], showAllFilters: boolean) => {
  const allFiltersDataUserLevel = !showAllFilters
    ? filterDataForOrg.filter((e) => (userAssignedBnames || []).some((userData) => userData.name === e.b_name))
    : filterDataForOrg

  return allFiltersDataUserLevel
}

export const getFilterDataSlugs = (filterDataForUser: ApiFilterResponseType[]): Set<string> => {
  const filterDataSlugs = filterDataForUser.map((e) => e.slug)
  return new Set(filterDataSlugs)
}

export const hasFilterDataChanged = (newFilterDataForUser: ApiFilterResponseType[], oldFilterDataSlugs: Set<string>): boolean => {
  const newFilterDataSlugs = getFilterDataSlugs(newFilterDataForUser)
  return !isEqual(newFilterDataSlugs, oldFilterDataSlugs)
}

export const checkedIfAllOptionsSelected = (activeGraphId: string, key: string) => {
  const activeGraph = filterGraphManager.getGraph(activeGraphId)
  const mainGraph = filterGraphManager.getGraph('main')
  let allOptionsSelected = true

  if (activeGraph && mainGraph) {
    if (activeGraph.getAllPossibleNodesByKey(key).length !== activeGraph.getVisibleSelectedIntersectionNodes(key).size) allOptionsSelected = false
    if (activeGraphId !== 'main') {
      if (activeGraph.getAllPossibleNodesByKey(key).length !== mainGraph.getAllPossibleNodesByKey(key).length) allOptionsSelected = false
    }
  }
  return allOptionsSelected
}
