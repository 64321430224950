import { Box, CircularProgress, SxProps } from '@mui/material'

interface CircularProgressProps {
  sx?: SxProps
}

export default function CircularLoader(props: CircularProgressProps) {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        p: 3,
        ...props.sx
      }}
    >
      <CircularProgress />
    </Box>
  )
}
