import * as Sentry from '@sentry/browser'
import axios from 'axios'
import { onAuthStateChanged, signInWithCustomToken, signOut } from 'firebase/auth'
import { collection, deleteField, doc, getDocs, onSnapshot, setDoc, updateDoc } from 'firebase/firestore'
import localforage from 'localforage'
import get from 'lodash/get'
import moment from 'moment'
import posthog from 'posthog-js'
import React, { createContext, useContext, useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { useErrorData } from 'src/context/ErrorContext'
import firebaseConfig from 'src/services/firebaseConfig'
import { default as db, default as firebaseDb } from 'src/services/firebaseDb'
import { OpenAPI as FrontendDataServiceBaseAPI } from 'src/services/openApi'
import { validateUserToken } from 'src/utils/firebaseFunctions/validateUserToken'
import { getAccessLevel } from 'src/utils/functions/accessLevel'
import { getLocal, setLocal } from 'src/utils/functions/localStorage'
import { shouldBlockForUser } from 'src/utils/functions/sandbox'
import useValidateLocalData from 'src/utils/hooks/useValidateLocalData'
import { auth, clearList, fetchGoogleStudioLinks, fetchOrgConfig, fetchSupersetLinks, fetchUserOrgAndRole } from '../services/firebase'

import filterGraphManager from './NewFilterContext/utils/FilterGraphManager'
import { useSnackData } from './SnackContext'

import {
  AccessLevelEnum,
  AccessLevelType,
  AuthContextInterface,
  PageDetails,
  RefreshAccessData,
  RouteType,
  SupersetLinkType,
  User,
  UserType
} from './AuthContext.type'



const initialState = {} as AuthContextInterface

export const AuthContext = createContext<AuthContextInterface>(initialState)

export const useAuth = () => useContext(AuthContext)

const AuthContextProvider = ({ children }: { children: React.ReactNode }) => {

  const location = useLocation()
  const navigate = useNavigate()
  const [params, setParams] = useSearchParams()
  const { openInfo, openError } = useSnackData()
  const [currentUser, setCurrentUser] = useState<User>()
  const [loading, setLoading] = useState(true)
  const { handleError, asyncWrapper } = useErrorData()
  const [orgConfig, setOrgConfig] = useState({})
  const [accessLevels, setAccessLevels] = useState<AccessLevelType[]>([])
  const [userList, setUserList] = useState<UserType[]>([])

  const [defaultRoute, setDefaultRoute] = useState('dashboard')
  const { refreshNeeded, localData, setLocalData } = useValidateLocalData()
  const [googleStudioLinks, setGoogleStudioLinks] = useState([])
  const [supersetLinks, setSupersetLinks] = useState<SupersetLinkType[]>([])
  const [pageDetails, setPageDetails] = useState<PageDetails>({
    path: location.pathname,
    visitTime: new Date().getTime()
  })
  const [standaloneRoutesLoaded, setStandaloneRoutesLoaded] = useState(false)
  const [magicLinkLoading, setMagicLinkLoading] = useState(false)
  const [globalRoutes, setGlobalRoutes] = useState<RouteType[]>([])
  const isDemo = get(currentUser, 'access-level', []).includes(AccessLevelEnum.DEMO) || false

  async function getNewAccessToken(refreshToken: string) {
    try {
      const { data }: { data: RefreshAccessData } = await axios.post(`https://securetoken.googleapis.com/v1/token?key=${firebaseConfig.apiKey}`, {
        grant_type: 'refresh_token',
        refresh_token: refreshToken
      })
      return data
    } catch (error) {
      console.log(error.message)
      if (handleError) handleError(error.message)
    }
  }

  async function getValidAccessToken(user: User) {
    const { accessToken, expirationTime, refreshToken } = get(user, 'stsTokenManager')
    if (new Date(expirationTime) < new Date()) {
      // this condition will run when access token is expired
      // use refresh token to get another accesstoken
      const { access_token } = await getNewAccessToken(refreshToken)
      return {
        accessToken: access_token,
        expirationTime: new Date().setHours(new Date().getHours() + 1)
      } //new access token
    }
    return { accessToken, expirationTime } // old access token
  }

  async function changeStatus(id: string) {
    const userRef = doc(firebaseDb, 'users', id)
    await updateDoc(userRef, { status: deleteField() })
  }
  const getApiData = async (currentUser, data) => {
    try {
      setLoading(true)
      setCurrentUser({
        ...currentUser,
        ...data
      })
      changeStatus(currentUser.uid)
      let result: any = await Promise.allSettled([
        fetchGoogleStudioLinks(),
        fetchOrgConfig(data.org, currentUser['access-level']),
        fetchOrgConfig('default', currentUser['access-level']),
        fetchSupersetLinks()
      ]).catch((error) => {
        console.log(error.message)
        if (handleError) handleError(error.message)
      })
      result = result.map((item) => item.value)
      setGoogleStudioLinks(result[0])
      setSupersetLinks(result[3])
      if (result[1]) {
        const access_levels = get(result, '[1].access_levels', {})
        const accessLevel = Array.isArray(data['access-level']) ? data['access-level'][0] : data['access-level']
        const defaultRouteForRole = get(access_levels, `${accessLevel}.defaultRoute.[0]`, get(access_levels, `${accessLevel}.routes.[0]`, 'dashboard'))
        if (defaultRouteForRole) setDefaultRoute(defaultRouteForRole)
        let navConfig = get(result, `[1].access_levels.[${accessLevel}].routes`, [])
        const logo = get(result, `[1].logo`, undefined)
        let filterConfig = result[1]?.filterConfig
        if (!navConfig.length) {
          navConfig = result[2]?.navConfig
        }
        if (!filterConfig) {
          filterConfig = result[2]?.filterConfig
        }
        const version = get(result, '[1].version', 0)
        const wizardConfig = get(result, '[1].wizardConfig', {})
        const uid = get(result, '[1].uid', '')
        const store_availability_config = get(result, '[1].store_availability_config', '')
        const orgConfigData = {
          navConfig,
          filterConfig,
          version,
          access_levels,
          wizardConfig,
          uid,
          logo,
          store_availability_config
        }
        setOrgConfig((prev: any) => ({ ...prev, ...orgConfigData }))
        setLocal('org_config', orgConfigData)
        localStorage.setItem('storageDate', new Date().toDateString())

        //set data to localstorage
        setLocal('data', data)
        setLocal('userOrg', data.org)
        setLocal('studio_links', result[0])
      } else {
        params.append('error', 'Sorry, the organization name you entered is incorrect. Please check spelling and capitalization and try again.')
        setParams(params)
        logout()
      }
    } catch (error) {
      console.log(error.message)
      if (handleError) handleError(error.message)
    }
    setLoading(false)
  }

  const hasMagicLink = (obj?: { user?: typeof currentUser; skipExpireDateCheck?: boolean }) => {
    try {
      const { user, skipExpireDateCheck } = obj || {}
      const data = user || currentUser
      const magicLinkExpired = () => {
        const expirationTime = moment(get(data, 'magic_link.expires_at', 0)).toDate()
        const now = new Date()
        return now.getTime() > expirationTime.getTime()
      }
      const result = 'magic_link' in data && (skipExpireDateCheck ? true : !magicLinkExpired())
      return result
    } catch (err) {
      return false
    }
  }

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      try {
        const condition = Boolean(auth.currentUser !== null && currentUser !== null)

        if (condition) {
          // checking if email is present in the url
          const regex = new RegExp('[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+.[A-Za-z]{2,}')
          if (regex.test(window.location.pathname)) {
            navigate('/')
          }
          Sentry.setUser({ email: currentUser.email })
          const data = await fetchUserOrgAndRole(currentUser.uid)
          if (getAccessLevel({ ...currentUser, ...data }) === AccessLevelEnum.OBSERVER) {
            setLoading(false)
            logout()
            return openError('Login access denied')
          }
          if (data) {
            if (currentUser && !currentUser.emailVerified) {
              setLoading(false)
              logout()
              return openError('Please verify your email before login')
            }
            setLoading(false)
            if (data.stage === 'waiting' && !hasMagicLink({ user: data })) {
              const waitingLink = '/create-account/wait'
              setCurrentUser({
                ...currentUser,
                ...data
              })
              changeStatus(currentUser.uid)
              if (location.pathname !== waitingLink) {
                navigate(waitingLink)
              }
              setTimeout(() => {
                analyticsReset()
              }, 2000)
              return
            }

            if (data.stage === 'verified' && !hasMagicLink({ user: data })) {
              const waitingLink = '/org/create'
              setCurrentUser({
                ...currentUser,
                ...data
              })
              changeStatus(currentUser.uid)
              if (location.pathname !== waitingLink) navigate(waitingLink)
              setTimeout(() => {
                analyticsReset()
              }, 2000)
              setLoading(false)
              return
            } else if (currentUser && get(data, 'stage') === 'org_created' && !hasMagicLink({ user: data })) {
              const waitingLink = '/org/invite'
              setCurrentUser({
                ...currentUser,
                ...data
              })
              changeStatus(currentUser.uid)
              if (location.pathname !== waitingLink) {
                navigate(waitingLink)
              }
              setTimeout(() => {
                analyticsReset()
              }, 2000)
              setLoading(false)
              return
            } else {
              //FETCH DATA FROM API IF EVERYTHING IS OK AFTER RELOAD
              await getApiData(currentUser, data)
              if (location.pathname === '/create-account/wait') navigate('/')
              setLoading(false)
            }
          } else {
            //create new user entry
            if (currentUser) {
              const newUserObj = {
                name: currentUser.displayName,
                email: currentUser?.email,
                org: null,
                phoneNumber: currentUser.phoneNumber,
                uid: currentUser.uid,
                access_names: [],
                'access-level': [AccessLevelEnum.BUSINESS_ADMIN],
                stage: 'waiting'
              }
              setCurrentUser({
                ...newUserObj,
                stage: 'waiting'
              })
              navigate('/create-account/wait')
              setTimeout(() => {
                analyticsReset()
              }, 2000)
              await setDoc(doc(firebaseDb, 'users', currentUser.uid), newUserObj)
              changeStatus(currentUser.uid)
              setLoading(false)
            }
          }
        } else {
          clearList()
          setCurrentUser(undefined)
          setLoading(false)
          logout()
        }
      } catch (error) {
        setLoading(false)
        if (handleError) {
          if (error.message.includes("Cannot read properties of null (reading 'org')")) {
            handleError('You are not registered with us')
            logout()
          } else {
            handleError(error.message)
          }
        } else {
          console.log(error.message)
        }
      }
    })
    return () => unsubscribe()
  }, [refreshNeeded, localData])

  React.useEffect(() => {
    let unsubscribeSpecificOrg: any
    let unsubscribeAccessLevels: any
    let unsubscribeUserList: any
    if (currentUser && currentUser.org) {
      const accessLevel = getAccessLevel(currentUser)
      if (accessLevel === AccessLevelEnum.OBSERVER) {
        logout()
        openError('Login access denied')
      }
      try {
        const q = doc(firebaseDb, `org_config_2/${currentUser.org}`)
        unsubscribeSpecificOrg = onSnapshot(q, (snapShot) => {
          const tempOrgConfig = getLocal('org_config')
          if (tempOrgConfig) {
            const version1 = get(tempOrgConfig, 'version', 0)
            const data = snapShot.data()
            const version2 = get(data, 'version', 0)

            setOrgConfig((prev: any) => ({ ...prev, ...data }))

            setLocal('org_config', data)
            if (version2.toString() !== version1.toString()) {
              openInfo('Your access level is updated by your organization')
            }
          }
        })
      } catch (error) {
        console.log(error.message)
      }

      try {
        const q = collection(firebaseDb, 'access_levels')
        unsubscribeAccessLevels = onSnapshot(q, (querySnapshot) => {
          const documents = querySnapshot.docs.map((doc) => {
            const data = doc.data()
            return { ...data, uid: doc.id, label: data.name, value: doc.id }
          })
          // @ts-ignore
          setAccessLevels(documents)
        })
      } catch (err) {
        console.log('access level error: ', err)
      }

      try {
        if (shouldBlockForUser(currentUser)) {
          setUserList([])
        } else {
          const q = collection(firebaseDb, 'users')
          unsubscribeUserList = onSnapshot(q, (querySnapshot) => {
            // @ts-ignore
            const documents: UserType[] = querySnapshot.docs.map((doc) => {
              const data = doc.data()
              return { ...data, access_level: data['access-level'], uid: doc.id }
            })
            const result = documents.filter((item) => {
              return item.org === currentUser.org
            })
            setUserList(result)
          })
        }
      } catch (err) {
        console.log('user list error: ', err)
      }
    }
    return () => {
      if (unsubscribeSpecificOrg) {
        unsubscribeSpecificOrg()
      }
      if (unsubscribeAccessLevels) {
        unsubscribeAccessLevels()
      }
      if (unsubscribeUserList) {
        unsubscribeUserList()
      }
    }
  }, [currentUser])

  React.useEffect(() => {
    let tokenInterval: NodeJS.Timer
    const getToken = async () => {
      if (auth.currentUser) {
        let token: string
        try {
          token = await auth.currentUser.getIdToken()
          FrontendDataServiceBaseAPI.TOKEN = token
        } catch (err) {
          console.warn(err)
        }
        tokenInterval = setInterval(
          async () => {
            if (auth.currentUser && auth.currentUser.refreshToken) {
              try {
                let res = await getNewAccessToken(auth.currentUser.refreshToken)
                token = get(res, 'access_token', undefined)
                if (token) {
                  FrontendDataServiceBaseAPI.TOKEN = token
                }
              } catch (err) {
                console.warn(err)
              }
            }
          },
          1000 * 60 * 9
        )
      }
    }
    getToken()
    return () => {
      clearInterval(tokenInterval)
    }
  }, [currentUser])

  React.useEffect(() => {
    FrontendDataServiceBaseAPI.HEADERS = {
      pageUrl: location.pathname
    }
  }, [location.pathname])

  function analyticsReset() {
    posthog.reset()
    posthog.unregister('email')
  }

  function posthogCapture(label, data = {}) {
    try {
      posthog.capture(label, {
        email: currentUser?.email,
        name: currentUser?.name,
        org: currentUser?.org,
        time: new Date().toISOString(),
        ...data
      })
    } catch (error) {
      handleError(error.message)
    }
  }

  React.useEffect(() => {
    const signInIfMagicLink = async () => {
      if (currentUser || loading) return
      try {
        const token = params.get('magic_token')?.replaceAll(/\s/g, '+')
        // remove magic_token from url
        if (!token) {
          return
        } else {
          setMagicLinkLoading(true)
        }
        const res = await validateUserToken({ tokenList: [token], getAuthToken: true })
        if (get(res, '[0].expired', true)) {
          setMagicLinkLoading(false)
          navigate('/login/expired')
        } else if (get(res, '[0].data.authToken', null)) {
          setParams((prev) => {
            prev.delete('magic_token')
            return prev
          })
          await signInWithCustomToken(auth, get(res, '[0].data.authToken', null))
        }
      } catch (err) {
        console.log('magic link error', err)
      }
    }
    signInIfMagicLink()
  }, [params, currentUser, loading])

  useEffect(() => {
    const getGlobalRoutes = async () => {
      try {
        const result = await getDocs(collection(db, 'routes'))
        const tempRoutes = result.docs.map((doc) => {
          return { ...doc.data(), uid: doc.id } as RouteType
        })
        setGlobalRoutes(tempRoutes)
      } catch (err) {
        setGlobalRoutes([])
      }
    }
    getGlobalRoutes()
  }, [])

  const logout = () => {
    asyncWrapper(
      (async () => {
        posthogCapture('User logged out')
        setCurrentUser(undefined)
        await signOut(auth)
        localStorage.clear()
        sessionStorage.clear()
        sessionStorage.clear()
        localforage
          .clear()
          .then(() => {})
          .catch((err) => {
            console.log('Failed to clear localforage: ', err.message)
          })
        filterGraphManager.clearGraphs()
        setLocalData(false)
        analyticsReset()
      })()
    )
  }

  const contextValue = useMemo(
    () => ({
      currentUser,
      loading,
      logout,
      orgConfig,
      accessLevels,
      userList,
      googleStudioLinks,
      supersetLinks,
      pageDetails,
      setPageDetails,
      defaultRoute,
      setDefaultRoute,
      getValidAccessToken,
      setCurrentUser,
      isDemo,
      posthogCapture,
      getApiData,
      hasMagicLink,
      magicLinkLoading,
      setMagicLinkLoading,
      globalRoutes,
      standaloneRoutesLoaded,
      setStandaloneRoutesLoaded
    }),
    [
      currentUser,
      loading,
      logout,
      orgConfig,
      accessLevels,
      userList,
      googleStudioLinks,
      supersetLinks,
      pageDetails,
      setPageDetails,
      defaultRoute,
      setDefaultRoute,
      getValidAccessToken,
      setCurrentUser,
      isDemo,
      posthogCapture,
      getApiData,
      magicLinkLoading,
      globalRoutes,
      standaloneRoutesLoaded,
      setStandaloneRoutesLoaded
    ]
  )

  return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
}

export default AuthContextProvider
