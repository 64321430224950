import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import Box from '@mui/material/Box'
import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'
import { useEffect, useState } from 'react'
import { useAuth } from 'src/context/AuthContext'
import { useErrorData } from 'src/context/ErrorContext'
import { isSlackIntegrated } from 'src/services/firebase'
import { sendSlackNotification } from 'src/utils/api'
import { shouldBlockForUser } from 'src/utils/functions/sandbox'
import slackLogo from '../../assets/icons/slack.png'

const slackOAuthURL =
  'https://slack.com/oauth/v2/authorize?client_id=3674891125905.4553087581846&scope=incoming-webhook,chat:write,chat:write.customize,chat:write.public,links.embed:write&user_scope=chat:write,users:read,users:write'

const boxStyle = {
  // marginTop: 2,
  display: 'flex',
  alignItems: 'center',
  gap: 1,
  paddingX: 2,
  paddingY: 1,
  background: '#F2F2F2',
  borderRadius: '4px',
  border: '2px solid #E6E6E6',
  whiteSpace: 'nowrap',
  ':hover': { border: '2px solid #777', cursor: 'pointer' }
}

export default function SlackIntegrationButton() {
  const { currentUser, posthogCapture } = useAuth()
  const { handleError } = useErrorData()
  const [isIntegrated, setIsIntegrated] = useState<boolean | null>(null)

  useEffect(() => {
    async function checkIntegration() {
      setIsIntegrated(await isSlackIntegrated(currentUser.org))
    }

    checkIntegration()
  }, [currentUser.org])

  return isIntegrated != null ? (
    isIntegrated ? (
      <Box sx={{ ...boxStyle, ':hover': null }}>
        <CheckCircleIcon color="success" />
        <Typography variant="body2">Slack integrated</Typography>
      </Box>
    ) : (
      <Link
        href={shouldBlockForUser(currentUser) ? null : slackOAuthURL}
        underline="none"
        target="_blank"
        rel="noreferrer"
        sx={boxStyle}
        onClick={() => {
          if (shouldBlockForUser(currentUser)) {
            handleError('This action is prohibited in demo mode.')
          }
          posthogCapture('slack_integration_button_clicked', {
            email: currentUser?.email || 'unknown'
          })
          sendSlackNotification({
            title: 'Slack Integration Button Clicked',
            message: `Slack Integration Button Clicked`,
            channel: 'fe-logs'
          })
        }}>
        <img
          alt="Add to Slack"
          height="20"
          width="20"
          src={slackLogo}
        />
        <Typography variant="body2">Add slack notification</Typography>
      </Link>
    )
  ) : null
}
