import { Box, Skeleton, Stack, SxProps, Tooltip, Typography } from '@mui/material'
import React from 'react'
import DeltaChip, { DeltaChipProps } from 'src/components/DeltaChip'
import InfoIcon from 'src/components/Icon/InfoIcon'

interface StoreRestoreCardProps {
  boxSx?: SxProps
  label?: string | JSX.Element
  labelSx?: SxProps
  infoText?: string
  value?: string
  valueSx?: SxProps
  deltaChipProps?: Omit<DeltaChipProps, 'loading'>
  loading?: boolean
}

export default function StoreRestoreCard(props: StoreRestoreCardProps) {
  const { label, labelSx, infoText, loading, boxSx, value, valueSx, deltaChipProps } = props
  return (
    <StoreRestoreCard.BorderBox sx={{ display: 'flex', flexDirection: 'column', alignItems: 'stretch', ...boxSx }}>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        gap="10px"
        sx={{ width: '100%', height: '24px' }}>
        {typeof label === 'string' ? (
          <StoreRestoreCard.Typography
            loading={loading}
            sx={labelSx}
            skeleton={{ width: 120 }}>
            {label}
          </StoreRestoreCard.Typography>
        ) : (
          label
        )}
        {infoText && (
          <Tooltip
            title={infoText}
            componentsProps={{
              tooltip: {
                sx: {
                  bgcolor: '#000',
                  px: 2,
                  py: 1.5,
                  maxWidth: '400px'
                }
              },
              arrow: {
                sx: {
                  color: '#000'
                }
              }
            }}
            arrow
            placement="top">
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center">
              <InfoIcon sx={{ width: '18px' }} />
            </Box>
          </Tooltip>
        )}
      </Stack>

      <Box sx={{ minHeight: '20px', flex: 1 }} />

      <Stack
        direction="row"
        alignItems="flex-end"
        gap={1.5}>
        <StoreRestoreCard.Typography
          loading={loading}
          skeleton={{ height: 36 }}
          sx={{ fontSize: '24px', color: '#000', fontWeight: 600, overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', ...valueSx }}>
          {value}
        </StoreRestoreCard.Typography>
        {deltaChipProps && (
          <Box sx={{ mb: '5px' }}>
            <DeltaChip
              {...deltaChipProps}
              loading={loading}
            />
          </Box>
        )}
      </Stack>
    </StoreRestoreCard.BorderBox>
  )
}

StoreRestoreCard.BorderBox = (props: { sx?: SxProps; children: React.ReactNode }) => {
  const { sx, children } = props
  return <Box sx={{ px: '13px', py: '20px', border: '1px solid rgba(230, 230, 230, 0.9)', borderRadius: '4px', ...sx }}>{children}</Box>
}

StoreRestoreCard.Typography = (props: { sx?: SxProps; children?: React.ReactNode; loading?: boolean; skeleton?: { width?: number; height?: number } }) => {
  const { sx, children, loading, skeleton } = props

  return (
    <>
      {loading ? (
        <Skeleton
          variant="rounded"
          width={skeleton?.width || 80}
          height={skeleton?.height || 20}
        />
      ) : (
        <Typography
          fontSize="12px"
          color="#1C1C1C"
          sx={sx}>
          {children}
        </Typography>
      )}
    </>
  )
}
