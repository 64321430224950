import TrendingDownIcon from '@mui/icons-material/TrendingDown'
import TrendingUpIcon from '@mui/icons-material/TrendingUp'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import * as React from 'react'
import { DefaultService } from 'src/services/openApi'
// import useFilter from 'src/utils/hooks/useFilter'
import { CustomMaterialTable } from '@LoopKitchen/loop-ui'
import { Stack } from '@mui/material'
import { CsvBuilder } from 'filefy'
import { get } from 'lodash'
import { useNavigate } from 'react-router-dom'
import NoTableDataImg from 'src/assets/images/no-table-data.png'
import EmptyScreen from 'src/components/EmptyScreen/EmptyScreen'
import { useAuth } from 'src/context/AuthContext'
import { useNewFilterContext } from 'src/context/NewFilterContext/NewFilterContext'
import { filterNames } from 'src/utils/config/config'
import { emptyScreenMessages } from 'src/utils/constants/emptyScreen'
import { formatCurrency, formatNumber } from 'src/utils/functions'
import { measurePerformance } from 'src/utils/functions/performance'
import { downloadFailedSlackAlert, downloadSuccessSlackAlert } from 'src/utils/functions/slackSupport'
import useLayoutDimension from 'src/utils/hooks/useLayoutDimension'
import usePostHogHook from 'src/utils/hooks/usePostHogHook'

interface HostKitchenProps {
  tab: number
}

type TableObj = {
  locations: {
    b_name: string
    location_orders: number
    location_sales: number
    brands_with_0_sales: number
    brands_with_0_orders: number
    brands_with_50_percent_sales_dip: number
    brands_with_50_percent_orders_dip: number
    brands_with_1000_dollar_dip: number
    brands_with_50_order_dip: number
    location_orders_delta: number
    location_sales_delta: number
    comments?: string
    brands: {
      vb_name: string
      brand_orders: number
      brand_sales: number
      brand_orders_delta: number
      brand_sales_delta: number
    }[]
  }[]
  next_offset: number
  max_rows: number
}

export const comments = (vb1, vb2, vb3) => {
  let sales = ''
  let dip = ''
  let dip_Dollar = ''
  if (vb1) sales = `${vb1} brands have 0 sales.`
  if (vb2) dip = `${vb2} brands have 50% dip from previous period.`
  if (vb3) dip_Dollar = `${vb3} brands have 1000 dollar dip from previous period.`

  return sales + '\n' + dip + '\n' + dip_Dollar
}

const HostKitchenTable = (props: HostKitchenProps) => {
  const { trackPostHogDownload } = usePostHogHook()
  const navigate = useNavigate()
  const { currentUser, orgConfig } = useAuth()
  const { headerHeightPx } = useLayoutDimension()
  const [tableObj, setTableObj] = React.useState<TableObj | null>(null)
  const [orderBy, setOrderBy] = React.useState<string>()
  const [ascending, setAscending] = React.useState(false)
  // const [tableData, setTableData] = React.useState([])
  const [tableDataLoading, setTableDataLoading] = React.useState(true)
  const { getFilters, getFiltersV2, selectFiltersFromContext } = useNewFilterContext()
  const procedureNameHostKitchenArray = [
    'data_visualization_metrics.procedure_dashboard__sales_overview__sales__host_kitchen',
    'data_visualization_metrics.procedure_dashboard__sales_overview__ordercount__host_kitchen',
    'data_visualization_metrics.procedure_dashboard__sales_overview__payout__host_kitchen'
  ]
  const procedureNameVirtualBrandArray = [
    'data_visualization_metrics.procedure_dashboard__sales_overview__sales__virtual_brand',
    'data_visualization_metrics.procedure_dashboard__sales_overview__ordercount__virtual_brand',
    'data_visualization_metrics.procedure_dashboard__sales_overview__payout__virtual_brand'
  ]

  const tableData = React.useMemo(() => {
    if (!tableObj || !Array.isArray(tableObj.locations)) {
      return []
    }
    return tableObj.locations
  }, [tableObj])

  const getTableData = async (obj: { limit: number; offset: number }) => {
    setTableDataLoading(true)
    try {
      const res = (await measurePerformance(
        async () =>
          await DefaultService.getSalesOverviewByLocationKpiSalesOverviewLocationPost({
            ...getFiltersV2(['b_name', 'vb_name', 'chain', 'vb_platform', 'am_name', 'start_date', 'end_date'], true),
            limit: obj.limit,
            offset: obj.offset,
            ascending: ascending,
            order_by: orderBy
          }),
        {
          procedure: 'sales_overview/kpi',
          email: currentUser.email,
          org: currentUser.org
        }
      )) as TableObj

      const tempLocations = res.locations.map((item) => {
        return {
          ...item,
          comments: comments(item.brands_with_0_sales, item.brands_with_50_percent_sales_dip, item.brands_with_1000_dollar_dip)
        }
      })

      const result = {
        ...res,
        locations: obj.offset === 0 ? tempLocations : [...tableObj.locations, ...tempLocations]
      }
      setTableObj(result)
    } catch (err) {
      console.log(err)
    }
    setTableDataLoading(false)
  }

  React.useEffect(() => {
    getTableData({ limit: 100, offset: 0 })
  }, [orderBy, ascending, ...getFilters(['b_name', 'vb_name', 'vb_platform', 'am_name', 'start_date', 'end_date'])])

  // React.useEffect(() => {
  //   setTableDataLoading(true)
  //   ;(async () => {
  //     try {
  //       const responseData: any = await measurePerformance(
  //         async () => {
  //           return await DefaultService.runProcedureAndGetDataRunQueryPost(
  //             procedureNameHostKitchenArray[props.tab],
  //             getFilters(['b_name', 'vb_name', 'chain', 'vb_platform', 'am_name', 'start_date', 'end_date'])
  //           )
  //         },
  //         {
  //           procedure: procedureNameHostKitchenArray[props.tab],
  //           email: currentUser.email,
  //           org: currentUser.org,
  //
  //         }
  //       )
  //       const vbResponseData: any = await measurePerformance(
  //         async () => {
  //           DefaultService.getSalesOverviewKpiSalesOverviewPost
  //           return await DefaultService.runProcedureAndGetDataRunQueryPost(
  //             procedureNameVirtualBrandArray[props.tab],
  //             getFilters(['b_name', 'vb_name', 'chain', 'vb_platform', 'am_name', 'start_date', 'end_date'])
  //           )
  //         },
  //         {
  //           procedure: procedureNameVirtualBrandArray[props.tab],
  //           email: currentUser.email,
  //           org: currentUser.org,
  //
  //         }
  //       )

  //       if (responseData && vbResponseData) {
  //         const tableData = responseData.map((item, index) => ({
  //           ...item,
  //           id: index + 1,
  //           hostKitchen: item.b_name,
  //           metricName: props.tab === 0 ? item.total_current_sales : props.tab === 1 ? item.total_current_ordercount : item.total_current_payout,
  //           percentDelta: item.delta,
  //           comments: comments(item.vb_brands_with_zero_sales, item.vb_brands_50_percent_dip, item.vb_brands_1000_dollar_dip)
  //         }))
  //         const vbTableData = vbResponseData.map((item, index) => ({
  //           id: index + 1,
  //           hostKitchen: item.b_name,
  //           vitualBrand: item.vb_name,
  //           totalSales: props.tab === 0 ? item.total_current_sales : props.tab === 1 ? item.total_current_ordercount : item.total_current_payout,
  //           percentDelta: item.delta,
  //           comments: comments(item.vb_platforms_with_zero_sales, item.vb_brands_50_percent_dip, item.vb_platforms_1000_dollar_dip)
  //         }))
  //         const combinedTableData = tableData.map((obj) => {
  //           const vbArray = []
  //           vbTableData.forEach((item) => {
  //             if (item.hostKitchen === obj.hostKitchen) {
  //               vbArray.push(item)
  //             }
  //           })
  //           return {
  //             ...obj,
  //             vbList: vbArray
  //           }
  //         })
  //         setTableData(combinedTableData)
  //         setTableDataLoading(false)
  //       }
  //     } catch (error) {
  //       setTableDataLoading(false)
  //       console.log(error)
  //     }
  //   })()
  // }, [props.tab, currentUser, ...getFilters(['b_name', 'vb_name', 'vb_platform', 'am_name', 'start_date', 'end_date'])])

  const goToUrlWithHostKitchenFilters = (b_name) => {
    selectFiltersFromContext('b_name', [b_name])
    return `/errors/overview`
  }

  const goToUrlWithVbNameFilters = (b_name, vb_name) => {
    selectFiltersFromContext('b_name', [b_name])
    selectFiltersFromContext('vb_name', [vb_name])
    return `/errors/overview`
  }

  const hostKitchenSalesColumns = [
    { title: filterNames.b_name, field: 'b_name' },
    {
      title: 'Total Sales',
      field: 'location_sales',
      render: (params) => {
        return (
          <>
            <Typography
              variant="body2"
              sx={{ mr: 2 }}>
              {formatCurrency(get(params, 'location_sales', 0))}
            </Typography>
          </>
        )
      }
    },
    {
      title: '% Delta',
      field: 'location_sales_delta',
      render: (params) => {
        return (
          <>
            <Typography
              variant="body2"
              sx={{ mr: 2 }}>
              {params.location_sales_delta !== 'N/A' ? formatNumber(get(params, 'location_sales_delta', 0)) + '%' : params.location_sales_delta}
            </Typography>
            {params.location_sales_delta !== 'N/A' && Number(params.location_sales_delta) !== 0 ? (
              params.location_sales_delta < 0 ? (
                <TrendingDownIcon sx={{ color: 'red' }} />
              ) : (
                <TrendingUpIcon sx={{ color: 'green' }} />
              )
            ) : null}
          </>
        )
      }
    },
    { title: 'Comments', field: 'comments' }
  ]
  const hostKitchenOrdersColumns = [
    { title: filterNames.b_name, field: 'b_name' },
    {
      title: 'Total Orders',
      field: 'location_orders',
      render: (params) => {
        return (
          <>
            <Typography
              variant="body2"
              sx={{ mr: 2 }}>
              {formatNumber(get(params, 'location_orders', 0))}
            </Typography>
          </>
        )
      }
    },
    {
      title: '% Delta',
      field: 'location_orders_delta',
      render: (params) => {
        return (
          <>
            <Typography
              variant="body2"
              sx={{ mr: 2 }}>
              {params.location_orders_delta !== 'N/A' ? formatNumber(get(params, 'location_orders_delta', 0)) + '%' : params.location_orders_delta}
            </Typography>
            {params.location_orders_delta !== 'N/A' && Number(params.location_orders_delta) !== 0 ? (
              params.location_orders_delta < 0 ? (
                <TrendingDownIcon sx={{ color: 'red' }} />
              ) : (
                <TrendingUpIcon sx={{ color: 'green' }} />
              )
            ) : null}
          </>
        )
      }
    },
    { title: 'Comments', field: 'comments' }
  ]
  const hostKitchenPayoutColumns = [
    { title: filterNames.b_name, field: 'b_name' },
    {
      title: 'Total Payout',
      field: 'metricName',
      render: (params) => {
        return (
          <>
            <Typography
              variant="body2"
              sx={{ mr: 2 }}>
              ${params.metricName}
            </Typography>
          </>
        )
      }
    },
    {
      title: '% Delta',
      field: 'percentDelta',
      render: (params) => {
        return (
          <>
            <Typography
              variant="body2"
              sx={{ mr: 2 }}>
              {params.percentDelta !== 'N/A' ? params.percentDelta + '%' : params.percentDelta}
            </Typography>
            {params.percentDelta !== 'N/A' && Number(params.percentDelta) !== 0 ? (
              params.percentDelta < 0 ? (
                <TrendingDownIcon sx={{ color: 'red' }} />
              ) : (
                <TrendingUpIcon sx={{ color: 'green' }} />
              )
            ) : null}
          </>
        )
      }
    },
    { title: 'Comments', field: 'comments' }
  ]

  const virtualBrandSalesColumns = [
    {
      title: 'Brand',
      field: 'vb_name'
    },
    {
      title: 'Total Sales',
      field: 'brand_sales',
      render: (params) => {
        return (
          <>
            <Typography
              variant="body2"
              sx={{ mr: 2 }}>
              {formatCurrency(get(params, 'brand_sales', 0))}
            </Typography>
            <TrendingDownIcon sx={{ color: 'red' }} />
          </>
        )
      }
    },
    {
      title: '% Delta',
      field: 'brand_sales_delta',
      render: (params) => {
        return (
          <>
            <Typography
              variant="body2"
              sx={{ mr: 2 }}>
              {params.brand_sales_delta !== 'N/A' ? formatNumber(get(params, 'brand_sales_delta', 0)) + '%' : params.brand_sales_delta}
            </Typography>
            {params.brand_sales_delta !== 'N/A' && Number(params.brand_sales_delta) !== 0 ? (
              params.brand_sales_delta < 0 ? (
                <TrendingDownIcon sx={{ color: 'red' }} />
              ) : (
                <TrendingUpIcon sx={{ color: 'green' }} />
              )
            ) : null}
          </>
        )
      }
    }
    // { title: 'Comments', field: 'comments' }
  ]
  const virtualBrandOrdersColumns = [
    {
      title: 'Brand',
      field: 'vb_name',
      hidden: !orgConfig?.filterConfig['vb_name']
    },
    {
      title: 'Total Orders',
      field: 'brand_orders',
      render: (params) => {
        return (
          <>
            <Typography
              variant="body2"
              sx={{ mr: 2 }}>
              {formatNumber(get(params, 'brand_orders', 0))}
            </Typography>
            <TrendingDownIcon sx={{ color: 'red' }} />
          </>
        )
      }
    },
    {
      title: '% Delta',
      field: 'brand_orders_delta',
      render: (params) => {
        return (
          <>
            <Typography
              variant="body2"
              sx={{ mr: 2 }}>
              {params.brand_orders_delta !== 'N/A' ? formatNumber(get(params, 'brand_orders_delta', 0)) + '%' : params.brand_orders_delta}
            </Typography>
            {params.brand_orders_delta !== 'N/A' && Number(params.brand_orders_delta) !== 0 ? (
              params.brand_orders_delta < 0 ? (
                <TrendingDownIcon sx={{ color: 'red' }} />
              ) : (
                <TrendingUpIcon sx={{ color: 'green' }} />
              )
            ) : null}
          </>
        )
      }
    }
    // { title: 'Comments', field: 'comments' }
  ]
  const virtualBrandPayoutColumns = [
    {
      title: 'Brand',
      field: 'vitualBrand',
      hidden: !orgConfig?.filterConfig['vb_name']
    },
    {
      title: 'Total Payout',
      field: 'totalSales',
      render: (params) => {
        return (
          <>
            <Typography
              variant="body2"
              sx={{ mr: 2 }}>
              ${params.totalSales}
            </Typography>
            <TrendingDownIcon sx={{ color: 'red' }} />
          </>
        )
      }
    },
    {
      title: '% Delta',
      field: 'percentDelta',
      render: (params) => {
        return (
          <>
            <Typography
              variant="body2"
              sx={{ mr: 2 }}>
              {params.percentDelta !== 'N/A' ? params.percentDelta + '%' : params.percentDelta}
            </Typography>
            {params.percentDelta !== 'N/A' && Number(params.percentDelta) !== 0 ? (
              params.percentDelta < 0 ? (
                <TrendingDownIcon sx={{ color: 'red' }} />
              ) : (
                <TrendingUpIcon sx={{ color: 'green' }} />
              )
            ) : null}
          </>
        )
      }
    },
    { title: 'Comments', field: 'comments' }
  ]

  return (
    <Box sx={{ width: '95%', m: 'auto' }}>
      <Stack sx={{ textAlign: 'right' }} />
      <CustomMaterialTable
        isLoading={tableDataLoading}
        data={tableData}
        columns={props.tab === 2 ? hostKitchenPayoutColumns : props.tab === 0 ? hostKitchenSalesColumns : hostKitchenOrdersColumns}
        options={{
          stickyHeader: headerHeightPx,
          search: false,
          export: true,
          pagination: true,
          totalPaginatedDataLength: get(tableObj, 'max_rows', undefined),
          sortable: true,
          showOrderBy: true,
          initialOrderBy: ascending ? 'ascending' : 'descending'
        }}
        detailPanel={
          orgConfig?.filterConfig?.vb_name &&
          ((rowData: TableObj['locations'][0]) => {
            return (
              <CustomMaterialTable
                data={get(rowData, 'brands', [])}
                columns={props.tab === 2 ? virtualBrandPayoutColumns : props.tab === 0 ? virtualBrandSalesColumns : virtualBrandOrdersColumns}
                options={{
                  search: false,
                  pagination: false,
                  sortable: true
                }}
                onRowClick={(data) => {
                  navigate(goToUrlWithVbNameFilters(data.hostKitchen, data.vitualBrand))
                }}
              />
            )
          })
        }
        onOrderByChange={(type) => {
          setAscending(type === 'ascending')
        }}
        onSortByChange={(params) => {
          setOrderBy(params.value)
        }}
        onLastPage={() => {
          if (get(tableObj, 'next_offset', 0) > 0) {
            getTableData({ limit: 100, offset: get(tableObj, 'next_offset', 0) })
          }
        }}
        onExportCSV={(columns, data) => {
          try {
            const newColumns = columns.filter((item) => get(item, 'hidden', false) === false)
            const csvData = data.map((rowData) => {
              return newColumns.map((item) => {
                return rowData[item.field]
              })
            })

            const fileName = `${filterNames.b_name}.csv`

            trackPostHogDownload({
              fileName,
              type: 'CSV'
            })

            const builder = new CsvBuilder(fileName)
              .setColumns(newColumns.map((item) => item.title))
              .addRows(csvData)
              .exportFile()

            downloadSuccessSlackAlert({ title: fileName })

            return builder
          } catch (err) {
            downloadFailedSlackAlert({ err })
          }
        }}
        onRowClick={(data) => {
          navigate(goToUrlWithHostKitchenFilters(data.b_name))
        }}
        emptyTemplate={
          <EmptyScreen
            NoDataImg={NoTableDataImg}
            labels={emptyScreenMessages.emptyTable}
          />
        }
      />
    </Box>
  )
}

export default HostKitchenTable
