import React from 'react'

interface TabPanelProps {
  children?: React.ReactNode
  index: number | string | (string | number)[]
  value: number | string
  preLoad?: boolean
  removeOnBlur?: boolean
}

const TabPanelContext = React.createContext({ tabFocused: false })

export const useTabPanelContext = () => React.useContext(TabPanelContext)

export default function TabPanel(props: TabPanelProps) {
  const { children, value, index, preLoad, removeOnBlur, ...other } = props
  const [isFocused, setIsFocused] = React.useState(false)

  const isActive = React.useMemo(() => {
    if (!Array.isArray(index) && value === index) {
      return true
    } else if (Array.isArray(index) && index.includes(value)) {
      return true
    }
    return false
  }, [value, index])

  React.useEffect(() => {
    if (!isFocused) {
      if (isActive) {
        setIsFocused(true)
      }
    }
  }, [value, isActive])

  return (
    <TabPanelContext.Provider value={{ tabFocused: isActive }}>
      <div
        role="tabpanel"
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        style={{ display: isActive ? 'block' : 'none' }}
        {...other}>
        {removeOnBlur ? isActive && children : (isFocused || preLoad) && children}
      </div>
    </TabPanelContext.Provider>
  )
}
