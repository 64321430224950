import { Compare } from '@mui/icons-material'
import { Box, Divider, Grid, Skeleton, Stack, Typography } from '@mui/material'
import { get } from 'lodash'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { useErrorData } from 'src/context/ErrorContext'
import { useFilter } from 'src/context/FilterContext'
import { StoreAvailabilityBnameConfigResponse, StoreAvailabilityService } from 'src/services/openApi'
import { filterNames } from 'src/utils/config/config'
import { formatCurrency, formatNumber } from 'src/utils/functions'
import { pluralize } from 'src/utils/functions/pluralize'
import OutageCard from './OutageCard'
import StoreRestoreCard from './StoreRestoreSummaryComps/StoreRestoreCard'
import { AverageDataType, DetectionStatus, detectionStatusProperties, OutageDataType } from './StoreRestoreTable'

interface StoreRestoreSummaryProps {
  onClickConfigure: () => void
  selectedFilters: string[]
  onFilterClick: (dataType: string) => void
  configData: StoreAvailabilityBnameConfigResponse[]
}

const StoreRestoreSummary = ({ onClickConfigure, selectedFilters, onFilterClick, configData }: StoreRestoreSummaryProps) => {
  const { getFilters, getFiltersV2, lastDate } = useFilter()
  const { handleError } = useErrorData()
  const [averageData, setAverageData] = useState<AverageDataType>(null)
  const [averageDataLoading, setAverageDataLoading] = useState<boolean>(true)
  const [detectedDataLoading, setDetectedDataLoading] = useState<boolean>(true)
  const [outageData, setOutageData] = useState<OutageDataType[]>([
    {
      icon: detectionStatusProperties[DetectionStatus.LOOP_DETECTED].icon,
      title: detectionStatusProperties[DetectionStatus.LOOP_DETECTED].title,
      type: DetectionStatus.LOOP_DETECTED,
      value: 0,
      noConfiguration: null
    },
    {
      icon: detectionStatusProperties[DetectionStatus.TURN_ON_SUCCESS].icon,
      title: detectionStatusProperties[DetectionStatus.TURN_ON_SUCCESS].title,
      type: DetectionStatus.TURN_ON_SUCCESS,
      value: 0,
      noConfiguration: null
    }
  ])

  const getAverageSummaryData = async () => {
    setAverageDataLoading(true)
    try {
      const requestBody = getFiltersV2(['b_name', 'vb_name', 'vb_platform', 'am_name', 'chain'])
      const res = await StoreAvailabilityService.autoTurnOnDowntimeSummaryStoreAvailabilityAutoTurnOnDowntimeSummaryPost({
        ...requestBody,
        start_date_in: moment(lastDate)?.subtract(1, 'month').format('YYYY-MM-DD'),
        end_date_in: moment(lastDate)?.format('YYYY-MM-DD')
      })
      setAverageData(res)
    } catch (err) {
      handleError(err)
    }
    setAverageDataLoading(false)
  }

  const getDetectedSummaryData = async () => {
    setDetectedDataLoading(true)

    if (!configData) return

    try {
      const requestBody = getFiltersV2(['b_name', 'vb_name', 'vb_platform', 'am_name', 'chain', 'start_date', 'end_date'], true)
      const res = await StoreAvailabilityService.autoTurnOnNotificationsSummaryStoreAvailabilityAutoTurnOnNotificationsSummaryPost(requestBody)

      const outageTypes = Object.keys(res)
      const tempOutageData = []

      const totalStores = configData.length
      const nonConfiguredStores = configData.filter((store) => !store.auto_turn_on)?.length || 0

      outageTypes.forEach((key) => {
        if (key === 'downtime_occured') return

        tempOutageData.push({
          icon: detectionStatusProperties[key].icon,
          title: detectionStatusProperties[key].title,
          type: key,
          value: res[key],
          noConfiguration:
            res[key] > 0
              ? null
              : {
                  title: `0 ${detectionStatusProperties[key].secondaryTitle}`,
                  subtitle:
                    nonConfiguredStores === 0
                      ? 'We are working on store restoration'
                      : `${nonConfiguredStores} / ${totalStores} ${pluralize(filterNames.b_name, nonConfiguredStores)} are not enabled for store restoration`,
                  button: 'Configure store restore',
                  onClick: () => {
                    onClickConfigure()
                  }
                }
        })
      })

      setOutageData(tempOutageData)
    } catch (err) {
      handleError(err)
    }
    setDetectedDataLoading(false)
  }

  useEffect(() => {
    getAverageSummaryData()
  }, [...getFilters(['b_name', 'vb_name', 'vb_platform', 'am_name', 'chain']), lastDate])

  useEffect(() => {
    getDetectedSummaryData()
  }, [...getFilters(['b_name', 'vb_name', 'vb_platform', 'am_name', 'chain', 'start_date', 'end_date']), configData])

  return (
    <Grid
      container
      spacing={1}
      sx={{ mt: '20px' }}>
      <Grid
        xl={7}
        xs={12}
        item>
        <StoreRestoreCard.BorderBox sx={{ p: '16px', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'stretch' }}>
          <Box
            display="flex"
            alignItems="center"
            gap={1}>
            <Compare sx={{ fontSize: '24px', color: '#000' }} />
            <Typography
              fontSize="12px"
              padding="4px 10px"
              borderRadius="15px"
              bgcolor="#E0EBFF"
              color="#004DE5">
              Date filter not applicable
            </Typography>
          </Box>
          <Box sx={{ flex: 1 }} />
          <Stack
            direction="row"
            alignItems="stretch"
            sx={{ mt: '20px' }}>
            {(averageDataLoading || get(averageData, 'percentage_change', 0) < 0) && (
              <>
                <Box sx={{ flex: 2 }}>
                  {averageDataLoading ? (
                    <Skeleton
                      variant="rounded"
                      width={150}
                      height={40}
                    />
                  ) : (
                    <Box>
                      <Typography
                        color="#000"
                        fontWeight={600}
                        fontSize={14}>
                        30 Days before Loop
                      </Typography>
                      <Typography
                        fontSize={13}
                        fontWeight={400}
                        color="#AAAAAA"
                        mt={0.5}>
                        {moment(get(averageData, 'prior_loop_date', ''))
                          .subtract(1, 'month')
                          .format('DD MMM YY')}{' '}
                        - {moment(get(averageData, 'prior_loop_date', 0)).format('DD MMM YY')}
                      </Typography>
                    </Box>
                  )}

                  <StoreRestoreCard
                    loading={averageDataLoading}
                    label="Average Downtime"
                    value={`${formatNumber(get(averageData, 'avg_dtm_min_prior_loop', 0), { maxFractionDigits: 1 })} ${pluralize(
                      'min',
                      get(averageData, 'avg_dtm_min_prior_loop', 0)
                    )}`}
                    boxSx={{ mt: '20px' }}
                  />
                </Box>

                <Box
                  display="flex"
                  alignItems="flex-end"
                  mb="1%">
                  <Box
                    display="flex"
                    alignItems="center"
                    position="relative"
                    height="60%"
                    sx={{ mx: '20px' }}>
                    <Typography
                      fontSize="12px"
                      fontWeight={400}
                      color="#000"
                      borderRadius="2px"
                      px={1}
                      border="1px solid #E6E6E6"
                      bgcolor="#FAFAFAFA"
                      left="10px"
                      sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)'
                      }}>
                      vs
                    </Typography>

                    <Divider
                      orientation="vertical"
                      flexItem
                      sx={{ borderColor: '#E6E6E6' }}
                    />
                  </Box>
                </Box>
              </>
            )}
            <Box sx={{ flex: 5 }}>
              {averageDataLoading ? (
                <Skeleton
                  variant="rounded"
                  width={150}
                  height={40}
                />
              ) : (
                <Box>
                  <Typography
                    color="#000"
                    fontWeight={600}
                    fontSize={14}>
                    Last 30 days
                  </Typography>
                  <Typography
                    fontSize={13}
                    fontWeight={400}
                    color="#AAAAAA"
                    mt={0.5}>
                    {moment(lastDate)?.subtract(1, 'month').format('DD MMM YY')} - {moment(lastDate)?.format('DD MMM YY')}
                  </Typography>
                </Box>
              )}

              <Stack
                direction="row"
                alignItems="stretch"
                gap={1}
                sx={{ mt: '20px' }}>
                <StoreRestoreCard
                  loading={averageDataLoading}
                  label="Average Downtime"
                  value={`${formatNumber(get(averageData, 'avg_dtm_min_post_loop', 0), { maxFractionDigits: 1 })} ${pluralize(
                    'min',
                    get(averageData, 'avg_dtm_min_post_loop', 0)
                  )}`}
                  valueSx={{ color: '#0E8C43' }}
                  deltaChipProps={
                    get(averageData, 'percentage_change', 0) < 0
                      ? {
                          delta: Number(formatNumber(Math.abs(get(averageData, 'percentage_change', 0)), { maxFractionDigits: 0 })),
                          deltaType: 'success'
                        }
                      : null
                  }
                  boxSx={{ flex: 1 }}
                />
                {(averageDataLoading || get(averageData, 'sales_saved', 0) > 0) && (
                  <StoreRestoreCard
                    loading={averageDataLoading}
                    label={'Sales Saved'}
                    infoText='"Sales Saved" is the revenue from orders placed within 2 hours after Loop restores your store from a downtime event, provided that these orders occur within your menu hours.'
                    value={formatCurrency(get(averageData, 'sales_saved', 0), { maxFractionDigits: 2 })}
                    valueSx={{ color: '#0E8C43' }}
                    boxSx={{ flex: 1 }}
                  />
                )}
              </Stack>
            </Box>
          </Stack>
        </StoreRestoreCard.BorderBox>
      </Grid>

      <Grid
        item
        xl={5}
        xs={12}>
        <Box
          height="100%"
          display="flex"
          flexDirection="row"
          gap={1}>
          {outageData.map((data, index) => (
            <OutageCard
              key={data.title + index}
              loading={detectedDataLoading}
              icon={data.icon}
              title={data.title}
              value={data.value}
              enabled={selectedFilters.includes(data.type)}
              onFilterClick={() => onFilterClick(data.type)}
              noConfiguration={data.noConfiguration}
            />
          ))}
        </Box>
      </Grid>
    </Grid>
  )
}

export default StoreRestoreSummary
