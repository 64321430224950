import moment from 'moment'

export default function TimePeriodFormatter(timeInMinutes: number): string {
  timeInMinutes = Math.floor(timeInMinutes)
  let days = Math.floor(timeInMinutes / (60 * 24))
  timeInMinutes -= days * 60 * 24
  let hours = Math.floor(timeInMinutes / 60)
  let minutes = timeInMinutes - hours * 60
  return days > 0 || hours > 0 || minutes > 0 ? `${days > 0 ? days + 'd ' : ''}${hours > 0 ? hours + 'hr ' : ''}${minutes > 0 ? minutes + 'min' : ''}` : `0min`
}

export function formatHoursMinutes(date: Date, isElapsedTime: boolean = true) {
  const updateDate = date
  let timeDiff

  if (isElapsedTime) {
    timeDiff = moment().utc().diff(updateDate, 'seconds')
  } else {
    timeDiff = moment(updateDate).utc().diff(moment(), 'seconds')
  }

  const hr = Math.floor(Math.abs(timeDiff) / 3600)
  const min = Math.floor(Math.abs(timeDiff % 3600) / 60)

  if (isElapsedTime) {
    return hr === 0 ? `${min} min ago` : `${hr} hr ${min} min ago`
  } else {
    return hr <= 0 ? `in ${min} min` : `in ${hr} hr ${min} min`
  }
}
