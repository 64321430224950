import { CustomMaterialTable, MultiSelect } from '@LoopKitchen/loop-ui'
import { AddToHomeScreen, Bolt, Check, ChevronRight, Close, Info } from '@mui/icons-material'
import { Box, Checkbox, Chip, Stack, Tooltip, Typography } from '@mui/material'
import { get } from 'lodash'
import moment from 'moment'
import React from 'react'
import AlarmDetector from 'src/components/Icon/AlarmDetector'
import PlatformLogo from 'src/components/PlatformLogo'
import StyledTypo from 'src/components/StyledTypo'
import { useErrorData } from 'src/context/ErrorContext'
import { useFilter } from 'src/context/FilterContext'
import { useSnackData } from 'src/context/SnackContext'
import { DefaultService, StoreAutoTurnOnPaginated } from 'src/services/openApi'
import { filterNames } from 'src/utils/config/config'
import { downloadCsv } from 'src/utils/functions'
import { pluralize } from 'src/utils/functions/pluralize'
import useLayoutDimension from 'src/utils/hooks/useLayoutDimension'
import usePostHogHook from 'src/utils/hooks/usePostHogHook'
import StoreRestoreOrdersDrawer from '../components/StoreRestoreOrdersDrawer'

export enum DetectionStatus {
  LOOP_DETECTED = 'loop_detected',
  TURN_ON_SUCCESS = 'turn_on_success',
  RESTORE_FAILED = 'restore_failed'
}

export const detectionStatusProperties = {
  [DetectionStatus.LOOP_DETECTED]: {
    primary: '#275DEA',
    secondary: '#E0EBFF',
    title: 'Loop detected',
    secondaryTitle: 'Detected by Loop',
    icon: <AlarmDetector sx={{ color: '#275DEA', fontSize: '22px' }} />,
    secondaryIcon: <AddToHomeScreen sx={{ color: '#275DEA', fontSize: '15px' }} />
  },
  [DetectionStatus.TURN_ON_SUCCESS]: {
    primary: '#0E8C43',
    secondary: '#DAF2E4',
    title: 'Restored',
    secondaryTitle: 'Successfully Restored',
    icon: <Bolt sx={{ color: '#0E8C43', fontSize: '24px' }} />,
    secondaryIcon: <Check sx={{ color: '#0E8C43', fontSize: '15px' }} />
  },
  [DetectionStatus.RESTORE_FAILED]: {
    primary: '#FF0000',
    secondary: '#FFEFEF',
    title: 'Restored',
    secondaryTitle: 'Restore Failed',
    icon: <Close sx={{ color: '#FF0000', fontSize: '24px' }} />,
    secondaryIcon: <Close sx={{ color: '#FF0000', fontSize: '15px' }} />
  }
}

const detectionFilters = [
  { label: 'Loop Detected', value: DetectionStatus.LOOP_DETECTED },
  { label: 'Successfully Restored', value: DetectionStatus.TURN_ON_SUCCESS }
]

export interface AverageDataType {
  avg_dtm_min_prior_loop: number
  avg_dtm_min_post_loop: number
  prior_loop_date: string
  sales_saved: number
  orders_saved: number
  percentage_change: number
}

export interface DetectedDataType {
  downtime_occured: number
  loop_detected: number
  turn_on_success: number
}

export interface OutageDataType {
  icon: JSX.Element
  title: string
  type: string
  value: number
  noConfiguration: {
    title: string
    subtitle: string
    button: string
    onClick: () => void
  }
}

export interface SavedOrdersType {
  b_name: string
  order_ids: string[]
}

interface StoreRestoreTableProps {
  selectedFilters: string[]
  setSelectedFilters: (value: string[]) => void
}

export default function StoreRestoreTable({ selectedFilters, setSelectedFilters }: StoreRestoreTableProps) {
  const { trackPostHogEvent } = usePostHogHook()
  const { setDownloadSnack } = useSnackData()
  const { handleError } = useErrorData()
  const { getFiltersV2, getFilters } = useFilter()
  const { headerHeightPx } = useLayoutDimension()
  const [tableDataloading, setTableDataLoading] = React.useState(false)
  const [tableData, setTableData] = React.useState<StoreAutoTurnOnPaginated | null>(null)
  const [orderBy, setOrderBy] = React.useState<string>('dtr_start_time_local')
  const [ascending, setAscending] = React.useState(false)
  const [savedOrdersData, setSavedOrdersData] = React.useState<SavedOrdersType | null>(null)

  const limit = 100

  const getTableData = async (obj: { limit: number; offset: number }, callback: (data: StoreAutoTurnOnPaginated) => void) => {
    setTableDataLoading(true)
    try {
      const isTurnOnSuccess = selectedFilters.includes(DetectionStatus.TURN_ON_SUCCESS)
      const isLoopDetected = selectedFilters.includes(DetectionStatus.LOOP_DETECTED)

      const res = await DefaultService.callStoreAutoTurnOnApiPaginatedStoreAutoTurnOnPost(
        {
          ...getFiltersV2(['b_name', 'vb_name', 'vb_platform', 'chain', 'am_name', 'start_date', 'end_date'], true),
          limit: obj.limit,
          offset: obj.offset,
          order_by: orderBy,
          ascending: ascending,
          success_status: isTurnOnSuccess ? true : null,
          detection_status: isLoopDetected ? true : null
        },
        true
      )
      callback(res)
    } catch (err) {
      handleError(err.message)
    }
    setTableDataLoading(false)
  }

  const exportCsv = async () => {
    setDownloadSnack({ status: 'start' })
    try {
      const isTurnOnSuccess = selectedFilters.includes(DetectionStatus.TURN_ON_SUCCESS)
      const isRestoreFailed = selectedFilters.includes(DetectionStatus.RESTORE_FAILED)
      const isLoopDetected = selectedFilters.includes(DetectionStatus.LOOP_DETECTED)

      const res = await DefaultService.exportStoreAutoTurnOnApiExportStoreAutoTurnOnPost({
        ...getFiltersV2(['b_name', 'vb_name', 'vb_platform', 'chain', 'am_name', 'start_date', 'end_date'], true),
        success_status: isTurnOnSuccess ? (isRestoreFailed ? null : true) : isRestoreFailed ? false : null,
        detection_status: isLoopDetected ? true : null
      })
      downloadCsv('store_restore_history.csv', res)
      setTimeout(() => {
        setDownloadSnack({ status: 'complete' })
      }, 1000)
    } catch (err) {
      setDownloadSnack({ status: 'close' })
      handleError(err.message)
    }
  }

  const onLastPage = () => {
    const next_offset = get(tableData, 'next_offset', null)
    if (next_offset) {
      getTableData(
        {
          limit,
          offset: next_offset
        },
        (data) => {
          setTableData((prev) => {
            return {
              ...data,
              data: [...get(prev, 'data', []), ...get(data, 'data', [])]
            }
          })
        }
      )
    }
  }

  React.useEffect(() => {
    getTableData({ limit: limit, offset: 0 }, (data) => {
      setTableData(data)
    })
  }, [orderBy, ascending, selectedFilters, ...getFilters(['b_name', 'vb_name', 'vb_platform', 'chain', 'am_name', 'start_date', 'end_date'])])

  return (
    <Box mt={2}>
      <StoreRestoreOrdersDrawer
        savedOrdersData={savedOrdersData}
        handleClose={() => setSavedOrdersData(null)}
      />

      <CustomMaterialTable
        isLoading={tableDataloading}
        data={get(tableData, 'data', [])}
        columns={[
          {
            title: filterNames.b_name,
            field: 'b_name',
            render: (data) => {
              return (
                <Stack
                  direction={'row'}
                  gap={1}>
                  <Stack
                    direction={'row'}
                    sx={{
                      alignItems: 'center',
                      justifyContent: 'flex-start',
                      gap: '5px'
                    }}>
                    <PlatformLogo
                      shortImg
                      platformName={get(data, 'vb_platform', '')}
                      height="100%"
                      width={'1.25rem'}
                    />
                    <Stack>
                      <StyledTypo
                        sx={{
                          fontSize: '0.75rem',
                          fontWeight: '500'
                        }}>
                        {get(data, 'b_name', '')}
                      </StyledTypo>
                      <StyledTypo
                        sx={{
                          fontSize: '0.75rem',
                          fontWeight: '500',
                          color: '#00000080'
                        }}>
                        {get(data, 'vb_address', '')}
                      </StyledTypo>
                    </Stack>
                  </Stack>
                </Stack>
              )
            }
          },
          {
            title: 'Time of Outage',
            field: 'dtr_start_time_local',
            render: (data) => {
              try {
                const date = get(data, 'dtr_start_time_local', '')
                const offlineMinutes = Number(get(data, 'offline_minutes', 0))

                return (
                  <Stack
                    direction="row"
                    alignItems="center"
                    spacing={1}>
                    <Typography
                      color="#000"
                      fontSize={13}
                      fontWeight={500}
                      lineHeight="initial"
                      whiteSpace="nowrap">
                      {moment.utc(date).format('DD MMM hh:mm A')}
                    </Typography>

                    <Typography
                      color="#0008"
                      fontSize={13}
                      fontWeight={500}
                      lineHeight="initial"
                      whiteSpace="nowrap">
                      ({offlineMinutes > 60 ? `${Math.floor(offlineMinutes / 60)} ${pluralize('hour', Math.floor(offlineMinutes / 60))} ` : ''}
                      {offlineMinutes % 60} {pluralize('min', offlineMinutes % 60)})
                    </Typography>
                  </Stack>
                )
              } catch (err) {
                return <></>
              }
            }
          },
          {
            title: 'Reason',
            field: 'report_reason',
            render: (data) => {
              const reason = get(data, 'report_reason', '')

              if (!reason || reason === '') return <></>

              return (
                <Box
                  px={1.5}
                  py={1}
                  borderRadius="4px"
                  bgcolor="#F3F3F3"
                  color="#000"
                  fontSize={12}
                  minWidth={150}>
                  <Typography
                    color="#000"
                    fontWeight={400}
                    fontSize="12px"
                    lineHeight="initial">
                    {reason}
                  </Typography>
                </Box>
              )
            }
          },
          {
            title: 'Detection Status',
            field: 'detection_status',
            sortable: false,
            render: (data) => {
              try {
                const statusArray = []

                if (get(data, DetectionStatus.TURN_ON_SUCCESS, false)) statusArray.push(DetectionStatus.TURN_ON_SUCCESS)
                else if (get(data, DetectionStatus.LOOP_DETECTED, false)) statusArray.push(DetectionStatus.LOOP_DETECTED)

                return (
                  <Stack gap={1}>
                    {statusArray.map((status, index) => (
                      <Box
                        key={status + index}
                        display="flex"
                        alignItems="center"
                        padding="6px 15px"
                        borderRadius="15px"
                        width="fit-content"
                        bgcolor={detectionStatusProperties[status].secondary}
                        gap={0.5}>
                        {detectionStatusProperties[status].secondaryIcon}
                        <Typography
                          color={detectionStatusProperties[status].primary}
                          fontWeight={500}
                          fontSize="12px"
                          lineHeight="initial"
                          whiteSpace="nowrap">
                          {detectionStatusProperties[status].title}
                        </Typography>
                      </Box>
                    ))}
                  </Stack>
                )
              } catch (err) {
                return <></>
              }
            }
          },
          {
            title: 'Orders Post Restore',
            field: 'orders_saved',
            alignType: 'currency',
            render: (data) => {
              const noOfOrders = Number(get(data, 'orders_saved', 0))

              return (
                <Box
                  display="flex"
                  justifyContent="flex-end">
                  <Box
                    display="flex"
                    alignItems="center"
                    gap={1}
                    p={1}
                    bgcolor="#F3F3F3"
                    borderRadius="2px"
                    width="fit-content"
                    sx={{
                      opacity: noOfOrders > 0 ? 1 : 0.5
                    }}>
                    <StyledTypo
                      sx={{
                        color: '#000',
                        fontSize: '12px',
                        fontWeight: '500'
                      }}>
                      {noOfOrders}
                    </StyledTypo>
                    <ChevronRight sx={{ fontSize: '20px', color: '#000' }} />
                  </Box>
                </Box>
              )
            }
          }
        ]}
        onRowClick={(data) => {
          if (Number(get(data, 'orders_saved', 0)) === 0) return

          const savedOrders = get(data, 'orders', [])
          setSavedOrdersData({
            b_name: get(data, 'b_name', ''),
            order_ids: savedOrders.map((order) => order.order_id)
          })

          trackPostHogEvent('Clicked orders post restore on store restore')
        }}
        onExportCSV={() => {
          exportCsv()
        }}
        options={{
          stickyHeader: headerHeightPx,
          pagination: true,
          export: true,
          totalPaginatedDataLength: get(tableData, 'max_rows', undefined),
          sortable: true,
          showOrderBy: true,
          initialSortableOption: 'dtr_start_time_local',
          initialOrderBy: ascending ? 'ascending' : 'descending',
          bodyCellStyle: {
            '&:not(:first-child)': {
              borderLeft: 'none'
            },
            '&:not(:last-child)': {
              borderRight: 'none '
            }
          },
          extraToolbarComponent: (
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              width="100%"
              gap={2}>
              <Stack
                direction="row"
                alignItems="center"
                gap={2}>
                <Typography
                  color="#000"
                  fontSize={12}>
                  Filter by
                </Typography>
                <MultiSelect
                  options={detectionFilters}
                  value={selectedFilters}
                  onChange={(value) => {
                    setSelectedFilters(value)

                    trackPostHogEvent('Selected filter on store restore')
                  }}
                  renderValue={() => (
                    <Typography
                      color="#000"
                      fontWeight={500}
                      fontSize={12}>
                      {selectedFilters.length > 0
                        ? selectedFilters
                            .map((val) => {
                              return detectionFilters.find((filter) => filter.value === val)?.label
                            })
                            .join(', ')
                        : 'Select status'}
                    </Typography>
                  )}
                  renderOption={(option, { isSelected }) => {
                    const indicatorColor = detectionStatusProperties[option.value]?.primary

                    return (
                      <Tooltip title={option.label}>
                        <Box
                          sx={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            gap: '5px',
                            position: 'relative'
                          }}>
                          <Box
                            sx={{
                              position: 'absolute',
                              left: '-16px',
                              top: 0,
                              bottom: 0,
                              width: '4px',
                              borderRadius: '0px 4px 4px 0px',
                              bgcolor: indicatorColor
                            }}
                          />

                          <Checkbox
                            checked={isSelected}
                            sx={{
                              '& .MuiSvgIcon-root': { fontSize: 16 },
                              color: 'rgba(230, 230, 230, 0.90)'
                            }}
                          />

                          <Typography
                            fontWeight={400}
                            color={'#000'}
                            sx={{ fontSize: '12px', flex: 1, display: 'inline-block' }}
                            noWrap>
                            {option.label}
                          </Typography>
                        </Box>
                      </Tooltip>
                    )
                  }}
                  selectButtonSx={{
                    height: '40px',
                    '& p': {
                      fontWeight: 600
                    },
                    minWidth: '200px'
                  }}
                  disableSearch
                  disableSort
                />
              </Stack>

              <Tooltip
                arrow
                placement="left"
                title="UberEats provides downtime inactivity data on an hourly basis throughout the day. This means that pinpointing the exact moment when a store goes offline can be ambiguous.">
                <Chip
                  icon={
                    <Info
                      fontSize="inherit"
                      style={{ color: 'rgba(0, 0, 0, 0.75)' }}
                    />
                  }
                  label="Note"
                  size="small"
                  sx={{
                    color: '#000000',
                    background: 'rgba(0, 0, 0, 0.12)',
                    border: '1px solid #333',
                    borderRadius: '4px'
                  }}
                />
              </Tooltip>
            </Stack>
          )
        }}
        onSortByChange={(param) => {
          setOrderBy(param.value)
        }}
        onOrderByChange={(param) => {
          setAscending(param === 'ascending')
        }}
        onLastPage={onLastPage}
      />
    </Box>
  )
}
