import { FilterAlt } from '@mui/icons-material'
import { Box, Button, IconButton, Skeleton, Tooltip, Typography } from '@mui/material'
import React from 'react'
import { formatNumber } from 'src/utils/functions'
import StoreRestoreCard from './StoreRestoreSummaryComps/StoreRestoreCard'

interface OutageCardProps {
  icon: React.ReactNode
  title: string
  value: number
  enabled: boolean
  loading: boolean
  onFilterClick: () => void
  noConfiguration: {
    title: string
    subtitle: string
    button: string
    onClick: () => void
  } | null
}

const OutageCard = ({ icon, title, value, enabled, loading, onFilterClick, noConfiguration }: OutageCardProps) => {
  return (
    <Box
      px={1.5}
      py={2}
      border="1px solid #E6E6E6"
      borderRadius="4px"
      width="100%"
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      gap={1}>
      {loading ? (
        <Skeleton
          variant="circular"
          sx={{ width: '28px', height: '28px' }}
        />
      ) : (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          gap={1}>
          {icon}
          {!!noConfiguration === false && (
            <IconButton
              sx={{
                width: '30px',
                height: '30px',
                borderRadius: '4px',
                bgcolor: enabled ? '#DBE7EA' : '#F3F3F3',
                '&:hover': {
                  bgcolor: enabled ? '#DBE7EA' : '#F3F3F3'
                }
              }}
              onClick={() => onFilterClick()}>
              <Tooltip
                componentsProps={{
                  tooltip: {
                    sx: {
                      bgcolor: '#000',
                      px: 2,
                      py: 1.5,
                      maxWidth: '400px'
                    }
                  },
                  arrow: {
                    sx: {
                      color: '#000'
                    }
                  }
                }}
                arrow
                placement="top"
                title="Apply filters to the table below">
                <FilterAlt sx={{ color: '#000', fontSize: '20px' }} />
              </Tooltip>
            </IconButton>
          )}
        </Box>
      )}
      {!!noConfiguration === false ? (
        <Box>
          {loading ? (
            <Skeleton
              variant="rectangular"
              sx={{ fontSize: '14px', width: '50%' }}
            />
          ) : (
            <Typography
              fontSize={'14px'}
              color="#1E1E1E"
              fontWeight={600}
              lineHeight="16px">
              {title}
            </Typography>
          )}
          <StoreRestoreCard
            label="Instances"
            loading={loading}
            value={formatNumber(value, { maxFractionDigits: 0 })}
            boxSx={{ mt: '20px' }}
          />
        </Box>
      ) : (
        <StoreRestoreCard.BorderBox sx={{ gap: 1.5, display: 'flex', flexDirection: 'column' }}>
          <Typography
            fontSize="14px"
            fontWeight={600}
            color="#000"
            lineHeight="initial">
            {noConfiguration.title}
          </Typography>
          <Typography
            fontSize="12px"
            fontWeight={500}
            color="#1E1E1E80"
            lineHeight="initial">
            {noConfiguration.subtitle}
          </Typography>
          <Button
            onClick={noConfiguration.onClick}
            sx={{
              minWidth: 'auto',
              width: '100%',
              fontSize: '12px',
              px: 0
            }}>
            {noConfiguration.button}
          </Button>
        </StoreRestoreCard.BorderBox>
      )}
    </Box>
  )
}

export default OutageCard
