
import React, { useEffect } from 'react'
import { createContext, useContext, useMemo, useState } from 'react'
interface OrderContextInterface {
    params: ParamTypes,
    setParams: React.Dispatch<React.SetStateAction<ParamTypes>>
    clearOrderIds: () => void
}

type ParamTypes = string[]

const initialState = {} as OrderContextInterface

const OrderContext = createContext<OrderContextInterface>(initialState)

export const useOrderParams = () => useContext(OrderContext)

const OrderContextProvider = ({ children }: { children: React.ReactNode }) => {
    const [params, changeParams] = useState<ParamTypes>([])

    function setParams(ids: ParamTypes) {
        localStorage.setItem("orderIds", JSON.stringify(ids))
        changeParams(ids)
    }
    function clearOrderIds() {
        localStorage.removeItem('orderIds')
        changeParams([])
    }

    useEffect(() => {
        const ids = localStorage.getItem('orderIds')
        if (ids) changeParams(JSON.parse(ids))
    }, [])

    const contextValue = useMemo(
        () => ({ params, setParams, clearOrderIds }),
        [params, setParams]
    )

    return (
        <OrderContext.Provider value={contextValue}>{children}</OrderContext.Provider>
    )
}

export default OrderContextProvider