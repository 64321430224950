export const emptyScreenMessages = {
    emptyGraph: {
        NO_DATA: 'No data with this set of seletion',
        TRY_FILTERS: 'Try adjusting your filters',
        GO_TO_FILTERS: 'GO UP TO FILTERS'
    },
    emptyTable: {
        NO_DATA: 'We need more data to generate insights',
        TRY_FILTERS: 'Come back next week',
        GO_TO_FILTERS: 'GO UP TO FILTERS'
    }
}