import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { Avatar, Box, Toolbar } from '@mui/material'
import Tooltip from '@mui/material/Tooltip'
import { get } from 'lodash'
import loopLogo1 from 'src/assets/logos/logo1.png'
import StoreLogo from 'src/components/StoreLogo'
import { useAuth } from 'src/context/AuthContext'
import { usePrivateLayoutDrawerContext } from 'src/context/PrivateLayoutDrawerContext'
import StyledIcon from 'src/layout/components/StyledIcon'
import StyledLogo from 'src/layout/components/StyledLogo'
import { AccessLevelEnum } from 'src/utils/config/config'

export default function SideBarHeader() {
  const { currentUser, orgConfig } = useAuth()
  const { theme, open, toggleDrawer } = usePrivateLayoutDrawerContext()
  const accessLevel = Array.isArray(currentUser['access-level']) ? currentUser['access-level'][0] : currentUser['access-level']

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          marginTop: '30px',
          marginBottom: open ? '20px' : '10px',
          flexDirection: 'row'
        }}>
        <Box
          sx={{
            flex: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            gap: 2
          }}>
          {get(currentUser, 'org') && (
            <div
              style={{
                justifyContent: 'center',
                display: open ? 'flex' : 'none',
                transition: 'display 225ms',
                marginLeft: '16px'
              }}>
              {accessLevel == AccessLevelEnum.DEMO ? (
                <Tooltip
                  title={'Demo'}
                  arrow
                  placement="top"
                  sx={{ width: '100%' }}>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      gap: '10px'
                    }}>
                    <Avatar
                      sx={{
                        bgcolor: '#f15412',
                        width: 38,
                        height: 38
                      }}>
                      {'D'}
                    </Avatar>
                  </div>
                </Tooltip>
              ) : get(orgConfig, 'logo') ? (
                <Tooltip
                  title={orgConfig.name}
                  arrow
                  placement="top"
                  sx={{ width: '100%' }}>
                  <img
                    src={orgConfig.logo}
                    style={{
                      width: '100%',
                      height: 50,
                      objectFit: 'contain'
                    }}
                  />
                </Tooltip>
              ) : (
                <StoreLogo showName />
              )}
            </div>
          )}
          <StyledLogo
            theme={theme}
            sx={{
              display: open ? 'flex' : 'none',
              m: '0px',
              height: 40,
              objectFit: 'contain'
              // marginLeft: '16px'
            }}
            // src={loopLogo}
            src={loopLogo1}
            open={open}
          />
          <StyledLogo
            theme={theme}
            sx={{
              display: !open ? 'flex' : 'none',
              height: 40,
              m: 'auto',
              marginLeft: '9px',
              objectFit: 'contain'
            }}
            src={loopLogo1}
            open={open}
          />
        </Box>
        <Toolbar sx={{ marginLeft: open ? '-5px' : '-20px', width: '10%' }}>
          <StyledIcon
            size="small"
            onClick={toggleDrawer}>
            {!open ? <ChevronRightIcon sx={{ color: 'white' }} /> : <ChevronLeftIcon sx={{ color: 'white' }} />}
          </StyledIcon>
        </Toolbar>
      </Box>
      <div
        style={{
          display: open ? 'none' : 'flex',
          justifyContent: 'center',
          marginBottom: '20px',
          transition: 'display 225ms'
        }}>
        {get(currentUser, 'org') && <StoreLogo showAvatarOnly />}
      </div>
    </>
  )
}
