export const defaultEmptyScreenStyles = () => {
    return {
        mainWrapper: {
            background: '#fff',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column'
        },
        imageWrapper: {
            background: '#fff',
            minWidth: '12%'
        },
        textWrapper: {
            textAlign: 'center',
            marginTop: '33px'
        },
        text1: {
            fontSize: '20px',
            fontWeight: '400'
        },
        text2: {
            fontSize: '16px',
            opacity: '0.5'
        },
        buttonWrapper: {
            marginTop: '23px',
            '& .MuiSvgIcon-root': {
                marginRight: '3px'
            }
        },
        button: {
            height: '30px',
            background: '#E8F1F3',
            color: '#0F465A',
            fontSize: '12px',
            fontWeight: '600',
            lineHeight: '20px',
            '&:hover': {
                color: '#E8F1F3'
            }
        }
    }
}