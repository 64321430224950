import { getNewLastDate, updateDateRangeFromLastDate } from 'src/components/NewFilterComponent/utils/filterUtils'
import { FilterGraph } from './FilterGraph'
import { ApiFilterResponseType, BusinessRefreshBnameLevelData } from './types'

class FilterGraphManager {
  private filterDataSlugs: Set<string> = new Set()
  private graphObj: {
    [graphId: string]: {
      graph: FilterGraph
      // stores special parameters (for eg. allowedFilters / singleSelect) that dictate new instance.
      // if params are different for same graphId, new instance has to be created
      params: {
        [paramKey: string]: any
      }
    }
  } = {}

  constructor() {}

  getGraphIds() {
    return Object.keys(this.graphObj || {})
  }

  setGraph(
    graphId: string,
    data: FilterGraph | ApiFilterResponseType[],
    businessRefreshData: BusinessRefreshBnameLevelData[],
    singleSelectFilterKeys?: string[],
    allowedFilters?: { [key: string]: (string | number)[] }
  ) {
    if (data instanceof FilterGraph) {
      this.graphObj[graphId].graph = data
    } else if (Array.isArray(data)) {
      // ? this case handles dynamic independent graphs, like if allowedFilters are dynamic on same page, graph needs to update
      this.graphObj[graphId] = {
        graph: new FilterGraph(graphId, data, singleSelectFilterKeys),
        params: {
          singleSelectFilterKeys: singleSelectFilterKeys ? [...singleSelectFilterKeys] : [],
          allowedFilters: allowedFilters ? { ...allowedFilters } : {}
        }
      }
      const newLastDate = getNewLastDate(businessRefreshData, this.graphObj[graphId].graph)
      this.graphObj[graphId].graph.setLastDate(newLastDate)
      updateDateRangeFromLastDate(this.graphObj[graphId].graph, newLastDate, true)
    }
  }

  getGraph(graphId: string): FilterGraph {
    if (graphId in this.graphObj) {
      return this.graphObj[graphId].graph
    }
    // ? instead of handling null everywhere, we are creating new empty graph
    // ? this empty graph is not set to any key in the graph manager
    // ? when filter data is received, new graph is created with the data and saved with a key
    return new FilterGraph(graphId, [])
  }

  deleteGraphId(graphId: string) {
    if (graphId in this.graphObj) {
      delete this.graphObj[graphId]
    }
  }

  getParams(graphId: string) {
    if (graphId in this.graphObj) {
      return this.graphObj[graphId].params || {}
    }
    return {}
  }

  clearGraphs() {
    this.graphObj = {}
    this.filterDataSlugs = new Set()
  }

  getFilterDataSlugs() {
    return this.filterDataSlugs
  }

  setFilterDataSlugs(newFilterDataSlugs: Set<string>) {
    this.filterDataSlugs = newFilterDataSlugs
  }
}

const filterGraphManager = new FilterGraphManager()

export default filterGraphManager
