import { initializeApp } from 'firebase/app'
import { getFirestore } from 'firebase/firestore'
import { BASE_URL } from 'src/utils/config/config'

export const firebaseConfig = {
  apiKey: 'AIzaSyAPstTsXFKtlRyC5XnfE71A1PBwQGPhuNY',
  authDomain: BASE_URL,
  projectId: 'arboreal-vision-339901',
  storageBucket: 'arboreal-vision-339901.appspot.com',
  messagingSenderId: '983150008719',
  appId: '1:983150008719:web:094b902dc0e51ab27dd3fa',
  measurementId: 'G-G71XJ4JH01'
}

const app = initializeApp(firebaseConfig)
const db = getFirestore(app)
export default db
