import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { Box, ButtonBase, Collapse, Skeleton, Stack, SxProps, Tooltip, Typography } from '@mui/material'
import React from 'react'

interface BorderedPriceBoxProps {
  startElement?: React.ReactNode
  label: string
  value: string | JSX.Element
  sx?: SxProps
  loading?: boolean
  collapseElement?: React.ReactNode
  collapseDefaultExpanded?: boolean
}

export default function BorderedPriceBox(props: BorderedPriceBoxProps) {
  const { startElement, label, value, loading, collapseElement, collapseDefaultExpanded } = props
  const [collapseIn, setCollapseIn] = React.useState(!!collapseDefaultExpanded)

  const handleClick = () => {
    if (collapseElement) {
      setCollapseIn((prev) => !prev)
    }
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Stack
        component={collapseElement && ButtonBase}
        onClick={handleClick}
        direction="row"
        alignItems="center"
        gap="7px"
        sx={{
          width: '100%',
          py: '3px',
          px: '6px',
          borderRadius: '4px',
          border: '1px solid rgba(230, 230, 230, 0.9)'
        }}>
        {startElement}
        {loading ? (
          <Skeleton
            variant="text"
            sx={{ flex: 1 }}
          />
        ) : (
          <Tooltip title={label}>
            <Typography
              fontSize="16px"
              color="#000"
              sx={{ flex: 1, textAlign: 'left', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
              {label}
            </Typography>
          </Tooltip>
        )}

        {loading ? (
          <Skeleton
            variant="text"
            width="100px"
          />
        ) : (
          <Typography
            fontSize="16px"
            color="#000">
            {value}
          </Typography>
        )}
        {collapseElement && (
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', transform: `rotateZ(${collapseIn ? 180 : 0}deg)`, transition: '0.5s' }}>
            <KeyboardArrowDownIcon sx={{ fontSize: '20px', color: '#000' }} />
          </Box>
        )}
      </Stack>
      <Collapse in={collapseIn}>{collapseElement}</Collapse>
    </Box>
  )
}
