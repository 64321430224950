import { Box, Icon, Stack, SxProps, Typography } from '@mui/material'
import React from 'react'

interface HeadingContainerProps {
  title: string
  icon: React.ReactNode
  children: React.ReactNode
  sx?: SxProps
}

export default function HeadingContainer(props: HeadingContainerProps) {
  const { title, icon, children, sx } = props

  return (
    <Box sx={sx}>
      <Stack
        direction="row"
        alignItems="center"
        gap="8px">
        {typeof icon === 'string' ? <Icon sx={{ fontSize: '24px', color: '#000' }}>{icon}</Icon> : icon}
        <Typography
          fontSize="14px"
          fontWeight={600}
          color="#000"
          sx={{ flex: 1 }}>
          {title}
        </Typography>
      </Stack>
      <Box sx={{ mt: '10px' }}>{children}</Box>
    </Box>
  )
}
