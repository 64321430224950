import { Box, Skeleton, Stack } from '@mui/material'
import { get } from 'lodash'
import React from 'react'
import ReactApexChart from 'react-apexcharts'
import * as ReactDOMServer from 'react-dom/server'
import { formatCurrency, formatNumber } from 'src/utils/functions'

interface DonutChartProps {
  data: {
    label: string
    value: number
    color: string
    textColor: string
    hide?: boolean
    tooltipLabel?: string
    // percentage?: number
  }[]
  threePFeePerc?: number
  centerLabelFormatter?: (label: string) => string
  centerValueFormatter?: (value: number) => string
  loading?: boolean
}

export default function DonutChart(props: DonutChartProps) {
  const { data } = props

  const { labels, values, colors, textColors, tooltipLabel } = React.useMemo(() => {
    let labels: string[] = []
    let values: number[] = []
    let colors: string[] = []
    let textColors: string[] = []
    let tooltipLabel: { [label: string]: string } = {}
    data
      .filter((e) => !e.hide)
      .forEach((item) => {
        labels.push(item.label)
        values.push(Math.abs(item.value))
        colors.push(item.color)
        textColors.push(item.textColor)
        if (item.tooltipLabel) {
          tooltipLabel[item.label] = item.tooltipLabel
        }
      })
    return { labels, values, colors, textColors, tooltipLabel }
  }, [data])

  return (
    <>
      {props.loading ? (
        <>
          <Stack
            direction="row"
            spacing={2}
            style={{ width: '100%', aspectRatio: 1 / 1 }}>
            <Box
              sx={{
                width: '60%',
                height: '60%',
                padding: '12px'
              }}>
              <Skeleton
                variant="circular"
                width="100%"
                height="100%"
              />
            </Box>
            <Stack style={{ width: '40%', marginTop: '32px' }}>
              <Skeleton
                animation="wave"
                height={24}
                width="80%"
                style={{ marginBottom: 2 }}
              />
              <Skeleton
                animation="wave"
                height={24}
                width="80%"
                style={{ marginBottom: 2 }}
              />
              <Skeleton
                animation="wave"
                height={24}
                width="80%"
              />
            </Stack>
          </Stack>
        </>
      ) : (
        <ReactApexChart
          type="donut"
          height={'100%'}
          width={'100%'}
          series={values}
          options={{
            labels,
            colors,
            states: {
              hover: {
                filter: {
                  type: 'none'
                }
              }
            },
            dataLabels: {
              enabled: true,
              dropShadow: {
                enabled: false
              },
              style: {
                colors: textColors
              },
              formatter: (val) => {
                if (typeof val === 'number') {
                  return formatNumber(val, { maxFractionDigits: 0 }) + '%'
                }
                return val.toString()
              }
            },
            tooltip: {
              custom: ({ seriesIndex, dataPointIndex }) => {
                let index = seriesIndex
                let dataLabel = labels[index]
                let label = tooltipLabel[dataLabel] || dataLabel
                let value = values[index]
                let color = colors[index]
                let textColor = textColors[index]
                return ReactDOMServer.renderToStaticMarkup(
                  <div style={{ padding: ' 5px 10px', background: color }}>
                    <span style={{ color: textColor }}>
                      {label}:{' '}
                      <b>
                        {formatCurrency(value, {
                          maxFractionDigits: 2
                        })}
                      </b>
                    </span>
                  </div>
                )
              },
              y: {
                formatter: (val) =>
                  formatCurrency(val, {
                    maxFractionDigits: 0,
                    notation: 'compact'
                  })
              }
            },
            legend: {
              position: 'right',
              horizontalAlign: 'center',
              labels: {
                colors: '#696C80'
              },
              markers: {
                width: 10,
                height: 10,
                radius: 0,
                offsetX: -10,
                offsetY: 1
              },
              formatter: (legendName, opts) => {
                const obj = props.data.find((obj) => obj.label === legendName)
                const subTotal = props.data.reduce((acc, obj) => acc + obj.value, 0)
                const perc = formatNumber((get(obj, 'value', 0) / subTotal) * 100, { maxFractionDigits: 0 })
                return `${legendName}: ${perc}% (${formatCurrency(get(obj, 'value', 0), {
                  maxFractionDigits: 0,
                  notation: 'compact'
                })})`
              }
            },
            plotOptions: {
              pie: {
                donut: {
                  size: '75%',
                  labels: {
                    show: true,
                    total: {
                      show: typeof props.threePFeePerc === 'number',
                      color: '#000',
                      label: '3P Fee',
                      formatter: (w) => {
                        return `${formatNumber(get(props, 'threePFeePerc', 0), {
                          maxFractionDigits: 0
                        })}%`
                      }
                    },
                    name: {
                      show: !!props.centerLabelFormatter,
                      formatter: (val) => {
                        if (props.centerLabelFormatter) {
                          return props.centerLabelFormatter(val)
                        }
                        return
                      }
                    },
                    value: {
                      show: !!props.centerValueFormatter,
                      formatter: (val) => {
                        if (props.centerValueFormatter) {
                          return props.centerValueFormatter(Number(val))
                        }
                        return
                      }
                    }
                  }
                }
              }
            }
          }}
        />
      )}
    </>
  )
}
