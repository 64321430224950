import { ThemeProvider } from '@mui/material'
import CssBaseline from '@mui/material/CssBaseline'
import { ConfirmationResult, RecaptchaVerifier } from 'firebase/auth'
import posthog from 'posthog-js'
import React, { Suspense, useEffect } from 'react'
import TagManager from 'react-gtm-module'
import { clarity } from 'react-microsoft-clarity'
import { BrowserRouter } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { OpenAPI as FrontendDataServiceBaseAPI } from 'src/services/openApi'
import theme from 'src/theme/defaultTheme'
import { POST_HOG_BASE_URL } from './assets/configs/globalConst'

// Context Providers
import AuthContextProvider from 'src/context/AuthContext'
import ErrorContextProvider from 'src/context/ErrorContext'
import LoadingContextProvider from 'src/context/LoadingContext'
import OrderContextProvider from 'src/context/OrderContext'
import SnackContextProvider from 'src/context/SnackContext'
import Router from 'src/routes/Routes'
import BugReportContextProvider from './context/BugReportContext'

// Sentry Initialization
import { BrowserTracing, Replay, init, reactRouterV6Instrumentation, withProfiler } from '@sentry/react'

import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom'
import Loading from './components/Loading'

// Lazy load router
// const Router = React.lazy(() => import('src/routes/Routes'));

// Global Declarations
declare global {
  interface Window {
    recaptchaVerifier: RecaptchaVerifier
    confirmationResult: ConfirmationResult
    dataLayer: any[]
    loop: any
    getComputedStyle: (elt: Element, pseudoElt?: string) => CSSStyleDeclaration
  }
}

// PostHog Initialization
posthog.init('phc_sikWFIgkDh7HTtJ6C3Gx5CTU3DvS21g5acZ8lZ9HRyi', {
  api_host: POST_HOG_BASE_URL,
  enable_recording_console_log: true,
  session_recording: {
    maskAllInputs: false,
    maskNetworkRequestFn: (request) => request
  }
})

// Sentry Initialization
init({
  dsn: 'https://31ca9b004e6d439181967f14e7f76394@o4504559796682752.ingest.sentry.io/4504650246062080',
  integrations: [
    new posthog.SentryIntegration(posthog, 'loop-frontend', 4504650246062080),
    new BrowserTracing({
      routingInstrumentation: reactRouterV6Instrumentation(useEffect, useLocation, useNavigationType, createRoutesFromChildren, matchRoutes)
    }),
    new Replay()
  ],
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  environment: process.env.NODE_ENV,
  release: process.env.REACT_APP_VERSION
})

// GTM Initialization
TagManager.initialize({
  gtmId: 'GTM-N5H6FCB'
})

// MS Clarity Initialization
clarity.init('fnd5k2tl32')

// Set API Base URL
FrontendDataServiceBaseAPI.BASE = 'https://frontend-data-service-3-ul5ne76yva-uc.a.run.app'

const App = React.memo(() => {
  return (
    <>
      <div id="recaptcha-container" />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        limit={5}
        hideProgressBar={false}
        newestOnTop
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss
        draggable={false}
        pauseOnHover
        closeButton={true}
        theme="colored"
      />
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <BrowserRouter>
          <LoadingContextProvider>
            <SnackContextProvider>
              <ErrorContextProvider>
                <AuthContextProvider>
                  <OrderContextProvider>
                    <BugReportContextProvider>
                      <Suspense fallback={<Loading />}>
                        <Router />
                      </Suspense>
                    </BugReportContextProvider>
                  </OrderContextProvider>
                </AuthContextProvider>
              </ErrorContextProvider>
            </SnackContextProvider>
          </LoadingContextProvider>
        </BrowserRouter>
      </ThemeProvider>
    </>
  )
})

export default withProfiler(App)
