import { Container } from '@LoopKitchen/loop-ui'
import CampaignIcon from '@mui/icons-material/Campaign'
import CategoryIcon from '@mui/icons-material/Category'
import DoNotDisturbOnIcon from '@mui/icons-material/DoNotDisturbOn'
import { Box } from '@mui/material'
import { get } from 'lodash'
import React from 'react'
import { useTabPanelContext } from 'src/components/TabPanel'
import { useFilter } from 'src/context/FilterContext'
import { useSnackData } from 'src/context/SnackContext'
import { CampaignCountAggregate, DefaultService } from 'src/services/openApi'
import { formatNumber } from 'src/utils/functions'
import AdjustIcon from '../Icons/AdjustIcon'
import LocationAutomationIcon from '../Icons/LocationAutomationIcon'
import CampaignTable from '../components/CampaignTable'

export default function CampaignMetrics() {
  const { tabFocused } = useTabPanelContext()
  const { openError } = useSnackData()
  const { getFiltersV2, getFilters } = useFilter()
  const [campaignData, setCampaignData] = React.useState<CampaignCountAggregate>(null)
  const [campaignLoading, setCampaignLoading] = React.useState(false)

  const getCampaignData = async () => {
    setCampaignLoading(true)
    try {
      const res = await DefaultService.callCampaignCountApiCampaignCountPost(getFiltersV2(['vb_name', 'chain', 'b_name', 'vb_platform', 'am_name'], true))
      setCampaignData(res)
    } catch (err) {
      console.log('campaign error: ', err)
      if (tabFocused) {
        openError(err.message)
      }
    }
    setCampaignLoading(false)
  }

  React.useEffect(() => {
    // getQueryData()
    getCampaignData()
  }, [...getFilters(['vb_name', 'chain', 'b_name', 'vb_platform', 'am_name'])])

  return (
    <>
      <Container
        sx={{ mt: '20px' }}
        icon={
          <CampaignIcon
            fontSize="inherit"
            color="inherit"
          />
        }
        title="Marketing campaigns"
        dataCards={
          campaignData !== null && [
            {
              icon: (
                <AdjustIcon
                  fontSize="inherit"
                  color="inherit"
                />
              ),
              title: 'All campaigns',
              loading: campaignLoading,
              amount: formatNumber(get(campaignData, 'total_campaigns', 0), {
                maxFractionDigits: 0
              }),
              sx: { width: '172px' }
            },
            {
              icon: (
                <Box
                  sx={{
                    display: 'flex',
                    color: 'rgba(241, 84, 18, 1)',
                    fontSize: 'inherit'
                  }}>
                  <DoNotDisturbOnIcon
                    fontSize="inherit"
                    color="inherit"
                  />
                </Box>
              ),
              // title: 'Campaigns to stop',
              title: 'Low performing campaigns',
              tooltip: 'Campaigns with ROI < 4 having sales below 100$',
              loading: campaignLoading,
              amount: formatNumber(get(campaignData, 'number_of_campaigns_low_performing', 0), { maxFractionDigits: 0 }),
              sx: { width: '172px' }
            },
            {
              icon: (
                <CategoryIcon
                  fontSize="inherit"
                  color="inherit"
                />
              ),
              title: 'Promo campaigns',
              loading: campaignLoading,
              amount: formatNumber(get(campaignData, 'total_promo_campaigns', 0), { maxFractionDigits: 0 }),
              sx: { width: '172px' }
            },
            {
              icon: (
                <LocationAutomationIcon
                  fontSize="inherit"
                  color="inherit"
                />
              ),
              title: 'Ad campaigns',
              loading: campaignLoading,
              amount: formatNumber(get(campaignData, 'total_ad_campaigns', 0), {
                maxFractionDigits: 0
              }),
              sx: { width: '172px' }
            }
          ]
        }>
        <Box>
          <Box sx={{ mt: '26px' }}>
            <CampaignTable />
          </Box>
        </Box>
      </Container>
    </>
  )
}
