import { CustomMaterialTable, IosSwitch, SingleSelect } from '@LoopKitchen/loop-ui'
import { Box, Button, Chip, CircularProgress, FormControlLabel, Stack, Typography } from '@mui/material'
import { get } from 'lodash'
import React from 'react'
import ConfirmDialog from 'src/components/ConfirmDialog'
import PlatformLogo from 'src/components/PlatformLogo'
import { useErrorData } from 'src/context/ErrorContext'
import { useFilter } from 'src/context/FilterContext'
import { useSnackData } from 'src/context/SnackContext'
import { CampaignDetailPaginatedV2, CampaignDetailPaginatedV2Paginated, DefaultService } from 'src/services/openApi'
import { filterNames } from 'src/utils/config/config'
import { downloadCsv, formatCurrency, formatNumber } from 'src/utils/functions'
import useLayoutDimension from 'src/utils/hooks/useLayoutDimension'
import LocalStorage from '../../MarketingCampaignManagement/utils/LocalStorage'

interface CampaignTableProps {}

export default function CampaignTable(props: CampaignTableProps) {
  const { headerHeightPx } = useLayoutDimension()
  const [data, setData] = React.useState<CampaignDetailPaginatedV2Paginated>(null)
  const [loading, setLoading] = React.useState(false)
  const [orderBy, setOrderBy] = React.useState<string>(undefined)
  const [ascending, setAscending] = React.useState<boolean>(false)
  const [openConfirmDialog, setOpenConfirmDialog] = React.useState<CampaignDetailPaginatedV2>(null)
  const [stopCampaignLoading, setStopCampaignLoading] = React.useState<CampaignDetailPaginatedV2>(null)
  const [showLowPerformingCampaign, setShowLowPerformingCampaign] = React.useState(false)
  const [campaignTypes, setCampaignTypes] = React.useState<string[]>([])
  const [selectedCampaignType, setSelectedCampaignType] = React.useState<string>()
  const [pageHasStopCampaign, setPageHasStopCampaign] = React.useState(false)
  const { getFiltersV2, getFilters } = useFilter()
  const { setDownloadSnack, openSuccess } = useSnackData()
  const { handleError } = useErrorData()

  const tableData = React.useMemo(() => {
    return get(data, 'data', [] as typeof data.data)
  }, [data])

  const exportCSV = async () => {
    setDownloadSnack({ status: 'start' })
    try {
      const res = await DefaultService.exportCampaignDetailV2ApiExportCampaignDetailV2Post({
        ...getFiltersV2(['vb_name', 'chain', 'b_name', 'vb_platform', 'am_name'], true),
        campaign_flag: showLowPerformingCampaign ? 'low_performing' : undefined,
        campaign_type: selectedCampaignType || undefined
      })
      downloadCsv('campaign.csv', res)
      setDownloadSnack({ status: 'complete' })
    } catch (err) {
      setDownloadSnack({ status: 'close' })
      handleError(err.message)
    }
  }

  const getData = async (
    obj: { offset: number; order_by?: string; ascending?: boolean; limit?: number },
    callback: (data: CampaignDetailPaginatedV2Paginated) => void
  ) => {
    setLoading(true)
    try {
      const res = await DefaultService.callCampaignDetailV2ApiPaginatedCampaignDetailV2Post(
        {
          limit: 100,
          campaign_flag: showLowPerformingCampaign ? 'low_performing' : undefined,
          campaign_type: selectedCampaignType || undefined,
          ...obj,
          ...getFiltersV2(['vb_name', 'chain', 'b_name', 'vb_platform', 'am_name'], true)
        },
        true
      )
      callback(res)
    } catch (err) {
      handleError(err.message)
    } finally {
      setLoading(false)
    }
  }

  const stopCampaign = async (model: CampaignDetailPaginatedV2) => {
    setStopCampaignLoading(JSON.parse(JSON.stringify(model)))

    try {
      let res = await DefaultService.stopCampaignsStopCampaignPost([{ id: model.campaignId, slug: model.slug }])
      LocalStorage.addActiveCampaignID(model.campaignId)
      openSuccess('Campaign Stopped')
    } catch (err) {
      handleError(err.message)
    }

    setStopCampaignLoading(null)

    getData(
      {
        limit: Math.max(100, get(data, 'data.length', 0)),
        offset: 0
      },
      (data) => {
        setData(data)
      }
    )
  }

  React.useEffect(() => {
    getData({ offset: 0, order_by: orderBy, ascending }, (res) => {
      setData(res)
    })
  }, [orderBy, ascending, showLowPerformingCampaign, selectedCampaignType, ...getFilters(['vb_name', 'chain', 'b_name', 'vb_platform', 'am_name'])])

  React.useEffect(() => {
    const getCampaignTypes = async () => {
      try {
        const res = await DefaultService.getCampaignTypesGetCampaignTypesGet()
        setCampaignTypes(res)
      } catch (err) {
        handleError(err.message)
      }
    }
    getCampaignTypes()
  }, [])

  return (
    <>
      <ConfirmDialog
        open={!!openConfirmDialog}
        title="Do you want to stop the campaign?"
        description=""
        onConfirm={() => {
          stopCampaign(openConfirmDialog)
          setOpenConfirmDialog(null)
        }}
        handleClose={() => {
          setOpenConfirmDialog(null)
        }}
      />
      <Box>
        <CustomMaterialTable
          data={tableData}
          isLoading={loading}
          columns={[
            {
              title: 'Campaign',
              field: 'campaignName',
              cellStyle: { minWidth: '250px' },
              render: (data) => {
                return (
                  <Stack
                    direction="row"
                    alignItems="center"
                    gap="25px">
                    <Box>
                      <PlatformLogo
                        shortImg
                        platformName={get(data, 'vb_platform', '')}
                        width="24px"
                        height="26px"
                      />
                    </Box>
                    <Box>
                      <Typography
                        fontSize="12px"
                        color="rgba(0, 0, 0, 0.5)"
                        sx={{ mb: '8px' }}>
                        {get(data, 'campaignId', '')}
                      </Typography>
                      <Typography
                        fontSize="12px"
                        fontWeight={600}>
                        {get(data, 'campaignName', '')}
                      </Typography>
                    </Box>
                  </Stack>
                )
              }
            },
            {
              title: filterNames.vb_name,
              field: 'vb_name'
            },
            {
              title: filterNames.vb_address,
              field: 'vb_address',
              cellStyle: { minWidth: '200px' }
            },
            {
              title: 'Type',
              field: 'campaignType',
              render: (data) => {
                return (
                  <Chip
                    size="small"
                    label={get(data, 'campaignType', 0)}
                  />
                )
              }
            },
            {
              title: 'Audience',
              field: 'audience',
              render: (data) => {
                return (
                  <Box
                    sx={{
                      py: '6px',
                      px: '14px',
                      borderRadius: '4px',
                      border: '1px solid #E6E6E6',
                      backgroundColor: 'white',
                      display: 'inline-block'
                    }}>
                    <Typography
                      fontSize="12px"
                      lineHeight="132%"
                      textTransform={'capitalize'}>
                      {get(data, 'audience', '').split('_').join(' ').toLowerCase()}
                    </Typography>
                  </Box>
                )
              }
            },
            {
              title: 'ROI',
              field: 'roi',
              sortable: true,
              alignType: 'currency',
              render: (data) => {
                const roi = get(data, 'roi', 0)
                return (
                  <Typography
                    fontSize="12px"
                    color={roi <= 4 ? 'rgba(241, 84, 18, 1)' : 'rgba(14, 140, 67, 1)'}
                    fontWeight={600}>
                    {formatNumber(roi, {
                      maxFractionDigits: 1
                    })}
                  </Typography>
                )
              }
            },
            {
              title: 'Orders',
              field: 'orders',
              alignType: 'currency',
              render: (data) => {
                return (
                  <>
                    <Typography variant="subtitle2">{formatNumber(get(data, 'orders', 0), { maxFractionDigits: 0 })}</Typography>
                  </>
                )
              }
            },
            {
              title: 'Sales',
              field: 'sales',
              alignType: 'currency',
              render: (data) => {
                return (
                  <>
                    <Typography variant="subtitle2">{formatCurrency(get(data, 'sales', 0), { maxFractionDigits: 2 })}</Typography>
                  </>
                )
              }
            },
            {
              title: 'Cost',
              field: 'cost',
              alignType: 'currency',
              render: (data) => {
                return (
                  <>
                    <Typography variant="subtitle2">{formatCurrency(get(data, 'cost', 0), { maxFractionDigits: 2 })}</Typography>
                  </>
                )
              }
            },
            {
              title: 'Action',
              field: 'action',
              sortable: false,
              render: (data) => {
                if (!data?.stoppable) {
                  return <></>
                }
                setPageHasStopCampaign(true)
                const isLoading = get(data, 'campaignId', undefined) === get(stopCampaignLoading, 'campaignId', undefined)
                return (
                  <Button
                    size="small"
                    disabled={isLoading}
                    onClick={() => {
                      setOpenConfirmDialog(data)
                    }}
                    sx={{
                      bgcolor: 'rgba(241, 84, 18, 0.75)',
                      color: 'white',
                      whiteSpace: 'nowrap',
                      '&:hover': { bgcolor: 'rgba(241, 84, 18, 1)' }
                    }}
                    startIcon={
                      isLoading && (
                        <CircularProgress
                          size="14px"
                          color="secondary"
                        />
                      )
                    }>
                    STOP CAMPAIGN
                  </Button>
                )
              }
            }
          ]}
          options={{
            stickyHeader: headerHeightPx,
            export: true,
            sortable: true,
            showOrderBy: true,
            initialOrderBy: ascending ? 'ascending' : 'descending',
            pagination: true,
            totalPaginatedDataLength: get(data, 'max_rows', undefined),
            extraToolbarComponent: (
              <>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="center"
                  sx={{ height: '40px' }}>
                  <FormControlLabel
                    value="low_performing"
                    control={
                      <IosSwitch
                        icon={
                          <Box
                            sx={{
                              width: 14,
                              height: 14,
                              borderRadius: '50%',
                              backgroundColor: '#fff'
                            }}
                          />
                        }
                        checkedIcon={
                          <Box
                            sx={{
                              width: 14,
                              height: 14,
                              borderRadius: '50%',
                              backgroundColor: '#fff'
                            }}
                          />
                        }
                        sx={{ height: '18px', width: '34px' }}
                      />
                    }
                    label={
                      <Typography
                        fontSize="12px"
                        fontWeight={600}
                        color="#000"
                        sx={{ mr: '5px' }}>
                        Low Performing Campaigns
                      </Typography>
                    }
                    labelPlacement="start"
                    checked={showLowPerformingCampaign}
                    onChange={(e, checked) => {
                      setShowLowPerformingCampaign(checked)
                    }}
                    sx={{ m: 0 }}
                  />
                </Stack>
                {campaignTypes.length > 0 && (
                  <SingleSelect
                    value={selectedCampaignType}
                    onChange={(val) => {
                      setSelectedCampaignType(val)
                    }}
                    options={[
                      {
                        label: 'All',
                        value: ''
                      },
                      ...campaignTypes.map((e) => {
                        return {
                          label: e.replaceAll('_', ' '),
                          value: e
                        }
                      })
                    ]}
                    selectButtonSx={{
                      height: '40px',
                      width: '140px',
                      bgcolor: '#F6f6f6',
                      color: '#000',
                      '&:hover': {
                        bgcolor: '#EEEEEE'
                      },
                      borderRadius: '4px',
                      px: '16px',
                      py: '5px'
                    }}
                    renderValue={(value) => {
                      const val = value || 'Campaign Type'
                      return (
                        <Typography
                          fontSize="12px"
                          fontWeight={600}
                          sx={{
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis'
                          }}>
                          {val}
                        </Typography>
                      )
                    }}
                  />
                )}
              </>
            )
          }}
          onExportCSV={() => {
            exportCSV()
          }}
          onOrderByChange={(type) => {
            setAscending(type === 'ascending')
          }}
          onSortByChange={(option) => {
            setOrderBy(option.value)
          }}
          onLastPage={() => {
            const nextOffset = get(data, 'next_offset', undefined)
            if (nextOffset) {
              getData({ offset: nextOffset, order_by: orderBy, ascending }, (res) => {
                setData((prev) => ({
                  ...res,
                  data: [...get(prev, 'data', []), ...get(res, 'data', [])]
                }))
              })
            }
          }}
        />
      </Box>
    </>
  )
}
