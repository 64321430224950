import { usePrivateLayoutDrawerContext } from 'src/context/PrivateLayoutDrawerContext'
import RefreshStatus from 'src/layout/components/RefreshStatus'

export default function SideBarRefreshStatus({ closeDrawer }: { closeDrawer: () => void }) {
  const { open } = usePrivateLayoutDrawerContext()
  return (
    <>
      <RefreshStatus
        open={open}
        to="/data-refresh-status"
        closeDrawer={closeDrawer}
        // isVisible={false}
      />
    </>
  )
}
