import LaunchIcon from '@mui/icons-material/Launch'
import { Box, Link, Skeleton, Stack, Typography } from '@mui/material'
import PlatformLogo from 'src/components/PlatformLogo'

interface OrderLinkProps {
  loading?: boolean
  link?: string
  platform?: string
}

export default function OrderLink(props: OrderLinkProps) {
  const { loading, link, platform } = props

  if (!loading && !link && !platform) {
    return <></>
  }

  return (
    <Link
      href={link}
      target="_blank"
      rel="noopener noreferrer"
      sx={{ pointerEvents: loading || !link ? 'none' : 'auto', display: 'flex', textDecoration: 'none' }}>
      <Stack
        direction="row"
        alignItems="center"
        gap="10px"
        sx={{ p: '10px', borderRadius: '2px', bgcolor: 'rgba(242, 242, 242, 1)', minWidth: '250px', maxWidth: '350px' }}>
        <Box>
          {loading ? (
            <Skeleton
              variant="rounded"
              width="15px"
              height="15px"
            />
          ) : (
            <PlatformLogo
              shortImg
              platformName={platform}
              width="15px"
              height="15px"
              boxSx={{ display: 'flex' }}
            />
          )}
        </Box>
        {loading ? (
          <Skeleton
            variant="text"
            sx={{ flex: 1 }}
          />
        ) : (
          <Typography
            fontSize="14px"
            lineHeight={1}
            sx={{ color: '#000', flex: 1, overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
            {link}
          </Typography>
        )}
        <LaunchIcon sx={{ fontSize: '16px', color: '#000' }} />
      </Stack>
    </Link>
  )
}
