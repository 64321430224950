import { useMediaQuery, useTheme } from '@mui/material'
import { collection, doc, onSnapshot, query } from 'firebase/firestore'
import { find, get, uniq } from 'lodash'
import React from 'react'
import { RouteObject } from 'react-router-dom'
import db from 'src/services/firebaseDb'
import { AccessLevelEnum } from 'src/utils/config/config'
import { getAccessLevel } from 'src/utils/functions/accessLevel'
import { useAuth } from './AuthContext'
import { useErrorData } from './ErrorContext'

interface PrivateLayoutDrawerContextInterface {
  theme: any
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  routes: any[]
  routeLoading: boolean
  routeCategories: any[]
  categoryLoading: boolean
  categoryOrder: string[]
  toggleDrawer: () => void
  privateDrawerChildren?: React.ReactNode
  setPrivateDrawerChildren: React.Dispatch<React.SetStateAction<React.ReactNode>>
  privateRoutes: RouteObject[]
  setPrivateRoutes: React.Dispatch<React.SetStateAction<RouteObject[]>>
}

const PrivateLayoutDrawerContext = React.createContext({} as PrivateLayoutDrawerContextInterface)

export const usePrivateLayoutDrawerContext = () => React.useContext(PrivateLayoutDrawerContext)

interface PrivateLayoutDrawerContextProviderProps {
  children: React.ReactNode
}

export default function PrivateLayoutDrawerContextProvider(props: PrivateLayoutDrawerContextProviderProps) {
  const theme: any = useTheme()
  const { currentUser, orgConfig, globalRoutes } = useAuth()
  const { handleError } = useErrorData()
  const [open, setOpen] = React.useState(true)
  const [routeLoading, setRouteLoading] = React.useState(false)
  const [routes, setRoutes] = React.useState([])
  const [categoryLoading, setCategoryLoading] = React.useState(true)
  const [routeCategories, setRouteCategories] = React.useState([])
  const [categoryOrder, setCategoryOrder] = React.useState([])
  const [privateDrawerChildren, setPrivateDrawerChildren] = React.useState<React.ReactNode>()
  const [privateRoutes, setPrivateRoutes] = React.useState<PrivateLayoutDrawerContextInterface['privateRoutes']>([])
  const sm = useMediaQuery(theme.breakpoints.down('md'))

  const toggleDrawer = () => setOpen(!open)

  React.useEffect(() => {
    if (sm === true) setOpen(false)
    if (sm === false) setOpen(true)
  }, [sm])

  const getRouteObject = (uid, routes) => {
    const route = find(routes, { uid })
    return route
  }

  React.useEffect(() => {
    const unsubscribeCategories = onSnapshot(query(collection(db, 'route_categories')), (snapshot) => {
      setCategoryLoading(true)
      const categories = snapshot.docs.map((doc) => {
        return { ...doc.data() }
      })
      setRouteCategories(categories)
      setCategoryLoading(false)
    })

    const unsubscribeCategoryOrder = onSnapshot(doc(db, 'configs/route_categories'), (snapshot) => {
      setCategoryLoading(true)
      const categories = snapshot.data()?.order || []
      setCategoryOrder(categories)
      setCategoryLoading(false)
    })

    return () => {
      unsubscribeCategories()
      unsubscribeCategoryOrder()
    }
  }, [])

  React.useEffect(() => {
    async function fetchOrgConfigOrder() {
      try {
        setRouteLoading(true)
        const tempLevel = getAccessLevel(currentUser)
        const accessLevel = Array.isArray(tempLevel) ? tempLevel[0] : tempLevel
        let tempAccessLevel = Object.keys(orgConfig?.access_levels || {}).includes(accessLevel) ? accessLevel : [AccessLevelEnum.BUSINESS_ADMIN]

        let tempRoutes = []
        tempAccessLevel = Array.isArray(tempAccessLevel) ? tempAccessLevel[0] : tempAccessLevel
        if (tempAccessLevel != AccessLevelEnum.DEMO) {
          tempRoutes = get(orgConfig, `access_levels.${Array.isArray(tempAccessLevel) ? tempAccessLevel[0] : tempAccessLevel}.routes`, [])
        } else {
          tempRoutes = get(orgConfig, `access_levels.${AccessLevelEnum.BUSINESS_ADMIN}.routes`, [])
        }
        tempRoutes = tempRoutes.map((item) => {
          if (typeof item === 'string') {
            return getRouteObject(item, globalRoutes)
          } else if (typeof item === 'object') {
            return {
              ...getRouteObject(item.uid, globalRoutes),
              hidden: true
            }
          }
          return item
        })
        const uniqRoutes = uniq(tempRoutes)
        setRoutes(uniqRoutes.filter((item) => item !== undefined))
        setRouteLoading(false)
      } catch (error) {
        setRouteLoading(false)
        handleError(error.message)
      }
    }
    if (currentUser) fetchOrgConfigOrder()
  }, [currentUser, orgConfig, globalRoutes])

  const contextValue = React.useMemo(() => {
    return {
      theme,
      open,
      setOpen,
      routes,
      routeLoading,
      routeCategories,
      categoryOrder,
      categoryLoading,
      toggleDrawer,
      privateDrawerChildren,
      setPrivateDrawerChildren,
      privateRoutes,
      setPrivateRoutes
    }
  }, [routeLoading, routes, categoryLoading, routeCategories, categoryOrder, theme, open, privateDrawerChildren, privateRoutes])

  return <PrivateLayoutDrawerContext.Provider value={contextValue}>{props.children}</PrivateLayoutDrawerContext.Provider>
}
