import MuseumIcon from '@mui/icons-material/Museum'
import Person4Icon from '@mui/icons-material/Person4'
import WorkspacesIcon from '@mui/icons-material/Workspaces'
import { SvgIcon } from '@mui/material'
import { get, isEqual, sortBy } from 'lodash'
import moment, { Moment } from 'moment'
import { FilterGraph } from 'src/context/NewFilterContext/utils/FilterGraph'
import { BusinessRefreshBnameLevelData } from 'src/context/NewFilterContext/utils/types'
import { filterNames } from 'src/utils/config/config'
import { shouldBlockForUser } from 'src/utils/functions/sandbox'
import { NewFilterComponentProps } from '../NewFilterComponent'

const filterMetadata: {
  [key: string]: { name: string; icon: JSX.Element }
} = {
  b_name: {
    name: filterNames.b_name,
    icon: <MuseumIcon sx={{ width: '12px', height: '12px' }} />
  },
  vb_name: {
    name: filterNames.vb_name,
    icon: <WorkspacesIcon sx={{ width: '12px', height: '12px' }} />
  },
  vb_platform: {
    name: filterNames.vb_platform,
    icon: (
      <SvgIcon sx={{ width: '12px', height: '12px' }}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="13"
          height="12"
          viewBox="0 0 13 12"
          fill="none">
          <path
            d="M5.73496 7.73711H6.93496V5.93711H8.73496V4.73711H6.93496V2.93711H5.73496V4.73711H3.93496V5.93711H5.73496V7.73711ZM3.93496 11.3371V10.1371H1.53496C1.20496 10.1371 0.922561 10.0197 0.687761 9.78491C0.452561 9.54971 0.334961 9.26711 0.334961 8.93711V1.73711C0.334961 1.40711 0.452561 1.12451 0.687761 0.889309C0.922561 0.654509 1.20496 0.537109 1.53496 0.537109H11.135C11.465 0.537109 11.7476 0.654509 11.9828 0.889309C12.2176 1.12451 12.335 1.40711 12.335 1.73711V8.93711C12.335 9.26711 12.2176 9.54971 11.9828 9.78491C11.7476 10.0197 11.465 10.1371 11.135 10.1371H8.73496V11.3371H3.93496Z"
            fill="black"
          />
        </svg>
      </SvgIcon>
    )
  },
  am_name: {
    name: filterNames.am_name,
    icon: <Person4Icon sx={{ width: '12px', height: '12px' }} />
  },
  b_name_id: {
    name: 'b_name_id',
    icon: null
  }
}

export const getFilterKeys = () => {
  return Object.keys(filterMetadata)
}

export const getFilterName = (key: string) => {
  if (key in filterMetadata) return filterMetadata[key].name
  else return ''
}

export const getFilterIcon = (key: string) => {
  const icon = get(filterMetadata, `[${key}].icon`, null)
  return icon ? icon : <></>
}

// * returns true if params are absent or unchanged, i.e. existing graph instance can be used
export const compareGraphParams = (
  params: { [paramKey: string]: any },
  singleSelectFilterKeys?: NewFilterComponentProps['singleSelectFilterKeys'],
  allowedFilters?: NewFilterComponentProps['allowedFilters']
): boolean => {
  // no params previously saved
  if (!params || Object.keys(params).length === 0) {
    if (singleSelectFilterKeys && singleSelectFilterKeys.length > 0) return false
    if (allowedFilters && Object.keys(allowedFilters).length > 0) {
      let emptyAllowedFilters = true
      Object.keys(allowedFilters).forEach((key) => {
        if (emptyAllowedFilters === false) return
        emptyAllowedFilters = emptyAllowedFilters && allowedFilters[key].length === 0
      })
      return emptyAllowedFilters
    }
    return true
  }
  // some params previously saved
  if (params.singleSelectFilterKeys) {
    if (singleSelectFilterKeys) {
      if (isEqual(sortBy(params.singleSelectFilterKeys), sortBy(singleSelectFilterKeys)) === false) return false
    } else if (params.singleSelectFilterKeys.length > 0) return false
  }
  if (params.allowedFilters) {
    if (allowedFilters) {
      if (Object.keys(params.allowedFilters).length !== Object.keys(allowedFilters).length) return false

      let result = true
      Object.keys(params.allowedFilters).forEach((key) => {
        if (result === false) return
        if (!(key in allowedFilters)) {
          result = false
          return
        }
        result = result && isEqual(sortBy(params.allowedFilters[key]), sortBy(allowedFilters[key]))
      })
      if (!result) return false
    } else if (Object.keys(params.allowedFilters).length > 0) return false
  }

  return true
}

export const getNewLastDate = (businessRefreshData: BusinessRefreshBnameLevelData[], activeGraph: FilterGraph): Moment => {
  const currentUser = JSON.parse(localStorage.getItem('data') || '{}')
  if (shouldBlockForUser(currentUser)) return moment('2024-01-15')
  if (businessRefreshData && businessRefreshData.length) {
    const visibleSelectedBnames = Array.from(activeGraph.getVisibleSelectedIntersectionNodes('b_name')).map((node) => node.value)

    const lastSuccessDates =
      visibleSelectedBnames.length === 0
        ? businessRefreshData.map((bnameRefreshData) => moment(bnameRefreshData.lastDate))
        : businessRefreshData.filter((bname) => visibleSelectedBnames.includes(bname.b_name)).map((bnameRefreshData) => moment(bnameRefreshData.lastDate))
    lastSuccessDates.sort((a, b) => (a.isBefore(b) ? -1 : 1))

    const index20thPercentile = Math.ceil(0.2 * lastSuccessDates.length)
    const p20Date = lastSuccessDates[index20thPercentile - 1]

    return p20Date
  }
  return moment()
}

export const updateDateRangeFromLastDate = (activeGraph: FilterGraph, newLastDate: Moment | undefined, maintainIntervalLength?: boolean) => {
  const dateRange = activeGraph.getDateRange()
  if (newLastDate && dateRange.end.isAfter(newLastDate)) {
    const end = newLastDate
    const start = maintainIntervalLength
      ? end.clone().subtract(Math.abs(dateRange.end.diff(dateRange.start, 'days')), 'days')
      : dateRange.start.isAfter(newLastDate)
      ? newLastDate
      : dateRange.start

    activeGraph.setDateRange({
      start,
      end
    })
  }
}
