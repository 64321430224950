import { intersection } from 'lodash'
import React from 'react'
import { NewFilterComponentProps } from 'src/components/NewFilterComponent/NewFilterComponent'
import { useNewFilterContext } from './NewFilterContext/NewFilterContext'

interface TopbarContextInterface {
  title: string | React.ReactNode
  setTitle: React.Dispatch<React.SetStateAction<React.ReactNode>>
  copyUrl: boolean
  setCopyUrl: React.Dispatch<React.SetStateAction<boolean>>
  hideFilters: boolean
  setHideFilters: React.Dispatch<React.SetStateAction<boolean>>
  filterProps: NewFilterComponentProps
  setFilterProps: React.Dispatch<React.SetStateAction<NewFilterComponentProps>>
}

const TopbarContext = React.createContext({} as TopbarContextInterface)

export const useTopbarContext = () => React.useContext(TopbarContext)

interface TopbarContextProviderProps {
  children: React.ReactNode
}

export default function TopbarContextProvider(props: TopbarContextProviderProps) {
  const { setActiveGraphId } = useNewFilterContext()

  const [title, setTitle] = React.useState<string | React.ReactNode>()
  const [copyUrl, setCopyUrl] = React.useState<boolean>(true)
  const [hideFilters, setHideFilters] = React.useState<boolean>(true)
  const [filterProps, setFilterProps] = React.useState<NewFilterComponentProps>({})

  const specialParamKeys = ['singleSelectFilterKeys', 'allowedFilters', 'independentFilter']

  React.useLayoutEffect(() => {
    const specialKeysPresent = intersection(specialParamKeys, Object.keys(filterProps))
    if (specialKeysPresent.length === 0) setActiveGraphId('main')
  }, [filterProps])

  const contextValue = React.useMemo(() => {
    return {
      title,
      setTitle,
      copyUrl,
      setCopyUrl,
      hideFilters,
      setHideFilters,
      filterProps,
      setFilterProps
    }
  }, [title, copyUrl, hideFilters, filterProps])

  return <TopbarContext.Provider value={contextValue}>{props.children}</TopbarContext.Provider>
}
