import { getFilterKeys } from 'src/components/NewFilterComponent/utils/filterUtils'

const filterKeys: string[] = getFilterKeys()

export class FilterNode {
  value: string | number
  key: string
  relations: {
    [key: string]: Map<string | number, { node: FilterNode; slugs: Set<string> }>
  }

  constructor(value: string | number, key: string) {
    this.value = value
    this.key = key
    // * not hardcoding the keys to allow filters to be added/removed in future
    this.relations = {}
    filterKeys.forEach((filterKey) => {
      if (filterKey !== this.key) this.relations[filterKey] = new Map()
    })
  }
}
