import { SvgIcon, SvgIconProps } from '@mui/material'

export default function DiamondCheckedIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg
        width="31"
        height="31"
        viewBox="0 0 31 31"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <rect
          x="0.69043"
          y="15.5193"
          width="21.2132"
          height="21.2132"
          rx="5.48935"
          transform="rotate(-45 0.69043 15.5193)"
          fill="#0E8C43"
          fillOpacity="0.21"
        />
        <rect
          x="4.79541"
          y="15.5194"
          width="15.4083"
          height="15.4083"
          rx="7.70416"
          transform="rotate(-45 4.79541 15.5194)"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.7485 12.3812C20.0262 12.6589 20.0262 13.1091 19.7485 13.3868L14.2681 18.8671L11.6321 16.2311C11.3544 15.9534 11.3544 15.5032 11.6321 15.2255C11.9098 14.9478 12.36 14.9478 12.6377 15.2255L14.2681 16.8559L18.7429 12.3812C19.0206 12.1035 19.4708 12.1035 19.7485 12.3812Z"
          fill="#0E8C43"
        />
      </svg>
    </SvgIcon>
  )
}
