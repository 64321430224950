import React from 'react'
import { useSearchParams } from 'react-router-dom'

export default function useTabUrlState(key: string, initialValue: string): [string, React.Dispatch<React.SetStateAction<string>>]

export default function useTabUrlState(key: string, initialValue: number): [number, React.Dispatch<React.SetStateAction<number>>]

export default function useTabUrlState<T>(key: string, initialValue: T): [T, React.Dispatch<React.SetStateAction<T>>]

export default function useTabUrlState<T>(key: string, initialValue: T | string | number) {
  const keyRef = React.useRef(`${key.split(' ').join('_')}`)
  const [searchParams, setSearchParams] = useSearchParams()

  const [tabValue, setTabValue] = React.useState(() => {
    const url = new URL(window.location.href)
    const hasKey = url.searchParams.has(keyRef.current)
    if (hasKey) {
      let value: string | null = null
      try {
        value = JSON.parse(url.searchParams.get(keyRef.current))
      } catch {
        value = String(url.searchParams.get(keyRef.current))
      }
      const type = typeof initialValue
      if (type === 'number') {
        const result = Number(value)
        return result
      } else {
        const result: typeof initialValue = value
        return result
      }
    } else {
      return initialValue
    }
  })

  React.useEffect(() => {
    setSearchParams(
      (searchParams) => {
        if (tabValue === undefined || tabValue === null) {
          searchParams.delete(keyRef.current)
        } else {
          searchParams.set(keyRef.current, String(tabValue))
        }
        // console.log("mount search params: ", searchParams, keyRef.current);
        return searchParams
      },
      { replace: true }
    )
  }, [tabValue])

  React.useEffect(() => {
    return () => {
      // setSearchParams((searchParams) => {
      //     searchParams.delete(keyRef.current);
      //     return searchParams;
      // }, { replace: true })
      const url = new URL(window.location.href)
      url.searchParams.delete(keyRef.current)
      window.history.replaceState({}, '', url)
    }
  }, [])

  return [tabValue, setTabValue]
}
