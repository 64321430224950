import { Add, Close, NotificationsPaused, Remove } from '@mui/icons-material'
import { Box, Button, ClickAwayListener, IconButton, Paper, Popper, TextField, Tooltip, Typography } from '@mui/material'
import React from 'react'
import { filterNames } from 'src/utils/config/config'

interface SnoozeTillProps {
  formattedSnoozeTill: string | null
  loading: boolean
  handleSnoozeTill: (snoozeHours: number) => void
  disabled?: boolean
}

const SnoozeTill = (props: SnoozeTillProps) => {
  const { formattedSnoozeTill, loading, handleSnoozeTill, disabled } = props
  const [snoozeHours, setSnoozeHours] = React.useState<number>(0)
  const [popperOpen, setPopperOpen] = React.useState<boolean>(false)
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)

  React.useEffect(() => {
    if (!popperOpen) setSnoozeHours(0)
  }, [popperOpen])

  return (
    <ClickAwayListener
      onClickAway={() => {
        setPopperOpen(false)
      }}>
      <div>
        {popperOpen ? (
          <Popper
            open={popperOpen}
            anchorEl={anchorEl}
            placement="bottom-end"
            disablePortal
            sx={{
              '&.MuiPopper-root': {
                zIndex: 10
              }
            }}>
            <Paper
              sx={{
                padding: '8px 12px',
                border: '1px solid #E6E6E6'
              }}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'end',
                  gap: 1
                }}>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center'
                  }}>
                  <Typography
                    fontSize={12}
                    color={'#000'}
                    mr={1}>
                    Snooze for next hours
                  </Typography>
                  <IconButton
                    disabled={snoozeHours === 0}
                    size="small"
                    onClick={() => {
                      setSnoozeHours((prev) => {
                        return prev - 1
                      })
                    }}
                    sx={{
                      borderRadius: '4px'
                    }}>
                    <Remove fontSize="small" />
                  </IconButton>
                  <TextField
                    type="number"
                    value={snoozeHours}
                    size="small"
                    onChange={(e) => {
                      if (e.target.value === '') return
                      const val = parseInt(e.target.value)
                      if (val < 0 || val > 9999) return
                      setSnoozeHours(parseInt(e.target.value))
                    }}
                    sx={{
                      '& input[type=number]': {
                        MozAppearance: 'textfield',
                        textAlign: 'center',
                        padding: '2px 0px'
                      },
                      '& input[type=number]::-webkit-inner-spin-button, & input[type=number]::-webkit-outer-spin-button': {
                        WebkitAppearance: 'none',
                        margin: 0
                      },
                      width: '50px'
                    }}
                  />
                  <IconButton
                    disabled={snoozeHours === 9999}
                    size="small"
                    onClick={() => {
                      setSnoozeHours((prev) => {
                        return prev + 1
                      })
                    }}
                    sx={{
                      borderRadius: '4px'
                    }}>
                    <Add fontSize="small" />
                  </IconButton>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1
                  }}>
                  <Button
                    size="small"
                    variant="text"
                    disableElevation
                    onClick={() => {
                      setPopperOpen(false)
                      setAnchorEl(null)
                    }}>
                    Cancel
                  </Button>
                  <Button
                    size="small"
                    disableElevation
                    disabled={snoozeHours === 0}
                    onClick={() => {
                      setPopperOpen(false)
                      setAnchorEl(null)
                      handleSnoozeTill(snoozeHours)
                    }}>
                    Save
                  </Button>
                </Box>
              </Box>
            </Paper>
          </Popper>
        ) : null}
        {formattedSnoozeTill ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              gap: 1
            }}>
            <Typography
              fontSize={12}
              color={'#000'}>
              Snoozed till{' '}
              <span
                style={{
                  fontWeight: 600,
                  marginLeft: '5px'
                }}>
                {formattedSnoozeTill}
              </span>
            </Typography>
            <Tooltip title="Remove Snooze">
              <IconButton
                disabled={disabled}
                onClick={() => {
                  handleSnoozeTill(-1)
                }}>
                <Close
                  sx={{
                    color: 'red'
                  }}
                />
              </IconButton>
            </Tooltip>
          </Box>
        ) : (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 1
            }}>
            <Button
              variant="text"
              disabled={disabled || loading}
              startIcon={<NotificationsPaused />}
              onClick={(e) => {
                setAnchorEl(e.currentTarget)
                setPopperOpen(true)
              }}
              sx={{
                '&, &.Mui-disabled': {
                  borderRadius: '2px',
                  backgroundColor: '#f3f3f3',
                  color: '#000',
                  fontSize: '12px',
                  fontWeight: 400,
                  padding: '8px 10px'
                },
                '& .MuiButton-startIcon': {
                  marginLeft: 0
                },
                '&:hover': {
                  backgroundColor: '#e8e8e8',
                  color: '#000'
                }
              }}>
              Snooze {filterNames.b_name}
            </Button>
          </Box>
        )}
      </div>
    </ClickAwayListener>
  )
}

export default SnoozeTill
