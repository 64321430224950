import { SvgIcon, SvgIconProps } from '@mui/material'

export default function LineStartDiamond(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg
        width="33"
        height="33"
        viewBox="0 0 33 33"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <mask
          id="mask0_3_8760"
          style={{ maskType: 'alpha' }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="33"
          height="33">
          <rect
            x="0.46582"
            y="3.05176e-05"
            width="32.2598"
            height="32.2598"
            fill="#D9D9D9"
          />
        </mask>
        <g mask="url(#mask0_3_8760)">
          <path
            d="M13.9776 24.1248C13.1966 24.9058 11.9302 24.9058 11.1492 24.1248L4.56851 17.5441C3.78746 16.7631 3.78746 15.4967 4.56851 14.7157L11.1492 8.13503C11.9302 7.35398 13.1966 7.35398 13.9776 8.13503L20.0426 14.2C20.4176 14.575 20.9263 14.7858 21.4568 14.7858H28.6933C29.4356 14.7858 30.0374 15.3876 30.0374 16.1299C30.0374 16.8723 29.4356 17.4741 28.6933 17.4741H21.4568C20.9263 17.4741 20.4176 17.6848 20.0426 18.0599L13.9776 24.1248Z"
            fill="#1C1B1F"
          />
        </g>
      </svg>
    </SvgIcon>
  )
}
