import DigitalStoresStyledComp from './DigitalStoresStyledComp'
import DynamicIconStyledComp from './DynamicIconStyledComp'
import PlatformStyledComp from './PlatformStyledComp'
import RatingStyledComp from './RatingStyledComp'

const defaultStyledColumns = {
  b_name: DynamicIconStyledComp('b_name'),
  vb_name: DynamicIconStyledComp('vb_name'),
  vb_platform: PlatformStyledComp,
  rating_numerator: RatingStyledComp,
  slug: DigitalStoresStyledComp
}

export default defaultStyledColumns
