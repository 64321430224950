import { SvgIcon, SvgIconProps } from '@mui/material'

export default function RealStateIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg
        width="21"
        height="22"
        viewBox="0 0 21 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13.5 7H14.5V6H13.5V7ZM11.5 7H12.5V6H11.5V7ZM13 21.5L6 19.55V10H7.95L14.15 12.3C14.7 12.5 15.1458 12.85 15.4875 13.35C15.8292 13.85 16 14.4 16 15H14C13.3 15 12.75 14.975 12.35 14.925C11.95 14.875 11.6 14.8 11.3 14.7L9.3 14.05L9 15L10.575 15.575C11.0417 15.7583 11.4667 15.875 11.85 15.925C12.2333 15.975 12.6833 16 13.2 16H18C19.1 16 19.875 16.1792 20.325 16.5375C20.775 16.8958 21 17.3833 21 18V19L13 21.5ZM0 21V10H4V21H0ZM17.525 13C17.2917 12.45 16.9583 11.9667 16.525 11.55C16.0917 11.1333 15.5583 10.8083 14.925 10.575L8 8H6V5.5L13 0.5L20 5.5V13H17.525ZM13.5 9H14.5V8H13.5V9ZM11.5 9H12.5V8H11.5V9Z"
          fill="#1C1B1F"
        />
      </svg>
    </SvgIcon>
  )
}
