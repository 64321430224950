import { Container } from '@LoopKitchen/loop-ui'
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet'
import AdsClickIcon from '@mui/icons-material/AdsClick'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'
import DvrIcon from '@mui/icons-material/Dvr'
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn'
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket'
import { Box, Grid, Stack, Typography } from '@mui/material'
import { get } from 'lodash'
import React from 'react'
import CircularLoader from 'src/components/CircularLoader'
import PlatformLogo from 'src/components/PlatformLogo'
import { useTabPanelContext } from 'src/components/TabPanel'
import { useFilter } from 'src/context/FilterContext'
import { useSnackData } from 'src/context/SnackContext'
import {
  DefaultService,
  MarketingKPIDatewise,
  MarketingKPISummaryModel,
  MarketingMetricAggregateV2,
  SalesDatewise,
  SalesSummaryModel,
  ThirdPartyFeeSummaryModel
} from 'src/services/openApi'
import { formatCurrency, formatNumber } from 'src/utils/functions'
import RealStateIcon from '../Icons/RealStateIcon'
import Chart from '../components/Chart'
import ThreePFeeChart from '../components/Charts/ThreePFeeChart'
import Metrics from '../components/visibility/Metrics/Metrics'

export default function Overview() {
  const { tabFocused } = useTabPanelContext()
  const { openError } = useSnackData()
  const { getFilters, getFiltersV2 } = useFilter()
  const [metricLoading, setMetricLoading] = React.useState(false)
  const [metricData, setMetricData] = React.useState<MarketingMetricAggregateV2>(null)
  const [marketingLoading, setMarketingLoading] = React.useState(false)
  const [marketingGraphData, setMarketingGraphData] = React.useState<MarketingKPISummaryModel>(null)
  const [salesLoading, setSalesLoading] = React.useState(false)
  const [salesData, setSalesData] = React.useState<SalesSummaryModel>(null)
  const [feeLoading, setFeeLoading] = React.useState(false)
  const [feeData, setFeeData] = React.useState<ThirdPartyFeeSummaryModel>(null)

  const getSalesOrdersRatioData = async () => {
    setMetricLoading(true)
    try {
      const res = await DefaultService.callMarketingMetricV2ApiMarketingMetricV2Post(
        getFiltersV2(['vb_name', 'chain', 'b_name', 'vb_platform', 'am_name', 'start_date', 'end_date'], true)
      )
      setMetricData(res[0])
    } catch (err) {
      if (tabFocused) {
        openError(err.message)
      }
    }
    setMetricLoading(false)
  }

  React.useEffect(() => {
    getSalesOrdersRatioData()
  }, [...getFilters(['vb_name', 'chain', 'b_name', 'vb_platform', 'am_name', 'start_date', 'end_date'])])

  React.useEffect(() => {
    const summaryBody = getFiltersV2(['v_name', 'vb_name', 'b_name', 'am_name', 'start_date', 'end_date', 'chain', 'vb_platform', 'granularity'], true)
    async function getMarketingData() {
      try {
        setMarketingLoading(true)
        // const tableData = await DefaultService.callKpiDashboardMarketingKpiDatewiseApiKpiDashboardMarketingKpiDatewisePost(tableBody)
        const result = await DefaultService.callMarketingKpiSummaryApiMarketingKpiSummaryPost(summaryBody)
        setMarketingGraphData({
          ...result,
          marketing_k_p_i_datewise: result.marketing_k_p_i_datewise.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime())
        })
      } catch (error) {
        openError(error.message)
      }
      setMarketingLoading(false)
    }
    async function getSalesData() {
      try {
        setSalesLoading(true)
        // const tableData = await DefaultService.callKpiDashboardSalesDatewiseApiKpiDashboardSalesDatewisePost(tableBody)
        const result = await DefaultService.callSalesSummaryApiSalesSummaryPost(summaryBody)
        setSalesData({
          ...result,
          sales_datewise: result.sales_datewise.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime())
        })
      } catch (error) {
        openError(error.message)
      }
      setSalesLoading(false)
    }
    async function getFeeData() {
      try {
        setFeeLoading(true)
        // const tableData = await DefaultService.callKpiDashboardThirdPartyFeeDatewiseApiKpiDashboardThirdPartyFeeDatewisePost(tableBody)
        const result = await DefaultService.callThirdPartyFeeSummaryApiThirdPartyFeeSummaryPost(summaryBody)
        setFeeData({
          ...result,
          third_party_fee_datewise: result.third_party_fee_datewise.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime())
        })
      } catch (error) {
        openError(error.message)
      }
      setFeeLoading(false)
    }
    Promise.all([getMarketingData(), getSalesData(), getFeeData()])
  }, [...getFilters(['v_name', 'vb_name', 'b_name', 'am_name', 'start_date', 'end_date', 'chain', 'vb_platform', 'granularity'])])

  return (
    <>
      <Grid
        container
        columns={14}
        spacing={'17px'}>
        <Grid
          item
          md={9}
          sm={14}>
          {metricData === null && metricLoading && <CircularLoader />}
          {metricData === null && !metricLoading && (
            <Typography
              textAlign="center"
              fontSize="12px"
              sx={{ px: '20px', p: '10px' }}>
              Overview data not available
            </Typography>
          )}
          {metricData !== null && (
            <Box
              sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                gap: '10px'
              }}>
              <Container
                title="What %age of my sales is marketing driven"
                icon={
                  <AttachMoneyIcon
                    fontSize="inherit"
                    color="inherit"
                  />
                }
                loading={metricLoading}
                dataCards={[
                  {
                    icon: (
                      <RealStateIcon
                        fontSize="inherit"
                        color="inherit"
                      />
                    ),
                    title: 'Sales',
                    amount: formatCurrency(get(metricData, 'sales_from_marketing', 0), { maxFractionDigits: 1 }),
                    outOfAmount: formatCurrency(get(metricData, 'sales', 0), {
                      maxFractionDigits: 1
                    }),
                    loading: metricLoading,
                    sx: { width: '200px' }
                  },
                  {
                    icon: (
                      <DvrIcon
                        fontSize="inherit"
                        color="inherit"
                      />
                    ),
                    title: 'Orders',
                    amount: formatNumber(get(metricData, 'orders_from_marketing', 0), { maxFractionDigits: 0 }),
                    outOfAmount: formatNumber(get(metricData, 'orders', 0), {
                      maxFractionDigits: 0
                    }),
                    loading: metricLoading,
                    sx: { width: '200px' }
                  },
                  {
                    icon: (
                      <ShoppingBasketIcon
                        fontSize="inherit"
                        color="inherit"
                      />
                    ),
                    title: 'Avg Basket Size',
                    amount: formatCurrency(get(metricData, 'basket_size_marketing', 0), { maxFractionDigits: 1 }),
                    subAmount: `/${formatCurrency(get(metricData, 'basket_size', 0), { maxFractionDigits: 1 })}`,
                    loading: metricLoading,
                    sx: { width: '200px' },
                    tooltip: 'Avg basket size driven from marketing / Avg basket size overall'
                  }
                ]}
              />

              <Container
                info="UberEats Ad fee  is excluded from calculations"
                loading={metricLoading}
                title="What is my marketing spend?"
                icon={
                  <AttachMoneyIcon
                    fontSize="inherit"
                    color="inherit"
                  />
                }
                sx={{ mb: '0px' }}
                dataCards={[
                  {
                    icon: (
                      <AccountBalanceWalletIcon
                        fontSize="inherit"
                        color="inherit"
                      />
                    ),
                    title: 'Dollar spent on marketing',
                    loading: metricLoading,
                    amount: formatCurrency(get(metricData, 'total_marketing_cost', 0), {
                      maxFractionDigits: 2
                    }),
                    sx: { width: '200px' },
                    bottomComponent: (
                      <Box sx={{ mt: 1 }}>
                        <Typography
                          fontSize="10px"
                          fontWeight={600}
                          color="rgba(128, 128, 128, 1)">
                          Order Attributed:{' '}
                          <span style={{ color: '#000' }}>
                            {formatCurrency(get(metricData, 'total_marketing_cost', 0) - get(metricData, 'unattributed_marketing_cost', 0), {
                              maxFractionDigits: 2
                            })}
                          </span>
                          <br />
                          Unattributed:{' '}
                          <span style={{ color: '#000' }}>{formatCurrency(get(metricData, 'unattributed_marketing_cost', 0), { maxFractionDigits: 2 })}</span>
                        </Typography>
                      </Box>
                    )
                  },
                  {
                    icon: (
                      <AccountBalanceWalletIcon
                        fontSize="inherit"
                        color="inherit"
                      />
                    ),
                    title: 'Avg Marketing spend/order',
                    loading: metricLoading,
                    amount: formatCurrency(get(metricData, 'marketing_spend_per_order', 0), { maxFractionDigits: 1 }),
                    sx: { width: '200px' }
                  },
                  {
                    icon: (
                      <KeyboardReturnIcon
                        fontSize="inherit"
                        color="inherit"
                      />
                    ),
                    title: 'ROI',
                    loading: metricLoading,
                    amount: formatNumber(get(metricData, 'roi', 0), {
                      maxFractionDigits: 1
                    }),
                    sx: { width: '200px' }
                  }
                ]}
              />
            </Box>
          )}
        </Grid>
        <Grid
          item
          md={5}
          sm={14}>
          <Container
            title="Conversion Metrics"
            icon={
              <AdsClickIcon
                fontSize="inherit"
                color="inherit"
              />
            }>
            <Box>
              <Stack
                direction="row"
                alignItems="center"
                gap={'8px'}
                sx={{
                  py: '12px',
                  px: '14px',
                  border: '1px solid #E6E6E6',
                  backgroundColor: '#F9F9F9',
                  borderRadius: '2px'
                }}>
                <Box sx={{ flex: 1 }}>
                  <Typography
                    fontSize="12px"
                    fontWeight={400}>
                    This data is only for UberEats and Doordash
                  </Typography>
                </Box>
                <PlatformLogo
                  shortImg
                  platformName="doordash"
                  height="22px"
                  width="22px"
                  boxSx={{ display: 'flex', flex: 'none' }}
                />
                <PlatformLogo
                  shortImg
                  platformName="ubereats"
                  height="22px"
                  width="22px"
                  boxSx={{ display: 'flex', flex: 'none' }}
                />
              </Stack>
            </Box>
            <Box
              sx={{
                p: '18px',
                border: '1px solid #EEEEEE',
                borderRadius: '2px',
                mt: '8px'
              }}>
              <Metrics />
            </Box>
          </Container>
        </Grid>
      </Grid>

      <Grid
        container
        spacing={3}
        sx={{ mt: '15px' }}>
        <Grid
          item
          md={6}
          xs={12}>
          <Chart
            title="Marketing Spend"
            loading={marketingLoading}
            data={marketingGraphData?.marketing_k_p_i_datewise || []}
            series={[
              {
                color: '#3399FF',
                name: 'Marketing Spend',
                data: get(marketingGraphData, 'marketing_k_p_i_datewise', []).map((item: MarketingKPIDatewise) => item.marketing_spend),
                type: 'area'
              }
            ]}
            options={[
              {
                colorType: 'gradient',
                opacity: 0.5,
                yaxis: {
                  seriesName: 'Marketing Spend',
                  labels: {
                    style: {
                      colors: '#3399FF'
                    },
                    formatter: (value) => {
                      return `${formatCurrency(value, {
                        notation: 'compact'
                      })}`
                    }
                  }
                }
              }
            ]}
          />
        </Grid>
        <Grid
          item
          md={6}
          xs={12}>
          <Chart
            title="Marketing Driven Sale"
            loading={marketingLoading}
            data={marketingGraphData?.marketing_k_p_i_datewise || []}
            series={[
              {
                color: '#004DE5',
                name: 'Marketing Driven Sale',
                data: get(marketingGraphData, 'marketing_k_p_i_datewise', []).map((item: MarketingKPIDatewise) => item.marketing_driven_sales),
                type: 'area'
              }
            ]}
            options={[
              {
                colorType: 'gradient',
                opacity: 0.5,
                yaxis: {
                  seriesName: 'Marketing Driven Sale',
                  labels: {
                    style: {
                      colors: '#004DE5'
                    },
                    formatter: (value) => {
                      return `${formatCurrency(value, {
                        notation: 'compact'
                      })}`
                    }
                  }
                }
              }
            ]}
          />
        </Grid>
        <Grid
          item
          md={6}
          xs={12}>
          <Chart
            title="ROI"
            loading={marketingLoading}
            data={marketingGraphData?.marketing_k_p_i_datewise || []}
            series={[
              {
                color: '#FF5733',
                name: 'ROI',
                data: get(marketingGraphData, 'marketing_k_p_i_datewise', []).map((item: MarketingKPIDatewise) => item.roi),
                type: 'area'
              }
            ]}
            options={[
              {
                colorType: 'gradient',
                opacity: 0.5,
                yaxis: {
                  seriesName: 'ROI',
                  labels: {
                    style: {
                      colors: '#FF5733'
                    },
                    formatter: (value) => {
                      return `${formatNumber(value, {
                        notation: 'compact'
                      })}`
                    }
                  }
                }
              }
            ]}
          />
        </Grid>
        <Grid
          item
          md={6}
          xs={12}>
          <Chart
            title="Sales"
            loading={salesLoading}
            data={salesData?.sales_datewise || []}
            series={[
              {
                color: '#004DE5',
                name: 'Sales',
                data: get(salesData, 'sales_datewise', []).map((item: SalesDatewise) => item.sales),
                type: 'area'
              }
            ]}
            options={[
              {
                colorType: 'gradient',
                opacity: 0.5,
                yaxis: {
                  seriesName: 'Sale',
                  labels: {
                    style: {
                      colors: '#004DE5'
                    },
                    formatter: (value) => {
                      return `${formatCurrency(value, {
                        notation: 'compact'
                      })}`
                    }
                  }
                }
              }
            ]}
          />
        </Grid>
        <Grid
          item
          md={6}
          xs={12}>
          <ThreePFeeChart
            data={feeData?.third_party_fee_datewise || []}
            loading={feeLoading}
          />
        </Grid>
      </Grid>
    </>
  )
}
