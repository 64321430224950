import { IosSwitch } from '@LoopKitchen/loop-ui'
import { Box, Checkbox, Icon, Skeleton, Typography } from '@mui/material'
import moment from 'moment'
import React from 'react'
import { StoreAvailabilityBnameConfigRequest, StoreAvailabilityBnameConfigResponse } from 'src/services/openApi'
import SnoozeTill from './SnoozeTill'

interface StoreAvailabilityBnameConfigProps {
  config: StoreAvailabilityBnameConfigResponse
  loading: number[] | null
  checked: boolean
  handleSingleConfigChange: (config: StoreAvailabilityBnameConfigRequest) => void
  handleSelectBnameConfig: (bNameId: number, selected: boolean) => void
  disabled?: boolean
}

const StoreAvailabilityBnameConfig = (props: StoreAvailabilityBnameConfigProps) => {
  const { config, loading, checked, handleSingleConfigChange, handleSelectBnameConfig, disabled } = props

  const formattedSnoozeTill = React.useMemo(() => {
    if (!config.snooze_till) return null
    const snoozeMoment = moment(config.snooze_till + '+00:00')
    if (snoozeMoment.isValid() && snoozeMoment.local().isAfter(moment())) {
      return snoozeMoment.local().format('DD MMM, hh:mm A')
    }
    return null
  }, [config.snooze_till])

  return (
    <tr
      style={{
        borderTop: '1px solid #E0E0E088'
      }}>
      <td
        style={{
          padding: '16px 16px',
          width: '50%'
        }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <Checkbox
            disabled={loading && loading.includes(config.b_name_id)}
            checked={checked}
            onChange={(e) => {
              handleSelectBnameConfig(config.b_name_id, e.target.checked)
            }}
            sx={{
              '& .MuiSvgIcon-root': { fontSize: 16 },
              color: 'rgba(230, 230, 230, 0.90)'
            }}
          />
          <Icon sx={{ fontSize: '16px' }}>museum</Icon>
          <Box>
            {loading && loading.includes(config.b_name_id) ? (
              <>
                <Skeleton
                  variant="text"
                  width={200}
                  height={24}
                />
                <Skeleton
                  variant="text"
                  width={250}
                  height={20}
                />
              </>
            ) : (
              <>
                <Typography
                  fontSize={14}
                  fontWeight={600}
                  color={'#000'}>
                  {config.b_name}
                </Typography>
                <Typography
                  fontSize={12}
                  color={'#8A8A8A'}>
                  {config.address}
                </Typography>
              </>
            )}
          </Box>
        </Box>
      </td>
      <td
        style={{
          padding: '16px 16px'
        }}>
        <IosSwitch
          disabled={(loading && loading.includes(config.b_name_id)) || disabled || formattedSnoozeTill !== null}
          checked={config.auto_turn_on && formattedSnoozeTill === null}
          onChange={(e) => {
            handleSingleConfigChange({
              b_name_id: config.b_name_id,
              auto_turn_on: e.target.checked
            })
          }}
          sx={{
            height: '18px',
            width: '34px',
            mb: 1,
            '& .MuiSwitch-thumb': { height: '14px', width: '14px' },
            '& .MuiSwitch-track': {
              backgroundColor: formattedSnoozeTill !== null && '#ffd76f'
            }
          }}
        />
      </td>
      <td
        style={{
          padding: '16px 16px'
        }}>
        <IosSwitch
          disabled={(loading && loading.includes(config.b_name_id)) || disabled || formattedSnoozeTill !== null}
          checked={config.notify_enabled && formattedSnoozeTill === null}
          onChange={(e) => {
            handleSingleConfigChange({
              b_name_id: config.b_name_id,
              notify_enabled: e.target.checked
            })
          }}
          sx={{
            height: '18px',
            width: '34px',
            mb: 1,
            '& .MuiSwitch-thumb': { height: '14px', width: '14px' },
            '& .MuiSwitch-track': {
              backgroundColor: formattedSnoozeTill !== null && '#ffd76f'
            }
          }}
        />
      </td>
      <td
        style={{
          padding: '16px 16px',
          maxWidth: '200px'
        }}>
        <SnoozeTill
          formattedSnoozeTill={formattedSnoozeTill}
          handleSnoozeTill={(snoozeHours) => {
            handleSingleConfigChange({
              b_name_id: config.b_name_id,
              snooze_till: snoozeHours
            })
          }}
          loading={loading && loading.includes(config.b_name_id)}
          disabled={disabled}
        />
      </td>
    </tr>
  )
}

export default StoreAvailabilityBnameConfig
