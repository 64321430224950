import { Box, Checkbox, Chip, Divider, FormControl, InputLabel, MenuItem, Select, Stack, TextField, Typography } from '@mui/material'
import React from 'react'
import Template from 'src/components/Template'
import { ItemType } from '../Tabs/ErrorsRevenueRecapture'
import { SelectedItemtype } from './ItemLevelRaiseDisputeDialog'
import { useReasonContext } from './ReasonContext'

interface ItemViewProps {
  data: ItemType
  platform: string
  item_level_error: boolean
  hideReasons?: boolean
  onChange: (obj: { type: 'checked' | 'unchecked'; obj: SelectedItemtype }) => void
}

export default function ItemView(props: ItemViewProps) {
  const reasons = useReasonContext()
  const [checked, setChecked] = React.useState(false)
  const [reason, setReason] = React.useState<(typeof reasons)[0]>(null)
  const [description, setDescription] = React.useState('')

  const getObject = () => {
    const obj: SelectedItemtype = { itemID: props.data.id }
    if (reason && reason.reason.length > 0) {
      obj['reason'] = reason.reason
    }
    if (description.length > 0) {
      obj['description'] = description
    }
    return obj
  }

  React.useEffect(() => {
    const obj = getObject()
    props.onChange({
      type: checked ? 'checked' : 'unchecked',
      obj
    })
  }, [checked, reason, description])

  return (
    <Stack
      direction="row"
      alignItems={'flex-start'}
      spacing={2}>
      <Box>
        <Checkbox
          checked={checked}
          onChange={(e, c) => {
            setChecked(c)
          }}
        />
      </Box>
      <Box flex={1}>
        <Typography variant="body1">
          <b>
            {props.data.quantity} x {props.data.name}
          </b>
        </Typography>
        <Chip
          size="small"
          label={<span style={{ textTransform: 'capitalize' }}>{props.data.error.replaceAll('_', ' ')}</span>}
          sx={{ background: '#FFE6E6', color: '#E62E2E', mt: 1 }}
        />
        {!props.hideReasons && (
          <Box
            pr={8}
            mt={2}>
            <FormControl
              fullWidth
              sx={{ mb: 2 }}>
              <InputLabel id="demo-simple-select-label">Choose a reason</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={reason?.reason || ''}
                label="Choose a reason"
                onChange={(e) => {
                  const obj = reasons.find((p) => p.reason === e.target.value)
                  setReason(obj)
                }}
                size="small">
                {reasons
                  .filter((e) => e.platform === props.platform && ((props.item_level_error && e.item_level) || (!props.item_level_error && e.order_level)))
                  .map((e) => {
                    return (
                      <MenuItem
                        key={e.reason}
                        value={e.reason}>
                        <span style={{ textTransform: 'capitalize' }}>{e.reason.split('_').join(' ').toLowerCase()}</span>
                      </MenuItem>
                    )
                  })}
              </Select>
            </FormControl>

            {!!reason && reason.description && (
              <>
                <TextField
                  label="Enter Description"
                  placeholder="Describe why"
                  value={description}
                  onChange={(e) => {
                    setDescription(e.target.value)
                  }}
                  multiline
                  maxRows={3}
                  minRows={3}
                  sx={{ mb: 2 }}
                  size="small"
                />
                {!!reason && !!reason.templates.length && (
                  <>
                    <Stack
                      direction="row"
                      alignItems={'center'}
                      spacing={1}
                      sx={{ my: 2 }}>
                      <Box flex={1}>
                        <Divider />
                      </Box>
                      <Typography variant={'subtitle2'}>OR</Typography>
                      <Box flex={1}>
                        <Divider />
                      </Box>
                    </Stack>

                    <Stack
                      direction="column"
                      spacing={1}
                      maxHeight="200px"
                      overflow={'auto'}>
                      {reason.templates.map((item) => {
                        return (
                          <Template
                            description={item.text}
                            onClick={() => {
                              setDescription(item.text)
                            }}
                          />
                        )
                      })}
                    </Stack>
                  </>
                )}
              </>
            )}
          </Box>
        )}
      </Box>
      <Box>
        <Typography
          variant="subtitle1"
          color={'rgba(241, 84, 18, 1)'}>
          <b>{props.data.display_price}</b>
        </Typography>
      </Box>
    </Stack>
  )
}
