import { TimelineModel } from '@LoopKitchen/typescript-client/lib/clients/FrontendDataService'
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
  timelineOppositeContentClasses
} from '@mui/lab'
import { Skeleton, Stack, Typography } from '@mui/material'
import moment from 'moment'
import DiamondCheckedIcon from 'src/components/Icon/DiamondCheckedIcon'
import LineStartDiamond from 'src/components/Icon/LineStartDiamondIcon'
import HeadingContainer from './helpers/HeadingContainer'

interface TimelineCompProps {
  timelineData?: TimelineModel
  loading?: boolean
}

export default function TimelineComp(props: TimelineCompProps) {
  const {
    timelineData = {
      received_ts: 'Order made by User',
      accepted_ts: 'Order accepted',
      prepared_ts: 'Order Ready',
      dispatch_ts: 'Order hand off',
      delivered_ts: 'Order delivered'
    },
    loading
  } = props
  const color = 'rgba(230, 230, 230, 0.9)'
  const keyMap = {
    received_ts: 'Order made by User',
    accepted_ts: 'Order accepted',
    prepared_ts: 'Order Ready',
    dispatch_ts: 'Order hand off',
    delivered_ts: 'Order delivered'
  }

  return (
    <HeadingContainer
      title="Timeline"
      icon={<LineStartDiamond sx={{ fontSize: '24px', color: '#000' }} />}>
      <Timeline
        sx={{
          [`& .${timelineOppositeContentClasses.root}`]: {
            maxWidth: 150
          },
          p: 0
        }}>
        {timelineData &&
          Object.keys(timelineData)
            .filter((key) => timelineData[key])
            .map((data, index) => (
              <TimelineItem
                sx={{ pl: 0 }}
                key={data}>
                <TimelineOppositeContent
                  color="text.secondary"
                  sx={{
                    pl: 0,
                    textAlign: 'right',
                    display: 'flex',
                    fontSize: 14,
                    fontWeight: 400,
                    pr: 1,
                    justifyContent: 'flex-end',
                    pt: 0
                  }}>
                  <Stack
                    direction="row"
                    alignItems="center"
                    sx={{ height: '30px' }}>
                    {loading ? (
                      <Skeleton
                        variant="text"
                        width="120px"
                        height="20px"
                      />
                    ) : (
                      <>
                        <Typography
                          fontWeight={400}
                          fontSize={'14px'}
                          color="rgba(0,0,0,1)"
                          sx={{ mr: 1 }}>
                          <span style={{ color: 'rgba(0, 0, 0, 0.3)' }}>{timelineData[data] && moment(timelineData[data]).format('DD MMM, ')}</span>{' '}
                          {timelineData[data] ? moment(timelineData[data]).format('hh:mmA') : 'No Data'}
                        </Typography>
                      </>
                    )}
                  </Stack>
                </TimelineOppositeContent>
                <TimelineSeparator>
                  <TimelineDot
                    sx={{
                      background: 'transparent',
                      boxShadow: 'none',
                      p: 0,
                      m: 0
                    }}>
                    <div
                      style={{
                        // width: 18,
                        // height: 18,
                        // borderRadius: '50%',
                        // background: 'rgba(101, 158, 171, 0.3)',
                        color,
                        paddingLeft: 1
                      }}>
                      <DiamondCheckedIcon
                        sx={{ fontSize: '30px' }}
                        color="inherit"
                      />
                    </div>
                  </TimelineDot>
                  {index !== Object.keys(timelineData).filter((key) => timelineData[key]).length - 1 && (
                    <TimelineConnector style={{ color, background: color, height: '35px' }} />
                  )}
                </TimelineSeparator>
                <TimelineContent
                  sx={{
                    fontSize: 14,
                    fontWeight: 400,
                    pt: 0,
                    pl: 1,
                    color: '#000'
                  }}>
                  <Stack
                    direction="row"
                    alignItems="center"
                    sx={{ height: '30px' }}>
                    {keyMap[data]}
                  </Stack>
                </TimelineContent>
              </TimelineItem>
            ))}
      </Timeline>
    </HeadingContainer>
  )
}
