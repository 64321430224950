import Stack from '@mui/material/Stack'
import React from 'react'
import StyledTypo from 'src/components/StyledTypo'
import IOSSwitch from 'src/forms/IosSwitch'

export default function CustomSwitch({ value, setValue }: { value: boolean; setValue: React.Dispatch<React.SetStateAction<boolean>> }) {
  return (
    <Stack
      direction="row"
      sx={{ maxHeight: 20 }}
      spacing={1}
      alignItems="center">
      <StyledTypo
        fs={12}
        fw={400}
        sx={{ lineHeight: 20 }}
        color="rgba(105, 108, 128, 1)">
        Trend
      </StyledTypo>
      <IOSSwitch
        sx={{
          width: 35,
          height: 18,
          '& .MuiSwitch-thumb': {
            width: 14,
            height: 14,
            boxSizing: 'border-box'
          },
          '& .MuiSwitch-track': {
            bgcolor: 'rgba(47, 74, 90, 1)'
          }
        }}
        size="small"
        checked={value}
        onChange={(e, checked) => {
          setValue(checked)
        }}
      />
      <StyledTypo
        fs={12}
        fw={400}
        color="rgba(105, 108, 128, 1)"
        sx={{ lineHeight: 20 }}>
        Breakdown
      </StyledTypo>
    </Stack>
  )
}
