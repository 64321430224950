import LinkIcon from '@mui/icons-material/Link'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import { styled, SxProps } from '@mui/material/styles'
import Tooltip, { tooltipClasses, TooltipProps } from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import React from 'react'
import { useSearchParams } from 'react-router-dom'
import { useSnackData } from 'src/context/SnackContext'

export const StyledBox = styled(Box)(
  ({ borderColor, bgcolor }: { borderColor?: string; bgcolor?: string }) => ({
    padding: 16,
    borderRadius: 8,
    backgroundColor: bgcolor || 'white',
    border: `1px solid ${borderColor || 'rgba(230, 230, 230, 1)'}`
  })
)

export const TableTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    padding: 4,
    fontSize: 16
  },
  [`& .${tooltipClasses.arrow}`]: {
    '&::before': {
      backgroundColor: 'white'
    }
  }
}))

export function SectionLinkBtn({ link }: { link: string }) {
  const { copyToClipboard } = useSnackData()
  return (
    <TableTooltip
      title={
        <Typography variant="body1" textAlign="center">
          Copy section link
        </Typography>
      }
      placement="top-start"
      arrow
    >
      <IconButton
        size="small"
        sx={{
          color: 'rgba(0, 0, 0, 0.3)'
        }}
        onClick={() => copyToClipboard(link)}
      >
        <LinkIcon color="inherit" />
      </IconButton>
    </TableTooltip>
  )
}

interface PropTypes {
  children: React.ReactNode
  id: string
  sx?: SxProps
}

export function SectionLink({ children, id, sx }: PropTypes) {
  // const location = useLocation()
  const [params] = useSearchParams()
  const sectionRef = React.useRef(null)
  React.useEffect(() => {
    const section = params.get('section')
    if (section === id) sectionRef.current.scrollIntoView()
  }, [params, sectionRef, id])
  return (
    <Box sx={sx} id={id} ref={sectionRef}>
      {children}
    </Box>
  )
}
