import { RouteObject } from 'react-router-dom'
import TermsOfService from 'src/pages/public/terms/TermsOfService'

const generalRoutes: RouteObject[] = [
  {
    path: 'terms-of-service',
    element: TermsOfService()
  }
]

export default generalRoutes
