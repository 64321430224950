import { Drawer } from '@LoopKitchen/loop-ui'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace'
import { Box, IconButton, Typography } from '@mui/material'
import { StoreAvailabilityBnameConfigResponse } from 'src/services/openApi'
import StoreConfigurationComp from './StoreConfigurationComp'

interface StoreAvailabilityConfigDrawerProps {
  isDrawerOpen: boolean
  handleCloseDrawer: () => void
  selectedConfigData?: StoreAvailabilityBnameConfigResponse[]
  selectedConfigDataLoading?: number[] | null
}

const StoreAvailabilityConfigDrawer = (props: StoreAvailabilityConfigDrawerProps) => {
  const { isDrawerOpen, handleCloseDrawer, selectedConfigData = null, selectedConfigDataLoading } = props

  return (
    <Drawer
      open={isDrawerOpen}
      anchor="right"
      containerSx={{
        width: `calc(100vw - ${open ? 280 : 60}px)`,
        padding: '30px 30px'
      }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '40px'
        }}>
        <Box sx={{ display: 'flex', gap: '12px', alignItems: 'center' }}>
          <IconButton onClick={handleCloseDrawer}>
            <KeyboardBackspaceIcon sx={{ fontSize: '20px', color: 'black' }} />
          </IconButton>
          <Typography
            fontWeight={600}
            fontSize={'16px'}
            color={'#000'}>
            Configuration
          </Typography>
        </Box>

        {selectedConfigData ? (
          <StoreConfigurationComp
            selectedConfigData={selectedConfigData}
            selectedConfigDataLoading={selectedConfigDataLoading}
          />
        ) : (
          <StoreConfigurationComp />
        )}
      </Box>
    </Drawer>
  )
}

export default StoreAvailabilityConfigDrawer
