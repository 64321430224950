import Box from '@mui/material/Box'
import { styled, SxProps } from '@mui/material/styles'
import Typography, { TypographyProps } from '@mui/material/Typography'
import React from 'react'

const CustomTypo = styled((props) => (
  <Typography
    variant="h3"
    {...props}
  />
))(({ fs, fw, c, lh, color }: { fs?: number; fw?: number; c?: string; lh?: string; color?: string }) => {
  const tempObj = {
    fontSize: fs || 16,
    fontWeight: fw || 600,
    color: c || color || '#000000'
  }
  if (lh) tempObj['lineHeight'] = lh
  return tempObj
})

export function CustomTypoContainer(props: any) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        height: '100%'
      }}>
      <CustomTypo {...props} />
    </Box>
  )
}

export function TypoContainer({ children, sx }: { children: React.ReactNode; sx?: SxProps }) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        textAlign: 'center',
        ...sx
      }}>
      {children}
    </Box>
  )
}

/**
 * Styled Typo props
 * @param {number} [fs] - Font size
 * @param {number} [fw] - Font weight
 * @param {string} [lh] - Line height
 * @param {string} [c] - Color
 */
interface StyledTypoPropTypes extends TypographyProps {
  fs?: number
  fw?: number
  lh?: string
  c?: string
  color?: string
}

export default function StyledTypo(props: StyledTypoPropTypes) {
  return <CustomTypo {...props} />
}
