import {
  AdminService as TSAdminService,
  BnamesService as TSBnamesService,
  DefaultService as TSDefaultService,
  DigitalStoresService as TSDigitalStoresService,
  NotificationsService as TSNotificationsService,
  UsersService as TSUsersService
} from '@LoopKitchen/typescript-client/lib/clients/FrontendDataService'
import { captureException } from '@sentry/react'
import { get } from 'lodash'
import { sendSlackNotification } from 'src/utils/api'
import { blockedApiEndpointsForSandbox } from 'src/utils/config/config'
import { getAccessLevel } from 'src/utils/functions/accessLevel'
import { shouldBlockForUser } from 'src/utils/functions/sandbox'

const restrictedEmails = [process.env.REACT_APP_CYPRESS_REGISTER_EMAIL, process.env.REACT_APP_CYPRESS_LOGIN_EMAIL, process.env.REACT_APP_CYPRESS_APP_EMAIL]

function containsRestrictedEmail(value) {
  if (typeof value === 'string') {
    return restrictedEmails.includes(value)
  }

  if (Array.isArray(value)) {
    return value.some((val) => containsRestrictedEmail(val))
  }

  if (typeof value === 'object' && value) {
    return Object.values(value).some((val) => containsRestrictedEmail(val))
  }

  return false
}

const handler = {
  get: function (target, prop, receiver) {
    const userObject = JSON.parse(localStorage.getItem('data') || '{}')
    if (shouldBlockForUser(userObject) && blockedApiEndpointsForSandbox.includes(prop)) {
      throw new Error('This action is prohibited in demo mode.')
    }

    if (prop === 'prototype') {
      return Reflect.get(target, prop, receiver)
    }
    const origMethod = target[prop]

    return async function (...args) {
      try {
        const result = await origMethod.apply(this, args)
        return result
      } catch (error) {
        const message =
          typeof error.body === 'string'
            ? error.body
            : error.body?.content || error.body?.message || error.body?.error || error.message || 'Something went wrong'

        const userContainsRestrictedEmail = restrictedEmails.includes(userObject.email)
        const argsContainRestrictedEmail = containsRestrictedEmail(args)

        if (!userContainsRestrictedEmail && !argsContainRestrictedEmail) {
          sendSlackNotification({
            title:
              `Error message: \`${message}\`\n` +
              `User: \`<https://us.posthog.com/project/26198/person/${encodeURIComponent(userObject.email)}|${userObject.email}>\`\n` +
              `Page URL: \`<${window.location.href}|${window.location.pathname}>\`\n` +
              `API URL: \`${get(error, 'url', undefined) || get(error, 'config.url', '')}\`\n` +
              `Org: \`${userObject?.org}\`\n` +
              `Access Level: \`${getAccessLevel(userObject)}\``,
            message: `Error occurred while calling API \`${target.name}\`.\`${String(prop)}\``,
            channel: 'frontend-alerts'
          })
          error.message = `Error occurred while calling method ${target.name}.${String(prop)}`
          captureException(error)
        }
        throw new Error(message)
      }
    }
  }
}

const AdminService: typeof TSAdminService = new Proxy(TSAdminService, handler)
const DefaultService: typeof TSDefaultService = new Proxy(TSDefaultService, handler)
const UsersService: typeof TSUsersService = new Proxy(TSUsersService, handler)
const BnamesService: typeof TSBnamesService = new Proxy(TSBnamesService, handler)
const DigitalStoresService: typeof TSDigitalStoresService = new Proxy(TSDigitalStoresService, handler)
const NotificationsService: typeof TSNotificationsService = new Proxy(TSNotificationsService, handler)

export * from '@LoopKitchen/typescript-client/lib/clients/FrontendDataService'
export { AdminService, BnamesService, DefaultService, DigitalStoresService, NotificationsService, UsersService }
