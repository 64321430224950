import { get } from 'lodash'
import moment, { Moment } from 'moment'
import React from 'react'
import allJsonData from 'src/pages/members/DrillDown/utils/jsonConfigs'
import { FiltersObjType } from 'src/pages/members/DrillDown/utils/jsonConfigs/types'
import { BreadcrumbsQueueType } from 'src/types/BreadcrumbsQueueType'
import { FilterGraph } from './NewFilterContext/utils/FilterGraph'
import filterGraphManager from './NewFilterContext/utils/FilterGraphManager'
import { FilterNode } from './NewFilterContext/utils/FilterNode'
import { ApiFilterResponseType, BusinessRefreshBnameLevelData } from './NewFilterContext/utils/types'
import { updateObserverVisibleNodesFromGraph } from './NewFilterContext/utils/updateObserverFunctions'

export type DrillDownStackObjType = {
  drillDownName: keyof typeof allJsonData
  filterObj: FiltersObjType[]
  breadcrumbObj: BreadcrumbsQueueType
}

interface DrillDownContextInterface {
  filtersLoading: boolean
  stack: DrillDownStackObjType[]
  filtersArr: FiltersObjType[]
  lastDate: Moment
  activeGraph: FilterGraph
  setFilterData: (data: FiltersObjType[], drillDownName: keyof typeof allJsonData, breadcrumbLabel: string, stackIndex: number) => void
  setDateRange: (range: { start: Moment; end: Moment }) => void
  setStack: React.Dispatch<React.SetStateAction<DrillDownStackObjType[]>>
  setAllFiltersData: React.Dispatch<React.SetStateAction<ApiFilterResponseType[]>>
  setBusinessRefreshBnameData: React.Dispatch<React.SetStateAction<BusinessRefreshBnameLevelData[]>>
  getDrillDownFilter: (key: string) => string | null
  resetDrillDown: () => void
  getFiltersArr: (stack: DrillDownStackObjType[]) => FiltersObjType[]
  selectFilter: (key: string, valueNodes: FilterNode[]) => void
}

const DrillDownContext = React.createContext({} as DrillDownContextInterface)

export const useDrillDownContext = () => React.useContext(DrillDownContext)

interface DrillDownContextProviderProps {
  children: React.ReactNode
}

export default function DrillDownContextProvider(props: DrillDownContextProviderProps) {
  const [allFiltersData, setAllFiltersData] = React.useState<ApiFilterResponseType[]>(null)
  const [businessRefreshBnameData, setBusinessRefreshBnameData] = React.useState<BusinessRefreshBnameLevelData[]>([])
  const [stack, setStack] = React.useState<DrillDownStackObjType[]>([])
  const [filtersLoading, setFiltersLoading] = React.useState(false)
  const [reRenderState, setReRender] = React.useState(0)

  const reRender = () => {
    setReRender((prev) => prev + 1)
  }

  const { drillDownName } = React.useMemo(() => {
    if (stack.length === 0) {
      return {} as DrillDownStackObjType
    }
    return stack.at(-1)
  }, [stack])

  const getFiltersArr = (stack: DrillDownStackObjType[]) => {
    const result: FiltersObjType[] = []
    for (let i = stack.length - 1; i >= 0; i--) {
      const item = stack[i]
      const { filterObj } = item
      if (filterObj) {
        filterObj.forEach((item) => {
          const hasKey = result.some((e) => e.key === item.key)
          if (!hasKey) {
            result.push(item)
          }
        })
      }
    }
    return result
  }

  const filtersArr = React.useMemo(() => {
    return getFiltersArr(stack)
  }, [stack])

  const activeGraphId = 'drill-down-component-' + drillDownName + '-' + filtersArr.map((e) => e.key).join('-')
  const dateRangeCache = React.useRef<{ start: Moment; end: Moment }>()
  const emptyGraphRef = React.useRef<FilterGraph>(filterGraphManager.getGraph('undefined'))
  const activeGraph = React.useMemo(() => {
    if (!drillDownName) return emptyGraphRef.current
    filterGraphManager.setGraph(activeGraphId, allFiltersData, businessRefreshBnameData)
    const result = filterGraphManager.getGraph(activeGraphId)
    if (dateRangeCache.current) {
      result.setDateRange(dateRangeCache.current)
    }
    return result
  }, [allFiltersData, activeGraphId, businessRefreshBnameData])

  const lastDate = activeGraph.getLastDate()

  const setDateRange = (range: { start: Moment; end: Moment }) => {
    activeGraph.setDateRange(range)
    dateRangeCache.current = range
    reRender()
  }

  const getDrillDownFilter = (key: string) => {
    if (stack.length === 0) {
      return null
    }
    const graphFilter = activeGraph.getFilters()
    if (key in graphFilter) {
      const intersectionSet = activeGraph.getVisibleSelectedIntersectionNodes(key)
      const intersectionArr = Array.from(intersectionSet)
      return intersectionArr.map((e) => e.value).join('|')
    }
    if (key === 'start_date') {
      const dateRange = activeGraph.getDateRange()
      return get(dateRange, 'start', moment()).format('YYYY-MM-DD')
    }
    if (key === 'end_date') {
      const dateRange = activeGraph.getDateRange()
      return get(dateRange, 'end', moment()).format('YYYY-MM-DD')
    }
    return null
  }

  const setFilterData = (data: FiltersObjType[], drillDownName: keyof typeof allJsonData, breadcrumLabel: string, stackIndex: number) => {
    // const jsonData: JSONDataType = get(allJsonData, drillDownName, undefined)
    setStack((prev) => {
      const arr = [...prev]
      const obj: DrillDownStackObjType = {
        drillDownName: drillDownName,
        filterObj: data,
        breadcrumbObj: {
          label: breadcrumLabel,
          onClick: () => {
            setStack((prevStack) => {
              return prevStack.slice(0, stackIndex + 1)
            })
          }
        }
      }
      arr.push(obj)
      return arr
    })
  }

  const selectFilter = (key: string, valueNodes: FilterNode[]) => {
    activeGraph.setSelected(key, valueNodes)
    let foundKey = false
    const arr = ['b_name', 'vb_name', 'vb_platform', 'am_name']
    arr.forEach((secondKey) => {
      if (key === secondKey) {
        foundKey = true
        return
      }
      if (foundKey) {
        updateObserverVisibleNodesFromGraph(secondKey, valueNodes, activeGraph)
      }
    })
  }

  React.useEffect(() => {
    setFiltersLoading(true)
    filtersArr.forEach((filter) => {
      const valueNodes = filter.value
        .map((name) => {
          const allData = activeGraph.getAllPossibleNodesByKey(filter.key)
          return allData.filter((item) => name === item.value.toString())
        })
        .reduce((prev, curr) => [...prev, ...curr])
      selectFilter(filter.key, valueNodes)
    })
    setFiltersLoading(false)
  }, [filtersArr, activeGraph])

  const resetDrillDown = () => {
    setStack([])
  }

  const contextValue = React.useMemo(() => {
    return {
      filtersLoading,
      stack,
      filtersArr,
      lastDate,
      activeGraph,
      setFilterData,
      getFiltersArr,
      setDateRange,
      setStack,
      setAllFiltersData,
      setBusinessRefreshBnameData,
      getDrillDownFilter,
      resetDrillDown,
      selectFilter
    }
  }, [allFiltersData, stack, activeGraph, reRenderState, lastDate])

  return <DrillDownContext.Provider value={contextValue}>{props.children}</DrillDownContext.Provider>
}
