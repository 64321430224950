import LinkIcon from '@mui/icons-material/Link'
import { Box, Tooltip } from '@mui/material'
import React, { useState } from 'react'
import { toast } from 'react-toastify'
import { useFilter } from 'src/context/FilterContext'

const CopyUrlWithFilter = () => {
  const [openSnackbar, setOpenSnackbar] = useState(false)
  const { getUrlWithFilters } = useFilter()

  const handleClose = () => {
    setOpenSnackbar(false)
  }

  React.useEffect(() => {
    if (openSnackbar) toast.info('The URL with filters has been copied to your clipboard.', { onClose: handleClose })
  }, [openSnackbar])

  return (
    <Box
      component="span"
      sx={{ cursor: 'pointer' }}>
      <Tooltip
        title="Copy URl with all the filters"
        arrow
        placement="right"
        sx={{ fontSize: '1rem' }}>
        <LinkIcon
          sx={{ marginLeft: '16px', transform: 'rotate(135deg)' }}
          onClick={() => {
            navigator.clipboard.writeText(getUrlWithFilters())
            setOpenSnackbar(true)
          }}
        />
      </Tooltip>
    </Box>
  )
}

export default CopyUrlWithFilter
