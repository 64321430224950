import { Box, Skeleton } from '@mui/material'
import { border } from 'src/theme/defaultTheme'

export const boxHeight = 540

export default function LoadingAnimation() {
  return (
    <Box
      border={border}
      p={2}
      height={boxHeight}
      display="flex"
      justifyContent="space-between"
      flexDirection="column">
      <Box
        display="flex"
        justifyContent="space-between">
        <div>
          <Skeleton
            variant="text"
            width={100}
            height={40}
          />
          <div style={{ display: 'flex', marginRight: 8 }}>
            <div style={{ marginRight: 8 }}>
              <Skeleton
                variant="text"
                width={100}
                height={40}
              />
              <Skeleton
                variant="text"
                width={100}
                height={40}
              />
            </div>
            <div>
              <Skeleton
                variant="text"
                width={100}
                height={40}
              />
              <Skeleton
                variant="text"
                width={100}
                height={40}
              />
            </div>
          </div>
        </div>
        <div>
          <Skeleton
            variant="text"
            width={100}
            height={40}
            sx={{ mt: 5 }}
          />
          <Skeleton
            variant="text"
            width={100}
            height={40}
          />
          <Skeleton
            variant="text"
            width={100}
            height={40}
          />
        </div>
      </Box>
      <div>
        <div style={{ display: 'flex' }}>
          <Skeleton
            variant="text"
            width={100}
            height={40}
            sx={{ mr: 1 }}
          />
          <Skeleton
            variant="text"
            width={100}
            height={40}
            sx={{ mr: 1 }}
          />
          <Skeleton
            variant="text"
            width={100}
            height={40}
            sx={{ mr: 1 }}
          />
        </div>
        <Skeleton
          variant="rounded"
          width={'100%'}
          height={230}
        />
      </div>
    </Box>
  )
}
