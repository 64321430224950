import { collection, getDocs } from 'firebase/firestore'
import { get } from 'lodash'
import React from 'react'
import { RouteObject } from 'react-router-dom'
import { useAuth } from 'src/context/AuthContext'
import Standalone from 'src/pages/members/Standalone/Standalone'
import { db } from 'src/services/firebase'

export type FieldType = { label: string; field: string; hide: boolean }

export type StandaloneType = {
  label: string
  url: string
  uid: string
  apiMethod: 'GET' | 'POST'
  showFilters: boolean
  exportUrl?: string
  sort: {
    enabled: boolean
    fields?: string[]
  }
  fields?: FieldType[]
}

export default function useStandaloneRouteData() {
  const { currentUser } = useAuth()
  const routes = React.useRef<any[]>()

  const getStandaloneObj = async () => {
    try {
      const docs = await getDocs(collection(db, 'standalone_config'))
      const result: {
        [uid: string]: StandaloneType
      } = {}
      docs.forEach((doc) => {
        let obj = {
          ...doc.data()
        }
        result[obj.key] = obj as any
      })
      return result
    } catch (err) {
      console.error(err)
      return {}
    }
  }

  const getFirebaseRoute = async () => {
    if (routes.current) {
      return routes.current
    }

    const docs = await getDocs(collection(db, 'routes'))

    const result = []
    docs.forEach((doc) => {
      result.push({ ...doc.data(), uid: doc.id })
    })
    routes.current = result
    return result
  }

  const generateStandaloneRoutes = async (navConfigKeyArr: string[]) => {
    const routes = await getFirebaseRoute()
    const standaloneObj = await getStandaloneObj()
    let result: RouteObject[] = []
    for (let i = 0; i < routes.length; i++) {
      const route = routes[i]
      if (!navConfigKeyArr.includes(route.uid) && !currentUser.internal) {
        continue
      }
      if ('subNav' in route && Array.isArray(route['subNav']) && route['subNav'].length > 0) {
        for (let i = 0; i < route.subNav.length; i++) {
          const subNav = route.subNav[i]
          const subNavKey = get(subNav, 'key', undefined)
          if (!subNavKey) {
            continue
          }
          const isAbsolute = get(subNav, 'key[0]', '') === '/'
          const key = isAbsolute ? subNavKey.slice(1) : subNavKey
          const obj = standaloneObj[key]
          if (obj) {
            result.push({
              path: isAbsolute ? key : `${get(route, 'key', '')}/${key}`,
              element: (
                <Standalone
                  title={isAbsolute ? get(subNav, 'label', '') : `${get(route, 'label', '')} - ${get(subNav, 'label', '')}`}
                  {...obj}
                />
              )
            })
          }
        }
      } else {
        const key = get(route, 'key', undefined)
        if (!key) {
          continue
        }
        const obj = standaloneObj[key]
        if (obj) {
          result.push({
            path: key,
            element: (
              <Standalone
                key={key}
                title={get(route, 'label', '')}
                {...obj}
              />
            )
          })
        }
      }
    }
    return result
  }

  React.useEffect(() => {
    getStandaloneObj()
  }, [])

  return {
    generateStandaloneRoutes,
    getFirebaseRoute
  }
}
