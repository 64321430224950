import { Box, SxProps, Typography } from '@mui/material'
import { get } from 'lodash'
import React, { useMemo } from 'react'
import PlatformLogo from 'src/components/PlatformLogo'
import StyledTypo from 'src/components/StyledTypo'
import { useFilter } from 'src/context/FilterContext'
import { useNewFilterContext } from 'src/context/NewFilterContext/NewFilterContext'
import { Dictionary } from 'src/utils/config/dictionary'
import { formatCurrency, formatNumber } from 'src/utils/functions'
import DataComp from '../../../components/DataComp'
import { indicatorColor, rowFlex } from '../../../utils/constants'
import { TransactionsPlatformBreakdownInterface } from '../../../utils/context/TransactionsBreakdownDataContext'
import { OrderBreakdownResponse } from '../../../utils/types'

interface PlatformBreakdownProps {
  platformBreakdownData: TransactionsPlatformBreakdownInterface
  platformBreakdownLoading?: boolean
  breakdownData?: OrderBreakdownResponse
  breakdownLoading?: boolean
  sx?: SxProps
}

export const BreakdownIndex = ({ label }: { label: string }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        margin: '0 5px'
      }}>
      <Box
        sx={{
          borderRadius: '8px',
          height: '50px',
          marginBottom: '10px',
          border: '1px solid #E6E6E6',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}>
        <StyledTypo
          fs={14}
          fw={400}
          sx={{ display: 'flex', justifyContent: 'center' }}>
          {label}
        </StyledTypo>
      </Box>

      <DataComp
        sx={{ px: '10px' }}
        rows={[
          {
            hideIndicator: true,
            sx: { height: '35px' },
            cols: [
              {
                label: 'Sales Incl. Tax',
                flex: rowFlex[2],
                sx: {
                  py: 0,
                  fontSize: '14px'
                },
                tooltip: 'Total revenue generated from orders, including taxes charged to customers.'
              }
            ]
          },
          {
            hideIndicator: true,
            sx: { height: '35px' },
            cols: [
              {
                label: 'Sales Excl. Tax',
                flex: rowFlex[2],
                sx: {
                  py: 0,
                  fontSize: '14px',
                  fontWeight: 400
                },
                tooltip: 'Total revenue generated from orders, excluding any taxes charged to customers.'
              }
            ]
          }
        ]}
      />

      <DataComp
        sx={{ mt: '10px', py: '10px', px: '10px' }}
        rows={[
          {
            hideIndicator: true,
            cols: [
              {
                label: 'Lost Sales',
                flex: rowFlex[2],
                sx: { color: 'rgba(47, 74, 90, 0.6)', fontSize: '14px', fontWeight: 400 },
                tooltip: `Potential revenue lost due to ${Dictionary.CANCELLED.toLowerCase()} or failed orders.`
              }
            ]
          }
        ]}
      />

      <DataComp
        sx={{ mt: '10px', px: '10px' }}
        rows={[
          {
            indicatorColor: indicatorColor.taxes,
            sx: { borderBottom: '1px solid rgba(230, 230, 230, 1)', height: '40px' },
            cols: [
              {
                label: 'Taxes',
                flex: rowFlex[0],
                sx: {
                  fontSize: '14px'
                },
                tooltip: 'Taxes collected from customers and paid to you by 3P platforms.'
              }
            ]
          },
          {
            sx: {
              mt: '15px',
              borderRadius: '4px',
              height: '40px'
            },
            cols: [
              {
                label: 'Taxes Withheld',
                flex: rowFlex[0],
                sx: { color: 'rgba(47, 74, 90, 0.6)', fontSize: '14px', fontWeight: 400 },
                tooltip: 'Taxes collected from customers and held by 3P platforms to pay to tax authorities on your behalf.'
              }
            ]
          }
        ]}
      />

      <DataComp
        sx={{ mt: '10px', py: '10px', px: '10px' }}
        rows={[
          {
            indicatorColor: indicatorColor.commissions,
            sx: { height: '40px' },
            cols: [
              {
                label: 'Commissions',
                flex: rowFlex[0],
                sx: {
                  fontSize: '14px'
                },
                tooltip: 'Fees charged by the platform for facilitating orders.'
              }
            ]
          }
        ]}
      />

      <DataComp
        sx={{ mt: '10px', py: '10px', px: '10px' }}
        rows={[
          {
            indicatorColor: indicatorColor.restaurant_delivery_charge,
            sx: { height: '40px' },
            cols: [
              {
                label: 'Rest. Delivery Charge',
                flex: rowFlex[0],
                sx: {
                  fontSize: '14px'
                },
                tooltip: 'Charges collected from customers for restaurant delivery.'
              }
            ]
          }
        ]}
      />

      <DataComp
        sx={{ mt: '10px', py: '10px', px: '10px' }}
        rows={[
          {
            indicatorColor: indicatorColor.marketing,
            sx: { height: '40px' },
            cols: [
              {
                label: 'Marketing',
                flex: rowFlex[0],
                sx: {
                  fontSize: '14px'
                },
                tooltip: 'Costs associated with promoting the restaurant on the platform.'
              }
            ]
          }
        ]}
      />

      <DataComp
        sx={{ mt: '10px', py: '10px', px: '10px' }}
        rows={[
          {
            indicatorColor: indicatorColor.chargebacks,
            sx: { borderBottom: '1px solid rgba(230, 230, 230, 1)', height: '40px' },
            cols: [
              {
                label: 'Chargebacks',
                flex: rowFlex[0],
                sx: {
                  fontSize: '14px'
                },
                tooltip: 'Amount refunded to customers due to disputes or issues with orders.'
              }
            ]
          },
          {
            indicatorColor: indicatorColor.reversals,
            sx: { height: '40px' },
            cols: [
              {
                label: 'Reversals',
                sx: {
                  color: 'rgba(47, 74, 90, 0.6)',
                  fontWeight: 400,
                  fontSize: '14px'
                },
                flex: rowFlex[0],

                tooltip: 'Amount reversed from previous transactions.'
              }
            ]
          }
        ]}
      />

      <DataComp
        sx={{ mt: '10px', py: '10px', px: '10px' }}
        rows={[
          {
            indicatorColor: indicatorColor.unaccounted,
            sx: { borderBottom: '1px solid rgba(230, 230, 230, 1)', height: '40px' },
            cols: [
              {
                label: 'Others',
                flex: rowFlex[0],
                sx: {
                  fontSize: '14px'
                }
              }
            ]
          },
          {
            hideIndicator: true,
            sx: { mt: '15px', height: '30px' },
            cols: [
              {
                label: 'Customer Tip',
                flex: rowFlex[0],
                sx: {
                  color: 'rgba(47, 74, 90, 0.6)',
                  fontWeight: 400,
                  fontSize: '13px'
                },
                tooltip: 'Additional amounts paid by customers as tips for service.'
              }
            ]
          },
          {
            hideIndicator: true,
            sx: { height: '30px' },
            cols: [
              {
                label: 'Miscellaneous',
                flex: rowFlex[0],
                sx: {
                  color: 'rgba(47, 74, 90, 0.6)',
                  fontWeight: 400,
                  fontSize: '13px'
                },
                tooltip: 'Various fees or charges that do not fit into specific categories.'
              }
            ]
          },
          {
            hideIndicator: true,
            sx: { height: '30px' },
            cols: [
              {
                label: 'Unaccounted',
                flex: rowFlex[0],
                sx: {
                  color: 'rgba(47, 74, 90, 0.6)',
                  fontWeight: 400,
                  fontSize: '13px'
                },
                tooltip: 'Amounts that are not allocated or categorized, often requiring further investigation or clarification.'
              }
            ]
          }
        ]}
      />

      <DataComp
        sx={{ mt: '10px', py: '10px', px: '10px' }}
        rows={[
          {
            hideIndicator: true,
            sx: { height: '40px' },
            cols: [
              {
                label: 'Estimated Payout',
                flex: rowFlex[0],
                sx: {
                  fontSize: '14px'
                }
              }
            ]
          }
        ]}
      />
    </Box>
  )
}

export const BreakdownByPlatform = ({
  label,
  data,
  image,
  fw,
  platformBreakdownLoading
}: {
  label: string
  data: OrderBreakdownResponse
  image?: string
  fw?: number
  platformBreakdownLoading: boolean
}) => {
  const otherTotal = useMemo(() => {
    return get(data, 'values.customer_tip', 0) + get(data, 'values.miscellaneous', 0) + get(data, 'values.unaccounted', 0)
  }, [data])

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        margin: '0 5px'
      }}>
      <Box
        sx={{
          borderRadius: '8px',
          height: '50px',
          marginBottom: '10px',
          border: '1px solid #E6E6E6',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}>
        {image && (
          <PlatformLogo
            platformName={image}
            shortImg
            width="24px"
            height="24px"
            boxSx={{ marginRight: '5px', flex: '0 !important', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
          />
        )}
        <StyledTypo
          fs={14}
          fw={fw ? fw : 400}
          sx={{ display: 'flex', justifyContent: 'center' }}>
          {label}
        </StyledTypo>
      </Box>

      <DataComp
        sx={{ bgcolor: label === 'Total' ? 'rgba(47, 74, 90, 1)' : '#FFFFFF', px: '10px' }}
        rows={[
          {
            hideIndicator: true,
            sx: { height: '35px' },
            cols: [
              {
                label: (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      flexWrap: 'wrap',
                      flexDirection: 'row-reverse',
                      gap: '10px'
                    }}>
                    <Typography
                      sx={{
                        fontSize: '16px',
                        fontWeight: 600,
                        lineHeight: 'initial',
                        color: label === 'Total' ? '#FFFFFF' : '#09234B'
                      }}>
                      {formatCurrency(get(data, 'values.subtotal_with_tax', 0), { maxFractionDigits: 2 })}
                    </Typography>
                    <Box
                      display="flex"
                      justifyContent="flex-end">
                      <Typography sx={{ bgcolor: '#E3EDF0', color: '#196E82', p: '4px 7px', lineHeight: 'initial', borderRadius: '14px', fontSize: '11px' }}>
                        {formatNumber(get(data, 'percentages.subtotal_with_tax', 0), { maxFractionDigits: 2 })}%
                      </Typography>
                    </Box>
                  </Box>
                ),
                flex: rowFlex[2],
                loading: platformBreakdownLoading,
                loadingSx: {
                  width: '100%'
                },
                sx: {
                  py: 0,
                  color: 'white',
                  textAlign: 'right',
                  display: 'block'
                }
              }
            ]
          },
          {
            hideIndicator: true,
            sx: { height: '35px' },
            cols: [
              {
                label: (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      flexWrap: 'wrap',
                      flexDirection: 'row-reverse',
                      gap: '10px'
                    }}>
                    <Typography
                      sx={{
                        fontSize: '16px',
                        fontWeight: 600,
                        lineHeight: 'initial',
                        color: label === 'Total' ? '#FFFFFF' : '#09234B'
                      }}>
                      {formatCurrency(get(data, 'values.subtotal', 0), { maxFractionDigits: 2 })}
                    </Typography>
                    <Box
                      display="flex"
                      justifyContent="flex-end">
                      <Typography
                        sx={{
                          bgcolor: label === 'Total' ? '#E3EDF0' : '#196E821F',
                          color: '#196E82',
                          p: '4px 7px',
                          lineHeight: 'initial',
                          borderRadius: '14px',
                          fontSize: '11px'
                        }}>
                        {formatNumber(get(data, 'percentages.subtotal', 0), { maxFractionDigits: 2 })}%
                      </Typography>
                    </Box>
                  </Box>
                ),
                flex: rowFlex[2],
                loading: platformBreakdownLoading,
                loadingSx: {
                  width: '100%'
                },
                sx: {
                  py: 0,
                  color: label === 'Total' ? 'rgba(255, 255, 255, 0.5)' : 'rgba(47, 74, 90, 0.6)',
                  textAlign: 'right',
                  display: 'block'
                }
              }
            ]
          }
        ]}
      />

      <DataComp
        sx={{ mt: '10px', py: '10px', px: '10px', height: '42.5px' }}
        rows={[
          {
            hideIndicator: true,
            cols: [
              {
                label: (
                  <>
                    <span
                      style={{
                        color: 'rgba(47, 74, 90, 0.6)',
                        fontSize: '16px',
                        lineHeight: 'initial',
                        fontWeight: 600
                      }}>
                      {formatCurrency(get(data, 'values.lost_sales', 0), { maxFractionDigits: 2 })}
                    </span>
                  </>
                ),
                loading: platformBreakdownLoading,
                loadingSx: {
                  width: '100%'
                },
                flex: rowFlex[2],
                sx: { textAlign: 'right', display: 'block' }
              }
            ]
          }
        ]}
      />

      <DataComp
        sx={{ mt: '10px', px: '10px' }}
        rows={[
          {
            hideIndicator: true,
            sx: { borderBottom: '1px solid rgba(230, 230, 230, 1)', height: '40px' },
            cols: [
              {
                label: (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      flexWrap: 'wrap',
                      flexDirection: 'row-reverse',
                      gap: '10px'
                    }}>
                    <Typography
                      sx={{
                        fontSize: '16px',
                        fontWeight: 600,
                        lineHeight: 'initial',
                        color: 'rgba(15, 120, 60, 1)'
                      }}>
                      +{formatCurrency(get(data, 'values.tax_host', 0), { maxFractionDigits: 2 })}
                    </Typography>
                    <Box
                      display="flex"
                      justifyContent="flex-end">
                      <Typography
                        sx={{
                          bgcolor: '#196E821F',
                          color: '#196E82',
                          p: '4px 7px',
                          lineHeight: 'initial',
                          borderRadius: '14px',
                          fontSize: '11px'
                        }}>
                        {formatNumber(get(data, 'percentages.tax_host', 0), { maxFractionDigits: 2 })}%
                      </Typography>
                    </Box>
                  </Box>
                ),
                loading: platformBreakdownLoading,
                loadingSx: {
                  width: '100%'
                },
                flex: rowFlex[2],
                sx: { textAlign: 'right', display: 'block' }
              }
            ]
          },
          {
            hideIndicator: true,
            sx: {
              mt: '15px',
              borderRadius: '8px',
              border: '1px solid #E6E6E6E6',
              height: '40px'
            },
            cols: [
              {
                label: (
                  <>
                    <span
                      style={{
                        color: 'rgba(47, 74, 90, 0.6)',
                        fontSize: '16px',
                        lineHeight: 'initial',
                        fontWeight: 600
                      }}>
                      {formatCurrency(get(data, 'values.tax_witheld', 0), { maxFractionDigits: 2 })}
                    </span>
                  </>
                ),
                loading: platformBreakdownLoading,
                loadingSx: {
                  width: '100%'
                },
                flex: rowFlex[2],
                sx: { textAlign: 'right', display: 'block' }
              }
            ]
          }
        ]}
      />

      <DataComp
        sx={{ mt: '10px', py: '10px', px: '10px' }}
        rows={[
          {
            hideIndicator: true,
            sx: { height: '40px' },
            cols: [
              {
                label: (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      flexWrap: 'wrap',
                      flexDirection: 'row-reverse',
                      gap: '10px'
                    }}>
                    <Typography
                      sx={{
                        fontSize: '16px',
                        fontWeight: 600,
                        lineHeight: 'initial',
                        color: 'rgba(241, 84, 18, 1)'
                      }}>
                      -{formatCurrency(get(data, 'values.commission', 0), { maxFractionDigits: 2 })}
                    </Typography>
                    <Box
                      display="flex"
                      justifyContent="flex-end">
                      <Typography
                        sx={{
                          bgcolor: '#196E821F',
                          color: '#196E82',
                          p: '4px 7px',
                          lineHeight: 'initial',
                          borderRadius: '14px',
                          fontSize: '11px'
                        }}>
                        {formatNumber(get(data, 'percentages.commission', 0), { maxFractionDigits: 2 })}%
                      </Typography>
                    </Box>
                  </Box>
                ),
                loading: platformBreakdownLoading,
                loadingSx: {
                  width: '100%'
                },
                flex: rowFlex[2],
                sx: { textAlign: 'right', display: 'block' }
              }
            ]
          }
        ]}
      />

      <DataComp
        sx={{ mt: '10px', py: '10px', px: '10px' }}
        rows={[
          {
            hideIndicator: true,
            sx: { height: '40px' },
            cols: [
              {
                label: (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      flexWrap: 'wrap',
                      flexDirection: 'row-reverse',
                      gap: '10px'
                    }}>
                    <Typography
                      sx={{
                        fontSize: '16px',
                        fontWeight: 600,
                        lineHeight: 'initial',
                        color: 'rgba(15, 120, 60, 1)'
                      }}>
                      +{formatCurrency(get(data, 'values.restaurant_delivery_charge', 0), { maxFractionDigits: 2 })}
                    </Typography>
                    <Box
                      display="flex"
                      justifyContent="flex-end">
                      <Typography
                        sx={{
                          bgcolor: '#196E821F',
                          color: '#196E82',
                          p: '4px 7px',
                          lineHeight: 'initial',
                          borderRadius: '14px',
                          fontSize: '11px'
                        }}>
                        {formatNumber(get(data, 'percentages.restaurant_delivery_charge', 0), { maxFractionDigits: 2 })}%
                      </Typography>
                    </Box>
                  </Box>
                ),
                loading: platformBreakdownLoading,
                loadingSx: {
                  width: '100%'
                },
                flex: rowFlex[2],
                sx: { textAlign: 'right', display: 'block' }
              }
            ]
          }
        ]}
      />

      <DataComp
        sx={{ mt: '10px', py: '10px', px: '10px' }}
        rows={[
          {
            hideIndicator: true,
            sx: { height: '40px' },
            cols: [
              {
                label: (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      flexWrap: 'wrap',
                      flexDirection: 'row-reverse',
                      gap: '10px'
                    }}>
                    <Typography
                      sx={{
                        fontSize: '16px',
                        fontWeight: 600,
                        lineHeight: 'initial',
                        color: 'rgba(241, 84, 18, 1)'
                      }}>
                      -{formatCurrency(get(data, 'values.marketing', 0), { maxFractionDigits: 2 })}
                    </Typography>
                    <Box
                      display="flex"
                      justifyContent="flex-end">
                      <Typography
                        sx={{
                          bgcolor: '#196E821F',
                          color: '#196E82',
                          p: '4px 7px',
                          lineHeight: 'initial',
                          borderRadius: '14px',
                          fontSize: '11px'
                        }}>
                        {formatNumber(get(data, 'percentages.marketing', 0), { maxFractionDigits: 2 })}%
                      </Typography>
                    </Box>
                  </Box>
                ),
                loading: platformBreakdownLoading,
                loadingSx: {
                  width: '100%'
                },
                flex: rowFlex[2],
                sx: { textAlign: 'right', display: 'block' }
              }
            ]
          }
        ]}
      />

      <DataComp
        sx={{ mt: '10px', py: '10px', px: '10px' }}
        rows={[
          {
            hideIndicator: true,
            sx: { height: '40px', borderBottom: '1px solid rgba(230, 230, 230, 1)' },
            cols: [
              {
                label: (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      flexWrap: 'wrap',
                      flexDirection: 'row-reverse',
                      gap: '10px'
                    }}>
                    <Typography
                      sx={{
                        fontSize: '16px',
                        fontWeight: 600,
                        lineHeight: 'initial',
                        color: 'rgba(241, 84, 18, 1)'
                      }}>
                      -{formatCurrency(get(data, 'values.chargeback', 0), { maxFractionDigits: 2 })}
                    </Typography>
                    <Box
                      display="flex"
                      justifyContent="flex-end">
                      <Typography
                        sx={{
                          bgcolor: '#196E821F',
                          color: '#196E82',
                          p: '4px 7px',
                          lineHeight: 'initial',
                          borderRadius: '14px',
                          fontSize: '11px'
                        }}>
                        {formatNumber(get(data, 'percentages.chargeback', 0), { maxFractionDigits: 2 })}%
                      </Typography>
                    </Box>
                  </Box>
                ),
                loading: platformBreakdownLoading,
                loadingSx: {
                  width: '100%'
                },
                flex: rowFlex[2],
                sx: { textAlign: 'right', display: 'block' }
              }
            ]
          },
          {
            hideIndicator: true,
            sx: { height: '40px' },
            cols: [
              {
                label: (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      flexWrap: 'wrap',
                      flexDirection: 'row-reverse',
                      gap: '10px'
                    }}>
                    <Typography
                      sx={{
                        fontSize: '16px',
                        fontWeight: 600,
                        lineHeight: 'initial',
                        color: 'rgba(15, 120, 60, 1)'
                      }}>
                      +{formatCurrency(get(data, 'values.reversal', 0), { maxFractionDigits: 2 })}
                    </Typography>
                    <Box
                      display="flex"
                      justifyContent="flex-end">
                      <Typography
                        sx={{
                          bgcolor: '#196E821F',
                          color: '#196E82',
                          p: '4px 7px',
                          lineHeight: 'initial',
                          borderRadius: '14px',
                          fontSize: '11px'
                        }}>
                        {formatNumber(get(data, 'percentages.reversal', 0), { maxFractionDigits: 2 })}%
                      </Typography>
                    </Box>
                  </Box>
                ),
                loading: platformBreakdownLoading,
                loadingSx: {
                  width: '100%'
                },
                flex: rowFlex[2],
                sx: { textAlign: 'right', display: 'block' }
              }
            ]
          }
        ]}
      />

      <DataComp
        sx={{ mt: '10px', py: '10px', px: '10px' }}
        rows={[
          {
            hideIndicator: true,
            sx: { borderBottom: '1px solid rgba(230, 230, 230, 1)', height: '40px' },
            cols: [
              {
                label: (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      flexWrap: 'wrap',
                      flexDirection: 'row-reverse',
                      gap: '10px'
                    }}>
                    <Typography
                      sx={{
                        fontSize: '16px',
                        fontWeight: 600,
                        lineHeight: 'initial',
                        color: otherTotal > 0 ? 'rgba(15, 120, 60, 1)' : 'rgba(241, 84, 18, 1)'
                      }}>
                      {formatCurrency(otherTotal, { maxFractionDigits: 2 })}
                    </Typography>
                    <Box
                      display="flex"
                      justifyContent="flex-end">
                      <Typography
                        sx={{
                          bgcolor: '#196E821F',
                          color: '#196E82',
                          p: '4px 7px',
                          lineHeight: 'initial',
                          borderRadius: '14px',
                          fontSize: '11px'
                        }}>
                        {formatNumber(Math.abs((otherTotal * 100) / get(data, 'values.subtotal_with_tax', 0)), { maxFractionDigits: 2 })}%
                      </Typography>
                    </Box>
                  </Box>
                ),
                loading: platformBreakdownLoading,
                loadingSx: {
                  width: '100%'
                },
                flex: rowFlex[2],
                sx: { textAlign: 'right', display: 'block' }
              }
            ]
          },
          {
            hideIndicator: true,
            sx: { mt: '15px', height: '30px' },
            cols: [
              {
                label: '+' + formatCurrency(get(data, 'values.customer_tip', 0), { maxFractionDigits: 2 }),
                flex: rowFlex[2],
                loading: platformBreakdownLoading,
                loadingSx: {
                  width: '100%'
                },
                sx: {
                  textAlign: 'right',
                  display: 'block',
                  color: 'rgba(14, 140, 67, 1)',
                  fontSize: '14px'
                }
              }
            ]
          },
          {
            hideIndicator: true,
            sx: { height: '30px' },
            cols: [
              {
                label: (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      flexDirection: 'row-reverse',
                      justifyContent: 'flex-start',
                      flexWrap: 'wrap',
                      gap: '5px'
                    }}>
                    <span>
                      {(get(data, 'values.miscellaneous', 0) >= 0 ? '+' : '-') +
                        formatCurrency(Math.abs(get(data, 'values.miscellaneous', 0)), { maxFractionDigits: 2 })}
                    </span>
                  </Box>
                ),
                flex: rowFlex[2],
                loading: platformBreakdownLoading,
                loadingSx: {
                  width: '100%'
                },
                sx: {
                  textAlign: 'right',
                  display: 'block',
                  color: get(data, 'values.miscellaneous', 0) >= 0 ? 'rgba(14, 140, 67, 1)' : 'rgba(241, 84, 18, 1)',
                  fontSize: '14px'
                }
              }
            ]
          },
          {
            hideIndicator: true,
            sx: { height: '30px' },
            cols: [
              {
                label: (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      flexDirection: 'row-reverse',
                      justifyContent: 'flex-start',
                      flexWrap: 'wrap',
                      gap: '5px'
                    }}>
                    <span>
                      {(get(data, 'values.unaccounted', 0) >= 0 ? '+' : '-') +
                        formatCurrency(Math.abs(get(data, 'values.unaccounted', 0)), { maxFractionDigits: 2 })}
                    </span>
                  </Box>
                ),
                flex: rowFlex[2],
                loading: platformBreakdownLoading,
                loadingSx: {
                  width: '100%'
                },
                sx: {
                  textAlign: 'right',
                  display: 'block',
                  color: get(data, 'values.unaccounted', 0) >= 0 ? 'rgba(14, 140, 67, 1)' : 'rgba(241, 84, 18, 1)',
                  fontSize: '14px'
                }
              }
            ]
          }
        ]}
      />

      <DataComp
        sx={{ bgcolor: label === 'Total' ? 'rgba(47, 74, 90, 1)' : '#FFFFFF', mt: '10px', py: '10px', px: '10px' }}
        rows={[
          {
            hideIndicator: true,
            sx: { height: '40px' },
            cols: [
              {
                label: (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      flexWrap: 'wrap',
                      flexDirection: 'row-reverse',
                      gap: '10px'
                    }}>
                    <Typography
                      sx={{
                        fontSize: '16px',
                        fontWeight: 600,
                        lineHeight: 'initial',
                        color: label === 'Total' ? '#FFFFFF' : '#09234B'
                      }}>
                      {formatCurrency(get(data, 'values.total_payment', 0), { maxFractionDigits: 2 })}
                    </Typography>
                    <Box
                      display="flex"
                      justifyContent="flex-end">
                      <Typography sx={{ bgcolor: '#E3EDF0', color: '#196E82', p: '4px 7px', lineHeight: 'initial', borderRadius: '14px', fontSize: '11px' }}>
                        {formatNumber(get(data, 'percentages.total_payment', 0), { maxFractionDigits: 2 })}%
                      </Typography>
                    </Box>
                  </Box>
                ),
                flex: rowFlex[2],
                loading: platformBreakdownLoading,
                loadingSx: {
                  width: '100%'
                },
                sx: { textAlign: 'right', display: 'block' }
              }
            ]
          }
        ]}
      />
    </Box>
  )
}

const PlatformBreakdown = React.forwardRef<HTMLElement, PlatformBreakdownProps>((props, componentRef) => {
  const { platformBreakdownData, platformBreakdownLoading, breakdownData, breakdownLoading, sx } = props
  const { getAllPossibleFilters } = useNewFilterContext()
  const { getFilters, getFiltersV2 } = useFilter()

  const platforms = React.useMemo(() => {
    const vb_platform_in = getFiltersV2(['vb_platform'])?.vb_platform_in

    if (vb_platform_in === '') return getAllPossibleFilters(['vb_platform']).vb_platform as string[]

    return vb_platform_in?.split('|')
  }, [...getFilters(['vb_platform'])])

  return (
    <Box
      sx={{ display: 'flex', marginTop: '20px', ...sx }}
      ref={componentRef}>
      <BreakdownIndex label="Component" />

      {platforms?.map((platform, index) => {
        return (
          <BreakdownByPlatform
            key={platform + index}
            label={platform}
            image={platform}
            data={platformBreakdownData[platform.toLowerCase()]}
            platformBreakdownLoading={platformBreakdownLoading}
          />
        )
      })}

      <BreakdownByPlatform
        label="Total"
        fw={600}
        data={breakdownData}
        platformBreakdownLoading={breakdownLoading}
      />
    </Box>
  )
})

export default PlatformBreakdown
