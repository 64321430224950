import { addDoc, collection } from 'firebase/firestore'
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage'
import { db, storage } from 'src/services/firebase'

const assetConfigName = 'asset_config'

export type AssetType = {
  uid: string
  label: string
  url: string
  type: string
  tags?: string[]
}

export async function uploadFile(type: string, file: File) {
  const storageRef = ref(storage, `${type}/${file.name}`)
  const uploadTask = await uploadBytes(storageRef, file)
  const url = await getDownloadURL(uploadTask.ref)
  return url
}

export function convertImageToFile(imageDataUrl: string, fileName: string) {
  const [header, imageBase64] = imageDataUrl.split(',')

  const mime = header.match(/:(.*?);/)[1]
  const byteCharacters = atob(imageBase64)
  const byteNumbers = Array.from(byteCharacters, (char) => char.charCodeAt(0))
  const byteArray = new Uint8Array(byteNumbers)

  const blob = new Blob([byteArray], { type: mime })

  return new File([blob], fileName, { type: mime })
}

export async function addImageAsset(asset: Omit<Omit<AssetType, 'uid'>, 'url'>, imageDataUrl: string, fileName?: string): Promise<string> {
  try {
    const file = convertImageToFile(imageDataUrl, fileName)
    const url = await uploadFile(asset.type, file)

    const obj = { ...asset, url }

    await addDoc(collection(db, assetConfigName), obj)
    return obj.url
  } catch (err) {
    throw new Error(err.message)
  }
}

export async function addAsset(asset: Omit<Omit<AssetType, 'uid'>, 'url'>, file: File): Promise<string> {
  try {
    const url = await uploadFile(asset.type, file)
    const obj = { ...asset, url }

    await addDoc(collection(db, assetConfigName), obj)
    return obj.url
  } catch (err) {
    throw new Error(err.message)
  }
}
