import React from 'react'

export default function useValidateLocalData() {
    const storageDate = localStorage.getItem( 'storageDate' )
    const [localData, setLocalData] = React.useState( !!storageDate )
    // const [loading, setLoading]= React.useState(true)
    const refreshNeeded = React.useMemo( () => {
        if ( storageDate ) {
            if (new Date().getDate() !== new Date(storageDate).getDate()) {
                return true
            }
            else if (new Date().getDate() === new Date(storageDate).getDate()) {
                return false
            }
        }
        return false
    }, [storageDate] )

    return {
        refreshNeeded, localData, setLocalData
    }
}
